import React from "react";
import TimeOffCardsComponent from "../../components/timeOffCards";

export default function TimeOffCardsPage() {
  return (
    <div>
      <TimeOffCardsComponent />
    </div>
  );
}
