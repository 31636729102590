import React from "react";
import TwoFactorAuthComponent from "../../components/TwoFactorAuth";

export default function TwoFactorPage() {
  return (
    <div>
      <TwoFactorAuthComponent />
    </div>
  );
}
