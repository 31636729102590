import { Form } from "react-bootstrap";
import moment from "moment";
import { FiHelpCircle } from "react-icons/fi";
import Tooltip from "rc-tooltip";
import "rc-tooltip/assets/bootstrap.css";
export default function ViewInput({ value, label, type }) {
  const renderTooltip = (
    <div>
      The last date of the first pay period to help calculate future pay
      periods. This can be the same date as the first pay date.
    </div>
  );
  return (
    <Form.Group className="mb-3" controlId="type">
      <Form.Label>{label}</Form.Label>
      {label === "First pay period end date" && (
        <Tooltip
          placement="left"
          overlay={renderTooltip}
          overlayClassName="pay-tooltip"
        >
          <FiHelpCircle className="help-circle" />
        </Tooltip>
      )}
      <Form.Control
        required
        type="input"
        value={type === "date" ? moment(value).format("MM-DD-YYYY") : value}
        disabled={true}
        autoComplete="off"
      />
    </Form.Group>
  );
}
