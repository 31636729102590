import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../Loader";
import "rc-tooltip/assets/bootstrap.css";
import "./style.scss";
import ModalDetail from "./modalDetail";
import { postData } from "../../api";
import { toast } from "react-toastify";
import { autocloseTiming } from "../../api/regex";
import moment from "moment";
import ViewModal from "./viewModal";
import AddModal from "./addModal";
import HolidayModal from "./holidayModal";
import ModalHolidayDetail from "./modalHolidayDetail";
import EmployeeDetails from "./employeeDetails";

export default function MultiCardDetailComponent() {
  const location = useLocation();
  const navigate = useNavigate();
  const row = location.state?.row;

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const userId = searchParams.get("profileId");
    const apiPageCount = searchParams.get("page");
    const urlPtoStatus = searchParams.get("status");

    if (userId) setUrlUserId(parseInt(userId));
    setApiCusPageCount(apiPageCount ? parseInt(apiPageCount) : 0);
    setPtoStatus(urlPtoStatus || "all");
    handleGetUserInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [, setLoading] = useState(false);
  const [employeeHoursData, setEmployeeHoursData] = useState([]);
  const [ptoStatus, setPtoStatus] = useState("all");
  const [groupId, setGroupId] = useState(0);
  const [ptoPendingData, setPtoPendingData] = useState([]);
  const [ptoDeniedData, setPtoDeniedData] = useState([]);
  const [ptoApprovedData, setPtoApprovedData] = useState([]);
  const [checkedValues, setCheckedValues] = useState([]);
  const [, setAllChecked] = useState(false);
  const [allIds, setAllIds] = useState([]);
  const [delModalOpen, setDelModalOpen] = useState(false);
  const [modalText, setModalText] = useState("");
  const [approveDeny, setApproveDeny] = useState(false);
  const [editAddEntry, setEditAddEntry] = useState(false);
  const [filteredEmployeeData, setFilteredEmployeeData] = useState([]);
  const [totalHoursData, setTotalHoursData] = useState({});

  const [modalOpen, setModalOpen] = useState(false);
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [holidayModal, setHolidayModal] = useState(false);

  const [modalIndividualData, setModalIndividualData] = useState({});
  const [addModalIndividualData, setAddModalIndividualData] = useState();
  const [holidayIndividualdata, setHolidayIndividualdata] = useState({});
  const [frequencyId, setFrequencyId] = useState({});
  const initialModalInputData = {
    timeIn: "",
    timeOut: "",
    totalHours: "",
    category: { value: "", label: "Select Category" },
    notes: "",
    jobLocation: { value: "", label: "Select Location" },
    jobCode: { value: "", label: "Select Code" },
  };
  const [modalInputData, setModalInputData] = useState(initialModalInputData);
  const [editModalInputData, setEditModalInputData] = useState(
    initialModalInputData
  );
  const [editModalHolidayData, setEditModalHolidayData] = useState({
    totalHours: "",
    category: { value: "", label: "Select Category" },
    notes: "",
    createdAt: "",
    createdBy: "",
  });
  const [policyOptions, setPolicyOptions] = useState([]);
  const [addModalErrors, setAddModalErrors] = useState({});
  const [btnLoading, setBtnLoading] = useState(false);
  const [calcTotalhrs, setCalcTotalhrs] = useState("");
  const [getUserId, setGetUserId] = useState(0);
  const [dateRangeData, setDateRangeData] = useState("");
  const [btnDelLoading, setBtnDelLoading] = useState(false);
  const [apiCusPageCount, setApiCusPageCount] = useState(0);
  const [holidayDelModal, setHolidayDelModal] = useState(false);

  const [, setPtoRequestId] = useState([]);
  const [regularAllId, setRegularAllId] = useState([]);
  const [approveText, setApproveText] = useState("");
  const [modalDiff, setModalDiff] = useState(false);
  const [calcEditTotalhrs, setCalcEditTotalhrs] = useState("");
  const [urlUserId, setUrlUserId] = useState(0);

  const [clockOutModal, setClockOutModal] = useState(false);
  const [leftArrowDisable, setLeftArrowDisable] = useState(false);

  const [employeeOptions, setEmployeeOptions] = useState([]);

  const [selectedEmployee, setSelectedEmployee] = useState({
    value: row?.name ? row?.name : totalHoursData?.emp_data?.name,
    label: row?.name ? row?.name : totalHoursData?.emp_data?.name,
  });

  const [jobLocation, setJobLocation] = useState({
    options: [],
    code: [],
  });
  const [selectedAllIds, setSelectedAllIds] = useState(false);
  const [hasCalledAPI, setHasCalledAPI] = useState(false);
  const [apiCallsInProgress, setApiCallsInProgress] = useState(0);
  const [, setIsInputChanged] = useState(false);
  const resetModalInputData = () => {
    setModalInputData(initialModalInputData);
  };

  const handleEmployeeChange = (selectedOption) => {
    setSelectedEmployee(selectedOption);
    setPtoStatus("all");
    const url = `/multi-card-detail?profileId=${selectedOption.value}&page=0&status=all`;
    navigate(url);
    setUrlUserId(selectedOption.value);
    window.location.reload();
  };
  const handleApiStart = () => {
    setApiCallsInProgress((prev) => prev + 1);
    setLoading(true);
  };

  const handleApiEnd = () => {
    setApiCallsInProgress((prev) => {
      const newCount = prev - 1;
      if (newCount === 0) {
        setLoading(false);
      }
      return newCount;
    });
  };
  const handleError = (message) => {
    handleApiEnd();
    toast.error(message, { theme: "colored", autoClose: autocloseTiming });
  };

  useEffect(() => {
    if (groupId === 5 || groupId === 8) {
      setModalInputData((prevData) => ({
        ...prevData,
        category: { value: -1, label: "Regular" },
      }));
    }
  }, [groupId, addModalOpen]);

  useEffect(() => {
    const initialCheckedValues = employeeHoursData.map(() => ({
      isChecked: false,
    }));
    setCheckedValues(initialCheckedValues);
  }, [employeeHoursData]);

  const handleMainCheckboxChange = (event) => {
    const isChecked = event.target.checked;
    const updatedCheckedValues = filteredEmployeeData.map((row) => ({
      ...row,
      isChecked: isChecked,
    }));
    setAllChecked(isChecked);
    setCheckedValues(updatedCheckedValues);

    if (isChecked) {
      const allIds = filteredEmployeeData
        .filter((row) => row.status === "Pending")
        .filter((row) => row.id !== "-")
        .filter(
          (row) => !(row.type === "time-card" && row.category === "Running")
        )
        .map((row) => row.id);

      const ptoRequestIds = allIds.filter(
        (id) =>
          filteredEmployeeData.find((row) => row.id === id).type ===
          "pto-request"
      );
      const regularIds = allIds.filter((id) => {
        const row = filteredEmployeeData.find((row) => row.id === id);
        return row.type === "time-card" && row.category !== "Running";
      });

      setAllIds(allIds);
      setPtoRequestId(ptoRequestIds);
      setRegularAllId(regularIds);
      setSelectedAllIds(true);
    } else {
      setAllIds([]);
      setPtoRequestId([]);
      setRegularAllId([]);
      setSelectedAllIds(false);
    }
  };

  const handleIndividualCheckboxChange = (index) => {
    const updatedCheckedValues = [...checkedValues];
    updatedCheckedValues[index].isChecked =
      !updatedCheckedValues[index].isChecked;
    setCheckedValues(updatedCheckedValues);
    const ptoId = employeeHoursData[index].id;
    // const assignedTo = employeeHoursData[index].assigned_to;
    const type = employeeHoursData[index].type;

    if (updatedCheckedValues[index].isChecked) {
      // if (groupId === 3 || assignedTo === loginUserId) {
      setAllIds((prevIds) => [...prevIds, ptoId]);
      if (type === "pto-request") {
        setPtoRequestId((prevIds) => [...prevIds, ptoId]);
      } else {
        setRegularAllId((prevIds) => [...prevIds, ptoId]);
      }
      // }
    } else {
      setAllIds((prevIds) => prevIds.filter((id) => id !== ptoId));
      if (type === "pto-request") {
        setPtoRequestId((prevIds) => prevIds.filter((id) => id !== ptoId));
      } else {
        setRegularAllId((prevIds) => prevIds.filter((id) => id !== ptoId));
      }
    }
    const eligibleRows = updatedCheckedValues.filter(
      (row, i) =>
        employeeHoursData[i].id !== "-" &&
        employeeHoursData[i].type !== "pto-request"
    );
    const eligibleRowCount = eligibleRows.length;
    const checkedCount = eligibleRows.filter((row) => row.isChecked).length;

    const isAllChecked =
      eligibleRowCount > 0 && eligibleRowCount === checkedCount;
    setSelectedAllIds(isAllChecked);
    setAllChecked(isAllChecked);
  };

  useEffect(() => {
    setFilteredEmployeeData(employeeHoursData);
  }, [employeeHoursData]);
  const handlePtoStatus = (status) => {
    let dataToSet = [];
    setPtoStatus(status);
    setEmployeeHoursData([]);
    setSelectedAllIds(false);
    const url = `/multi-card-detail?profileId=${urlUserId}&page=${apiCusPageCount}&status=${status}`;
    navigate(url);
    if (status === "all") {
      dataToSet = [...ptoPendingData, ...ptoApprovedData, ...ptoDeniedData];
    } else if (status === "pending") {
      dataToSet = ptoPendingData.filter((item) => item.id !== "-");
    } else if (status === "denied") {
      dataToSet = [...ptoDeniedData];
      setRegularAllId([]);
    } else {
      dataToSet = [...ptoApprovedData];
      setRegularAllId([]);
    }
    const uniqueDataSet = Array.from(
      new Set(dataToSet.map((item) => JSON.stringify(item)))
    ).map((item) => JSON.parse(item));
    uniqueDataSet.sort((a, b) => {
      if (a.date === b.date) {
        if (a.week_status === 1 && b.week_status !== 1) return -1;
        if (a.week_status === 0 && b.week_status === 2) return -1;
        if (a.week_status === 2 && b.week_status !== 2) return 1;
      }
      return new Date(a.date) - new Date(b.date);
    });
    setEmployeeHoursData(uniqueDataSet);
  };
  const handleModalOpen = (data) => {
    setModalOpen(true);
    setModalIndividualData(data);
  };
  const handleAddModalOpen = (data) => {
    setAddModalOpen(true);
    setAddModalIndividualData(data);
  };
  const handleHolidayModalOpen = (data) => {
    setHolidayModal(true);
    setHolidayIndividualdata(data);
  };
  const handleClose = () => {
    setModalOpen(false);
    setEditAddEntry(false);
    setAddModalOpen(false);
    resetModalInputData();
    setAddModalErrors({});
    setCalcTotalhrs("");
    setCalcEditTotalhrs("");
    setHolidayModal(false);
    setEditModalHolidayData("");
    setIsInputChanged(false);
  };
  const handleDelModalOpen = (textModal) => {
    setDelModalOpen(true);
    setModalOpen(false);
    setModalText(textModal);
    setHolidayModal(false);
  };

  const handleDelModalClose = () => {
    setDelModalOpen(false);
    if (!approveDeny) {
      setModalOpen(true);
    }
  };
  const handleHolidayDelModalOpen = (textModal) => {
    setHolidayDelModal(true);
    setModalText(textModal);
    setHolidayModal(false);
  };
  const handleHolidayDelModalClose = () => {
    setHolidayDelModal(false);
    if (!approveDeny) {
      setHolidayModal(true);
    }
  };
  const getEmployeeCardData = async () => {
    handleApiStart();
    let obj = {
      frequency_id: row?.pay_frequency_id
        ? row?.pay_frequency_id
        : frequencyId?.pay_frequency_id,
      user_id: urlUserId ? urlUserId : row?.user_id ? row?.user_id : getUserId,
      page: apiCusPageCount,
    };
    try {
      const res = await postData("get-employee-timecard", {}, obj);
      if (res?.status === true) {
        setLoading(false);
        setDateRangeData(res?.data?.date_range);
        setTotalHoursData(res?.data);
        setPtoPendingData(res?.data?.userData?.pending);
        setPtoApprovedData(res?.data?.userData?.approved);
        setPtoDeniedData(res?.data?.userData?.denied);
      } else if (
        res?.status === false &&
        res?.message === "No time record before hire date"
      ) {
        setLeftArrowDisable(true);
        handleError(res.message);
      } else {
        handleError(res.message);
      }
    } catch (err) {
      handleError(err.message);
    } finally {
      handleApiEnd();
    }
  };
  const handleConditionalApiCall = () => {
    if (hasCalledAPI) return;
    if (row && apiCusPageCount) {
      getEmployeeCardData();
      setHasCalledAPI(true);
    } else if (selectedEmployee && Object.keys(frequencyId).length > 0) {
      getEmployeeCardData();
      setHasCalledAPI(true);
    }
  };

  useEffect(() => {
    setHasCalledAPI(false);
    handleConditionalApiCall();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [row, apiCusPageCount, frequencyId, selectedEmployee]);

  useEffect(() => {
    let combinedData = [];
    switch (ptoStatus) {
      case "all":
        combinedData = [
          ...ptoPendingData,
          ...ptoApprovedData,
          ...ptoDeniedData,
        ];
        break;

      case "pending":
        if (groupId !== 5) {
          combinedData = ptoPendingData.filter(
            (item) => item.id !== "-" && item.is_edit !== 1
          );
        } else {
          combinedData = ptoPendingData.filter((item) => item.id !== "-");
        }
        break;

      case "denied":
        combinedData = [...ptoDeniedData];
        setRegularAllId([]);
        break;

      case "editRequest":
        combinedData = ptoPendingData.filter((item) => item.is_edit === 1);
        break;

      default:
        combinedData = [...ptoApprovedData];
        setRegularAllId([]);
        break;
    }
    const uniqueDataSet = Array.from(
      new Set(combinedData.map((item) => JSON.stringify(item)))
    ).map((item) => JSON.parse(item));

    uniqueDataSet.sort((a, b) => {
      const dateA = new Date(a.date);
      const dateB = new Date(b.date);
      if (dateA - dateB !== 0) {
        return dateA - dateB;
      }
      if (a.week_status !== b.week_status) {
        if (a.week_status === 1) return -1;
        if (b.week_status === 1) return 1;
        if (a.week_status === 0) return -1;
        if (b.week_status === 0) return 1;
        if (a.week_status === 2) return 1;
        if (b.week_status === 2) return -1;
      }
      return 0;
    });

    setEmployeeHoursData(uniqueDataSet);
  }, [groupId, ptoApprovedData, ptoDeniedData, ptoPendingData, ptoStatus]);

  const tableDataArray =
    filteredEmployeeData.length > 0 ? filteredEmployeeData : [];

  useEffect(() => {
    if (jobLocation?.options.length === 1) {
      handleOnChange(jobLocation.options[0], "jobLocation");
    }
    if (jobLocation?.code?.length === 1) {
      handleOnChange(jobLocation.code[0], "jobCode");
    }
  }, [jobLocation?.options, jobLocation?.code]);
  const handleOnChange = (e, type) => {
    const value =
      type === "category" || type === "jobLocation" || type === "jobCode"
        ? e
        : e.target.value;
    setModalInputData((prevData) => ({
      ...prevData,
      [type]: value,
    }));
  };
  const convertTo24HourFormat = (time) => {
    if (!time) return "";
    const [timePart, modifier] = time.split(" ");
    let [hours, minutes] = timePart.split(":");
    if (hours === "12") {
      hours = "00";
    }
    if (modifier === "PM") {
      hours = parseInt(hours, 10) + 12;
    }
    return `${hours.toString().padStart(2, "0")}:${minutes}`;
  };
  useEffect(() => {
    if (modalIndividualData) {
      setEditModalInputData({
        timeIn: convertTo24HourFormat(modalIndividualData.in),
        timeOut: convertTo24HourFormat(modalIndividualData.out),
        totalHours: modalIndividualData.hours || "",
        category: {
          value: modalIndividualData.category_id || "",
          label: modalIndividualData.category || "",
        },
        notes: modalIndividualData.note_data || "",
        jobLocation: modalIndividualData.job_location_title || "",
        jobCode: modalIndividualData.job_code_title || "",
      });
    }
  }, [modalIndividualData, jobLocation.options, jobLocation.code]);

  useEffect(() => {
    if (holidayIndividualdata) {
      setEditModalHolidayData({
        totalHours: holidayIndividualdata?.hours || "",
        category: {
          value: holidayIndividualdata.category || "",
          label: holidayIndividualdata.category || "",
        },
        notes: holidayIndividualdata.notes || "",
        createdAt: holidayIndividualdata?.created_date,
        createdBy: holidayIndividualdata?.created_by,
      });
    }
  }, [holidayIndividualdata, holidayModal]);

  const handleEditOnChange = (e, type) => {
    const value =
      type === "category" || type === "jobLocation" || type === "jobCode"
        ? e
        : e.target.value;
    if (editAddEntry) {
      setIsInputChanged(true);
    }
    setEditModalInputData((prevData) => ({
      ...prevData,
      [type]: value,
    }));
  };
  const handleHolidayEditOnChange = (e, type) => {
    const value = type === "category" ? e : e.target.value;
    setEditModalHolidayData((prevData) => ({
      ...prevData,
      [type]: value,
    }));
  };

  const handleGetUserInfo = async () => {
    handleApiStart();
    const res = await postData("get_user_details", {}, {});
    try {
      if (res?.status === true) {
        setLoading(false);
        setGroupId(res?.data?.group_id);
        setGetUserId(res?.data?.user_id);
      } else {
        handleError(res.message);
      }
    } catch (err) {
      handleError(err.message);
    } finally {
      handleApiEnd();
    }
  };

  const handleGetPolicData = async () => {
    handleApiStart();
    let obj = {
      user_id: urlUserId ? urlUserId : row?.user_id,
    };
    const res = await postData("get_user_policy_reg", {}, obj);
    try {
      if (res?.status === true) {
        setLoading(false);
        setPolicyOptions(res?.data);
      } else {
        console.log(res?.message);
      }
    } catch (err) {
      console.log(err?.message);
    } finally {
      handleApiEnd();
    }
  };
  useEffect(() => {
    if (urlUserId !== 0) handleGetPolicData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlUserId]);

  const momentDate = moment(
    modalOpen ? modalIndividualData?.date_label : addModalIndividualData,
    "ddd MM/DD/YYYY"
  );
  const formattedDate = momentDate.format("YYYY-MM-DD");
  const validateInputs = () => {
    const errors = {};
    const inputData = modalOpen ? editModalInputData : modalInputData;

    if (!inputData.timeIn || inputData.timeIn === "Invalid date") {
      errors.timeIn = "Clock In time is required or invalid";
    }
    if (!inputData.timeOut || inputData.timeOut === "Invalid date") {
      errors.timeOut = "Clock Out time is required or invalid";
    }
    if (
      inputData.timeIn &&
      inputData.timeOut &&
      inputData.timeIn !== "Invalid date" &&
      inputData.timeOut !== "Invalid date"
    ) {
      if (inputData.timeIn >= inputData.timeOut) {
        errors.timeOut = "Clock Out time should be later than Clock In time";
      }
    }
    if (
      !(groupId === 5 || groupId === 8 || clockOutModal) &&
      !inputData.category.value &&
      editModalInputData?.category?.label !== "Running"
    ) {
      errors.category = "Category is required";
    }
    if (!inputData.notes || inputData.notes.trim().length < 1) {
      errors.notes = "Notes should contain at least 1 character";
    }
    if (inputData.category.value === -1) {
      if (jobLocation.options.length > 0) {
        if (
          !(modalOpen && editModalInputData?.jobLocation === "") &&
          !inputData.jobLocation?.id
        ) {
          errors.jobLocation = "Job Location is required";
        }
      }

      if (jobLocation.code.length > 0) {
        if (
          !(modalOpen && editModalInputData?.jobCode === "") &&
          !inputData.jobCode?.id
        ) {
          errors.jobCode = "Job Code is required";
        }
      }
    }

    return errors;
  };
  const handleApproveData = async () => {
    const errors = validateInputs();
    if (Object.keys(errors).length > 0) {
      setAddModalErrors(errors);
      return;
    }
    setBtnLoading(true);
    let obj = {
      req_start_time: modalOpen
        ? editModalInputData?.timeIn
        : modalInputData?.timeIn,
      req_end_time: modalOpen
        ? editModalInputData?.timeOut
        : modalInputData?.timeOut,
      pto_policy_id:
        groupId === 5 ||
        groupId === 8 ||
        editModalInputData?.category?.label === "Running" ||
        clockOutModal
          ? -1
          : modalOpen
          ? editModalInputData?.category?.value
          : modalInputData?.category?.value,
      user_id: row?.user_id ? row?.user_id : urlUserId ? urlUserId : getUserId,
      date: formattedDate,
      pay_frequency_id: row?.pay_frequency_id
        ? row?.pay_frequency_id
        : frequencyId?.pay_frequency_id,
      pay_start_date: dateRangeData?.start_date,
      pay_end_date: dateRangeData?.end_date,
      admin_note:
        groupId === 5 || groupId === 8
          ? undefined
          : modalOpen
          ? editModalInputData?.notes
          : modalInputData?.notes,
      req_note_in:
        groupId === 5 || groupId === 8
          ? modalOpen
            ? editModalInputData?.notes
            : addModalOpen
            ? modalInputData?.notes
            : undefined
          : undefined,
      id: modalOpen ? modalIndividualData?.id : undefined,
      job_code_id: modalOpen
        ? editModalInputData?.jobCode.id
        : modalInputData?.jobCode.id,
      job_location_id: modalOpen
        ? editModalInputData?.jobLocation.id
        : modalInputData?.jobLocation.id,
      page_count: apiCusPageCount,
    };
    const res = await postData("timer_data", {}, obj);
    try {
      if (res?.status === true) {
        setBtnLoading(false);
        toast.success(res?.message, {
          theme: "colored",
          autoClose: autocloseTiming,
        });
        setAddModalOpen(false);
        setModalOpen(false);
        resetModalInputData();
        setCalcTotalhrs("");
        setCalcEditTotalhrs("");
        getEmployeeCardData();
        setEditAddEntry(false);
        handleClose();
      } else {
        setBtnLoading(false);
        handleClose();
        handleError(res.message);
      }
    } catch (err) {
      handleError(err.message);
      setEditAddEntry(false);
    }
  };
  const handleLeftArrowClick = () => {
    if (!leftArrowDisable) {
      setApiCusPageCount(parseInt(apiCusPageCount) + 1);
    }
  };

  const handleRightArrowClick = () => {
    if (parseInt(apiCusPageCount) > 0) {
      setApiCusPageCount(parseInt(apiCusPageCount) - 1);
    }
  };

  useEffect(() => {
    const url = `/multi-card-detail?profileId=${urlUserId}&page=${apiCusPageCount}&status=${ptoStatus}`;
    navigate(url);
  }, [apiCusPageCount, urlUserId, navigate, ptoStatus]);
  const getDetailDateRangeData = async () => {
    handleApiStart();
    let payLoadObj = {
      frequency_id: row?.pay_frequency_id
        ? row?.pay_frequency_id
        : frequencyId?.pay_frequency_id,
      page: apiCusPageCount,
      format_status: 1,
    };
    try {
      const res = await postData("get-frequency-employee-list", {}, payLoadObj);
      if (res?.status === true) {
        setLoading(false);
        setEmployeeOptions(res?.data?.userData);
      } else {
        handleError(res.message);
      }
    } catch (err) {
      handleError(err.message);
    } finally {
      handleApiEnd();
    }
  };
  useEffect(() => {
    if (Object.keys(frequencyId).length > 0) {
      getDetailDateRangeData(apiCusPageCount);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiCusPageCount, frequencyId]);
  useEffect(() => {
    if (modalInputData.timeIn && modalInputData.timeOut) {
      const timeIn = moment(modalInputData.timeIn, "HH:mm");
      const timeOut = moment(modalInputData.timeOut, "HH:mm");

      if (timeOut.isBefore(timeIn)) {
        timeOut.add(1, "day");
      }

      const duration = moment.duration(timeOut.diff(timeIn));
      const hours = Math.floor(duration.asHours());
      const minutes = duration.minutes();

      const formattedTotalHours = `${hours}h:${
        minutes < 10 ? "0" : ""
      }${minutes}m`;
      setCalcTotalhrs(formattedTotalHours);
    }
  }, [modalInputData.timeIn, modalInputData.timeOut]);
  useEffect(() => {
    if (editModalInputData.timeIn && editModalInputData.timeOut) {
      const timeIn = moment(editModalInputData.timeIn, "HH:mm");
      const timeOut = moment(editModalInputData.timeOut, "HH:mm");

      if (timeOut.isBefore(timeIn)) {
        timeOut.add(1, "day");
      }

      const duration = moment.duration(timeOut.diff(timeIn));
      const hours = Math.floor(duration.asHours());
      const minutes = duration.minutes();

      const formattedTotalHours = `${hours}h:${
        minutes < 10 ? "0" : ""
      }${minutes}m`;
      setCalcEditTotalhrs(formattedTotalHours);
    }
  }, [editModalInputData.timeIn, editModalInputData.timeOut]);

  const handleDeleteTimeCardUser = async () => {
    setBtnDelLoading(true);
    let delObj = {
      delete_id:
        holidayIndividualdata.type === "holiday"
          ? holidayIndividualdata?.id
          : modalIndividualData?.id,
      user_id:
        holidayIndividualdata.type === "holiday"
          ? holidayIndividualdata?.user_id
          : modalIndividualData?.user_id,
      pay_frequency_id: row?.pay_frequency_id
        ? row?.pay_frequency_id
        : frequencyId?.pay_frequency_id,
      pay_start_date: dateRangeData?.start_date,
      pay_end_date: dateRangeData?.end_date,
    };
    const url =
      modalIndividualData.type === "pto-request" ||
      holidayIndividualdata.type === "holiday"
        ? "delete_pto"
        : "delete_timer";
    const res = await postData(url, {}, delObj);
    try {
      if (res?.status === true) {
        setBtnDelLoading(false);
        setDelModalOpen(false);
        setModalOpen(false);
        setHolidayDelModal(false);
        setHolidayModal(false);
        toast.success(res?.message, {
          theme: "colored",
          autoClose: 2000,
        });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
        setTimeout(() => {
          handleGetFrequencyId();
        }, 2500);
      } else {
        setBtnDelLoading(false);
        handleError(res.message);
      }
    } catch (err) {
      handleError(err.message);
      setBtnDelLoading(false);
    }
  };

  const handleApproveRejectAll = async (textType) => {
    handleApiStart();
    let allObj = {
      user_id: row?.user_id ? row?.user_id : urlUserId ? urlUserId : getUserId,
      pay_frequency_id: row?.pay_frequency_id
        ? row?.pay_frequency_id
        : frequencyId?.pay_frequency_id,
      pay_start_date: dateRangeData?.start_date,
      pay_end_date: dateRangeData?.end_date,
      approve_type: textType ? textType : approveText,
      pto_ids: [],
      ids:
        modalIndividualData?.category === "Regular"
          ? [modalIndividualData?.id]
          : regularAllId,
      selected_all_ids: selectedAllIds,
    };
    const res = await postData("multitimecard_user_approve", {}, allObj);
    try {
      if (res?.status === true) {
        setLoading(false);
        setDelModalOpen(false);
        setAllIds([]);
        setRegularAllId([]);
        getEmployeeCardData();
        setModalOpen(false);
        setSelectedAllIds(false);
        toast.success(res?.message, {
          theme: "colored",
          autoClose: 2000,
        });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        setAllIds([]);
        setRegularAllId([]);
        handleError(res.message);
      }
    } catch (err) {
      handleError(err.message);
      setAllIds([]);
      setRegularAllId([]);
    } finally {
      handleApiEnd();
    }
  };

  const handleGetFrequencyId = async () => {
    handleApiStart();
    let obj = {
      user_id: urlUserId || getUserId,
    };
    try {
      const res = await postData("get-frequency-id", {}, obj);
      if (res?.status === true) {
        setLoading(false);
        setFrequencyId(res?.data);
      }
    } catch (err) {
      handleError(err.message);
    } finally {
      handleApiEnd();
    }
  };
  useEffect(() => {
    if (urlUserId !== 0) {
      handleGetFrequencyId();
    } else if (getUserId !== 0) {
      handleGetFrequencyId();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlUserId, getUserId]);

  useEffect(() => {
    if (urlUserId !== 0) {
      const employee = employeeOptions.find(
        (option) => option.user_id === urlUserId
      );
      if (employee) {
        setSelectedEmployee({
          value: employee.user_id,
          label: employee.name,
        });
      }
      setLoading(false);
    }
  }, [urlUserId, employeeOptions]);
  const getJobLocationOption = async () => {
    handleApiStart();
    let jobPayload = {
      user_id: urlUserId ? urlUserId : getUserId,
    };
    try {
      const res = await postData("user_job_location", {}, jobPayload);
      if (res?.status === true) {
        setLoading(false);
        setJobLocation((prevState) => ({ ...prevState, options: res.data }));
      } else {
        handleError(res?.message);
      }
    } catch (err) {
      handleError(err.message);
    } finally {
      handleApiEnd();
    }
  };
  const getJobCodeOption = async () => {
    handleApiStart();
    let jobCodePayload = {
      user_id: urlUserId ? urlUserId : getUserId,
    };
    try {
      const res = await postData("user_job_code", {}, jobCodePayload);
      if (res?.status === true) {
        setLoading(false);
        setJobLocation((prevState) => ({ ...prevState, code: res.data }));
      } else {
        handleError(res.message);
      }
    } catch (err) {
      handleError(err.message);
    } finally {
      handleApiEnd();
    }
  };
  useEffect(() => {
    if (urlUserId) {
      getJobLocationOption();
      getJobCodeOption();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlUserId]);
  return (
    <>
      {apiCallsInProgress > 0 ? (
        <Loader />
      ) : (
        <>
          <div className="individual-card-container">
            <EmployeeDetails
              tableDataArray={tableDataArray}
              checkedValues={checkedValues}
              handleMainCheckboxChange={handleMainCheckboxChange}
              handleIndividualCheckboxChange={handleIndividualCheckboxChange}
              handleAddModalOpen={handleAddModalOpen}
              setApproveDeny={setApproveDeny}
              handleModalOpen={handleModalOpen}
              setEditAddEntry={setEditAddEntry}
              ptoStatus={ptoStatus}
              groupId={groupId}
              handleHolidayModalOpen={handleHolidayModalOpen}
              frequencyId={frequencyId}
              dateRangeData={dateRangeData}
              setLoading={setLoading}
              getEmployeeCardData={getEmployeeCardData}
              addModalErrors={addModalErrors}
              calcTotalhrs={calcTotalhrs}
              btnLoading={btnLoading}
              handleApproveData={handleApproveData}
              editAddEntry={editAddEntry}
              addModalIndividualData={addModalIndividualData}
              modalInputData={modalInputData}
              handleOnChange={handleOnChange}
              resetModalInputData={resetModalInputData}
              clockOutModal={clockOutModal}
              setClockOutModal={setClockOutModal}
              setAddModalErrors={setAddModalErrors}
              ptoPendingData={ptoPendingData}
              ptoApprovedData={ptoApprovedData}
              ptoDeniedData={ptoDeniedData}
              employeeOptions={employeeOptions}
              selectedEmployee={selectedEmployee}
              handleEmployeeChange={handleEmployeeChange}
              row={row}
              leftArrowDisable={leftArrowDisable}
              handleLeftArrowClick={handleLeftArrowClick}
              handleRightArrowClick={handleRightArrowClick}
              apiCusPageCount={apiCusPageCount}
              totalHoursData={totalHoursData}
              allIds={allIds}
              setApproveText={setApproveText}
              setModalDiff={setModalDiff}
              handleDelModalOpen={handleDelModalOpen}
              handlePtoStatus={handlePtoStatus}
              selectedAllIds={selectedAllIds}
              handleApiStart={handleApiStart}
              handleApiEnd={handleApiEnd}
            />
          </div>{" "}
          <>
            <ViewModal
              modalOpen={modalOpen}
              handleClose={handleClose}
              modalIndividualData={modalIndividualData}
              handleDelModalOpen={handleDelModalOpen}
              setEditAddEntry={setEditAddEntry}
              editAddEntry={editAddEntry}
              editModalInputData={editModalInputData}
              handleEditOnChange={handleEditOnChange}
              row={row}
              groupId={groupId}
              policyOptions={policyOptions}
              handleApproveData={handleApproveData}
              btnLoading={btnLoading}
              setLoading={setLoading}
              setModalOpen={setModalOpen}
              getEmployeeCardData={getEmployeeCardData}
              setModalDiff={setModalDiff}
              calcEditTotalhrs={calcEditTotalhrs}
              handleApproveRejectAll={handleApproveRejectAll}
              addModalErrors={addModalErrors}
              totalHoursData={totalHoursData}
              jobLocation={jobLocation}
            />
          </>
          <>
            <AddModal
              addModalOpen={addModalOpen}
              setHolidayModal={setHolidayModal}
              handleClose={handleClose}
              addModalIndividualData={addModalIndividualData}
              editAddEntry={editAddEntry}
              modalInputData={modalInputData}
              handleOnChange={handleOnChange}
              addModalErrors={addModalErrors}
              calcTotalhrs={calcTotalhrs}
              groupId={groupId}
              policyOptions={policyOptions}
              btnLoading={btnLoading}
              handleApproveData={handleApproveData}
              setLoading={setLoading}
              jobLocation={jobLocation}
            />
          </>
          <>
            <HolidayModal
              holidayModal={holidayModal}
              handleClose={handleClose}
              holidayIndividualdata={holidayIndividualdata}
              handleHolidayDelModalOpen={handleHolidayDelModalOpen}
              setEditAddEntry={setEditAddEntry}
              editAddEntry={editAddEntry}
              editModalHolidayData={editModalHolidayData}
              handleHolidayEditOnChange={handleHolidayEditOnChange}
              row={row}
              groupId={groupId}
              policyOptions={policyOptions}
              handleApproveData={handleApproveData}
              btnLoading={btnLoading}
              setHolidayModal={setHolidayModal}
              setLoading={setLoading}
            />
          </>
          <>
            <ModalDetail
              show={delModalOpen}
              closeModal={handleDelModalClose}
              modalText={modalText}
              handleDeleteTimeCardUser={handleDeleteTimeCardUser}
              btnDelLoading={btnDelLoading}
              holidayIndividualdata={holidayIndividualdata}
              handleModalOnClick={handleApproveRejectAll}
              modalDiff={modalDiff}
              approveDeny={approveDeny}
            />
          </>
          <ModalHolidayDetail
            show={holidayDelModal}
            closeModal={handleHolidayDelModalClose}
            modalText={modalText}
            handleDeleteTimeCardUser={handleDeleteTimeCardUser}
            btnDelLoading={btnDelLoading}
            holidayIndividualdata={holidayIndividualdata}
          />
        </>
      )}
    </>
  );
}
