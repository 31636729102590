import React, { useState, useRef, useEffect } from "react";
import { Row, Col, Button, Modal, Form } from "react-bootstrap";
import TablePagination from "./tablePagination";
// import deleteSvg from "../../../assets/images/delete_blue.svg";
// import deleteForeverSvg from "../../../assets/images/delete_forever.svg";
import taskIconSvg from "../../../assets/images/taskIconSvg.svg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FileUploader } from "react-drag-drop-files";
import "./index.scss";
import { postUploadData } from "../../../api";
import { format } from "date-fns";
import { toast } from "react-toastify";
import Loader from "../../../components/Loader";
import Select from "react-select";
import FormModal from "../../../components/FormModal";
import { autocloseTiming } from "../../../api/regex";
import { useLocation } from "react-router-dom";

const TasksTab = ({
  userid,
  clientRole,
  userList,
  taskAllData,
  taskUserList,
}) => {
  const location = useLocation();
  const [showOpenTask, setShowOpenTask] = useState(true);
  const [showCompletedTask, setShowCompletedTask] = useState(false);
  const [showAddTask, setShowAddTask] = useState(false);
  const [showViewTask, setShowViewTask] = useState(false);
  const [deleteTask, setDeleteTask] = useState(false);
  const [deleteDocument, setDeleteDocument] = useState(false);
  // const [deleteTaskResponse, setDeleteTaskResponse] = useState(false);
  const [showViewCompletedTask, setShowViewCompletedTask] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showSelectUsers, setShowSelectUsers] = useState(false);
  const [selectedTaskId, setSelectedTaskId] = useState();
  const [openTaskData, setOpenTaskData] = useState([]);
  const [completedTaskData, setCompletedTaskData] = useState([]);
  const initialFormState = {
    id: null,
    name: "",
    description: "",
    due_date: "",
    copy_upload: 2,
    document_required: 0,
    assign_to_user: "",
    completed: null,
    selectedUserList: [],
  };
  const [dateInputs, setDateInputs] = useState({
    due_date: null,
  });
  const [taskTabData, setTaskTabData] = useState(initialFormState);
  const focusDate = useRef(null);
  const [validated, setValidated] = useState(false);
  const [errors, setErrors] = useState({});
  const [file, setFile] = useState([]);
  const fileTypes = [
    "JPEG",
    "PNG",
    "PDF",
    "DOCX",
    "SVG",
    "JPG",
    "WEBP",
    "GIF",
    "CSV",
    "XLSX",
    "ZIP",
  ];
  const [checkedUser, setCheckedUser] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [selectedUserValue, setSelectedUserValue] = useState();
  const [showError, setShowError] = useState(false);
  const [deleteDocumentId, setDeleteDocumentId] = useState();
  // open view task modal from cake php
  useEffect(() => {
    // Check if the current route is "/profile/view-task/:id"
    if (location.pathname.includes("/profile-view-task")) {
      // Find the index of "profile-view-task"
      const profileViewTaskIndex =
        location.pathname.indexOf("profile-view-task");
      // Extract the value after "profile-view-task"
      if (profileViewTaskIndex !== -1) {
        // Get the substring starting from the index after "profile-view-task"
        const remainingURL = location.pathname.substring(
          profileViewTaskIndex + "profile-view-task".length + 1
        );

        // Find the index of the next '/'
        const nextSlashIndex = remainingURL.indexOf("/");

        // Extract the task value
        const taskValue =
          nextSlashIndex !== -1
            ? remainingURL.substring(0, nextSlashIndex)
            : remainingURL;

        const filteredObject = taskAllData.find(
          (task) => task.id === parseInt(taskValue)
        );
        if (filteredObject !== undefined) {
          valueCallback(filteredObject);
        }
      }
    }
  }, [location.pathname]);

  const removeFragmentFromURL = () => {
    const currentPath = location.pathname + location.search;
    const newPath = currentPath.replace(
      /^\/profile-view-task\/\d+\/profile\//,
      "/profile/"
    ); // Extract the desired path
    window.history.replaceState(null, "", newPath); // Update the URL without the fragment
  };

  const handleCloseAddTask = () => {
    setShowAddTask(false);
    setErrors({});
    setValidated(false);
  };

  const handleOpenAddTask = () => {
    setTaskTabData(initialFormState);
    setDateInputs("");
    setSelectedUserValue([]);
    setShowSelectUsers(false);
    setShowError(false);
    setCheckedUser(false);
    setShowAddTask(true);
    setErrors({});
    setValidated(false);
  };

  const handleCloseViewTask = () => {
    setShowViewTask(false);
    setErrors({});
    removeFragmentFromURL();
  };

  const handleCloseCompletedViewTask = () => {
    setShowViewCompletedTask(false);
  };

  const handleOpenDeleteTask = () => {
    setShowViewTask(false);
    setDeleteTask(true);
    removeFragmentFromURL();
  };

  const handleOpenDeleteDocument = (id) => {
    setShowViewTask(false);
    removeFragmentFromURL();
    setDeleteDocumentId(id);
    setDeleteDocument(true);
  };

  const handleCloseDeleteDocument = () => {
    setShowViewTask(true);
    setDeleteDocument(false);
  };

  const handleCompletedDeleteTask = () => {
    setShowViewCompletedTask(false);
    setDeleteTask(true);
  };

  const handleOpenDeleteDocumentRespone = async (e) => {
    e.preventDefault();
    setModalLoading(true);
    let data = new FormData();
    data.append("document_id", deleteDocumentId);
    data.append("task_id", selectedTaskId);
    const res = await postUploadData("task-document-delete", {}, data);
    if (res.status === 1) {
      toast.success(res.message, {
        theme: "colored",
        autoClose: autocloseTiming,
      });
      setShowViewTask(false);
      removeFragmentFromURL();
      setDeleteDocument(false);
      setModalLoading(false);
      //refreshTable("open_task");
      reRender("open_task", res.data);
    } else {
      toast.error(res.message, {
        theme: "colored",
        autoClose: autocloseTiming,
      });
    }
  };

  const handleOpenDeleteTaskRespone = async (e) => {
    e.preventDefault();
    setModalLoading(true);
    let data = new FormData();
    data.append("user_id", userid);
    data.append("task_id", taskTabData.id);
    data.append("action", "delete");
    const res = await postUploadData("task-actions", {}, data);
    if (res.status === 1) {
      toast.success(res.message, {
        theme: "colored",
        autoClose: autocloseTiming,
      });
      setDeleteTask(false);
      // setDeleteTaskResponse(true);
      setModalLoading(false);
      setLoading(false);
      //refreshTable("open_task");
      reRender("open_task", res.data);
    } else {
      toast.error(res.message, {
        theme: "colored",
        autoClose: autocloseTiming,
      });
    }
  };

  const handleOpenViewTask = () => {
    setCheckedUser(false);
    setShowSelectUsers(false);
    // select current user
    if (clientRole !== "Contractor") {
      const filteredUsers = taskUserList?.filter((user, index) => {
        if (index !== 0 && user?.id?.toString() === userid) {
          setCheckedUser(true);
          setShowSelectUsers(true);
          return user?.id?.toString() === userid;
        }
      });
      setSelectedUserValue(formatSelectOptions(filteredUsers));
    }
    setFile([]);
    setShowViewTask(true);
    setErrors({});
    setValidated(false);
    setShowError(false);
  };

  const handleCompletedViewTask = () => {
    setShowViewCompletedTask(true);
  };

  const valueCallback = (value) => {
    setSelectedTaskId(value.id);
    setTaskTabData(value);
    setDateInputs({
      due_date: new Date(value.due_date),
    });
    if (value.completion === 0) {
      handleOpenViewTask();
    } else {
      handleCompletedViewTask();
    }
  };

  const submitTaskIsCompleted = async (value) => {
    setLoading(true);
    let data = new FormData();
    data.append("user_id", userid);
    data.append("task_id", value.id);
    data.append("action", "complete");
    const res = await postUploadData("task-actions", {}, data);
    if (res.status === 1) {
      toast.success(res.message, {
        theme: "colored",
        autoClose: autocloseTiming,
      });
      setModalLoading(false);
      setLoading(false);
      //refreshTable("open_task");
      reRender("open_task", res.data);
    } else {
      setModalLoading(false);
      toast.error(res.message, {
        theme: "colored",
        autoClose: autocloseTiming,
      });
    }
  };

  const selectUploadDt = (dateSel, name) => {
    const date = new Date(`${dateSel}`);
    const chkDt = date.getDate();

    if (chkDt > 0) {
      setDateInputs((prev) => ({
        ...prev,
        [name]: dateSel,
      }));
    }
  };

  const handleFocusDate = () => {
    const datepickerElement = focusDate.current;
    datepickerElement.setFocus(true);
  };

  const handleDocumentChange = (item) => {
    setFile(item);
  };
  const handleStatus = (e) => {
    setTaskTabData((prev) => ({
      ...prev,
      copy_upload: e.target.value,
    }));
  };

  function toggleUser(value) {
    setCheckedUser(!value);
    setShowSelectUsers(!value);
    return !value;
  }

  const findFormErrors = () => {
    const { name } = taskTabData;
    const { due_date } = dateInputs;
    const newErrors = {};
    if (!name || name === "") newErrors.name = "Please enter the task name.";
    if (
      !due_date ||
      due_date === "" ||
      due_date === null ||
      due_date === undefined
    )
      newErrors.due_date = "Please enter the due date.";
    return newErrors;
  };

  const findFormEditTaskErrors = () => {
    const { name, document_required, copy_upload, user_id } = taskTabData;
    const { due_date } = dateInputs;
    const newErrors = {};
    if (!name || name === "") newErrors.name = "Please enter the task name.";
    if (
      !due_date ||
      due_date === "" ||
      due_date === null ||
      due_date === undefined
    )
      newErrors.due_date = "Please enter the due date.";
    if (user_id === userid) {
      if (
        file.length === 0 &&
        parseInt(document_required) === 1 &&
        parseInt(copy_upload) === 1
      ) {
        newErrors.file = "Please upload the file.";
      }
    }
    return newErrors;
  };

  const handleReopenTask = async (e) => {
    e.preventDefault();
    setModalLoading(true);
    let data = new FormData();
    data.append("user_id", userid);
    data.append("task_id", taskTabData.id);
    data.append("action", "reopen");
    const res = await postUploadData("task-actions", {}, data);
    if (res.status === 1) {
      toast.success(res.message, {
        theme: "colored",
        autoClose: autocloseTiming,
      });
      setShowViewCompletedTask(false);
      setModalLoading(false);
      setLoading(false);
      //refreshTable("open_task");
      reRender("open_task", res.data);
    } else {
      toast.error(res.message, {
        theme: "colored",
        autoClose: autocloseTiming,
      });
    }
  };

  const reRender = (type, allData) => {
    if (allData && Object.keys(allData).length !== 0) {
      let openArr = [];
      let completedArr = [];
      allData.forEach((item) => {
        if (item.completion === 0) {
          openArr.push(item);
          return openArr;
        } else {
          completedArr.push(item);
          return completedArr;
        }
      });
      setOpenTaskData(openArr);
      setCompletedTaskData(completedArr);
    }
  };

  const submitNewTask = async (e) => {
    e.preventDefault();
    const newErrors = findFormErrors();
    setValidated(true);
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      if (checkedUser === true && selectedUserValue.length === 0) {
        setShowError(true);
      } else {
        setShowError(false);
        setModalLoading(true);
        let checkedUser_value = 0;
        if (checkedUser === true) {
          checkedUser_value = 1;
        } else {
          checkedUser_value = 0;
        }
        if (clientRole === "ClientAdmin" || clientRole === "ClientManager") {
          checkedUser_value = 1;
        }
        let userArray = [];
        if (clientRole !== "Contractor") {
          selectedUserValue.forEach((item, i) => {
            userArray.push(item?.id);
          });
        }
        let data = new FormData();
        data.append("user_id", userid);
        data.append("name", taskTabData.name);
        data.append("description", taskTabData.description);
        data.append("due_date", format(dateInputs.due_date, "yyyy-MM-dd"));
        data.append("copy_upload", taskTabData.copy_upload);
        data.append("assign_task", checkedUser_value);
        data.append("document", file);
        data.append("assign_user_id", userArray.join());
        data.append("document_required", taskTabData?.document_required);
        const res = await postUploadData("task-add-or-update", {}, data);
        if (res.status === 1) {
          toast.success(res.message, {
            theme: "colored",
            autoClose: autocloseTiming,
          });
          setModalLoading(false);
          setShowAddTask(false);
          reRender("open_task", res.data);
          //refreshTable("open_task");
        } else {
          setModalLoading(false);
          toast.error(res.message, {
            theme: "colored",
            autoClose: autocloseTiming,
          });
        }
      }
    }
  };

  const editTask = async (e) => {
    e.preventDefault();
    const newErrors = findFormEditTaskErrors();
    setValidated(true);
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      if (checkedUser === true && selectedUserValue.length === 0) {
        setShowError(true);
      } else {
        setShowError(false);
        setModalLoading(true);
        let checkedUser_value = 0;
        if (checkedUser === true) {
          checkedUser_value = 1;
        } else {
          checkedUser_value = 0;
        }
        if (clientRole === "ClientAdmin" || clientRole === "ClientManager") {
          checkedUser_value = 1;
        }
        let userArray = [];
        if (clientRole !== "Contractor") {
          selectedUserValue.forEach((item, i) => {
            userArray.push(item?.id);
          });
        }
        let data = new FormData();
        data.append("user_id", userid);
        data.append("task_id", taskTabData.id);
        data.append("name", taskTabData.name);
        data.append("description", taskTabData.description);
        data.append("due_date", format(dateInputs.due_date, "yyyy-MM-dd"));
        data.append("assign_task", checkedUser_value);
        data.append("copy_upload", taskTabData.copy_upload);
        data.append("document", file);
        data.append("assign_user_id", userArray.join());
        data.append("document_required", taskTabData?.document_required);
        const res = await postUploadData("task-add-or-update", {}, data);
        if (res.status === 1) {
          toast.success(res.message, {
            theme: "colored",
            autoClose: autocloseTiming,
          });
          setModalLoading(false);
          setShowViewTask(false);
          removeFragmentFromURL();
          // refreshTable("open_task");
          reRender("open_task", res.data);
        } else {
          setModalLoading(false);
          toast.error(res.message, {
            theme: "colored",
            autoClose: autocloseTiming,
          });
        }
      }
    }
  };

  // useEffect(() => {
  //   refreshTable("open_task");
  //   setSelectedUserValue([]);
  // }, []);

  useEffect(() => {
    if (taskAllData && Object.keys(taskAllData).length !== 0) {
      let openArr = [];
      let completedArr = [];
      taskAllData.forEach((item) => {
        if (item.completion === 0) {
          openArr.push(item);
          return openArr;
        } else {
          completedArr.push(item);
          return completedArr;
        }
      });
      setOpenTaskData(openArr);
      setCompletedTaskData(completedArr);
    }
  }, [taskAllData]);

  const refreshTable = async (type) => {
    //setTaskData([]);
    if (type === "open_task") {
      setShowOpenTask(true);
      setShowCompletedTask(false);
    } else {
      setShowOpenTask(false);
      setShowCompletedTask(true);
    }
    // setLoading(true);
    // let data = new FormData();
    // data.append("user_id", userid);
    // data.append("type", type);
    // const res = await postUploadData("task-list", {}, data);
    // if (res.status === 1) {
    //   setTaskData(res.data);
    //   setLoading(false);
    // } else {
    //   setLoading(false);
    // }
  };

  const handleInput = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setTaskTabData({
      ...taskTabData,
      [name]: value,
    });
    if (!!errors[name])
      setErrors({
        ...errors,
        [name]: null,
      });
  };

  const formatSelectOptions = (data) => {
    let finalArr = [];
    if (data && data.length > 0) {
      data.forEach((item) => {
        finalArr.push({
          value: item.id,
          label: item.title,
          id: item.id,
          title: item.title,
        });
      });
    }
    return finalArr;
  };

  const handleSelectUserOnChange = (data) => {
    setSelectedUserValue(data);
    setTaskTabData((prev) => ({
      ...prev,
      selectedUserList: data,
    }));
    setShowError(false);
  };

  const handleSizeError = (fileErr) => {
    toast.error(fileErr, { theme: "colored", autoClose: autocloseTiming });
  };

  const handleFileTypeError = (fileErr) => {
    toast.error(fileErr, { theme: "colored", autoClose: autocloseTiming });
  };

  const handleCheckDocumentMandatoryChange = (e) => {
    if (e.target.checked === true) {
      setTaskTabData((prev) => ({
        ...prev,
        document_required: 1,
      }));
    } else {
      setTaskTabData((prev) => ({
        ...prev,
        document_required: 0,
      }));
    }
  };

  return (
    <div className="tasks_container mb-3 task-profile">
      <div>
        <Row className="mb-3">
          <Col className="d-flex">
            <h5 className="tabs_main_heading">
              <span className="me-1">
                <img src={taskIconSvg} alt="" srcSet="" />
              </span>
              Tasks
            </h5>
          </Col>
        </Row>
        <Row>
          <Col lg={4} className="d-flex">
            <div
              className={`tasks_btn  ${showOpenTask ? "active" : ""}`}
              onClick={() => {
                refreshTable("open_task");
              }}
            >
              Open Tasks
            </div>
            <div
              className={`tasks_btn  ${showCompletedTask ? "active" : ""}`}
              onClick={() => {
                refreshTable("completed_task");
              }}
            >
              Completed Tasks
            </div>
          </Col>
        </Row>
        {/* <Row className="mt-2">
            <Col className="d-flex justify-content-end">
              <Button
                variant="primary"
                className="save_btn"
                onClick={handleOpenAddTask}
              >
                Add New Task
              </Button>
            </Col>
          </Row> */}
        {showOpenTask && (
          <Row>
            <Col>
              {!loading && (
                <TablePagination
                  data={openTaskData}
                  onClickHandler={valueCallback}
                  type={"open_task"}
                  handleTaskIsCompleted={submitTaskIsCompleted}
                />
              )}
              {loading && <Loader />}
            </Col>
          </Row>
        )}
        {showCompletedTask && (
          <Row>
            <Col>
              {!loading && (
                <TablePagination
                  onClickHandler={valueCallback}
                  data={completedTaskData}
                  type={"completed_task"}
                />
              )}
              {loading && <Loader />}
            </Col>
          </Row>
        )}

        <Row className="mt-2">
          <Col className="d-flex justify-content-end">
            <Button
              variant="primary"
              className="save_btn"
              onClick={handleOpenAddTask}
            >
              Add New Task
            </Button>
          </Col>
        </Row>
        {showAddTask && (
          <Modal
            show={showAddTask}
            onHide={handleCloseAddTask}
            className="add_task_modal task_form"
          >
            <Modal.Header closeButton>
              <Modal.Title>Add Task</Modal.Title>
            </Modal.Header>
            <Form noValidate validated={validated} onSubmit={submitNewTask}>
              <Modal.Body>
                <Row>
                  <Col lg={12}>
                    <Form.Group className="mb-3" controlId="name">
                      <Form.Label>
                        Task Name <span className="asterisk">*</span>{" "}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        value={taskTabData?.name}
                        placeholder="Enter Task Name"
                        name="name"
                        onChange={handleInput}
                        isInvalid={!!errors.name}
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.name}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={12}>
                    <Form.Group className="mb-3" controlId="description">
                      <Form.Label>Description</Form.Label>
                      <Form.Control
                        as="textarea"
                        placeholder="Leave a description here"
                        style={{ height: "100px" }}
                        value={taskTabData?.description}
                        name="description"
                        onChange={handleInput}
                        isInvalid={!!errors.description}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.description}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Row>
                      <Col lg={6}>
                        <Form.Group
                          className="mb-3 position-relative"
                          controlId="due_date"
                        >
                          <Form.Label>
                            Due date <span className="asterisk">*</span>{" "}
                          </Form.Label>
                          <DatePicker
                            ref={focusDate}
                            className="datepicker-payscheme"
                            selected={
                              dateInputs?.due_date ? dateInputs?.due_date : null
                            }
                            onChange={(date) =>
                              selectUploadDt(date, "due_date")
                            }
                            name="due_date"
                            dateFormat="MM-dd-yyyy"
                            dropdownMode="select"
                            showMonthDropdown
                            showYearDropdown
                            calendarIcon={true}
                            closeCalendar={true}
                            clearIcon={true}
                            required
                          />
                          <span
                            className="calendar-icon"
                            onClick={handleFocusDate}
                          ></span>
                          {validated &&
                            (dateInputs?.due_date === "" ||
                              dateInputs?.due_date === null ||
                              dateInputs?.due_date === undefined) && (
                              <div className="err-feedback">
                                {errors?.due_date}
                              </div>
                            )}
                        </Form.Group>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <FileUploader
                      multiple={false}
                      handleChange={handleDocumentChange}
                      onSizeError={handleSizeError}
                      onTypeError={handleFileTypeError}
                      name="file"
                      label={
                        file.length !== 0
                          ? "Uploaded Successfully!."
                          : " Drop your files here or browse."
                      }
                      types={fileTypes}
                      classes="employee-document-upload"
                      maxSize="10"
                      required
                    />
                  </Col>
                </Row>
                {clientRole !== "Employee" && clientRole !== "Contractor" && (
                  <Row className="mt-4">
                    <Col lg={6} className="mt-2" s>
                      <Form.Group className="mb-3" controlId="copy_upload">
                        <Form.Label className="me-2">
                          Add upload(s) to user files
                        </Form.Label>
                        <div>
                          <Form.Check
                            inline
                            label="Yes"
                            name="copy_upload"
                            type="radio"
                            checked={
                              taskTabData.copy_upload == 1 ? true : false
                            }
                            value={1}
                            onChange={(e) => handleStatus(e)}
                            id="copy_upload"
                          />
                          <Form.Check
                            inline
                            label="No"
                            name="copy_upload"
                            type="radio"
                            checked={
                              taskTabData.copy_upload == 2 ? true : false
                            }
                            value={2}
                            onChange={(e) => handleStatus(e)}
                            id="copy_upload_no"
                          />
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>
                )}

                {parseInt(taskTabData.copy_upload) === 1 && (
                  <Row className="mt-2">
                    <Col lg={6}>
                      <Form.Check
                        inline
                        label="Document upload is mandatory"
                        checked={
                          parseInt(taskTabData.document_required) === 1
                            ? true
                            : false
                        }
                        value={1}
                        onChange={(e) => handleCheckDocumentMandatoryChange(e)}
                        name="document_required"
                        type="checkbox"
                        id="document_required"
                      />
                    </Col>
                  </Row>
                )}

                {clientRole !== "Employee" && clientRole !== "Contractor" && (
                  <Row className="mt-2">
                    <Col lg={6}>
                      <Form.Check
                        inline
                        label="Assign tasks to users"
                        checked={checkedUser}
                        onChange={() => setCheckedUser(toggleUser)}
                        name="assign_to_user"
                        type="checkbox"
                        id="assign_to_user"
                      />
                    </Col>
                  </Row>
                )}
                {clientRole !== "Employee" &&
                  clientRole !== "Contractor" &&
                  showSelectUsers && (
                    <Row className="mt-2">
                      <Col lg={12}>
                        <Form.Group
                          className="mb-3"
                          controlId="selectedUserList"
                        >
                          <Form.Label>Select users </Form.Label>
                          <Select
                            closeMenuOnSelect={true}
                            hideSelectedOptions={false}
                            options={formatSelectOptions(taskUserList)}
                            onChange={(selectedOption) =>
                              handleSelectUserOnChange(selectedOption)
                            }
                            value={selectedUserValue}
                            placeholder="Select users"
                            className="custom-select"
                            isMulti
                          />
                          {showError && (
                            <div className="user-error">
                              This field is required
                            </div>
                          )}
                        </Form.Group>
                      </Col>
                    </Row>
                  )}
              </Modal.Body>
              {!modalLoading && (
                <Modal.Footer>
                  <Row>
                    <Col lg={12} xs={12} className="d-flex justify-content-end">
                      <Button
                        variant="secondary"
                        onClick={handleCloseAddTask}
                        className="cancel_btn me-3 btn-default "
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="primary"
                        type="submit"
                        className="save_btn btn_clr_white"
                      >
                        Save
                      </Button>
                    </Col>
                  </Row>
                </Modal.Footer>
              )}
              {modalLoading && <Loader />}
            </Form>
          </Modal>
        )}
        {showViewTask && (
          <Modal
            show={showViewTask}
            onHide={handleCloseViewTask}
            className="add_task_modal"
          >
            <Modal.Header closeButton>
              <Modal.Title>Task view</Modal.Title>
            </Modal.Header>
            <Modal.Body className="task_form">
              <Form noValidate validated={validated} onSubmit={editTask}>
                <Modal.Body>
                  <Row>
                    <Col lg={12}>
                      <Form.Group className="mb-3" controlId="name">
                        <Form.Label>
                          Task Name <span className="asterisk">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          value={taskTabData?.name}
                          placeholder="Enter Task Name"
                          name="name"
                          onChange={handleInput}
                          required
                          isInvalid={!!errors.name}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.name}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12}>
                      <Form.Group className="mb-3" controlId="description">
                        <Form.Label>Description</Form.Label>
                        <Form.Control
                          as="textarea"
                          placeholder="Leave a description here"
                          style={{ height: "100px" }}
                          value={taskTabData?.description}
                          name="description"
                          onChange={handleInput}
                          isInvalid={!!errors.description}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.description}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Row>
                        <Col lg={6}>
                          <Form.Group
                            className="mb-3 position-relative"
                            controlId="due_date"
                          >
                            <Form.Label>
                              Due date <span className="asterisk">*</span>{" "}
                            </Form.Label>
                            <DatePicker
                              ref={focusDate}
                              className="datepicker-payscheme"
                              selected={
                                dateInputs?.due_date
                                  ? dateInputs?.due_date
                                  : null
                              }
                              onChange={(date) =>
                                selectUploadDt(date, "due_date")
                              }
                              name="due_date"
                              dateFormat="MM-dd-yyyy"
                              dropdownMode="select"
                              showMonthDropdown
                              showYearDropdown
                              calendarIcon={true}
                              closeCalendar={true}
                              clearIcon={true}
                              required
                              isInvalid={!!errors.due_date}
                            />
                            <span
                              className="calendar-icon"
                              onClick={handleFocusDate}
                            ></span>
                            {validated &&
                              (dateInputs?.due_date === "" ||
                                dateInputs?.due_date === null ||
                                dateInputs?.due_date === undefined) && (
                                <div className="err-feedback">
                                  {errors?.due_date}
                                </div>
                              )}
                          </Form.Group>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  {taskTabData?.taskDocuments.length !== 0 && (
                    <p>Attachment(s)</p>
                  )}
                  {taskTabData?.taskDocuments.map((data, i) => {
                    return (
                      <Row key={i}>
                        <Col lg={8} xs={8}>
                          <a
                            href={data?.document_path}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="document"
                          >
                            {data?.friendly_name}
                          </a>
                        </Col>
                        <Col lg={1} xs={1}>
                          <span
                            className="document"
                            onClick={() => {
                              handleOpenDeleteDocument(data?.id);
                            }}
                          >
                            x
                          </span>
                        </Col>
                      </Row>
                    );
                  })}
                  <Row className="mt-3">
                    <p>Add New Attachment</p>
                    <Col xs={12}>
                      <FileUploader
                        multiple={false}
                        handleChange={handleDocumentChange}
                        onSizeError={handleSizeError}
                        onTypeError={handleFileTypeError}
                        name="file"
                        label={
                          file.length !== 0
                            ? "Uploaded Successfully!."
                            : " Drop your files here or browse."
                        }
                        types={fileTypes}
                        classes="employee-document-upload"
                        maxSize="10"
                        required
                      />
                      {validated &&
                        file?.length === 0 &&
                        parseInt(taskTabData.document_required) === 1 &&
                        taskTabData?.user_id === userid && (
                          <div className="err-feedback mt-2">
                            Please upload the file
                          </div>
                        )}
                    </Col>
                  </Row>
                  {clientRole !== "Employee" && clientRole !== "Contractor" && (
                    <Row className="mt-4">
                      <Col lg={6} className="mt-2">
                        <Form.Group className="mb-3" controlId="copy_upload">
                          <Form.Label className="me-2">
                            Add upload(s) to user files
                          </Form.Label>
                          <div>
                            <Form.Check
                              inline
                              label="Yes"
                              name="copy_upload"
                              type="radio"
                              checked={
                                taskTabData.copy_upload == 1 ? true : false
                              }
                              value={1}
                              onChange={(e) => handleStatus(e)}
                              id="yes"
                            />
                            <Form.Check
                              inline
                              id="no"
                              label="No"
                              name="copy_upload"
                              type="radio"
                              checked={
                                taskTabData.copy_upload == 2 ? true : false
                              }
                              value={2}
                              onChange={(e) => handleStatus(e)}
                            />
                          </div>
                        </Form.Group>
                      </Col>
                    </Row>
                  )}
                  {clientRole === "Employee" || clientRole === "Contractor" ? null : (
                    <>
                      {parseInt(taskTabData.copy_upload) === 1 && (
                        <Row className="mt-2">
                          <Col lg={6}>
                            <Form.Check
                              inline
                              label="Document upload is mandatory"
                              checked={
                                parseInt(taskTabData.document_required) === 1
                                  ? true
                                  : false
                              }
                              value={1}
                              onChange={(e) =>
                                handleCheckDocumentMandatoryChange(e)
                              }
                              name="document_required"
                              type="checkbox"
                              id="document_required"
                            />
                          </Col>
                        </Row>
                      )}
                    </>
                  )}
                  {clientRole !== "Employee" && clientRole !== "Contractor" && (
                    <Row className="mt-2">
                      <Col lg={6}>
                        <Form.Check
                          inline
                          label="Assign tasks to users"
                          checked={checkedUser}
                          onChange={() => setCheckedUser(toggleUser)}
                          name="assign_to_user"
                          type="checkbox"
                          id="assign_to_user"
                        />
                      </Col>
                    </Row>
                  )}
                  {clientRole !== "Employee" &&
                    clientRole !== "Contractor" &&
                    showSelectUsers && (
                      <Row className="mt-2">
                        <Col lg={12}>
                          <Form.Group
                            className="mb-3"
                            controlId="selectedUserList"
                          >
                            <Form.Label>Select users </Form.Label>
                            <Select
                              closeMenuOnSelect={true}
                              hideSelectedOptions={false}
                              options={formatSelectOptions(taskUserList)}
                              onChange={(selectedOption) =>
                                handleSelectUserOnChange(selectedOption)
                              }
                              value={selectedUserValue}
                              placeholder="Select users"
                              className="custom-select"
                              isMulti
                            />
                            {showError && (
                              <div className="user-error">
                                This field is required
                              </div>
                            )}
                          </Form.Group>
                        </Col>
                      </Row>
                    )}
                </Modal.Body>
                {!modalLoading && (
                  <Modal.Footer>
                    <Row>
                      <Col
                        md={3}
                        xs={12}
                        className="btn-delete"
                        onClick={handleOpenDeleteTask}
                      >
                        <Button className="cancel_btn d-flex align-items-center">
                          {/* <img src={deleteSvg} alt="" srcSet="" className="me-2" /> */}
                          Delete
                        </Button>
                      </Col>
                      <Col
                        md={9}
                        xs={12}
                        className="tasks_save_cancel_container"
                      >
                        <Button
                          variant="secondary"
                          onClick={handleCloseViewTask}
                          className="cancel_btn me-3 btn-default"
                        >
                          Cancel
                        </Button>
                        <Button
                          variant="primary"
                          type="submit"
                          className="save_btn btn_clr_white"
                        >
                          Save
                        </Button>
                      </Col>
                    </Row>
                  </Modal.Footer>
                )}
                {modalLoading && <Loader />}
              </Form>
            </Modal.Body>
          </Modal>
        )}

        {showViewCompletedTask && (
          <Modal
            show={showViewCompletedTask}
            onHide={handleCloseCompletedViewTask}
            className="add_task_modal"
          >
            <Modal.Header closeButton>
              <Modal.Title>View Completed Task</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Modal.Body>
                  <Row>
                    <Col lg={12}>
                      <Form.Group className="mb-3">
                        <Form.Label>
                          Task Name <span className="asterisk">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          value={taskTabData?.name}
                          name="name"
                          disabled
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col lg={12}>
                      <Form.Label>Description</Form.Label>
                      <Form.Control
                        as="textarea"
                        style={{ height: "100px" }}
                        value={taskTabData?.description}
                        name="description"
                        disabled
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6}>
                      <Form.Group
                        className="mb-3 position-relative"
                        controlId="due_date"
                      >
                        <Form.Label>
                          Due date <span className="asterisk">*</span>{" "}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          value={taskTabData?.due_date}
                          name="due_date"
                          disabled
                        />
                      </Form.Group>
                    </Col>
                    <Col lg={6}>
                      <Form.Group className="mb-3 position-relative">
                        <Form.Label>Completed</Form.Label>
                        <Form.Control
                          type="text"
                          value={taskTabData?.completed}
                          name="completed"
                          disabled
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  {taskTabData?.taskDocuments.length !== 0 && (
                    <p>Attachment(s)</p>
                  )}
                  {taskTabData?.taskDocuments.map((data, i) => {
                    return (
                      <Row key={i}>
                        <Col lg={12} xs={12}>
                          <a
                            href={data?.document_path}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="document"
                          >
                            {data?.friendly_name}
                          </a>
                        </Col>
                      </Row>
                    );
                  })}
                </Modal.Body>
                {!modalLoading && (
                  <Modal.Footer>
                    <Row>
                      <Col
                        lg={12}
                        xs={12}
                        className="d-flex justify-content-end"
                      >
                        <div
                          className="btn-delete1 save_btn btn_clr_white btn"
                          onClick={handleCompletedDeleteTask}
                        >
                          {/* <span>
                            <img src={deleteSvg} alt="" srcSet="" />
                          </span> */}
                          <span style={{ color: "#2773B1" }} className="ms-2">
                            Delete
                          </span>
                        </div>
                        <Button
                          variant="primary"
                          className="save_btn btn_clr_white"
                          onClick={handleReopenTask}
                        >
                          Reopen
                        </Button>
                      </Col>
                    </Row>
                  </Modal.Footer>
                )}
                {modalLoading && <Loader />}
              </Form>
            </Modal.Body>
          </Modal>
        )}

        {deleteDocument && (
          <FormModal
            heading="Are you sure about deleting the attachment?"
            show={deleteDocument}
            size="sm"
            onClose={() => handleCloseDeleteDocument()}
            backdrop="static"
            modalClassName="confirmation_modal"
          >
            <div className="text-center">
              <Button
                variant="secondary"
                onClick={() => handleCloseDeleteDocument()}
                className="cancel_btn btn-default "
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                className="btn_clr_white"
                style={{ marginLeft: "10px" }}
                onClick={handleOpenDeleteDocumentRespone}
              >
                Delete
              </Button>
            </div>
          </FormModal>
        )}

        {deleteTask && (
          <FormModal
            heading="Are you sure you want to delete ?"
            show={deleteTask}
            size="sm"
            onClose={() => setDeleteTask(false)}
            backdrop="static"
            modalClassName="confirmation_modal"
          >
            <div className="text-center">
              <Button
                variant="secondary"
                onClick={() => setDeleteTask(false)}
                className="cancel_btn btn-default"
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                className="btn_clr_white"
                style={{ marginLeft: "10px" }}
                onClick={handleOpenDeleteTaskRespone}
              >
                Delete
              </Button>
            </div>
          </FormModal>
        )}
      </div>
    </div>
  );
};

export default TasksTab;
