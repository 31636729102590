import React from "react";
import FormModal from "../../../components/FormModal";
import Button from "../../../components/Form/Button";

const ViewAcknowledgeModal = ({ show, onClose, selectedAcknowledgeDetails }) => {

    return (
        <FormModal heading={'Task details'} show={show} onClose={onClose} size="lg" backdrop="static" modalClassName="view_task_modal">
            <div className="onboard-task-title my-1">
                {selectedAcknowledgeDetails?.name}
                <div className="onboard-sub-title my-2">Due date :  {selectedAcknowledgeDetails?.due_date} </div>
                <div className="onboard-sub-title my-1">Completed date :  {selectedAcknowledgeDetails?.completed} </div>
            </div>
            <div className="onboard-body my-3">
                {selectedAcknowledgeDetails?.description}
                <div className="my-3">  Attachments : <a href={selectedAcknowledgeDetails?.url} target="_blank" rel="noopener noreferrer"> handbook-signed.pdf</a></div>
            </div>

            <div className="text-end">
                <Button
                    type="button"
                    className="btn-primary text-white"
                    onClick={onClose}
                >
                    Cancel
                </Button>
            </div>

        </FormModal>
    );
};

export default ViewAcknowledgeModal;
