import React, { useState } from "react";
import FormModal from "../../components/FormModal";
import { Form } from "react-bootstrap";
import Button from "../../components/Form/Button";
import Signature from "../../components/Signature";
import { postUploadData } from "../../api";
import { toast } from "react-toastify";
import {autocloseTiming} from '../../api/regex';

const ViewOfferLetterModal = ({ show, handleClose, offerletter, acknowledgedShow }) => {

    const [validated, setValidated] = useState(false);
    const [loading, setLoading] = useState(false);
    const [ackShow, setAckShow] = useState(true);
    const [signObject, setSignObject] = useState();

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        let formData = new FormData();
        formData.append('stage', "offer_sign");
        formData.append('signature_data', signObject);
        const res = await postUploadData("employee_offer_letter", {}, formData);
        if (res.status === true) {
            setLoading(false);
            acknowledgedShow(true)
            handleClose();
        } else {
             toast.error(res.message, { theme: "colored", autoClose: autocloseTiming });;
            setLoading(false);
        }

    };

    const acknowledgeShow = (data) => {
        setAckShow(data)
    }
    const signatureObj = (data) => {
        if (data?.drawSign !== '' || data?.uploadSign !== '') {
            if (data?.drawSign !== '') {
                setSignObject(data?.drawSign)
            } else {
                setSignObject(data?.uploadSign[0])
            }
        } else {
            setAckShow(true)
        }
    }
    return (
        <>

            <FormModal heading={'Offer letter'} show={show} onClose={handleClose} size="lg" backdrop="static" modalClassName="view_task_modal view-hand-book-modal">
                <div className="preview-tool">
                    <iframe src={offerletter?.document_path !== undefined && offerletter?.document_path} className="pdf-doc mb-4" name="pdf-view" height="263px" width="100%" title="Iframe Example"></iframe>
                </div>
                <div className="onboard-body mb-3">
                    I have read and agreed to the terms in the above document (Please draw or upload your signature to agree)
                </div>
                <Form noValidate validated={validated} onSubmit={handleSubmit} autoComplete="off" className="custom-form">
                    <Signature className="mb-3" acknowledgeShow={acknowledgeShow} signatureObj={signatureObj} />
                    <div className="text-end">
                        <Button
                            type="button"
                            className="btn-default text-blacksix me-3 cancel-btn"
                            onClick={handleClose}
                        >
                            Cancel
                        </Button>
                        <Button
                            type="submit"
                            disabled={ackShow}
                            loading={loading}
                            className="btn-primary text-white"
                        >
                            Agree
                        </Button>
                    </div>
                </Form>
            </FormModal>


        </>
    );
};

export default ViewOfferLetterModal;
