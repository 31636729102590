import React, { useState, useEffect, useRef } from "react";
import "./style.scss";

const CustomTimePicker = (props) => {
  const { onTimeChange, initialValue, editAddEntry } = props;
  const [selectedHour, setSelectedHour] = useState("");
  const [selectedMinute, setSelectedMinute] = useState("");
  const [amPm, setAmPm] = useState("");

  const isInitialValueSet = useRef(false);

  const hours = Array.from({ length: 12 }, (_, i) =>
    String(i + 1).padStart(2, "0")
  );
  const minutes = Array.from({ length: 60 }, (_, i) =>
    String(i).padStart(2, "0")
  );
  const amPmOptions = ["AM", "PM"];

  useEffect(() => {
    if (
      !isInitialValueSet.current &&
      initialValue &&
      initialValue.hour &&
      initialValue.minute &&
      initialValue.amPm
    ) {
      setSelectedHour(initialValue.hour);
      setSelectedMinute(initialValue.minute);
      setAmPm(initialValue.amPm);
      isInitialValueSet.current = true;
    }
  }, [initialValue]);

  useEffect(() => {
    if (selectedHour && selectedMinute && amPm) {
      onTimeChange(`${selectedHour}:${selectedMinute} ${amPm}`);
    } else {
      onTimeChange("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedHour, selectedMinute, amPm]);

  useEffect(() => {
    if (editAddEntry === false) {
      isInitialValueSet.current = false;
    }
  }, [editAddEntry]);

  const handleHourChange = (e) => {
    setSelectedHour(e.target.value);
    setSelectedMinute("00");
    setAmPm("AM");
  };

  const handleMinuteChange = (e) => {
    setSelectedMinute(e.target.value);
  };

  const handleAmPmChange = (e) => {
    setAmPm(e.target.value);
  };

  return (
    <div
      className="time-dropdown"
      style={{
        background:
          editAddEntry === false || selectedHour === "Invalid date"
            ? "#f0f0f0"
            : "#fff",
      }}
    >
      <div className="time-option">
        <select
          value={selectedHour}
          onChange={handleHourChange}
          className="time-select"
          disabled={editAddEntry === false}
        >
          <option value="">hh</option>
          {hours.map((hour) => (
            <option key={hour} value={hour}>
              {hour}
            </option>
          ))}
        </select>
        :
        <select
          value={selectedMinute}
          onChange={handleMinuteChange}
          className="time-select"
          disabled={
            editAddEntry === false ||
            selectedHour === "" ||
            selectedHour === "Invalid date"
          }
        >
          <option value="">mm</option>
          {minutes.map((minute) => (
            <option key={minute} value={minute}>
              {minute}
            </option>
          ))}
        </select>
        <select
          value={amPm}
          onChange={handleAmPmChange}
          className="time-select"
          disabled={
            editAddEntry === false ||
            selectedHour === "" ||
            selectedHour === "Invalid date"
          }
        >
          <option value="">AM/PM</option>
          {amPmOptions.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default CustomTimePicker;
