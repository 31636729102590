import React from "react";
import TimeCards from "../../components/timeCards";

export default function TimeCardsPage() {
  return (
    <>
      <TimeCards />
    </>
  );
}
