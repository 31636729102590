import React, { useState } from "react";
import ServerSideTable from "../ServerSideTable";
import "./style.scss";
import PlusIcon from "../../assets/images/timekeeping_icons/add.svg";
import ViewIcon from "../../assets/images/timekeeping_icons/visibility.svg";
import EditIcon from "../../assets/images/timekeeping_icons/edit.svg";
import ScheduleGreenIcon from "../../assets/images/timekeeping_icons/schedule.svg";
import PlayRedIcon from "../../assets/images/timekeeping_icons/play_arrow.svg";
import ExpandViewDetailModal from "./expandDetailModal";
import PlayModalComponent from "./playModal";

export default function ExpandDetail(props) {
  const { expandDetailData } = props;
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizeperPage] = useState(10);
  const [totalSize] = useState(0);
  const [employeeHoursData, setEmployeeHoursData] = useState(expandDetailData);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [isPlayModaOpen, setIsPlayModalOpen] = useState(false);
  const [playModalData, setPlayModalData] = useState(null);
  //   const [loading, setLoading] = useState(false);
  //   const currentStatusFormatter = (cell, row) => {
  //     return (
  //       <>
  //         {row?.status?.length === 1 ? (
  //           <div>{row?.status[0]}</div>
  //         ) : row?.status?.length === 0 ? (
  //           <div>{"N/A"}</div>
  //         ) : (
  //           <ul className="m-0 p-0 py-2">
  //             {row?.status?.map((data) => {
  //               return <li>{data}</li>;
  //             })}
  //           </ul>
  //         )}
  //       </>
  //     );
  //   };
  //   const ActionFormatter = (cell, row) => {
  //     return (
  //       <>
  //         {
  //           <div className="emp-action-status">
  //             {/* <Button onClick={() => handleLinkUser(row?.user_id)}>
  //               {row?.link}
  //             </Button> */}
  //           </div>
  //         }
  //       </>
  //     );
  //   };
  const handlePlusIconClick = (data, id) => {
    // setIsPlayModalOpen(true);
    const selectedRow = expandDetailData.find((item) => item.id === id);
    setModalData({ data, selectedRow });
  };
  const handleViewIconClick = (data, id) => {
    setIsModalOpen(true);
    const selectedRow = expandDetailData.find((item) => item.id === id);
    setModalData({ data, selectedRow });
  };
  const handlePlayIconClick = (data, id) => {
    setIsPlayModalOpen(true);
    const selectedRow = expandDetailData.find((item) => item.id === id);
    setPlayModalData({ data, selectedRow });
  };
  const handleEditIconClick = (data, id) => {
    // setIsModalOpen(true);
    const selectedRow = expandDetailData.find((item) => item.id === id);
    setModalData({ data, selectedRow });
  };
  const handleScheduleIconClick = (data, id) => {
    // setIsModalOpen(true);
    const selectedRow = expandDetailData.find((item) => item.id === id);
    setModalData({ data, selectedRow });
  };

  const handleCheckboxClick = () => {
    console.log("checked");
  };
  const renderActionCell = (cell, row) => {
    if (row.status === "unapproved") {
      return (
        <div className="emp-action-btn">
          <button onClick={() => handlePlusIconClick(cell, row.id)}>
            <img src={PlusIcon} alt="Pending" className="pe-2" />
          </button>
          <button onClick={() => handleViewIconClick(cell, row.id)}>
            <img src={ViewIcon} alt="Unapproved" className="pe-2" />
          </button>
          <button onClick={() => handleEditIconClick(cell, row.id)}>
            <img src={EditIcon} alt="Edit Approved" className="pe-2" />
          </button>
        </div>
      );
    } else if (row.status === "approved") {
      return (
        <div className="emp-action-btn">
          <button onClick={() => handleViewIconClick(cell, row.id)}>
            <img src={ViewIcon} alt="Unapproved" className="pe-2" />
          </button>
        </div>
      );
    } else if (row.status === "Pending") {
      return (
        <div className="emp-action-btn">
          <button onClick={() => handlePlusIconClick(cell, row.id)}>
            <img src={PlusIcon} alt="Pending" className="pe-2" />
          </button>
          <button onClick={() => handleViewIconClick(cell, row.id)}>
            <img src={ViewIcon} alt="Unapproved" className="pe-2" />
          </button>
          <button onClick={() => handleEditIconClick(cell, row.id)}>
            <img src={EditIcon} alt="Edit Approved" className="pe-2" />
          </button>
          <button onClick={() => handleScheduleIconClick(cell, row.id)}>
            <img src={ScheduleGreenIcon} alt="scheduke" className="pe-2" />
          </button>
        </div>
      );
    } else if (row.status === "editingRequest") {
      return (
        <div className="emp-action-btn">
          <button onClick={() => handlePlusIconClick(cell, row.id)}>
            <img src={PlusIcon} alt="Pending" className="pe-2" />
          </button>
          <button onClick={() => handlePlayIconClick(cell, row.id)}>
            <img src={PlayRedIcon} alt="play-icon" className="pe-2" />
          </button>
        </div>
      );
    }
    return null;
  };
  const columns = [
    {
      dataField: "data",
      text: "Date",
      headerStyle: { width: "25%", textAlign: "center" },
      style: { textAlign: "center" },
      formatter: (cellContent, row) => {
        return (
          <div className="d-flex align-items-center ">
            <input
              type="checkbox"
              onChange={() => handleCheckboxClick(row.id)}
              className="me-3"
            />
            {cellContent}
          </div>
        );
      },
    },
    {
      dataField: "in",
      text: "In",
      headerStyle: { width: "10.5%", textAlign: "left" },
      style: { textAlign: "left" },
    },
    {
      dataField: "out",
      text: "Out",
      headerStyle: { width: "10.5%", textAlign: "left" },
      style: { textAlign: "left" },
    },
    {
      dataField: "regularHours",
      text: "Regular Hours",
      headerStyle: { width: "16%", textAlign: "left" },
      style: { textAlign: "left" },
    },
    {
      dataField: "oTHours",
      text: "OT Hours",
      headerStyle: { width: "16%", textAlign: "left" },
      style: { textAlign: "left" },
    },
    {
      dataField: "totalHrs",
      text: "Total Hours",
      headerStyle: { width: "16%", textAlign: "left" },
      style: { textAlign: "left" },
    },
    {
      dataField: "statusName",
      text: "Status",
      headerStyle: { width: "16%", textAlign: "left" },
      style: { textAlign: "left" },
      formatter: (cellContent, row) => {
        let color;
        if (row.statusName === "Pending") {
          color = "#f5bc00";
        } else if (row.statusName === "Approved") {
          color = "#27c24c";
        } else if (row.statusName === "Unapproved") {
          color = "#f04b4b";
        } else {
          color = "#f04b4b";
        }
        return <div style={{ color }}>{cellContent}</div>;
      },
    },
    {
      dataField: "action",
      text: "Action",
      headerStyle: { width: "16%", textAlign: "left" },
      style: { textAlign: "left" },
      formatter: renderActionCell,
    },
  ];
  const updatedColumns = columns.map((column) => {
    if (column.dataField === "leave") {
      return {
        ...column,
        formatter: (cellContent, row) => {
          return (
            <>
              <div className="d-flex align-items-center">
                {row.data.toLowerCase().trim() === "sick leave" ? null : (
                  <input
                    type="checkbox"
                    onChange={() => handleCheckboxClick(row.id)}
                    className="me-3"
                  />
                )}
                {cellContent}
              </div>
            </>
          );
        },
      };
    } else {
      return {
        ...column,
      };
    }
  });

  // Assuming employeeHoursData holds the API response
  // const filteredRows = employeeHoursData.filter((row, index, self) => {
  //   console.log("rowData", row, index, self);
  //   return (
  //     index === self.findIndex((r) => r.data === row.data && r.data === "leave")
  //   );
  // });
  const onFilter = (page, sizePerPage, search) => {
    setPage(page);
    setSizeperPage(sizePerPage);
    setEmployeeHoursData(page, sizePerPage, search);
  };
  return (
    <>
      <div>
        <ServerSideTable
          columns={updatedColumns}
          data={employeeHoursData}
          page={page}
          sizePerPage={sizePerPage}
          totalSize={totalSize}
          onFilter={onFilter}
          noSearch
          // loading={loading}
        ></ServerSideTable>
      </div>
      {isModalOpen && (
        <ExpandViewDetailModal
          heading="Pay Period Summary"
          show={isModalOpen}
          modalData={modalData}
          setIsModalOpen={setIsModalOpen}
        />
      )}
      {isPlayModaOpen && (
        <PlayModalComponent
          heading="Timecard Preview"
          show={isPlayModaOpen}
          modalData={playModalData}
          setIsPlayModalOpen={setIsPlayModalOpen}
        />
      )}
    </>
  );
}
