import React from "react";
import closebutton from "../../assets/images/multi-card-approval/x.svg";
import { Button, Modal } from "react-bootstrap";

export default function ApprovalModal(props) {
  const {
    modalOpen,
    handleModalClose,
    approveText,
    handleApproveAllClick,
    allIds,
  } = props;
  return (
    <div>
      <Modal
        className="approval-final-modal"
        show={modalOpen}
        onHide={handleModalClose}
      >
        <Modal.Header className="modal-header-wrapper">
          <Modal.Title>
            <h4>Time card approval</h4>
          </Modal.Title>
          <div className="modal-close" onClick={handleModalClose}>
            <img src={closebutton} alt="closebutton" />
          </div>
        </Modal.Header>
        <Modal.Body className="modal-confirm-body-wrapper">
          <p>
            {`Are you sure you want to ${
              approveText === "pending" ? "un approve" : approveText
            } the selected time card ${
              allIds.length > 1 ? "entries" : "entry"
            }?`}
          </p>
        </Modal.Body>
        <Modal.Footer className="modal-footer-wrapper footer-bttn-flex-wrapper">
          <Button
            variant="primary"
            className="modal-btn-wrapper approval-confirm-bttn modal-footer-button"
            onClick={handleApproveAllClick}
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
