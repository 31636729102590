import React, { useState, useEffect, useContext, useCallback } from "react";
import { Form, Row, Col, Card } from "react-bootstrap";
import Button from "../../../components/Form/Button";
import { autocloseTiming } from "../../../api/regex";
import { postData, getData } from "../../../api";
import { toast } from "react-toastify";
import Loader from "../../../components/Loader";
import Signature from "../../../components/Signature";
import { EmployeeAgreementContext } from "../../../hooks/Context/EmployeeAgreementContext";

const EmployeeAgreement = (props) => {
  const { nextStep, onboardObject, reloadCallback, empAgreeTabActive } = props;

  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [employeeObject, setEmployeeObject] = useState({
    signature_data: "",
  });
  const [errors, setErrors] = useState({});
  const [empAgreement, setEmpAgreement] = useState(false);
  //   const [employeeAgreementData, setEmployeeAgreementData] = useState([]);
  const [ackShow, setAckShow] = useState(true);
  const [isIframeLoading, setIsIframeLoading] = useState(true);
  const [onboardData, setOnboardData] = useState([]);
  const empAgreeValueNew = useContext(EmployeeAgreementContext);
  const [empAgreeDownloadStatus, setEmpAgreeDownloadStatus] = useState(false);
  const [empAgreeDataChange, setEmpAgreeDataChange] = useState(false);
  const [agreementObject, setAgreementObject] = useState(null);
  const [signatureError, setSignatureError] = useState("");
  const [signatureData, setSignatureData] = useState("");

  const reloadCallbackMemoized = useCallback(reloadCallback, [reloadCallback]); // Add appropriate dependencies if needed
  useEffect(() => {
    // if (
    //   empAgreeValueNew?.empAgreeValue !== null &&
    //   empAgreeValueNew?.empAgreeValue !== "" &&
    //   empAgreeValueNew?.empAgreeValue !== undefined
    // ) {
    //   if (empAgreeValueNew?.empAgreeValue) {
    getContactOnboard();
    //   }
    // }
    reloadCallbackMemoized(); // Trigger the memoized callback
  }, [reloadCallbackMemoized]);
  useEffect(() => {
    if (onboardObject?.emp_agreement) {
      setEmployeeObject(onboardObject?.emp_agreement);
    }
    setEmpAgreement(onboardObject?.emp_agreement);
    setOnboardData(onboardObject);
    let contact_initial_store = {
      address: onboardObject?.contact_info?.address,
      city: onboardObject?.contact_info?.city,
      country: onboardObject?.contact_info?.country,
      phone_number: onboardObject?.contact_info?.phone_number,
      state: onboardObject?.contact_info?.state,
      zip: onboardObject?.contact_info?.zip,
    };
    // Store contact_info data in local storage
    localStorage.setItem("contact_info", JSON.stringify(contact_initial_store));

    setEmpAgreeDownloadStatus(false);
  }, [onboardObject]);

  const getContactOnboard = async () => {
    setLoading(true);
    const res = await getData("employee_onboarding_steps", {});
    if (res.status === true) {
      setLoading(false);
      setOnboardData(res.data);
      setAgreementObject(res.data?.emp_agreement_iframe);
      // const agreement = localStorage.getItem("employee_agreement");
      // if (agreement) {
      //   try {
      //     const parsedAgreement = JSON.parse(agreement);
      //     setAgreementObject(parsedAgreement);
      //   } catch (error) {
      //     console.error(
      //       "Error parsing employee_agreement from local storage:",
      //       error
      //     );
      //     setAgreementObject({});
      //   }
      // }
    } else {
      toast.error(res.message, {
        theme: "colored",
        autoClose: autocloseTiming,
      });
      setLoading(false);
    }
  };

  // Add this function at the beginning of your component or in a utility file
  const deepEqual = (obj1, obj2) => {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const storedContactInfoString = localStorage.getItem("contact_info");
    const storedContactInfo =
      storedContactInfoString !== undefined
        ? JSON.parse(storedContactInfoString)
        : null;
    // Compare stored data with the current form data
    let new_contact_store = {
      address: employeeObject?.address,
      city: employeeObject?.city,
      country: employeeObject?.country,
      phone_number: employeeObject?.phone_number,
      state: employeeObject?.state,
      zip: employeeObject?.zip,
    };
    const isDataChanged = !deepEqual(storedContactInfo, new_contact_store);
    setEmpAgreeDataChange(isDataChanged);
    if (signatureData === "" || !signatureData) {
      setSignatureError("Please Draw or Upload your Signature*");
    } else {
      setSignatureError("");
      if (
        onboardData?.employee_agreement_url !== "" &&
        onboardData?.employee_agreement_url !== undefined &&
        onboardData?.employee_agreement_url !== null
      ) {
        nextStep("emergency_contact");
      } else {
        if (empAgreement && isDataChanged) {
          empAgreePdfUrlContactOnboard();
        } else if (
          empAgreement &&
          !isDataChanged &&
          agreementObject?.agreement?.url !== undefined &&
          employeeObject?.signature_data !== undefined
        ) {
          empAgreeSubmitContactOnboard();
        } else if (
          empAgreement &&
          (isDataChanged || !isDataChanged) &&
          (agreementObject?.url ||
            agreementObject?.url !== undefined ||
            agreementObject?.url === undefined) &&
          (employeeObject?.signature_data === undefined ||
            employeeObject?.signature_data === "" ||
            employeeObject?.signature_data === null)
        ) {
          empAgreePdfUrlContactOnboard();
        } else {
          empAgreePdfUrlContactOnboard();
        }
      }
    }
  };
  const empAgreePdfUrlContactOnboard = async () => {
    setLoading(true);
    let data = {
      stage: "employee_agreement",
      emp_agreement: empAgreement,
      emp_agreement_state: "pdf_url",
      filename: agreementObject?.filename,
      ...employeeObject,
    };

    const res = await postData("employee_onboarding_steps", {}, data);
    if (res.status === true) {
      setLoading(false);
      setAgreementObject(res?.emp_agreement);
      nextStep(res?.next_steps);
      // To view the employee agreement view and download btn
      setEmpAgreeDownloadStatus(true);
      setEmpAgreeDataChange(true);
      let contact_store = {
        address: employeeObject?.address,
        city: employeeObject?.city,
        country: employeeObject?.country,
        phone_number: employeeObject?.phone_number,
        state: employeeObject?.state,
        zip: employeeObject?.zip,
      };
      // Store contact_info data in local storage
      localStorage.setItem("contact_info", JSON.stringify(contact_store));
    } else {
      toast.error(res.message, {
        theme: "colored",
        autoClose: autocloseTiming,
      });
      setLoading(false);
    }
  };

  const empAgreeSubmitContactOnboard = async () => {
    setLoading(true);
    let data = {
      stage: "employee_agreement",
      emp_agreement: empAgreement,
      emp_agreement_state: "submit",
      filename: agreementObject?.filename,
      ...employeeObject,
    };

    const res = await postData("employee_onboarding_steps", {}, data);
    if (res.status === true) {
      setLoading(false);
      setAgreementObject(res?.emp_agreement);
      nextStep(res?.next_steps);
      // To view the employee agreement view and download btn
      setEmpAgreeDownloadStatus(false);
      setEmpAgreeDataChange(false);
      let contact_store = {
        address: employeeObject?.address,
        city: employeeObject?.city,
        country: employeeObject?.country,
        phone_number: employeeObject?.phone_number,
        state: employeeObject?.state,
        zip: employeeObject?.zip,
      };
      // Store contact_info data in local storage
      localStorage.setItem("contact_info", JSON.stringify(contact_store));
    } else {
      toast.error(res.message, {
        theme: "colored",
        autoClose: autocloseTiming,
      });
      setLoading(false);
    }
  };

  const contactOnboard = async () => {
    setLoading(true);
    let data = {
      stage: "contact_info",
      emp_agreement: false,
      ...employeeObject,
    };

    const res = await postData("employee_onboarding_steps", {}, data);
    if (res.status === true) {
      setLoading(false);
      let contact_store = {
        address: employeeObject?.address,
        city: employeeObject?.city,
        country: employeeObject?.country,
        phone_number: employeeObject?.phone_number,
        state: employeeObject?.state,
        zip: employeeObject?.zip,
      };
      // Store contact_info data in local storage
      localStorage.setItem("contact_info", JSON.stringify(contact_store));

      nextStep(res.next_steps);
    } else {
      toast.error(res.message, {
        theme: "colored",
        autoClose: autocloseTiming,
      });
      setLoading(false);
    }
  };

  // sign
  const acknowledgeShow = (data) => {
    setAckShow(data);
  };
  const signatureObj = (data) => {
    if (!data?.drawSign && !data?.uploadSign) {
      setAckShow(true);
    } else {
      if (data?.drawSign !== "") {
        setEmployeeObject((prev) => ({
          ...prev,
          signature_data: data?.drawSign,
        }));
        setSignatureData((prev) => ({
          ...prev,
          signature_data: data?.drawSign,
        }));
        setErrors({
          ...errors,
          signature_data: null,
        });
      } else {
        setEmployeeObject((prev) => ({
          ...prev,
          signature_data: data?.uploadSign[0],
        }));
        setSignatureData((prev) => ({
          ...prev,
          signature_data: data?.uploadSign[0],
        }));
        setErrors({
          ...errors,
          signature_data: null,
        });
      }
    }
  };
  const handleIframeLoad = () => {
    setIsIframeLoading(false);
  };
  useEffect(() => {
    if (
      onboardData?.employee_agreement_url !== "" &&
      onboardData?.employee_agreement_url !== undefined &&
      onboardData?.employee_agreement_url !== null
    )
      setSignatureData("");
  }, [onboardData?.employee_agreement_url]);
  return (
    <>
      {!loading && (
        <>
          <div className="board-right board-tab-sections">
            <h5 className="mb-3">Employee Agreement</h5>

            <Form
              noValidate
              validated={validated}
              onSubmit={handleSubmit}
              autoComplete="off"
              className="custom-form"
            >
              {onboardData &&
              onboardData?.employee_agreement_url !== "" ? null : (
                <>
                  {agreementObject && agreementObject?.url !== null && (
                    <div className="iframe-wrapper">
                      {isIframeLoading && (
                        <div className="emp-iframe-loading">
                          <Loader />
                        </div>
                      )}
                      <div className="preview-tool">
                        <iframe
                          src={agreementObject !== null && agreementObject?.url}
                          // className="mb-4 "
                          name="pdf-view"
                          height="478px"
                          width="100%"
                          title="Iframe Example"
                          onLoad={handleIframeLoad}
                        ></iframe>
                      </div>
                      <Row>
                        <Col md={6}>
                          <Signature
                            className="mb-3"
                            acknowledgeShow={acknowledgeShow}
                            signatureObj={signatureObj}
                            signatureError={signatureError}
                            setSignatureError={setSignatureError}
                          />
                        </Col>
                        <div className="button-wrapper">
                          <Button
                            type="button"
                            className="btn-outline-primary me-2"
                            onClick={() => nextStep("contact_info")}
                          >
                            Back
                          </Button>
                          <Button
                            type="submit"
                            disabled={loading}
                            loading={loading}
                            className="btn-primary text-white"
                          >
                            Save & Continue
                          </Button>
                        </div>
                      </Row>
                    </div>
                  )}
                </>
              )}

              {onboardData &&
                onboardData?.employee_agreement_url !== "" &&
                onboardData?.employee_agreement_url !== undefined &&
                onboardData?.employee_agreement_url !== null && (
                  <div>
                    <Row className="my-3">
                      <Col md="12">
                        <Card className="task_card white_bg_card">
                          <Card.Body className="my-1">
                            <div className="d-flex align-items-center">
                              <div className="task_detail">
                                <p className="task_title">
                                  Employee agreement{" "}
                                </p>
                              </div>
                              <div className="task_action text-end">
                                {
                                  <a
                                    href={onboardData?.employee_agreement_url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="btn-success text-white success-btn offer-view-link"
                                  >
                                    View & Download
                                  </a>
                                }
                              </div>
                            </div>
                          </Card.Body>
                        </Card>
                      </Col>
                    </Row>
                  </div>
                )}
              {onboardData &&
                onboardData?.employee_agreement_url !== "" &&
                onboardData?.employee_agreement_url !== undefined &&
                onboardData?.employee_agreement_url !== null && (
                  <div className="text-end mt-4">
                    <Button
                      type="button"
                      className="btn-outline-primary me-2"
                      onClick={() => nextStep("contact_info")}
                    >
                      Back
                    </Button>
                    <Button
                      type="submit"
                      disabled={loading}
                      loading={loading}
                      onClick={() => nextStep("emergency_contact")}
                      className="btn-primary text-white"
                    >
                      Save & Continue
                    </Button>
                  </div>
                )}
            </Form>
          </div>
        </>
      )}
      {loading && <Loader />}
    </>
  );
};

export default EmployeeAgreement;
