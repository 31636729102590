
import React, { useState, useEffect, useContext } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import "./style.scss";
import logo from "../../assets/images/mobile_logo.svg";
import sideExpand from '../../assets/images/side_expand.png'
import sideClose from '../../assets/images/side_close.png';
// import user from '../../assets/images/user.svg'
import noUser from '../../assets/images/no-user.svg';
// import cakeLogo from '../../assets/images/cake_logo.jpg'
import mobileSettingImg from "../../assets/images/setting_mobile.svg"
import faqMobileIcon from '../../assets/images/help_mobile_header.svg'
import logoutMobileIcon from '../../assets/images/logout_mobile_icon.svg'
import { UserContext } from "../../hooks/Context/UserContext";
import { getCurrentUser, cakePhpURL, reactURL } from '../../api';
import { FaRegBell } from "react-icons/fa";



const MobileHeader = ({ headerMenus }) => {
    let navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [sidebarExpand, setSidebarExpand] = useState(false);
    const { userProfileImage, updateUserProfileImage } = useContext(UserContext);
    const companyLogo = headerMenus?.userInfo?.company_logo !== null || headerMenus?.userInfo?.company_logo !== '' ? headerMenus?.userInfo?.company_logo : logo;
    const [clientName, setClientName] = useState(null)
    // const companyLogo = cakeLogo
    // const userImg = noUser
    useEffect(() => {
        if (headerMenus?.userInfo?.profile_pic) {
            updateUserProfileImage(headerMenus.userInfo.profile_pic);
        } else {
            updateUserProfileImage(noUser);
        }
    }, [headerMenus?.userInfo?.profile_pic]);// eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => {
        if (sidebarExpand) {
            document.body.classList.add('mobile-minimize-sidebar');
        } else {
            document.body.classList.remove("mobile-minimize-sidebar")
        }

    }, [sidebarExpand])



    useEffect(() => {
        let name = headerMenus?.userInfo?.client_name.toUpperCase()
        setClientName(name)
    }, [headerMenus?.userInfo?.client_name]);

    const handleExpand = () => {
        setSidebarExpand(!sidebarExpand)
    }

    const handleOpen = () => {
        setSidebarExpand(true)
        setOpen(!open)

    }

    const logOut = async () => {
        if (getCurrentUser() === null || getCurrentUser() === undefined || getCurrentUser() === "") {
            navigate("/login");
        } else {
            window.open(cakePhpURL + "users/logout", "_self");
        }
    }

    const handleNotificationsClick = () => {
        window.open(reactURL + "profile#signforms", "_self");
    };

    return (
        <>

            <div className="mobile-sidebar-view">
                <div className="mobile-logo">
                    <div className="expand-logo d-flex align-item-center">
                        <div><img src={`${sidebarExpand ? sideClose : sideExpand}`} alt="side expand" className="me-2" onClick={handleExpand} /></div>
                        {
                            companyLogo ?
                                <div><img src={companyLogo} alt="logo" className="logo-icon mobileLogoIcon" /> </div> :
                                <div className="max-logo-new color_set mob__header_size">
                                    {clientName}
                                </div>
                        }
                        {/* <img src={companyLogo ? companyLogo : logo} alt="logo" className="logo-icon mobileLogoIcon" /> */}
                    </div>
                    <div className="mobile-with-bell">
                        {headerMenus?.header?.filter(item => item.name === "Notifications").map((item, i) => (
                            <div key={i} className={item.name === "Notifications" && "notify_bell"}>
                                <a
                                    href={item?.link}
                                    onClick={item.link === "/profile#signforms" ? handleNotificationsClick : undefined}
                                >
                                    <FaRegBell className="bell mobile-bell" />
                                </a>
                            </div>
                        ))}

                        <div onClick={handleOpen} > <img src={userProfileImage ? userProfileImage : noUser} alt="user" className="userprofileImg img-circle" /></div>
                    </div>

                </div>
                <div>
                    <div className={`${open ? 'd-block signout-dropdown' : 'd-none'}`}>
                        <div className="sub-menu mobile-submenu">
                            {
                                headerMenus?.header?.filter(item => item.name !== "Notifications").map((item, i) => (
                                    <a href={item?.link} className="nav-link" key={i} target={item.name === 'FAQ' ? '_blank' : '_self'}>
                                        <div className="nav-text py-2 mobile_menu_gap">
                                            {item.name === "Settings" && <img src={mobileSettingImg} alt="side-icon" />}
                                            {item.name === "FAQ" && <img src={faqMobileIcon} alt="side-icon" />}
                                            <div>{item.name}</div>
                                        </div>
                                    </a>
                                ))
                            }

                            <div className="pt-2 mobile_menu_gap" onClick={() => logOut()}>
                                <img src={logoutMobileIcon} alt="" />
                                Log Out
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default MobileHeader;
