import React, { createContext, useState } from "react";

export const SignContext = createContext();

export const SignContextProvider = ({ children }) => {
  const [eSignValue, setEsignValue] = useState(null);

  return (
    <SignContext.Provider value={{ eSignValue, setEsignValue }}>
      {children}
    </SignContext.Provider>
  );
};