import React from "react";
import { Col, Row, Card, Container } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import backIcon from "../../assets/images/back_icon.svg";
import "./style.scss";
import OnboardHeading from "../OnboardHeading";
import BackButtonComponent from "../backButton";
import { getRevertUser } from "../../api";

export default function IFrame() {
  const location = useLocation();
  const navigate = useNavigate();
  const receivedData = location.state || {};
  return (
    <Container fluid className="new_company">
      <Row>
        <Col md={getRevertUser() ? 10 : 12} sm={getRevertUser() ? 8 : 12}>
          <OnboardHeading title={receivedData?.title} subtitle={receivedData?.subTitle} />
          <BackButtonComponent hasHistoryback={true} />
        </Col>
      </Row>
      <div className="exiting-payroll mb-5 px-1">
        <div className="header mb-3">
          <div className="run-payroll mb-3">
            <Row>
              <Col md={12}>
                <Card>
                  <Card.Header className="ps-4">
                    {receivedData?.name}
                  </Card.Header>
                  <Card.Body>
                    <div className="emp-state-tax-view">
                      <iframe
                        src={receivedData?.url}
                        name="emp-state-tax-view"
                        // height="100%"
                        width="100%"
                        title="Iframe Example"
                      ></iframe>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </Container>
  );
}
