export const everyWeek = [
    {
        label: "Name",
        type : "text",
        name: "name",
    },
    {
        label: "First pay date",
        type : "date",
        name: "firstphase"
    },
    {
        label: "Deadline to run payroll",
        type : "date",
        name: "deadline"

    },
    {
        label: "First pay period end date",
        type : "date",
        name : "secondphase"
    },
    {
        label: "Total Employee",
        type : "text",
        name : "employee_count"
    },

]

export const twiceMonth = [
    {
        label: "Name",
        type : "text",
        name: "name",
    },
    {
        label: "First pay date",
        type : "date",
        name: "firstphase"
    },
    {
        label: "Deadline to run payroll",
        type : "date",
        name: "deadline"

    },
    {
        label: "First pay day of month",
        type : "text",
        name : "first_pay_day"
    },
    {
        label: "Second pay day of month",
        type : "text",
        name : "second_pay_day"
    },
    {
        label: "First pay period end date",
        type : "date",
        name : "secondphase"
    },
    {
        label: "Total Employee",
        type : "text",
        name : "employee_count"
    },
    
]