import React from "react";
import MultiCardApprovalComponent from "../../components/multiCardApproval";

export default function MultiCardApprovalPage() {
  return (
    <div>
      <MultiCardApprovalComponent />
    </div>
  );
}
