import React from "react";
import ReportsCSVFilterComponent from "../../components/reportsCSVFilter";

export default function ReportsCSVFilter() {
  return (
    <div>
      <ReportsCSVFilterComponent />
    </div>
  );
}
