import React, { useState } from "react";
import TimeLapseImage from "../../assets/images/timekeeping_icons/time_Lapse.svg";
import "./style.scss";
import { Pagination } from "react-bootstrap";

const profileArray = [
  {
    profileName: "Devi active",
    date: "01/11/2023",
    time: "8 hours",
    category: "VC",
  },
  {
    profileName: "Keerthana active",
    date: "31/12/2023",
    time: "8 hours",
    category: "VC",
  },
  {
    profileName: "Jeya singh active",
    date: "22/12/2023",
    time: "8 hours",
    category: "VC",
  },
  {
    profileName: "Shylaja active",
    date: "14/12/2023",
    time: "8 hours",
    category: "VC",
  },
  {
    profileName: "Muralitharan active",
    date: "28/12/2023",
    time: "8 hours",
    category: "VC",
  },
  {
    profileName: "Guhan Active",
    date: "16/12/2023",
    time: "8 hours",
    category: "VC",
  },
  {
    profileName: "Sriram active",
    date: "01/12/2023",
    time: "8 hours",
    category: "VC",
  },
];
export default function TimeOffComponent() {
  const [currentPage, setCurrentPage] = useState(1);
  const profilesPerPage = 4;

  const totalPages = Math.ceil(profileArray.length / profilesPerPage);

  const displayedProfiles = profileArray.slice(
    (currentPage - 1) * profilesPerPage,
    currentPage * profilesPerPage
  );
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const handleLastPage = () => {
    setCurrentPage(totalPages);
  };
  return (
    <div>
      <div className="title-wrapper">
        <img src={TimeLapseImage} alt="pay-record" />
        <h5>Time off requests</h5>
      </div>
      <div className="view-all-request-wrapper">
        <p>View All Requests</p>
      </div>
      <div className="profile-main-container">
        {displayedProfiles.map((item, index) => (
          <div className="profile-wrapper">
            <div className="profile-sub-wrapper">
              <h5>{item.profileName}</h5>
              <div className="profile-btn-wrapper">
                <button className="me-3">Approve</button>
                <button className="ms-1">Deny</button>
              </div>
            </div>
            <div className="sub-text-wrapper">
              <p>{item.date}</p>
              <h3>{item.category}</h3>
              <h5>{item.time}</h5>
            </div>
          </div>
        ))}
        <Pagination>
          {Array.from({ length: totalPages }).map((_, index) => (
            <Pagination.Item
              key={index + 1}
              active={index + 1 === currentPage}
              onClick={() => handlePageChange(index + 1)}
            >
              {index + 1}
            </Pagination.Item>
          ))}
          <Pagination.Last onClick={handleLastPage} />
        </Pagination>
      </div>
    </div>
  );
}
