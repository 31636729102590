import React from "react";
import "./style.scss";
import { Col, Row, Card } from "react-bootstrap";

export default function RunPayroll({ runPayrollLink,name }) {
  return (
    <div className="exiting-payroll mb-5 px-1">
      <div className="header mb-3">
        <div className="run-payroll mb-3">
          <Row>
            <Col md={12}>
              <Card>
                <Card.Header className="ps-4">{name}</Card.Header>
                <Card.Body>
                  <div className="emp-state-tax-view">
                    <iframe
                      src={runPayrollLink}
                      name="emp-state-tax-view"
                      height="100%"
                      width="100%"
                      title="Iframe Example"
                    ></iframe>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}
