import React, { useEffect } from "react";
import RemoveIcon from "../../assets/images/time-off-cards-icons/time-off-close-icon.svg";
import UpArrow from "../../assets/images/time-off-cards-icons/upArrow.svg";
import DownArrow from "../../assets/images/time-off-cards-icons/downArrow.svg";
import moment from "moment";

export default function SelectedDatesTile(props) {
  const {
    selectedDateArray,
    setSelectedDateArray,
    setDateRange,
    hoursArray,
    setHoursArray,
    // handleValidation,
    individualError = [],
    insufficientError = [],
    userInfo,
    setRemainingHours,
  } = props;

  const sortedDates = selectedDateArray
    ? [...selectedDateArray].sort(
        (a, b) =>
          new Date(a.year, a.month - 1, a.day) -
          new Date(b.year, b.month - 1, b.day)
      )
    : [];

  const firstDate =
    sortedDates.length > 0
      ? moment(
          `${sortedDates[0].month}-${sortedDates[0].day}-${sortedDates[0].year}`,
          "MM-DD-YYYY"
        ).format("MM-DD-YYYY")
      : null;

  const lastDate =
    sortedDates.length > 0
      ? moment(
          `${sortedDates[sortedDates.length - 1].month}-${
            sortedDates[sortedDates.length - 1].day
          }-${sortedDates[sortedDates.length - 1].year}`,
          "MM-DD-YYYY"
        ).format("MM-DD-YYYY")
      : null;

  useEffect(() => {
    setHoursArray((prevArray) =>
      selectedDateArray.map((date, index) => {
        const day = moment(
          `${date.year}-${date.month}-${date.day}`,
          "YYYY-MM-DD"
        ).day();
        if (prevArray[index] !== undefined) {
          return prevArray[index];
        } else {
          return (day === 0 || day === 6 ? 0.0 : 8.0).toFixed(2);
        }
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDateArray]);
  const handleIncrement = (index) => {
    setHoursArray((prevArray) =>
      prevArray.map((val, i) =>
        i === index
          ? Math.min(
              parseFloat((parseFloat(val) + 0.5).toFixed(2)),
              8.0
            ).toFixed(2)
          : val
      )
    );
    // handleValidation();
  };

  const handleDecrement = (index) => {
    setHoursArray((prevArray) =>
      prevArray.map((val, i) =>
        i === index
          ? Math.max(
              parseFloat((parseFloat(val) - 0.5).toFixed(2)),
              0.01
            ).toFixed(2)
          : val
      )
    );
    // handleValidation();
  };

  const handleInputChange = (e, index) => {
    let newValue = e.target.value;
    if (newValue === "" || parseFloat(newValue) === 0) {
      setHoursArray((prevArray) =>
        prevArray.map((val, i) => (i === index ? "0.01" : val))
      );
    } else {
      setHoursArray((prevArray) =>
        prevArray.map((val, i) => (i === index ? newValue : val))
      );
    }
    // handleValidation();
  };

  const handleInputBlur = (index) => {
    setHoursArray((prevArray) =>
      prevArray.map((val, i) => {
        if (i === index) {
          let newValue = parseFloat(val);
          if (isNaN(newValue) || newValue === 0) {
            newValue = 0.01;
          } else {
            newValue = Math.max(0.01, Math.min(8.0, newValue));
          }
          return newValue.toFixed(2);
        }
        return val;
      })
    );
    // handleValidation();
  };

  const handleRemove = (index) => {
    setSelectedDateArray((prevArray) => {
      const newArray = prevArray.slice(0, index);
      if (newArray.length === 0) {
        setDateRange([
          {
            startDate: new Date(),
            endDate: new Date(),
            key: "selection",
          },
        ]);
        setRemainingHours(0);
      } else {
        const newStartDate = new Date(
          newArray[0].year,
          newArray[0].month - 1,
          newArray[0].day
        );
        const newEndDate = new Date(
          newArray[newArray.length - 1].year,
          newArray[newArray.length - 1].month - 1,
          newArray[newArray.length - 1].day
        );
        setDateRange([
          {
            startDate: newStartDate,
            endDate: newEndDate,
            key: "selection",
          },
        ]);
      }
      return newArray;
    });
    setHoursArray((prevArray) => prevArray.slice(0, index));
    // setTimeout(() => {
    //   handleValidation();
    // }, 2000);
  };

  const generalError =
    insufficientError.length > 0 ? insufficientError[0] : null;

  return (
    <div className="time-off-common-border-wrapper selected-dates-container">
      {selectedDateArray && selectedDateArray.length > 0 ? (
        <div
          className={
            userInfo?.group_id === 5 || userInfo?.group_id === 8
              ? "selected-dates-tiles-container selected-date-employee-tiles"
              : "selected-dates-tiles-container selected-date-client-tiles"
          }
        >
          <div className="selected-date-range-title">
            <h5>Time off requested dates</h5>
            <p>
              Requested from ({firstDate} to {lastDate})
            </p>
            <p>Total no. of days: {selectedDateArray.length} days</p>
          </div>
          <>
            {
              // insufficientError.length > 0
              //   ?
              generalError && (
                <div className="error-message">
                  <p>{generalError}</p>
                </div>
              )
              // : ""
            }
          </>
          {selectedDateArray.map((date, index) => {
            const formattedDate = moment(
              `${date.year}-${date.month}-${date.day}`,
              "YYYY-MM-DD"
            ).format("YYYY-MM-DD");
            const error = individualError.find(
              (error) => error.date === formattedDate
            );
            return (
              <>
                <div key={index} className="selected-card-tiles-main-wrapper">
                  <div className="selected-card-wrapper">
                    <h5>
                      Leave request for{" "}
                      {moment(
                        `${date.month}-${date.day}-${date.year}`,
                        "MM-DD-YYYY"
                      ).format("MM-DD-YYYY")}
                    </h5>
                    <div className="d-flex select-card-sub-wrapper">
                      <div className="hours-selector">
                        <div className="hours-text-sub-wrapper">
                          <input
                            type="number"
                            step="0.5"
                            value={hoursArray[index]}
                            onChange={(e) => handleInputChange(e, index)}
                            onBlur={() => handleInputBlur(index)}
                            min="0"
                            max="8"
                            style={{ width: "60px", textAlign: "center" }}
                          />
                          <div className="hours-btn-wrapper">
                            <img
                              src={UpArrow}
                              alt="up-arrow"
                              onClick={
                                parseFloat(hoursArray[index]) === 8.0
                                  ? null
                                  : () => handleIncrement(index)
                              }
                              style={{
                                cursor:
                                  parseFloat(hoursArray[index]) === 8.0
                                    ? "not-allowed"
                                    : "pointer",
                                opacity:
                                  parseFloat(hoursArray[index]) === 8.0
                                    ? 0.5
                                    : 1,
                              }}
                            />
                            <img
                              src={DownArrow}
                              alt="down-arrow"
                              onClick={
                                parseFloat(hoursArray[index]) === 0.5 ||
                                parseFloat(hoursArray[index]) === 0.0
                                  ? null
                                  : () => handleDecrement(index)
                              }
                              style={{
                                cursor:
                                  parseFloat(hoursArray[index]) === 0.5 ||
                                  parseFloat(hoursArray[index]) === 0.0
                                    ? "not-allowed"
                                    : "pointer",
                                opacity:
                                  parseFloat(hoursArray[index]) === 0.5 ||
                                  parseFloat(hoursArray[index]) === 0.0
                                    ? 0.5
                                    : 1,
                              }}
                            />
                          </div>
                        </div>
                        <p>hours</p>
                      </div>
                      <div className="close-icon-wrapper">
                        <img
                          src={RemoveIcon}
                          alt="close-icon"
                          onClick={() => handleRemove(index)}
                        />
                      </div>
                    </div>
                  </div>
                </div>{" "}
                {error && error.message && (
                  <div className="error-message">
                    <p>{error.message}</p>
                  </div>
                )}
              </>
            );
          })}
        </div>
      ) : (
        <div className="selected-dates-wrapper">
          <div className="no-date-selecte-text">
            <h5>No date selected</h5>
            <p>Please select time off request date from the calendar</p>
          </div>
        </div>
      )}
    </div>
  );
}