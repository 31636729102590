import React from "react";
import { Modal } from "react-bootstrap";
// import CloseButton from "react-bootstrap/CloseButton";
import "../FormModal/style.scss";
import "./style.scss";

export default function ExpandViewDetailModal(props) {
  const { show, onClose, size, modalData, setIsModalOpen } = props;
  const handleClose = () => {
    setIsModalOpen(false);
  };
  return (
    <div>
      <Modal
        show={show}
        centered
        onHide={onClose}
        size={size}
        className="modal-main-wrapper"
      >
        <Modal.Header className="modal-expand-header">
          <div className="form-modal">
            <Modal.Title className="modal-tile-expand">
              View details
            </Modal.Title>
            {/* {modalData.userName
                ? `Are you sure you want to ${modalData.data} ${modalData.userName}'s time cards?`
                : `Are you sure you want to ${modalData.data} time cards?`} */}
          </div>
        </Modal.Header>
        <Modal.Body className="modal-expand-body">
          <div>
            <div className="d-flex justify-content-between modal-wrap-class">
              <div className="modal-expand-content-title">
                <h5>Date</h5>
                <p>{modalData?.selectedRow?.data}</p>
              </div>
              <div className="modal-expand-content-title">
                <h5>In</h5>
                <p>{modalData?.selectedRow?.in}</p>
              </div>
              <div className="modal-expand-content-title">
                <h5>Out</h5>
                <p>{modalData?.selectedRow?.out}</p>
              </div>
              <div className="modal-expand-content-title">
                <h5>Total Hours</h5>
                <p>{modalData?.selectedRow?.totalHrs}</p>
              </div>
              <div className="modal-expand-content-title">
                <h5>Status</h5>
                <p
                  className={`text-${modalData?.selectedRow?.statusName.toLowerCase()}`}
                >
                  {modalData?.selectedRow?.statusName}
                </p>
              </div>
            </div>
            <div className="modal-expand-content-title width-wrapper-cus">
              <h5>Address</h5>
              <p>{modalData?.selectedRow?.address}</p>
            </div>
            <div className="modal-expand-content-title">
              <h5>Note</h5>
              <p>In</p>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="modal-expand-footer">
          <div className="modal-btn-wrapper d-flex button-gap justify-content-end ">
            <button
              className="deny-all-button deny-active-btn"
              onClick={handleClose}
            >
              Cancel
            </button>
            {/* <button
              className="approve-all-button active-btn"
              onClick={handleClose}
            >
              Confirm
            </button> */}
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
