import React, { useState } from "react";
import FormModal from "../../../components/FormModal";
import Button from "../../../components/Form/Button";
import { useNavigate } from "react-router-dom";
import { getCurrentUser, cakePhpURL } from '../../../api';
import checkCircle from '../../../assets/images/check_circle.svg'
import Loader from "../../../components/Loader";

const SuccessSubmitModal = ({ show, handleClose, autoUrl, user }) => {
    let navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const logOut = async () => {

        if (getCurrentUser() === null || getCurrentUser() === undefined || getCurrentUser() === "") {
            navigate("/login");
        } else {
            window.open(cakePhpURL + "users/logout", "_self");
        }

    }

    const dashboard = async () => {
        window.open(autoUrl, "_self");
    }

    return (
        <>
            {!loading && (
                <FormModal show={show} onClose={handleClose} size="md" backdrop="static" modalClassName="successModal">
                    <div>
                        <div><img src={checkCircle} alt="icon" /></div>

                        {/* 1. is_comany_user - client admin =>employee and yes signatatory
                        2. is_normal_user - add employee user
                        3. is_signatory_user - no Signatatory user */}

                        {user?.is_comany_user &&
                            <>
                                <h5>  Almost Done </h5>
                                <div className="sub-title py-2">
                                    We'll need a few minutes to complete setup of your account. As soon as the next steps are ready for you to complete we will email you a notification when you can verify Company Bank Details and Enter your State Tax Information.
                                    {/* Please log in again after some time and navigate to the company settings section where you can complete the company set up by following these steps:
                                    <div className="d-flex justify-content-center py-3">
                                        <ul className="text-start">
                                            <li> Verify Company bank details.</li>
                                            <li>  Enter the state tax information.</li>
                                        </ul>
                                    </div> */}
                                </div>
                            </>}
                        {user?.is_normal_user &&
                            <>
                                <h5> Almost Done </h5>
                                <div className="sub-title py-2">
                                    We are preparing your forms to finalize your onboarding process, be on the lookout for an email notifying you when they are ready.
                                    {/* Soon you’ll be able to complete the onboarding process by entering your state tax details. Please login back after some time to enter your state tax details. */}
                                </div>
                            </>}
                        {
                            user?.is_signatory_user &&
                            <>
                                <h5>You have successfully created your account.</h5>
                                <div className="sub-title py-2">
                                    As the signatory, there are some forms you must complete for your company to complete onboarding. Please proceed to the dashboard to complete those forms.
                                </div>
                            </>
                        }

                        <div className="my-3">
                            {
                                user?.is_signatory_user ? '' :
                                    <Button
                                        type="button"
                                        className="btn-outline-primary me-2"
                                        onClick={() => logOut()}
                                    >
                                        Log out
                                    </Button>
                            }
                            <Button
                                type="button"
                                className="btn-primary text-white"
                                onClick={() => dashboard()}
                            >
                                View dashboard
                            </Button>
                        </div>
                    </div>
                </FormModal>
            )
            }
            {loading && <Loader />}
        </>
    );
};

export default SuccessSubmitModal;
