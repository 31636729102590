import React from "react";
import {
  FormControl,
  FormGroup,
  FormLabel,
  Modal,
  Spinner,
} from "react-bootstrap";
import closebutton from "../../assets/images/multi-card-approval/x.svg";
import { getData, postData } from "../../api";
import { toast } from "react-toastify";
import { autocloseTiming } from "../../api/regex";
import Select from "react-select";

export default function FilterModal(props) {
  const {
    filterState,
    setFilterState,
    handleFilterModalClose,
    clientFilterOptions,
    setFilterErrors,
    filterErrors,
    groupId,
  } = props;
  const {
    showFilterModal,
    dates,
    allowExport,
    filterBtnLoading,
    exportBtnLoading,
    individualFilterData,
    filterApiData,
    selectedClient,
  } = filterState;
  const handleError = (message) => {
    toast.error(message, { theme: "colored", autoClose: autocloseTiming });
  };
  const handleSuccess = (message) => {
    toast.success(message, { theme: "colored", autoClose: 3000 });
  };

  const handleDateChange = (event, title) => {
    const value = event.target.value;
    setFilterState((prevState) => {
      if (title === "Start Date") {
        return {
          ...prevState,
          dates: {
            startDate: value,
            endDate:
              value &&
              prevState.dates.endDate &&
              value <= prevState.dates.endDate
                ? prevState.dates.endDate
                : null,
          },
          allowExport:
            value &&
            prevState.dates.endDate &&
            value === prevState.dates.startDate
              ? prevState.allowExport
              : false,
        };
      } else if (title === "End Date") {
        return {
          ...prevState,
          dates: {
            ...prevState.dates,
            endDate: value,
          },
          allowExport: value ? prevState.allowExport : false,
        };
      }
      return prevState;
    });
  };

  const handleEnableExport = async () => {
    setFilterState((prevState) => ({
      ...prevState,
      filterBtnLoading: true,
    }));
    const exportPayload = {
      report_id: individualFilterData?.id,
      start_date: dates?.startDate,
      end_date: dates?.endDate,
      client_id: groupId === 1 || groupId === 6 ? selectedClient?.id : 0,
    };
    if ((groupId === 1 || groupId === 6) && selectedClient === null) {
      setFilterErrors("Please select a client before exporting.");
      setFilterState((prevState) => ({
        ...prevState,
        filterBtnLoading: false,
      }));
    } else {
      setFilterErrors("");
      const res = await postData("prepare-custom-report", {}, exportPayload);
      try {
        if (res.status === true) {
          setFilterState((prevState) => ({
            ...prevState,
            allowExport: true,
            filterBtnLoading: false,
            filterApiData: res.data,
          }));
          handleSuccess(res.message);
        } else {
          setFilterState((prevState) => ({
            ...prevState,
            allowExport: false,
            filterBtnLoading: false,
          }));
          handleError(res.message);
        }
      } catch (err) {
        setFilterState((prevState) => ({
          ...prevState,
          allowExport: false,
          filterBtnLoading: false,
        }));
        handleError(err);
      }
    }
  };
  const handleExportToCsv = async () => {
    setFilterState((prevState) => ({
      ...prevState,
      exportBtnLoading: true,
    }));
    const client_id = groupId === 1 || groupId === 6 ? selectedClient?.id : 0;
    const url = `report-export/${filterApiData?.request_uuid}/${client_id}`;
    const res = await getData(url, {});
    try {
      if (res.status === true) {
        const reportUrl = res.data.report_urls[0];
        window.open(reportUrl, "_blank");
        setFilterState((prevState) => ({
          ...prevState,
          exportBtnLoading: false,
        }));
        handleSuccess(res.message);
        handleFilterModalClose();
      } else {
        setFilterState((prevState) => ({
          ...prevState,
          exportBtnLoading: false,
        }));
        handleError(res.message);
      }
    } catch (err) {
      setFilterState((prevState) => ({
        ...prevState,
        exportBtnLoading: false,
      }));
      handleError(err);
    }
  };
  return (
    <div className="details-modal-wrapper">
      <Modal
        show={showFilterModal}
        onHide={handleFilterModalClose}
        className="modal-detail-wrapper reports-filter-modal-wrapper"
      >
        <Modal.Header className="report-modal-header-wrapper">
          <Modal.Title>
            <h4>{individualFilterData?.name}</h4>
          </Modal.Title>
          <div className="modal-close" onClick={handleFilterModalClose}>
            <img src={closebutton} alt="closebutton" />
          </div>
        </Modal.Header>
        <Modal.Body className="modal-body-wrapper">
          <div className="date-selector-wrapper">
            <FormGroup controlId="startDate" className="date-selector">
              <FormLabel>
                Start Date<span>*</span>
              </FormLabel>
              <FormControl
                type="date"
                value={dates.startDate || ""}
                onChange={(event) => handleDateChange(event, "Start Date")}
              />
            </FormGroup>
            <FormGroup controlId="endDate" className="date-selector">
              <FormLabel>
                End Date<span>*</span>
              </FormLabel>
              <FormControl
                type="date"
                value={dates.endDate || ""}
                onChange={(event) => handleDateChange(event, "End Date")}
                disabled={!dates.startDate}
                min={dates.startDate}
              />
            </FormGroup>
          </div>
          {(groupId === 1 || groupId === 6) && (
            <div className="client-selection-wrapper">
              <Select
                placeholder="Select the client"
                closeMenuOnSelect={true}
                hideSelectedOptions={false}
                options={clientFilterOptions.map((client) => ({
                  label: client.legal_name,
                  value: client,
                }))}
                onChange={(selectedOption) =>
                  setFilterState((prevState) => ({
                    ...prevState,
                    selectedClient: selectedOption.value,
                  }))
                }
              />{" "}
              {filterErrors !== "" && (
                <div style={{ color: "red" }}>{filterErrors}</div>
              )}
            </div>
          )}{" "}
          <div className="date-selector-btn">
            <button
              className={
                !dates.endDate
                  ? "disabled-btn-wrapper"
                  : "btn-cancel-wrapper btn-apply-filter"
              }
              onClick={!dates.endDate ? null : handleEnableExport}
            >
              {filterBtnLoading ? (
                <Spinner animation="border" size="sm" />
              ) : (
                "APPLY FILTER"
              )}
            </button>
          </div>
          {allowExport && (
            <p className="filter-prepared-text">
              Your CSV file is ready to download
            </p>
          )}
        </Modal.Body>
        <Modal.Footer className="modal-add-detail-main-wrapper column-footer-wrapper">
          <button
            className="btn-cancel-wrapper"
            onClick={exportBtnLoading ? null : handleFilterModalClose}
          >
            CANCEL
          </button>
          <button
            className={
              allowExport ? "approve-btn-active" : "disabled-export-csv-btn"
            }
            onClick={
              exportBtnLoading || !allowExport ? null : handleExportToCsv
            }
          >
            {exportBtnLoading ? (
              <Spinner animation="border" size="sm" />
            ) : (
              "EXPORT TO CSV"
            )}
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
