import React, { useEffect, useState } from "react";
import "./style.scss";
import OnboardHeading from "../OnboardHeading";
import BackButtonComponent from "../backButton";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { postData } from "../../api";
import { autocloseTiming } from "../../api/regex";
import Loader from "../Loader";
import { Modal } from "react-bootstrap";

export default function TwoFactorAuthComponent() {
  const [isToggleOn, setIsToggleOn] = useState(false);
  const [loading, setLoading] = useState(false);
  const [twoFactorData, setTwoFactorData] = useState({});
  const [showModal, setShowModal] = useState(false);

  const handlePostData = async () => {
    setLoading(true);
    let obj = {
      api_type: "get",
    };
    const res = await postData("two-factor-authentication", {}, obj);
    if (res.status === true) {
      setLoading(false);
      setTwoFactorData(res);
      if (res.isTwoFactorAuth === true) {
        setIsToggleOn(true);
      }
    } else {
      toast.error(res.message, {
        theme: "colored",
        autoClose: autocloseTiming,
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    handlePostData();
  }, []);

  const updateTwoFactorAuth = async () => {
    let obj = {
      api_type: "set",
      isTwoFactorAuth: !isToggleOn,
    };
    const res = await postData("two-factor-authentication", {}, obj);
    if (res.status === true) {
      setLoading(false);
      setTwoFactorData(res);
    } else {
      setLoading(false);
    }
  };

  const handleOnClick = async (e) => {
    if (isToggleOn) {
      setShowModal(true);
    } else {
      setLoading(true);
      setIsToggleOn((prevState) => !prevState);
      updateTwoFactorAuth();
    }
  };
  const handleConfirm = () => {
    setShowModal(false);
    setIsToggleOn(!isToggleOn);
    updateTwoFactorAuth();
  };
  return (
    <>
      {!loading ? (
        <div className="two-factor-title">
          <div className="onboarding-wrapper">
            <OnboardHeading title="TWO FACTOR AUTHENTICATION" />
            {/* <Button variant="danger" className="revert_btn">
              Revert User
            </Button> */}
          </div>
          <div className="back-btn-wrapper">
            <BackButtonComponent reDirectionpath="/settings" />
          </div>
          <div className="main-card-wrapper">
            <div className="two-factor-toggle-wrapper">
              <h5>Enable two factor authentication</h5>
              <label class="switch">
                <input
                  type="checkbox"
                  onClick={handleOnClick}
                  checked={twoFactorData?.isTwoFactorAuth}
                />
                <span class="slider round"></span>
              </label>
            </div>
            <p>
              Two-step authentication (also known as two-factor authentication
              or 2FA) is a security process in which a user provides two
              different authentication factors to verify their identity. This
              adds an extra layer of security beyond just a username and
              password.
            </p>
          </div>
        </div>
      ) : (
        <Loader />
      )}
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        className="two-factor-modal"
      >
        <Modal.Body className="two-factor-modal-body">
          Are you sure you want to disable two factor authentication
        </Modal.Body>
        <Modal.Footer className="two-factor-modal-footer">
          <Button
            variant="light"
            onClick={() => setShowModal(false)}
            className="cancel-btn modal-btn-wrapper"
          >
            No
          </Button>
          <Button
            variant="primary"
            onClick={handleConfirm}
            className="confirm-btn modal-btn-wrapper"
          >
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
