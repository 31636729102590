import React, { useState, useEffect } from "react";
import { Form, Row, Col } from "react-bootstrap";
import Button from "../../components/Form/Button";
import Select from "react-select";
import { postData } from "../../api";
import { toast } from "react-toastify";
import Loader from "../../components/Loader";
import {autocloseTiming} from '../../api/regex';

const SelectIndustry = ({ companySettingsObject, industryType }) => {

    const [validated, setValidated] = useState(false);
    const [loading, setLoading] = useState(false);
    const [clientObject, setClientObject] = useState({
        industryType: '',
        naics_code: '',
        sic_codes: '',
        title: ''
    });
    const [errors, setErrors] = useState({});
    const [industryTypeValue, setIndustryTypeValue] = useState();
    const [disable, setDisable] = useState(true);


    useEffect(() => {
        if (companySettingsObject?.industry) {
            setClientObject(companySettingsObject?.industry);
            let data = {
                label: companySettingsObject?.industry?.title,
                naics_code: companySettingsObject?.industry?.naics_code,
                sic_codes: companySettingsObject?.industry?.sic_codes,
                value: companySettingsObject?.industry?.naics_code,
            }
            setIndustryTypeValue(data);
            setClientObject((prev) => ({
                ...prev,
                industryType: companySettingsObject?.industry?.naics_code,
            }));
        }
    }, [companySettingsObject]);



    const formatSelectOptions = (data) => {
        let finalArr = [];
        if (data && data.length > 0) {
            data.forEach((item) => {
                finalArr.push({
                    value: item?.naics_code,
                    label: item?.title,
                    naics_code: item?.naics_code,
                    sic_codes: item?.sic_codes,
                });
            });
        }
        return finalArr;
    };


    const findFormErrors = () => {
        const { industryType } = clientObject;
        const newErrors = {};
        if (!industryType || industryType === "") newErrors.industryType = "Please enter industry type.";

        return newErrors;
    };

    const handleDisable = () => {
        setDisable(false)
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        const newErrors = findFormErrors();
        setValidated(true);
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
        } else {
            if (!disable) {
                selectIndustry();
            }

        }
    };

    const selectIndustry = async () => {
        setLoading(true);
        let data = {
            stage: "industry",
            ...clientObject
        }
        setLoading(true);
        const res = await postData("client_setting", {}, data);
        if (res.status === true) {
            setLoading(false);
            setDisable(true);
            toast.success(res.message, { theme: "colored", autoClose: autocloseTiming });
        } else {
             toast.error(res.message, { theme: "colored", autoClose: autocloseTiming });;
            setLoading(false);
        }
    };

    const handleStateOnChange = async (data) => {
        setIndustryTypeValue(data);
        setClientObject((prev) => ({
            ...prev,
            industryType: data.value,
            title: data.label,
            naics_code: data.naics_code,
            sic_codes: data.sic_codes
        }));

    }

    return (
        <>
            {!loading && (
                <div className="board-right board-tab-sections">
                    <Form noValidate validated={validated} onSubmit={handleSubmit} autoComplete="off" className="custom-form add-addres-settings">
                        <Row>
                            <Col md={6}>
                                <Form.Group className="mb-3" controlId="industryType">
                                    <Form.Label>Industry type <span className="asterisk">*</span> </Form.Label>
                                    <Select
                                        closeMenuOnSelect={true}
                                        hideSelectedOptions={false}
                                        options={formatSelectOptions(industryType)}
                                        onChange={(selectedOption) => handleStateOnChange(selectedOption)}
                                        value={industryTypeValue}
                                        placeholder="Select industry type"
                                        className="custom-select"
                                        isDisabled={disable}
                                    />
                                    {
                                        validated && clientObject?.industryType === '' &&
                                        <div className="err-feedback">{errors.industryType}</div>
                                    }
                                </Form.Group>
                            </Col>
                        </Row>


                        <div className="text-end mt-4">
                            {
                                !disable &&
                                <Button
                                    type="submit"
                                    disabled={loading}
                                    loading={loading}
                                    className="btn-primary text-white create-btn"
                                >
                                    Save changes
                                </Button>
                            }
                        </div>

                    </Form>
                    {
                        disable &&
                        <div className="text-end mt-4">
                            <Button
                                type="button"
                                className="btn-outline-primary me-2"
                                onClick={() => handleDisable()}
                            >
                                Edit
                            </Button>
                        </div>
                    }
                </div>
            )
            }
            {loading && <Loader />}

        </>
    )
}

export default SelectIndustry