import React, { useEffect, useState } from "react";
import "./style.scss";
import { Button, Col, Container, Row, Spinner } from "react-bootstrap";
import logo from "../../assets/images/logo.png";
import LoginFooter from "../../pages/LoginFooter";
import { NavLink } from "react-router-dom";
import { autocloseTiming } from "../../api/regex";
import { toast } from "react-toastify";
import { cakePhpURL, getData } from "../../api";
import Loader from "../Loader";

export default function EmployeeTwoFactorAuthComp() {
  const [loading, setLoading] = useState(false);
  const [twoFactorApiData, setTwoFactorApiData] = useState({});
  const [btnLoading, setBtnLoading] = useState(false);
  const getMobileVerificationData = async () => {
    setLoading(true);
    const res = await getData("user-two-factor-auth", {});
    if (res.status === true) {
      setLoading(false);
      setTwoFactorApiData(res);
      // toast.success("OTP has been sent to the entered mobile number.", {
      //   theme: "colored",
      //   autoClose: autocloseTiming,
      //   style: {
      //     background: "#6AA84F",
      //     borderRadius: "16px",
      //   },
      // });
      localStorage.setItem("guhroo_token", res.token);
    } else {
      toast.error(res.message, {
        theme: "colored",
        autoClose: autocloseTiming,
      });
      setLoading(false);
    }
  };
  useEffect(() => {
    getMobileVerificationData();
  }, []);
  const handleBacktoLogin = () => {
    localStorage.clear();
    window.open(cakePhpURL + "users/logout", "_self");
    window.history.pushState(null, null, window.location.href);
  };
  // const clearStorage = () => {
  //   localStorage.clear();
  //   window.open(cakePhpURL + "users/logout", "_self");
  //   sessionStorage.setItem("tabClosed", "true");
  // };
  // useEffect(() => {
  //   const handleBeforeUnload = () => {
  //     clearStorage();
  //   };
  //   window.addEventListener("beforeunload", handleBeforeUnload);
  //   return () => {
  //     window.removeEventListener("beforeunload", handleBeforeUnload);
  //   };
  // }, []);
  // const handleUnload = () => {
  //   localStorage.clear();
  //   window.open(cakePhpURL + "users/logout", "_self");
  // };

  // useEffect(() => {
  //   const handleBeforeUnload = (event) => {
  //     localStorage.setItem("tabClosed", "true");
  //   };
  //   const handleVisibilityChange = () => {
  //     if (!document.hidden) {
  //       if (localStorage.getItem("tabClosed") === "true") {
  //         handleUnload();
  //         localStorage.removeItem("tabClosed");
  //       }
  //     }
  //   };
  //   window.addEventListener("beforeunload", handleBeforeUnload);
  //   document.addEventListener("visibilitychange", handleVisibilityChange);
  //   if (localStorage.getItem("tabClosed") === "true") {
  //     handleUnload();
  //     localStorage.removeItem("tabClosed");
  //   }
  //   return () => {
  //     window.removeEventListener("beforeunload", handleBeforeUnload);
  //     document.removeEventListener("visibilitychange", handleVisibilityChange);
  //   };
  // }, []);
  return (
    <>
      {loading ? (
        <div className="loader-wrapper">
          <Loader />
        </div>
      ) : (
        <div className="login-body">
          <div className="login-header">
            <img src={logo} alt="logo" />
          </div>
          <section className="login-section">
            <Container>
              <Row className="justify-content-center align-items-center main-container-wrapper">
                <Col md={6}>
                  <div className="login-box employee-auth-card-wrapper">
                    <div>
                      <h5>Two factor authentication</h5>
                      <p>
                        Two-step authentication (also known as two-factor
                        authentication or 2FA) is a security process in which a
                        user provides two different authentication factors to
                        verify their identity. This adds an extra layer of
                        security beyond just a username and password.
                      </p>
                    </div>
                    <div className="text-center mt-4">
                      {twoFactorApiData && twoFactorApiData.redirectUrl ? (
                        <NavLink
                          to={twoFactorApiData.redirectUrl}
                          onClick={() => setBtnLoading(true)}
                        >
                          <Button
                            type="submit"
                            className="btn-primary text-white"
                            disabled={btnLoading}
                            loading={btnLoading}
                          >
                            {btnLoading ? (
                              <Spinner animation="border" size="sm" />
                            ) : (
                              "Proceed"
                            )}
                          </Button>
                        </NavLink>
                      ) : (
                        <Button
                          type="submit"
                          className="btn-primary text-white"
                        >
                          Proceed
                        </Button>
                      )}
                    </div>
                    <div className="my-3 back-to-login">
                      <p onClick={handleBacktoLogin}>Back to Login</p>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
        </div>
      )}
      <LoginFooter />
    </>
  );
}
