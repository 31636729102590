import React, { useEffect, useState } from "react";
import "./style.scss";
import PayProfileIcon from "../../assets/images/timekeeping_icons/pay_period_profile_icon.svg";
import Select from "react-select";
import DownArrow from "../../assets/images/timekeeping_icons/btn_down_arrow.svg";
import RightArrow from "../../assets/images/timekeeping_icons/double_arrow_right.svg";
import LeftArrow from "../../assets/images/timekeeping_icons/double_arrow_left.svg";
import { Modal } from "react-bootstrap";
import "../FormModal/style.scss";
import moment from "moment";
import { postData } from "../../api";
import InfoIcon from "../../assets/images/timekeeping_icons/info.svg";

export default function PayPeriodFilterSection(props) {
  const {
    payPeriodData,
    timeCardStatus,
    employeeData,
    activeFrequency,
    inActiveFrequency,
    selectFrequency,
    setPayPeriodData,
    setLoading,
    setFilterLoading,
  } = props;

  const [nameOptions, setNameOptions] = useState([]);
  const [timeCardoptions, setTimeCardOptions] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [activeFreqOption, setActiveFreqOption] = useState([]);
  const [inActiveFreqOption, setInActiveFreqOption] = useState([]);
  const [selectedValue, setSelectedValue] = useState(
    activeFreqOption?.find((option) => option.value === selectFrequency)
  );
  const [inActiveSelectedValue, setInActiveSelectedValue] = useState(
    inActiveFreqOption?.find((option) => option.value === selectFrequency)
  );
  const [selectedEmployee, setSelectedEmployee] = useState("");
  const [selectedTimeCard, setSelectedTimeCard] = useState("");

  const handleEmployeeFilter = async (e) => {
    setFilterLoading(true);
    setSelectedEmployee(e);
    setSelectedTimeCard(null);
    setInActiveSelectedValue(null);
    setSelectedValue(null);
    let obj = {
      emp_id: e.value,
    };
    const res = await postData("time-keeping-filter-data", {}, obj);
    if (res.status === true) {
      setPayPeriodData(res?.data);
      setFilterLoading(false);
    } else {
      setFilterLoading(false);
    }
  };
  const handleTimeCardFilter = async (e) => {
    setFilterLoading(true);
    setSelectedTimeCard(e);
    setSelectedEmployee(null);
    setInActiveSelectedValue(null);
    setSelectedValue(null);
    let obj = {
      employees_type: e.value,
    };
    const res = await postData("time-keeping-filter-data", {}, obj);
    if (res.status === true) {
      setPayPeriodData(res?.data);
      setFilterLoading(false);
    } else {
      setFilterLoading(false);
    }
  };
  const handleActiveFrequency = async (e) => {
    setFilterLoading(true);
    setSelectedEmployee(null);
    setSelectedTimeCard(null);
    let obj = {
      pay_frequency_id: e.value,
    };
    const res = await postData("time-keeping-filter-data", {}, obj);
    if (res.status === true) {
      setPayPeriodData(res?.data);
      setFilterLoading(false);
    } else {
      setFilterLoading(false);
    }
  };
  const handleInActiveFrequency = async (e) => {
    setFilterLoading(true);
    setSelectedEmployee(null);
    setSelectedTimeCard(null);
    let obj = {
      pay_frequency_id: e.value,
    };
    const res = await postData("time-keeping-filter-data", {}, obj);
    if (res.status === true) {
      setPayPeriodData(res?.data);
      setFilterLoading(false);
    } else {
      setFilterLoading(false);
    }
  };
  useEffect(() => {
    const mapOptions = () => {
      const mappedOptions = employeeData?.map((item) => ({
        value: item.id,
        label: item.name,
      }));
      setNameOptions(mappedOptions);
    };

    mapOptions();
  }, [payPeriodData, employeeData]);

  useEffect(() => {
    const mapDropDownOptions = () => {
      const mappedOptions = timeCardStatus?.map((item) => ({
        value: item.id,
        label: item.name,
      }));
      setTimeCardOptions(mappedOptions);
    };

    mapDropDownOptions();
  }, [payPeriodData, timeCardStatus]);

  useEffect(() => {
    const mapDropDownOptions = () => {
      const mappedOptions = activeFrequency?.map((item) => ({
        value: item.id,
        label: item.label,
      }));
      setActiveFreqOption(mappedOptions);
    };

    mapDropDownOptions();
  }, [payPeriodData, activeFrequency]);

  useEffect(() => {
    const mapDropDownOptions = () => {
      const mappedOptions = inActiveFrequency?.map((item) => ({
        value: item.id,
        label: item.label,
      }));
      setInActiveFreqOption(mappedOptions);
    };
    mapDropDownOptions();
  }, [payPeriodData, inActiveFrequency]);
  const handleModalOpen = () => {
    setShowModal(true);
  };
  const handleClose = () => {
    setShowModal(false);
    // window.open("/pay-period-summary", "_self");
  };
  const formatTime = (minutes) => {
    const time = moment.duration(minutes, "minutes");
    const formattedTime = `${time.hours()}hrs ${time.minutes()}mins`;
    return formattedTime;
  };

  useEffect(() => {
    setSelectedValue(
      activeFreqOption?.find((option) => option.value === selectFrequency)
    );
  }, [selectFrequency, activeFreqOption]);

  useEffect(() => {
    setInActiveSelectedValue(
      inActiveFreqOption?.find((option) => option.value === selectFrequency)
    );
  }, [selectFrequency, inActiveFreqOption]);
  useEffect(() => {
    if (selectedValue) {
      setInActiveSelectedValue(null);
    } else if (inActiveSelectedValue) {
      setSelectedValue(null);
    }
  }, [inActiveSelectedValue, selectedValue]);
  return (
    <>
      <div>
        <div className="pay-filter-title d-flex py-3">
          <img src={PayProfileIcon} alt="profile-icon" className="px-3" />
          <h5 className="ms-1">PAY period summary</h5>
        </div>
        <div className="d-flex px-4 py-4 justify-content-between search-filter-wrapper">
          <div className="pay-filter-dropdown">
            <p>Employee</p>
            <Select
              closeMenuOnSelect={true}
              hideSelectedOptions={false}
              options={nameOptions}
              placeholder="Select employee"
              onChange={(e) => handleEmployeeFilter(e)}
              value={selectedEmployee}
            />
          </div>
          <div className="pay-filter-dropdown">
            <p>Frequency</p>
            <Select
              closeMenuOnSelect={true}
              hideSelectedOptions={false}
              options={activeFreqOption}
              placeholder="Select frequency"
              value={selectedValue}
              onChange={(option) => {
                setSelectedValue(option);
                handleActiveFrequency(option);
              }}
            />
          </div>
          <div className="pay-filter-dropdown">
            <p>Inactive frequency</p>
            <Select
              closeMenuOnSelect={true}
              hideSelectedOptions={false}
              options={inActiveFreqOption}
              placeholder="Search inactive frequency"
              value={inActiveSelectedValue}
              onChange={(option) => {
                handleInActiveFrequency(option);
                setInActiveSelectedValue(option);
              }}
            />
          </div>
          <div className="pay-filter-dropdown">
            <p>Time card</p>
            <Select
              closeMenuOnSelect={true}
              hideSelectedOptions={false}
              options={timeCardoptions}
              placeholder="Select time card"
              onChange={(e) => handleTimeCardFilter(e)}
              value={selectedTimeCard}
            />
          </div>
        </div>
        {/* <div className="filter-detail-wrapper py-5">
          <div className="filter-detail-cards">
            <p>Regular</p>
            <h5>{formatTime(payPeriodData?.overview?.regular_minutes)}</h5>
          </div>
          <div className="filter-detail-cards">
            <p>Overtime</p>
            <h5>{formatTime(payPeriodData?.overview?.overtime_minutes)}</h5>
          </div>
          <div className="filter-card-last-section">
            <div className="new-filter-cards">
              <p>Monthly</p>
              <h5>
                <img src={LeftArrow} alt="left-arrow" />
                {payPeriodData?.overview?.time_line}
                <img src={RightArrow} alt="right-arrow" />
              </h5>
            </div>
            <div className="timecard-approved-wrapper" />
            <div className="resp-text-info-wrapper">
              <p>
                Timecard approved: {payPeriodData?.overview?.approved}/
                {payPeriodData?.overview?.timecard}
              </p>
              <p>Edit requests: {payPeriodData?.overview?.edit_req}</p>
            </div>
          </div>
        </div> */}
        {/* <div className="report-button-wrapper">
          <div className="info-text-wrapper">
            <img src={InfoIcon} alt="info-icon" />
            <p>
              Please manage time cards for both <span>'weekly'</span> and{" "}
              <span>'monthly'</span> pay periods before processing payroll.
            </p>
          </div>
          <div className="info-btn-wrapper">
            <button className="btn export-button" onClick={handleModalOpen}>
              Export to payroll relief
            </button>
            <button className="btn btn-primary go-report-button">
              Go to reports <img src={DownArrow} alt="down-arrow" />
            </button>
          </div>
        </div> */}
      </div>
      <div>
        <Modal
          show={showModal}
          centered
          // onHide={onClose}
          // size={size}
          className="modal-main-wrapper"
        >
          <Modal.Header className="modal-expand-header">
            <div className="form-modal">
              <Modal.Title className="modal-tile-expand">
                Approval pending
              </Modal.Title>
              {/* {modalData.userName
                ? `Are you sure you want to ${modalData.data} ${modalData.userName}'s time cards?`
                : `Are you sure you want to ${modalData.data} time cards?`} */}
            </div>
          </Modal.Header>
          <Modal.Body className="modal-expand-body">
            <div className="modal-approve-content-title">
              <p>
                To ensure accurate accrual balances, please manage time cards
                before processing payroll. Note: the pending requests are
                highlighted.
              </p>
            </div>
            {/* <div>
            <div className="d-flex justify-content-between modal-wrap-class">
          
              <div className="modal-expand-content-title">
                <h5>In</h5>
                <p>{modalData?.selectedRow?.in}</p>
              </div>
              <div className="modal-expand-content-title">
                <h5>Out</h5>
                <p>{modalData?.selectedRow?.out}</p>
              </div>
              <div className="modal-expand-content-title">
                <h5>Total Hours</h5>
                <p>{modalData?.selectedRow?.totalHrs}</p>
              </div>
              <div className="modal-expand-content-title">
                <h5>Status</h5>
                <p
                  className={`text-${modalData?.selectedRow?.statusName.toLowerCase()}`}
                >
                  {modalData?.selectedRow?.statusName}
                </p>
              </div>
            </div>
            <div className="modal-expand-content-title width-wrapper-cus">
              <h5>Address</h5>
              <p>{modalData?.selectedRow?.address}</p>
            </div>
            <div className="modal-expand-content-title">
              <h5>Note</h5>
              <p>In</p>
            </div>
          </div> */}
          </Modal.Body>
          <Modal.Footer className="modal-expand-footer">
            <div className="d-flex button-gap justify-content-end ">
              <button
                className="manage-modal-btn deny-active-btn"
                onClick={handleClose}
              >
                manage Timekeeping 
              </button>
              {/* <button
              className="approve-all-button active-btn"
              onClick={handleClose}
            >
              Confirm
            </button> */}
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}
