import React from "react";
import { Form, Modal } from "react-bootstrap";
import CloseIcon from "../../assets/images/timekeeping_icons/close.svg";
import "../FormModal/style.scss";
import "./style.scss";

export default function PlayModalComponent(props) {
  const { show, onClose, size, modalData, setIsPlayModalOpen, heading } = props;
  const handleClose = () => {
    setIsPlayModalOpen(false);
  };
  return (
    <div>
      <Modal
        show={show}
        centered
        onHide={onClose}
        size={size}
        className="modal-play-main-wrapper"
      >
        <Modal.Header className="modal-play-header">
          <div></div>
          <div className="form-modal">
            <Modal.Title className="modal-tile-play">{heading} </Modal.Title>
            {/* {modalData.userName
                  ? `Are you sure you want to ${modalData.data} ${modalData.userName}'s time cards?`
                  : `Are you sure you want to ${modalData.data} time cards?`} */}
          </div>
          <div className="modal-play-close" onClick={handleClose}>
            <img src={CloseIcon} alt="close" />
          </div>
        </Modal.Header>
        <Modal.Body className="modal-play-body">
          <div>
            <div className="modal-date-wrapper">
              <p>Monday, Jan 1, 2024</p>{" "}
            </div>
            <div className="modal-play-content-wrapper">
              <h5>Requested times</h5>
            </div>
            <div className="modal-clock-wrapper">
              <div className="modal-play-clock">
                <h5>Clock In</h5>
                <Form.Control type="time" name="date" placeholder="Date" />{" "}
              </div>
              <div className="modal-play-clock">
                <h5>Clock Out</h5>
                <Form.Control type="time" name="date" placeholder="Date" />{" "}
              </div>
            </div>
            <div className="total-hours-wrapper">
              <h5>Total</h5>
              <p>09 hours 30 minute</p>
            </div>
            <div className="input-disabled-wrapper">
              <input type="text" placeholder="In:" />
              <input type="text" placeholder="Out:" />
            </div>
            <div className="text-area-wrapper">
              <div className="col-md-12 m-t-10">
                <div className="form-group">
                  <div className="input-group note-input-group">
                    <div className="dropdown-wrapper">
                      <span className="input-group-addon">
                        <select name="note_type1">
                          <option value="">Manage note</option>
                        </select>
                      </span>
                    </div>
                    <textarea
                      className="form-control"
                      id="admin_note"
                      name="data[Timer][admin_note]"
                      placeholder="Add Note"
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-play-btn-wrapper">
            <button>Delete entry</button>
            <button>Delete request</button>
            <button>Edit & approve request</button>
          </div>
        </Modal.Body>
        {/* <Modal.Footer className="modal-expand-footer">
          <div className="modal-btn-wrapper d-flex button-gap justify-content-end ">
            <button
              className="deny-all-button deny-active-btn"
              onClick={handleClose}
            >
              Cancel
            </button>
            <button
                className="approve-all-button active-btn"
                onClick={handleClose}
              >
                Confirm
              </button>
          </div>
        </Modal.Footer> */}
      </Modal>
    </div>
  );
}
