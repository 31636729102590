import { useEffect } from "react";
import { useParams } from "react-router-dom";
import base64 from 'react-native-base64'

const SetAuth = () => {
    let { data } = useParams();
    useEffect(() => {
        let newData = base64.decode(data);
        let redirectURL = "/";
        newData = JSON.parse(newData);
        if (newData) {
            localStorage.removeItem('revert_url');
            Object.keys(newData).map((innerAttr, index) => {
                if (innerAttr === "guhroo_user_details") {
                    localStorage.setItem(innerAttr, JSON.stringify(newData[innerAttr]));
                    if (newData[innerAttr]['dashbaord_url'] != "") {
                        redirectURL = newData[innerAttr]['dashbaord_url'];
                    } else if (newData[innerAttr]['auto_login_url'] != "") {
                        redirectURL = newData[innerAttr]['auto_login_url'];
                    }
                } else if (innerAttr === "guhroo_token") {
                    localStorage.setItem(innerAttr, newData[innerAttr]);
                } else if (innerAttr === "revert_url") {
                    localStorage.setItem(innerAttr, newData[innerAttr]);
                }
            })
        }
        window.open(redirectURL, "_self");
    }, []);// eslint-disable-line react-hooks/exhaustive-deps
};
export default SetAuth;
