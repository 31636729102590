import {
  Col,
  Row,
  Tab,
  Nav,
  NavDropdown,
  Dropdown,
  Form,
  InputGroup,
} from "react-bootstrap";
import JobTab from "./Job";
import Personal from "./Personal";
import "./style.scss";
import BankingTab from "./Banking/Index";
import EmergencyTab from "./Emergency/index";
import DocumentTab from "./Documents";
import EmployeeSignDocumentsTab from "./EmployeeSignDocuments";
import { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import { getData, postData, getRevertUser, cakePhpURL } from "../../api";
import Loader from "../../components/Loader";
import TimeOff from "./TimeOff";
import Notes from "./Notes";
import TasksTab from "./Tasks";
import AssesmentsTab from "./Assesments";
import { toast } from "react-toastify";
import PaysTub from "./Paystub";
import StatetaxTab from "./Statetax";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import revertIcon from "../../assets/images/fa_refresh.svg";
import { useRef } from "react";
import { autocloseTiming, onlySpaceRegex } from "../../api/regex";
import { MdClear } from "react-icons/md";
import Profileicon from "../../assets/images/profile_icons/profile_icon.svg";
import BackButtonComponent from "../../components/backButton";

const Profile = () => {
  const navigate = useNavigate();
  let { id } = useParams();
  const { state } = useLocation();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [profileId, setProfileId] = useState();
  const [stateList, setStateList] = useState();
  const [empTypeList, setEmpTypeList] = useState();
  const [compensationList, setCompensationList] = useState();
  const [payfrequencyList, setPayfrequencyList] = useState();
  const [flsaClassificationList, setflsaClassificationList] = useState();
  const [relationshipList, setRelationshipList] = useState();
  const [profileData, setProfileData] = useState();
  const [clientRole, setClientRole] = useState("");
  const [allUsers, setAllUsers] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [userId, setUserId] = useState("");
  const [prevUsername, setPrevUsername] = useState("");
  const [nextUsername, setNextUsername] = useState("");
  const [tabsList, setTabsList] = useState([]);
  const [step, setStep] = useState();
  const profileTabChangeData = state;
  const [list, setList] = useState([]);
  const [dropdownData, setDropdownData] = useState([]);
  const [MoreOptions, setMoreOptions] = useState(false);
  const innerWidth = useRef(null);
  const [taskUserList, setTaskUserList] = useState([]);
  const [profileSearch, setProfileSearch] = useState("");
  const [gustoUser, setGustoUser] = useState("");
  const [validated, setValidated] = useState(false);
  const [activeStatus, setActiveStatus] = useState("");
  const [errors, setErrors] = useState({});
  const [disableProfileSearch, setDisableProfileSearch] = useState(true);
  const [groupId, setGroupId] = useState("");
  const [newCurrentIndex, setNewCurrentIndex] = useState(0);
  const [userType, setUserType] = useState("");

  useEffect(() => {
    // Check if the current route is "/profile"
    if (location.pathname.includes("/profile")) {
      // Create the script element
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.async = true;
      script.innerHTML = `
        window.$sleek = [];
        window.SLEEK_PRODUCT_ID = 884804572;
        (function () {
          d = document;
          s = d.createElement("script");
          s.src = "https://client.sleekplan.com/sdk/e.js";
          s.async = 1;
          d.getElementsByTagName("head")[0].appendChild(s);
        })();
      `;

      // Append the script to the head of the document
      document.head.appendChild(script);

      // Cleanup: Remove the script when the component unmounts
      return () => {
        document.head.removeChild(script);
      };
    }
  }, [location.pathname]);

  useEffect(() => {
    const fragment = window.location.hash;
    if (fragment === "#signforms") {
      setStep("signforms");
      if (localStorage.getItem("profile_id")) {
        localStorage.removeItem("profile_id");
      }
    }
  }, []);

  useEffect(() => {
    const fragment = window.location.hash;
    if (fragment === "#statetax") {
      setStep("statetax");
    } else if (fragment === "#personal") {
      setStep("personal");
    } else if (fragment === "#job") {
      setStep("job");
    } else if (fragment === "#banking") {
      setStep("banking");
    } else if (fragment === "#emergency") {
      setStep("emergency");
    } else if (fragment === "#documents") {
      setStep("documents");
    } else if (fragment === "#notes") {
      setStep("notes");
    } else if (fragment === "#tasks") {
      setStep("tasks");
    } else if (fragment === "#assessments") {
      setStep("assessments");
    } else if (fragment === "#paystub/w2") {
      setStep("paystub/w2");
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (id !== undefined) {
      getProfileUserById(id);
    } else {
      getInitialProfile();
    }

    getStaticList();
    return () => {
      localStorage.removeItem("profile_id");
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (profileTabChangeData !== undefined && profileTabChangeData !== null) {
      setStep(profileTabChangeData);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // Check if the current route is "/profile/view-task/:id"
    if (location.pathname.includes("/profile-view-task")) {
      console.log("here task profile");
      setStep("tasks");
    }
  }, [location.pathname]);

  const handleTabSelect = (key) => {
    setStep(key);
    removeFragmentFromURL();
  };

  const nextStep = (whichStep) => {
    setStep(whichStep);
  };

  const removeFragmentFromURL = () => {
    const currentPath = location.pathname + location.search;
    const newPath = currentPath.replace(/#.*$/, ""); // Remove the fragment from the current path
    window.history.replaceState(null, "", newPath); // Update the URL without the fragment
  };

  const getStaticList = async () => {
    setLoading(true);
    const res = await postData("get_constant", {});
    if (res.status === true) {
      setStateList(res.data.stateList);
      setEmpTypeList(res.data.employmentType);
      setCompensationList(res.data.compensation);
      setPayfrequencyList(res.data.payFrequencyList);
      setflsaClassificationList(res.data.flsaClassification);
      setRelationshipList(res.data.emergencyContactList);
      setLoading(false);
    } else {
      toast.error(res.message, {
        theme: "colored",
        autoClose: autocloseTiming,
      });
      setLoading(false);
    }
  };

  const getInitialProfile = async () => {
    setLoading(true);
    const res = await getData("profile", {});
    if (res.status === true) {
      setProfileData(res.data);
      setClientRole(res.data.role);
      setAllUsers(res.data.allUsers);
      setTaskUserList(res?.data?.users_list);
      setUserId(res.data.user_id);
      setTabsList(res.data.tabs);
      setGustoUser(res?.data?.gusto_user);
      setActiveStatus(res?.data?.active_status);
      setGroupId(res?.data?.group_id);
      setLoading(false);
      let profile_id = localStorage.getItem("profile_id");
      if (profile_id) {
        res.data.allUsers.forEach((item, i) => {
          if (profile_id === item.id?.toString()) {
            setCurrentIndex(i);
            getProfileUserById(item?.id);
          }
        });
      }
    } else {
      toast.error(res.message, {
        theme: "colored",
        autoClose: autocloseTiming,
      });
      setLoading(false);
    }
  };

  const getProfileUserById = async (userid) => {
    setLoading(true);
    const res = await getData(`profile/${userid}/${id}`);
    if (res.status === true) {
      setProfileData(res.data);
      setClientRole(res.data.role);
      setUserId(res.data.user_id);
      setTabsList(res.data.tabs);
      setAllUsers(res.data.allUsers);
      setTaskUserList(res?.data?.users_list);
      setGustoUser(res?.data?.gusto_user);
      setActiveStatus(res?.data?.active_status);
      setGroupId(res?.data?.group_id);
      setLoading(false);
    } else {
      toast.error(res.message, {
        theme: "colored",
        autoClose: autocloseTiming,
      });
      setLoading(false);
    }
  };

  const handleRevertUser = () => {
    window.open(getRevertUser(), "_self");
  };

  const nextUser = () => {
    setNewCurrentIndex(newCurrentIndex + 1);
    let userid = allUsers[newCurrentIndex + 1].id;
    localStorage.setItem("profile_id", userid);
    setProfileId(userid);
    getProfileUserById(userid);
    setStep("personal");
    navigate(`/profile/${userid}`);
  };
  const prevUser = () => {
    setNewCurrentIndex(newCurrentIndex - 1);
    let userid = allUsers[newCurrentIndex - 1].id;
    localStorage.setItem("profile_id", userid);
    setProfileId(userid);
    getProfileUserById(userid);
    setStep("personal");
    navigate(`/profile/${userid}`);
  };

  const prevMouseHover = () => {
    let username = allUsers[currentIndex - 1].full_name;
    setPrevUsername(username);
  };

  const nextMouseHover = () => {
    let username = allUsers[currentIndex + 1].full_name;
    setNextUsername(username);
  };

  const parentsWithChild = document.querySelectorAll(".main-layout");
  parentsWithChild.forEach((parent) => {
    if (parent.querySelector(".profile_container")) {
      parent.classList.add("parent-classname-with-child");
    }
  });

  // inner width finder
  useEffect(() => {
    tabCalculation();
    window.addEventListener("resize", tabCalculation);
    return () => {
      window.removeEventListener("resize", tabCalculation);
    };
  }, [tabsList]); // eslint-disable-line react-hooks/exhaustive-deps

  const tabCalculation = () => {
    const tabWidth = 125;
    const maxTabs = Math.floor(innerWidth.current?.offsetWidth / tabWidth);
    const slicedTabs = tabsList?.slice(0, maxTabs);
    if (tabsList?.length > maxTabs) {
      setList(slicedTabs);
      setMoreOptions(true);
    } else {
      setMoreOptions(false);
      setList(tabsList);
    }
  };

  useEffect(() => {
    let arrayOne = [...tabsList];
    let arrayTwo = [...list];
    const notMatchingValues = arrayOne
      .filter((value) => !arrayTwo.includes(value))
      .concat(arrayTwo.filter((value) => !arrayOne.includes(value)));
    setDropdownData(notMatchingValues);
  }, [list]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleDropdownSelect = (tab) => {
    let dropData = [...list];
    dropData.pop();
    setList(dropData);
    dropData.push(tab);
    setList(dropData);
  };

  const handleSearch = (e) => {
    const value = e.target.value;
    // Check if the value consists of only whitespace
    if (onlySpaceRegex?.test(value)) {
      setProfileSearch(value);
      setDisableProfileSearch(true);
    } else {
      setProfileSearch(value); // Allow characters after a space
      setDisableProfileSearch(false);
    }
  };

  const cancelProfileSearch = () => {
    setProfileSearch("");
    setDisableProfileSearch(true);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setValidated(true);
    const profileSearchWithoutLeadingWhitespace = profileSearch?.trim();
    window.open(
      cakePhpURL +
        "clients/team_members?s=" +
        profileSearchWithoutLeadingWhitespace,
      "_self"
    );
  };
  useEffect(() => {
    const index = allUsers.findIndex((user) => user.id === parseInt(id));
    if (index !== -1) {
      setNewCurrentIndex(index);
    }
  }, [allUsers, id]);
  useEffect(() => {
    const userDetails = JSON.parse(localStorage.getItem("guhroo_user_details"));
    if (userDetails) {
      setUserType(userDetails.user_type);
    }
  }, []);

  return (
    <div className="profile_container">
      {!loading && (
        <div>
          <div className="client_container">
            <Row className="d-flex justify-content-between my-3">
              <Col md={12}>
                {userType === "Contractor" || userType === "Employee" ? (
                  <>
                    {getRevertUser() ? (
                      <div
                        className={`revert ${
                          clientRole === "Contractor" && "mt-3"
                        }`}
                      >
                        <Button
                          variant="danger"
                          className="revert_btn profile_revert_btn"
                          onClick={handleRevertUser}
                        >
                          <img
                            src={revertIcon}
                            alt="revert"
                            className="revert-img me-1"
                          />
                          Revert User
                        </Button>
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="profile-border mb-3">
                      <div className="client_details ms-2">
                        <h1>{profileData?.user_name}</h1>
                        <p>Profile</p>
                      </div>
                      <div className="profile-right-btns">
                        <div
                          className={
                            clientRole !== "Contractor" ? "d-block" : "d-hide"
                          }
                        >
                          <div className="prev_next_user_container">
                            <span>
                              {newCurrentIndex + 1} of {allUsers?.length}
                            </span>
                            <span>
                              <Button
                                variant="link"
                                onMouseOver={() => prevMouseHover()}
                                title={prevUsername}
                                disabled={newCurrentIndex === 0 ? true : false}
                                onClick={() => prevUser()}
                              >
                                Prev
                              </Button>
                              |
                              <Button
                                onMouseOver={() => nextMouseHover()}
                                onClick={() => nextUser()}
                                title={nextUsername}
                                variant="link"
                                disabled={
                                  newCurrentIndex === allUsers?.length - 1
                                    ? true
                                    : false
                                }
                              >
                                Next
                              </Button>
                            </span>
                          </div>
                          <div className="profile_search mb-0">
                            <Form
                              noValidate
                              className="profile_search_form"
                              validated={validated}
                              onSubmit={handleSubmit}
                            >
                              <span>
                                <Form.Group controlId="search">
                                  <InputGroup className="profile-search-cancel">
                                    <Form.Control
                                      type="text"
                                      className="search-form-profile me-2"
                                      name="search"
                                      placeholder="Search"
                                      value={profileSearch}
                                      onChange={(e) => handleSearch(e)}
                                    />
                                    <InputGroup.Text
                                      onClick={() => cancelProfileSearch()}
                                    >
                                      {" "}
                                      <MdClear />{" "}
                                    </InputGroup.Text>
                                  </InputGroup>
                                </Form.Group>
                              </span>
                              <span>
                                <Button
                                  variant="primary"
                                  className="search_btn_profile btn_clr_white"
                                  loading={loading}
                                  type="submit"
                                  disabled={disableProfileSearch}
                                >
                                  Search
                                </Button>
                              </span>
                              {/* <span>
                             <Button
                               variant="outlined"
                               className="search_cancel_btn btn-default"
                               onClick={() => cancelProfileSearch()}
                             >
                               Clear
                             </Button>
                           </span> */}
                            </Form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    {" "}
                    <div className="back-button-wrapper">
                      <BackButtonComponent hasHistoryback />
                    </div>
                    <div className="mt-3 mb-2 profile-icon-container">
                      <div className="profile-wrapper">
                        <img src={Profileicon} alt="profile-icon" />
                        <h5>{profileData?.section_name}</h5>
                      </div>
                      {getRevertUser() ? (
                        <div
                          className={`revert ${
                            clientRole === "Contractor" && "mt-3"
                          }`}
                        >
                          <Button
                            variant="danger"
                            className="revert_btn"
                            onClick={handleRevertUser}
                          >
                            <img
                              src={revertIcon}
                              alt="revert"
                              className="revert-img me-1"
                            />
                            Revert User
                          </Button>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </>
                )}
              </Col>
              <Col md={6}></Col>
            </Row>
          </div>
          {userType === "Contractor" || userType === "Employee" ? null : (
            <>
              <div className="profile-border mb-3">
                <div className="client_details ms-2">
                  <h1>{profileData?.user_name}</h1>
                  <p>Profile</p>
                </div>
                <div className="profile-right-btns">
                  <div
                    className={
                      clientRole !== "Contractor" ? "d-block" : "d-hide"
                    }
                  >
                    <div className="prev_next_user_container">
                      <span>
                        {newCurrentIndex + 1} of {allUsers?.length}
                      </span>
                      <span>
                        <Button
                          variant="link"
                          onMouseOver={() => prevMouseHover()}
                          title={prevUsername}
                          disabled={newCurrentIndex === 0 ? true : false}
                          onClick={() => prevUser()}
                        >
                          Prev
                        </Button>
                        |
                        <Button
                          onMouseOver={() => nextMouseHover()}
                          onClick={() => nextUser()}
                          title={nextUsername}
                          variant="link"
                          disabled={
                            newCurrentIndex === allUsers?.length - 1
                              ? true
                              : false
                          }
                        >
                          Next
                        </Button>
                      </span>
                    </div>
                    <div className="profile_search mb-0">
                      <Form
                        noValidate
                        className="profile_search_form"
                        validated={validated}
                        onSubmit={handleSubmit}
                      >
                        <span>
                          <Form.Group controlId="search">
                            <InputGroup className="profile-search-cancel">
                              <Form.Control
                                type="text"
                                className="search-form-profile me-2"
                                name="search"
                                placeholder="Search"
                                value={profileSearch}
                                onChange={(e) => handleSearch(e)}
                              />
                              <InputGroup.Text
                                onClick={() => cancelProfileSearch()}
                              >
                                {" "}
                                <MdClear />{" "}
                              </InputGroup.Text>
                            </InputGroup>
                          </Form.Group>
                        </span>
                        <span>
                          <Button
                            variant="primary"
                            className="search_btn_profile btn_clr_white"
                            loading={loading}
                            type="submit"
                            disabled={disableProfileSearch}
                          >
                            Search
                          </Button>
                        </span>
                        {/* <span>
                          <Button
                            variant="outlined"
                            className="search_cancel_btn btn-default"
                            onClick={() => cancelProfileSearch()}
                          >
                            Clear
                          </Button>
                        </span> */}
                      </Form>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

          <div ref={innerWidth} className="tab_container">
            <Tab.Container
              defaultActiveKey="personal"
              id="uncontrolled-tab-example"
              activeKey={step}
              onSelect={(key) => handleTabSelect(key)}
            >
              <Nav>
                {list?.includes("personal_info") && (
                  <Nav.Item>
                    <Nav.Link eventKey="personal">Personal</Nav.Link>
                  </Nav.Item>
                )}
                {list?.includes("job") && (
                  <Nav.Item>
                    <Nav.Link eventKey="job">Job</Nav.Link>
                  </Nav.Item>
                )}
                {list?.includes("direct_deposit") && (
                  <Nav.Item>
                    <Nav.Link eventKey="banking">Banking</Nav.Link>
                  </Nav.Item>
                )}
                {list?.includes("time_off") && (
                  <Nav.Item>
                    <Nav.Link eventKey="timeoff">Time Off</Nav.Link>
                  </Nav.Item>
                )}
                {list?.includes("emergency_contact") && (
                  <Nav.Item>
                    <Nav.Link eventKey="emergency">Emergency</Nav.Link>
                  </Nav.Item>
                )}
                {list?.includes("documents") && (
                  <Nav.Item>
                    <Nav.Link eventKey="documents">Documents</Nav.Link>
                  </Nav.Item>
                )}
                {list?.includes("notes") && (
                  <Nav.Item>
                    <Nav.Link eventKey="notes">notes</Nav.Link>
                  </Nav.Item>
                )}
                {list?.includes("tasks") && (
                  <Nav.Item>
                    <Nav.Link eventKey="tasks">tasks</Nav.Link>
                  </Nav.Item>
                )}
                {list?.includes("assessments") && (
                  <Nav.Item>
                    <Nav.Link eventKey="assessments">Assessments</Nav.Link>
                  </Nav.Item>
                )}
                {list?.includes("statetax") && (
                  <Nav.Item>
                    <Nav.Link eventKey="statetax">State Tax</Nav.Link>
                  </Nav.Item>
                )}
                {list?.includes("paystub/w2") && (
                  <Nav.Item>
                    <Nav.Link eventKey="paystub/w2">paystub/w2</Nav.Link>
                  </Nav.Item>
                )}
                {list?.includes("signforms") && (
                  <Nav.Item>
                    <Nav.Link eventKey="signforms">Sign Forms</Nav.Link>
                  </Nav.Item>
                )}
                {MoreOptions && (
                  <NavDropdown
                    title="More"
                    id="nav-dropdown-within-tab"
                    onSelect={handleDropdownSelect}
                  >
                    {dropdownData?.includes("personal_info") && (
                      <Dropdown.Item eventKey="personal">
                        Personal
                      </Dropdown.Item>
                    )}
                    {dropdownData?.includes("job") && (
                      <Dropdown.Item eventKey="job">Job</Dropdown.Item>
                    )}
                    {dropdownData?.includes("direct_deposit") && (
                      <Dropdown.Item eventKey="banking">Banking</Dropdown.Item>
                    )}
                    {dropdownData?.includes("time_off") && (
                      <Dropdown.Item eventKey="timeoff">Time Off</Dropdown.Item>
                    )}
                    {dropdownData?.includes("emergency_contact") && (
                      <Dropdown.Item eventKey="emergency">
                        Emergency
                      </Dropdown.Item>
                    )}
                    {dropdownData?.includes("documents") && (
                      <Dropdown.Item eventKey="documents">
                        Documents
                      </Dropdown.Item>
                    )}
                    {dropdownData?.includes("notes") && (
                      <Dropdown.Item eventKey="notes">Notes</Dropdown.Item>
                    )}
                    {dropdownData?.includes("tasks") && (
                      <Dropdown.Item eventKey="tasks">Tasks</Dropdown.Item>
                    )}
                    {dropdownData?.includes("assessments") && (
                      <Dropdown.Item eventKey="assessments">
                        Assessments
                      </Dropdown.Item>
                    )}
                    {dropdownData?.includes("statetax") && (
                      <Dropdown.Item eventKey="statetax">
                        State Tax
                      </Dropdown.Item>
                    )}
                    {dropdownData?.includes("paystub/w2") && (
                      <Dropdown.Item eventKey="paystub/w2">
                        Paystub/W2
                      </Dropdown.Item>
                    )}
                    {dropdownData?.includes("signforms") && (
                      <Dropdown.Item eventKey="signforms">
                        Sign Forms
                      </Dropdown.Item>
                    )}
                  </NavDropdown>
                )}
              </Nav>
              <Tab.Content>
                {tabsList?.includes("personal_info") && (
                  <Tab.Pane eventKey="personal">
                    <Personal
                      personalData={profileData?.personal_info}
                      clientRole={clientRole}
                      userid={userId}
                      stateList={stateList}
                      activeStatus={activeStatus}
                    />
                  </Tab.Pane>
                )}
                {tabsList?.includes("job") && (
                  <Tab.Pane eventKey="job">
                    <JobTab
                      jobData={profileData?.job}
                      clientRole={clientRole}
                      managers={profileData?.managers}
                      empTypeList={empTypeList}
                      compensationList={compensationList}
                      payfrequencyList={payfrequencyList}
                      flsaClassificationList={flsaClassificationList}
                      salaryHistory={profileData?.job?.history}
                      jobCodeList={profileData?.job_codes}
                      jobLocationList={profileData?.job_locations}
                      userid={userId}
                      gustoUser={gustoUser}
                      groupId={groupId}
                    />
                  </Tab.Pane>
                )}
                {tabsList?.includes("direct_deposit") && (
                  <Tab.Pane eventKey="banking">
                    <BankingTab
                      bankingData={profileData?.direct_deposit}
                      clientRole={clientRole}
                      userid={userId}
                      gustoUser={gustoUser}
                    />
                  </Tab.Pane>
                )}
                {tabsList?.includes("time_off") && (
                  <Tab.Pane eventKey="timeoff">
                    <TimeOff
                      timeOffDatalist={profileData?.timeoff}
                      userid={userId}
                      clientRole={clientRole}
                      userName={profileData?.user_name}
                      timeOffYearList={profileData?.timeoffyear}
                    />
                  </Tab.Pane>
                )}
                {tabsList?.includes("emergency_contact") && (
                  <Tab.Pane eventKey="emergency">
                    <EmergencyTab
                      emergencyData={profileData?.emergency_contact}
                      clientRole={clientRole}
                      userid={userId}
                      relationshipList={relationshipList}
                    />
                  </Tab.Pane>
                )}
                {tabsList?.includes("documents") && (
                  <Tab.Pane eventKey="documents">
                    <DocumentTab
                      documentTab={profileData?.documents.data}
                      clientRole={clientRole}
                      userid={userId}
                      hideForUser={profileData?.documents.hideforuser}
                      hideForManager={profileData?.documents.hideformanager}
                      actions={profileData?.documents.actions}
                      showColumn={profileData?.documents.viewonly}
                    />
                  </Tab.Pane>
                )}
                {tabsList?.includes("notes") && (
                  <Tab.Pane eventKey="notes">
                    <Notes
                      notesData={profileData?.notes?.data}
                      userid={userId}
                      clientRole={clientRole}
                      hideForUser={profileData?.notes.hideforuser}
                    />
                  </Tab.Pane>
                )}
                {tabsList?.includes("tasks") && (
                  <Tab.Pane eventKey="tasks">
                    <TasksTab
                      userid={userId}
                      clientRole={clientRole}
                      userList={profileData?.allUsers}
                      taskAllData={profileData?.onboarding_tasks.tasks}
                      taskUserList={taskUserList}
                    />
                  </Tab.Pane>
                )}
                {tabsList?.includes("assessments") && (
                  <Tab.Pane eventKey="assessments">
                    <AssesmentsTab
                      assessmentProfileData={profileData?.assessments}
                      userid={userId}
                      clientRole={clientRole}
                    />
                  </Tab.Pane>
                )}
                {tabsList?.includes("statetax") && (
                  <Tab.Pane eventKey="statetax">
                    <StatetaxTab userId={userId} />
                  </Tab.Pane>
                )}
                {tabsList?.includes("paystub/w2") && (
                  <Tab.Pane eventKey="paystub/w2">
                    <PaysTub
                      userid={userId}
                      paystubTabData={profileData?.paystubs.url}
                      w2TabData={profileData?.w2}
                    />
                  </Tab.Pane>
                )}
                {tabsList?.includes("signforms") && (
                  <Tab.Pane eventKey="signforms">
                    <EmployeeSignDocumentsTab
                      userid={userId}
                      signforms={profileData?.signforms}
                      nextStep={nextStep}
                    />
                  </Tab.Pane>
                )}
              </Tab.Content>
            </Tab.Container>
          </div>
        </div>
      )}

      {loading && <Loader />}
    </div>
  );
};

export default Profile;
