import React from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import './style.scss'
const Steper = ({max, ProgressValue, className}) => {
    const Step = ProgressValue;
    return (
    <CircularProgressbar className={className} value={Step} maxValue={max} text={`${Step}/${max}`} />
    );
};

export default Steper;