import React from "react";
import OnboardHeading from "../OnboardHeading";
import { Col, Row, Container } from "react-bootstrap";
import PayRecord from "./payRecord";
import "./style.scss";
import WhosWorking from "./whosWorking";
import TimeOffComponent from "./timeOff";

export default function TimeCards() {
  return (
    <div>
      <Container fluid>
        <div className="time-card-container">
          <Row>
            <Col>
              <OnboardHeading title="TIMEKEEPING" />
              <div className="justify-content-between w-100 time-keeping-wrapper">
                <div className="bg-white timoff-whos-wrapper me-4">
                  <TimeOffComponent />
                </div>
                <div className="bg-white timoff-whos-wrapper ms-2">
                  <WhosWorking />
                </div>
              </div>
              <div className=" justify-content-between w-100 bg-white border-class time-keeping-wrapper-responsive">
                <Col xs={12}>
                  <TimeOffComponent />
                </Col>
                <Col xs={12}>
                  <WhosWorking />
                </Col>
              </div>
              <PayRecord />
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
}
