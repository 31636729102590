import React, { useState, useEffect } from "react";
import { Form, Row, Col } from "react-bootstrap";
import Button from "../../components/Form/Button";
import Select from "react-select";
import InputGroup from "react-bootstrap/InputGroup";
import {
    mustNumberswithPhone,
    emptySpace,
    onlyCharacterWithSpaceAndComma,
    mustNumbers,
    autocloseTiming
} from "../../api/regex";
import { postData, phoneNumberFormat } from "../../api";
import { toast } from "react-toastify";
import Loader from "../../components/Loader";
import { AiOutlinePlus } from "react-icons/ai";
import "./style.scss";

const AddAddress = ({ companySettingsObject, stateList }) => {
    const [validated, setValidated] = useState(false);
    const [loading, setLoading] = useState(false);
    const [clientObject, setClientObject] = useState({
        title: "",
        address1: "",
        address2: "",
        city: "",
        state: "",
        zip: "",
        phone_number: "",
        mailing_address: "0",
        filing_address: "0",
    });
    const [errors, setErrors] = useState({});
    const [inputArrayList, setInputArrayList] = useState([clientObject]);
    const [disable, setDisable] = useState(true);
    const [addDisable, setAddDisable] = useState(false);
    const [addErr, setAddErr] = useState();
    const [stateDisable, setStateDisable] = useState({});

    useEffect(() => {
        if (companySettingsObject?.add_address) {
            setInputArrayList(companySettingsObject?.add_address);
        }
    }, [companySettingsObject]);

    const handleInput = (e, index) => {
        let name = [e.target.name];
        let value = e.target.value;
        let copyData = [...inputArrayList];
        copyData[index][name] = value;
        setInputArrayList(copyData);
        if (!!errors[`${name + index}`])
            setErrors({
                ...errors,
                [name + index]: null,
            });
    };
    const handleCheckMailAddressChange = (e, index) => {
        let checkData = [...inputArrayList];
        checkData.forEach((element, i) => {
            if (element.mailing_address === "1") {
                element.mailing_address = "0";
            }
        });
        let copyData = [...checkData];
        if (e.target.checked === true) {
            copyData[index].mailing_address = "1";
        } else {
            copyData[index].mailing_address = "0";
        }
        setInputArrayList(copyData);
    };

    const handleCheckFillAddressChange = (e, index) => {
        let checkData = [...inputArrayList];
        checkData.forEach((element, i) => {
            if (element.filing_address === "1") {
                element.filing_address = "0";
            }
        });
        let copyData = [...checkData];
        if (e.target.checked === true) {
            copyData[index].filing_address = "1";
        } else {
            copyData[index].filing_address = "0";
        }
        setInputArrayList(copyData);
    };

    const formatSelectOptions = (data) => {
        let finalArr = [];
        if (data && data.length > 0) {
            data.forEach((item) => {
                finalArr.push({
                    value: item.name,
                    label: item.name,
                    name: item.name,
                });
            });
        }
        return finalArr;
    };


    const handlePhoneFormatChange = (e,index) => {
        let name = [e.target.name];
        let value = phoneNumberFormat(e.target.value);
        let copyData = [...inputArrayList];
        copyData[index][name] = value;
        setInputArrayList(copyData);
    }

    const handlePhoneNumberKeyPress = (event) => {
        const keyCode = event.which || event.keyCode;
        const keyValue = String.fromCharCode(keyCode);
        const isValidKey = /^\d$/.test(keyValue);
        const inputValue = event.target.value?.replace(/-/g, ''); // Remove hyphens from input value
        const isInputValidLength = inputValue.length < 10;

        if (!isValidKey || !isInputValidLength) {
            event.preventDefault();
        }
    };

    const handleStateOnChange = (data, index) => {
        let copyData = [...inputArrayList];
        copyData[index].state = data;
        setInputArrayList(copyData);
        if (!!errors[`state${index}`])
            setErrors({
                ...errors,
                [`state${index}`]: null,
            });
    };

    // add more address field
    const addAddress = () => {
        let existingInput = [...inputArrayList];
        existingInput.push({
            title: "",
            address1: "",
            address2: "",
            city: "",
            state: "",
            zip: "",
            phone_number: "",
            mailing_address: "0",
            filing_address: "0",
        });

        if (!addErr) {
            setInputArrayList(existingInput);
            setErrors({})
        } else {
            toast.error("Please check all the required fields", {
                theme: "colored",
            });
        }
    };

    useEffect(() => {
        let newStateDisable = {}
        inputArrayList.forEach((item, index) => {
            if (item?.id) {
                newStateDisable[`state${index}`] = true;
            } else {
                newStateDisable[`state${index}`] = false
            }

            if (
                !item?.title ||
                item?.address1 === "" ||
                item?.state === '' ||
                item?.city === "" ||
                item?.zip === "" ||
                item?.phone_number === ''
            ) {
                setAddErr(true);
            } else {
                setAddErr(false);
            }

            return newStateDisable;
        });
        setStateDisable(newStateDisable)
    }, [inputArrayList]);



    const handleDisable = () => {
        setDisable(false);
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        const newErrors = {};
        inputArrayList.forEach((item, index) => {
            if (!item?.title || item?.title === "")
                newErrors[`title${index}`] = "Please enter title.";
            if (!item?.address1 || item?.address1 === "")
                newErrors[`address1${index}`] = "Please enter street 1.";
            else if (item?.address1?.length < 5) {
                newErrors[`address1${index}`] =
                    "The street name should be  5 to 250 characters long";
            } else if (item?.address1?.length > 250) {
                newErrors[`address1${index}`] =
                    "The street name should be  5 to 250 characters long";
            } else if (!item?.address1?.match(emptySpace)) {
                newErrors[`address1${index}`] =
                    "The street name should be  5 to 250 characters long";
            }
            if (!item?.city || item?.city === "")
                newErrors[`city${index}`] = "Please enter your city.";
            else if (!item?.city?.match(onlyCharacterWithSpaceAndComma)) {
                newErrors[`city${index}`] = "The city name should be alphabetic.";
            } else if (item?.city?.length < 4) {
                newErrors[`city${index}`] = "The city name should be  4 to 20 characters long";
            } else if (item?.city?.length > 20) {
                newErrors[`city${index}`] = "The city name should be  4 to 20 characters long";
            }
            if (!item?.state || item?.state === "" || item.state === null)
                newErrors[`state${index}`] = "Please enter your state.";
            if (!item?.zip || item?.zip === "")
                newErrors[`zip${index}`] = "Please enter your zip.";
            else if (!item?.zip.toString()?.match(mustNumbers)) {
                newErrors[`zip${index}`] = "The zip should be numeric.";
            } else if (item?.zip?.length !== 5) {
                newErrors[`zip${index}`] = "The zip code should be 5 digit";
            }
            if (!item?.phone_number || item?.phone_number === "")
                newErrors[`phone_number${index}`] = "Please enter your phone number.";
            if (item?.phone_number !== "") {
                const Phone_numbers_Only = item?.phone_number?.replace(/\D/g, '');
                if (!Phone_numbers_Only?.match(mustNumberswithPhone)) {
                    newErrors[`phone_number${index}`] = "The phone number should be numeric.";
                } else if (Phone_numbers_Only.length !== 10) {
                    newErrors[`phone_number${index}`] = "Please enter a 10 digit phone number";
                }
            }
            return newErrors;
        });
        let isAddressValid = false;

        const isMailingAddressValid = inputArrayList.some(el => el.mailing_address === "1");
        const isFillingAddressValid = inputArrayList.some(el => el.filing_address === "1");
        if (!isMailingAddressValid || !isFillingAddressValid) {
            isAddressValid = true
        }
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
        }
        setValidated(true);
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
        } else {
            if (form.checkValidity() === true) {
                if (!disable && !isAddressValid) {
                    addressOnboard();
                } else {
                    toast.error("Please check all the required fields", {
                        theme: "colored",
                    });
                }
            } else {
                toast.error("Please check all the required fields", {
                    theme: "colored",
                });
            }
        }

    };
    const addressOnboard = async () => {
        setLoading(true);
        let copyInputArrayList = [...inputArrayList];
        let arrayData = [];
        copyInputArrayList.forEach((data) => {
            arrayData.push(data);
        });
        let data = {
            stage: "add_address",
            empAddress: inputArrayList,
        };
        const res = await postData("client_setting", {}, data);
        if (res.status === true) {
            setLoading(false);
            setDisable(true);
            setAddDisable(false);
            setErrors({})
            setInputArrayList(res?.data?.add_address);
            toast.success(res.message, { theme: "colored", autoClose: autocloseTiming });
        } else {
            toast.error(res.message, { theme: "colored", autoClose: autocloseTiming });;
            setLoading(false);
        }
    };

    return (
        <>
            {!loading && (
                <div className="board-right board-tab-sections">
                    <Form
                        noValidate
                        validated={validated}
                        onSubmit={handleSubmit}
                        autoComplete="off"
                        className="custom-form company-settings-form add-addres-settings"
                    >
                        <Row>
                            {inputArrayList &&
                                inputArrayList?.map((item, index, arr) => (
                                    <div className="row mul-box" key={index}>
                                        <Col md={6} className="mb-2">
                                            <h4 className="add-title">Address {index + 1}</h4>
                                        </Col>
                                        <Col md={8}>
                                            <Form.Group className="mb-3" controlId="title">
                                                <Form.Label>Title <span className="asterisk">*</span> </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Enter title"
                                                    value={item.title}
                                                    onChange={(e) => handleInput(e, index)}
                                                    dataid={index}
                                                    isInvalid={!!errors[`title${index}`]}
                                                    name="title"
                                                    maxLength="250"
                                                    required
                                                    disabled={disable}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors[`title${index}`]}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className="mb-3" controlId="address1">
                                                <Form.Label>Street 1 <span className="asterisk">*</span> </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Enter street 1"
                                                    value={item.address1}
                                                    onChange={(e) => handleInput(e, index)}
                                                    dataid={index}
                                                    isInvalid={!!errors[`address1${index}`]}
                                                    name="address1"
                                                    maxLength="250"
                                                    required
                                                    disabled={disable}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors[`address1${index}`]}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className="mb-3" controlId="address2">
                                                <Form.Label>Street 2 (Optional)</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Enter street 2"
                                                    value={item.address2}
                                                    onChange={(e) => handleInput(e, index)}
                                                    dataid={index}
                                                    isInvalid={!!errors[`address2${index}`]}
                                                    name="address2"
                                                    maxLength="250"
                                                    disabled={disable}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors[`address2${index}`]}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className="mb-3" controlId="City">
                                                <Form.Label>City <span className="asterisk">*</span> </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Enter city"
                                                    value={item.city}
                                                    onChange={(e) => handleInput(e, index)}
                                                    dataid={index}
                                                    isInvalid={!!errors[`city${index}`]}
                                                    name="city"
                                                    required
                                                    maxLength="250"
                                                    disabled={disable}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors[`city${index}`]}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className="mb-3" controlId="State">
                                                <Form.Label>State <span className="asterisk">*</span> </Form.Label>
                                                <Select
                                                    closeMenuOnSelect={true}
                                                    hideSelectedOptions={false}
                                                    options={formatSelectOptions(stateList)}
                                                    onChange={(selectedOption) =>
                                                        handleStateOnChange(selectedOption, index)
                                                    }
                                                    value={item.state}
                                                    placeholder="Select state"
                                                    className="custom-select"
                                                    isDisabled={stateDisable[`state${index}`]}
                                                />

                                                {
                                                    validated && errors[`state${index}`] &&
                                                    <div className="err-feedback">Please enter state.</div>
                                                }
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className="mb-3" controlId="zip">
                                                <Form.Label>Zip code <span className="asterisk">*</span> </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Enter Zip code"
                                                    value={item.zip}
                                                    onChange={(e) => handleInput(e, index)}
                                                    dataid={index}
                                                    isInvalid={!!errors[`zip${index}`]}
                                                    name="zip"
                                                    required
                                                    maxLength="250"
                                                    disabled={disable}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors[`zip${index}`]}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className="mb-3" controlId="phone_number">
                                                <Form.Label> Phone number <span className="asterisk">*</span> </Form.Label>
                                                <InputGroup className="phone-group">
                                                    <InputGroup.Text>+1</InputGroup.Text>

                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Enter phone number"
                                                        value={item.phone_number}
                                                        onChange={(e) => handleInput(e, index)}
                                                        dataid={index}
                                                        isInvalid={!!errors[`phone_number${index}`]}
                                                        name="phone_number"
                                                        // maxLength="10"
                                                        onBlur={(e) => handlePhoneFormatChange(e, index)}
                                                        onKeyPress={handlePhoneNumberKeyPress}
                                                        required
                                                        disabled={disable}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors[`phone_number${index}`]}
                                                    </Form.Control.Feedback>
                                                </InputGroup>
                                            </Form.Group>
                                        </Col>
                                        <Col md={12}>
                                            <Form.Group
                                                className="mb-3 company-add-address"
                                                controlId="mailing_address"
                                            >
                                                <Form.Label>Select address type(s)</Form.Label>
                                                <Form.Check
                                                    label="Mailing address (Optional)"
                                                    name="mailing_address"
                                                    type="checkbox"
                                                    checked={item.mailing_address == "1" ? true : false}
                                                    value="1"
                                                    onChange={(e) =>
                                                        handleCheckMailAddressChange(e, index)
                                                    }
                                                    disabled={disable}
                                                    id={`mailing_address ${index}`}
                                                    dataid={index}
                                                />
                                                <div className="check-subtitle">
                                                    This is where you’d like to receive mail. it’s usually
                                                    the same as your filing address.
                                                </div>
                                            </Form.Group>
                                        </Col>
                                        <Col md={12}>
                                            <Form.Group
                                                className="mb-3 company-add-address"
                                                controlId="filing_address"
                                            >
                                                <Form.Check
                                                    label="Filing address (Optional)"
                                                    name="filing_address"
                                                    type="checkbox"
                                                    checked={item.filing_address == "1" ? true : false}
                                                    value="2"
                                                    onChange={(e) =>
                                                        handleCheckFillAddressChange(e, index)
                                                    }
                                                    disabled={disable}
                                                    id={`filing_address ${index}`}
                                                />
                                                <div className="check-subtitle">
                                                    This is your primary physical place of business. it
                                                    can’t be a P.O. Box. The filing address should match
                                                    the address you have on file with the IRS, which you
                                                    can find federal EIN assignment form (form CP575).
                                                    We’ll use it for local, state, and federal filings.
                                                </div>
                                            </Form.Group>
                                        </Col>
                                    </div>
                                ))}
                        </Row>
                        <Row></Row>
                        <Row>
                            <Col md={12} className="my-3 w-60">
                                <p>
                                    We will need to collect and add any employee’s physical
                                    working address in the US including remote employees and
                                    employees who work from home
                                </p>
                            </Col>
                        </Row>
                        {!disable && (
                            <Row>
                                <Col md={6}>
                                    <div className="text-start mt-4">
                                        <Button
                                            type="button"
                                            className="btn-outline-primary me-2"
                                            onClick={addAddress}
                                            disabled={addDisable}
                                        >
                                            <AiOutlinePlus className="me-1" /> Add address
                                        </Button>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="text-end mt-4">
                                        <Button
                                            type="submit"
                                            disabled={loading}
                                            loading={loading}
                                            className="btn-primary text-white create-btn"
                                        >
                                            Save changes
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        )}
                    </Form>
                    {disable && (
                        <div className="text-end mt-4">
                            <Button
                                type="button"
                                className="btn-outline-primary me-2"
                                onClick={() => handleDisable()}
                            >
                                Edit
                            </Button>
                        </div>
                    )}
                </div>
            )}
            {loading && <Loader />}
        </>
    );
};

export default AddAddress;
