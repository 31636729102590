import React, { useState, useEffect } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import { postData } from "../../../api";
import Loader from "../../../components/Loader";
import { toast } from "react-toastify";
import "./style.scss";
import stateTaxIcon from "../../../assets/images/statetaxIcon.svg"
import { Markup } from "interweave";
import { autocloseTiming } from '../../../api/regex';

const StatetaxTab = ({ userId }) => {
  const [loading, setLoading] = useState(false);
  const [statetaxData, setStatetaxData] = useState();
  const [validated, setValidated] = useState(false);
  // const [errors, setErrors] = useState({});
  const [fireNewHireDisable, setFireNewHireDisable] = useState(false);
  const [noData, setNoData] = useState("");
  const [stateTaxFullData, setStateTaxFullData] = useState();

  useEffect(() => {
    getInitialData();
  }, []);// eslint-disable-line react-hooks/exhaustive-deps

  const formattedData = (statedata) => {
    let dataCopy = [...statedata];
    dataCopy.forEach((category, category_index) => {
      category.questions.forEach((question, index) => {

        if (dataCopy[category_index].questions[index].answers.length !== 0) {
          let defaultAnsValue = dataCopy[category_index].questions[index].answers[0].value
          dataCopy[category_index].questions[index].answers_value = defaultAnsValue


          // set disable to file hire report
          if (dataCopy[category_index].questions[index].key === 'file_new_hire_report') {
            setFireNewHireDisable(true)
          }

        } else {
          if (dataCopy[category_index].questions[index].key === 'file_new_hire_report') {
            dataCopy[category_index].questions[index].answers_value = false;
            setFireNewHireDisable(false)
          } else {
            dataCopy[category_index].questions[index].answers_value = null
          }
        }
      });
    });
    setStatetaxData(dataCopy);
  };

  const getInitialData = async () => {
    let data = {
      user_id: userId,
    };
    setLoading(true);
    const res = await postData("get-employee-state-tax", {}, data);
    if (res.status === 1) {
      setLoading(false);
      setNoData(res?.no_data);
      setStatetaxData(res?.data);
      formattedData(res?.data)
      setStateTaxFullData(res)
    } else if (
      res.status === undefined ||
      res.status === 0 ||
      res.status === null
    ) {
      setLoading(false);
    }
  };

  const handleCheckInputChange = (e, question_index, main_index, whichStatus) => {

    const newFormData = [...statetaxData];
    if (newFormData[main_index].questions[question_index].answers?.length === 0) {
      let valueData = { 'value': whichStatus }
      newFormData[main_index].questions[question_index].answers.push(valueData)
    } else {
      newFormData[main_index].questions[question_index].answers[0].value = whichStatus
    }
    newFormData[main_index].questions[question_index].answers_value = whichStatus;

    setStatetaxData(newFormData);
  };

  const handleInputChange = (e, question_index, main_index) => {
    const value = e.target.value;
    const newFormData = [...statetaxData];
    if (newFormData[main_index].questions[question_index].answers?.length === 0) {
      let valueData = { 'value': value }
      newFormData[main_index].questions[question_index].answers.push(valueData)
    } else {
      newFormData[main_index].questions[question_index].answers[0].value = value
    }
    newFormData[main_index].questions[question_index].answers_value = value;
    setStatetaxData(newFormData);

  };

  const stateTaxSubmit = async (answersArray) => {
    setLoading(true);
    let data = {
      user_id: userId,
      show_admin_only_question: stateTaxFullData?.show_admin_only_question,
      show_all_question: stateTaxFullData?.show_all_question,
      show_employee_only_question: stateTaxFullData?.show_employee_only_question,
      sync: stateTaxFullData?.sync,
      states: answersArray

    };
    const res = await postData("update-employee-state-tax", {}, data);
    if (res.status === 1) {
      setLoading(false);
      toast.success(res.message, { theme: "colored", autoClose: autocloseTiming });
      // Reload the current page for w4 forms tab show
      window.location.reload();
    } else {
      setLoading(false);
      toast.error(res.message, { theme: "colored", autoClose: autocloseTiming });;
    }
  };

  const formSubmitHandler = (e) => {
    e.preventDefault();
    const newStateTaxData = [...statetaxData];
    const formattedStateTaxData = [];

    newStateTaxData.forEach((stateData) => {
      const formattedStateData = {
        questions: [],
        state: stateData.state,

      };
      stateData.questions.forEach((question) => {
        const formattedQuestion = {
          answers: question.answers.map((answer) => ({
            value: answer.value,
            valid_from: answer.valid_from ? answer.valid_from : "2010-01-01",
            valid_up_to: null
          })),
          key: question.key
        };

        // if (question?.key === 'file_new_hire_report' && 'file_new_hire_report_answer' in stateData && formattedQuestion?.answers?.length === 0) {
        //   formattedQuestion.answers.push({
        //     value: stateData?.file_new_hire_report_answer ? stateData?.file_new_hire_report_answer : false, valid_from: "2010-01-01",
        //     valid_up_to: null
        //   });
        // }
        if (question?.key === 'file_new_hire_report' && formattedQuestion?.answers?.length === 0) {
          formattedQuestion.answers.push({
            value: false, valid_from: "2010-01-01",
            valid_up_to: null
          });
        }

        formattedStateData.questions.push(formattedQuestion);
      });

      formattedStateTaxData.push(formattedStateData);
    });


    // newStateTaxData.forEach((stateData) => {
    //   const formattedStateData = {
    //     questions: [],
    //     state: stateData.state
    //   };

    //   stateData.questions.forEach((question) => {
    //     const formattedQuestion = {
    //       answers: question.answers.map((answer) => ({
    //         value: answer.value,
    //         valid_from: answer.valid_from ? answer.valid_from : "2010-01-01",
    //         valid_up_to: null
    //       })),
    //       key: question.key
    //     };

    //     formattedStateData.questions.push(formattedQuestion);
    //   });

    //   formattedStateTaxData.push(formattedStateData);
    // });

    stateTaxSubmit(formattedStateTaxData);
  };

  return (
    <div className="statetax_container">
      {!loading && (
        <div>
          <Row >
            <Col className="d-flex align-items-center">
              <h5 className="tabs_main_heading">
                <span className="me-1">
                  <img src={stateTaxIcon} alt="" srcSet="" />
                </span>
                State Tax
              </h5>
            </Col>
          </Row>

          <Row>
            {
              statetaxData?.length !== 0 ?

                <Form noValidate validated={validated} className="statetax_form" onSubmit={formSubmitHandler}>
                  {statetaxData?.map((data, main_index) => {
                    return (
                      <Row key={main_index}>
                        <Col>
                          <Row className="mt-3">
                            <Col>
                              <p className="statetax_location_name">
                                {data.state_full_form}
                              </p>
                            </Col>
                          </Row>
                          {data.questions?.length === 0 &&
                            <div className="no-data mb-0">No information needed</div>
                          }
                          {data.questions.map((question, question_index) => {
                            return (
                              <Col key={question_index}>
                                {/* {!(data?.show_file_new_hire_report_ui === false && question.key === "file_new_hire_report") && ( */}
                                <p className="mb-1" id="key_label">
                                  {question.label}
                                </p>
                                {/* )} */}
                                <Form.Group
                                  className="mb-3"
                                  controlId="compensation"
                                >
                                  <Form.Label>
                                    {/* {!(data?.show_file_new_hire_report_ui === false && question.key === "file_new_hire_report") && ( */}
                                    <p
                                      className="grey_text"
                                      dangerouslySetInnerHTML={{
                                        __html: question.description,
                                      }}
                                    ></p>
                                    {/* )} */}

                                  </Form.Label>
                                  {question.input_question_format.type ===
                                    "Select" && question.key === "filing_status" ? (
                                    <>
                                      <Form.Select
                                        name={question.key}
                                        size="lg"
                                        className="custom_form_select"
                                        value={question?.answers_value ? question?.answers_value : question?.answers[0]?.value}
                                        onChange={(e) =>
                                          handleInputChange(
                                            e,
                                            question_index,
                                            main_index
                                          )
                                        }
                                      >
                                        <option value="" className="placeholder_text">Please select a value</option>
                                        {question.input_question_format.options.map(
                                          (option, i) => {
                                            return (
                                              <option
                                                key={i}
                                                value={option.value}
                                                className="placeholder_text"
                                              >
                                                {option.label}
                                              </option>
                                            );
                                          }
                                        )}
                                      </Form.Select>
                                    </>

                                  )
                                    : question.input_question_format.type ===
                                      "Select" && question.key === "withholding_rate" ? (
                                      <>
                                        <Form.Select
                                          name={question.key}
                                          size="lg"
                                          className="custom_form_select"
                                          value={question?.answers_value ? question?.answers_value : question?.answers[0]?.value}
                                          onChange={(e) =>
                                            handleInputChange(
                                              e,
                                              question_index,
                                              main_index
                                            )
                                          }
                                        >
                                          <option value="" className="placeholder_text">Please select a value</option>
                                          {question.input_question_format.options.map(
                                            (option, i) => {
                                              return (
                                                <option
                                                  key={i}
                                                  value={option.value}
                                                  className="placeholder_text"
                                                >
                                                  {option.label}
                                                </option>
                                              );
                                            }
                                          )}
                                        </Form.Select>
                                      </>

                                    )
                                      : question.input_question_format.type ===
                                        "Select" &&
                                        question.key === "file_new_hire_report" ? (
                                        <Form.Group
                                          className="mb-3"
                                          // className={`mb-3 ${data?.show_file_new_hire_report_ui ? "d-block" : "d-none"}`}
                                          controlId="compensation"
                                        >
                                          {

                                            question.input_question_format.options.map(
                                              (option, i) => {
                                                const ansValue = question.answers.length > 0 ? question.answers[0].value : false
                                                const isChecked = ansValue && option.label == "Yes, file the state new hire report for me." ? true : !ansValue && option.label == "No, I have already filed." ? true : false

                                                return (
                                                  <React.Fragment key={i}>
                                                    <div >
                                                      <Form.Check
                                                        type="radio"
                                                        id={`default-radio-${i}`}
                                                        name={option.label}
                                                        checked={isChecked}
                                                        value={option.value}
                                                        label={option.label}
                                                        onChange={(e) =>
                                                          handleCheckInputChange(
                                                            e,
                                                            question_index,
                                                            main_index,
                                                            option.value
                                                          )
                                                        }
                                                        disabled={fireNewHireDisable}
                                                      />
                                                    </div>
                                                  </React.Fragment>
                                                );
                                              }
                                            )
                                          }
                                          {/* {
                                        fireNewHireDisable &&
                                        <div className="err-feedback"> File new hire report settings cannot be changed after it has been configured</div>
                                      } */}
                                        </Form.Group>
                                      ) : question.input_question_format.type ===
                                        "Select" ? (
                                        <>
                                          <Form.Select
                                            name={question.key}
                                            size="lg"
                                            className="custom_form_select"
                                            value={question?.answers_value ? question?.answers_value : question?.answers[0]?.value}
                                            onChange={(e) =>
                                              handleInputChange(
                                                e,
                                                question_index,
                                                main_index
                                              )
                                            }
                                          >
                                            <option value="" className="placeholder_text">Please select a value</option>
                                            {question.input_question_format.options.map(
                                              (option, i) => {
                                                return (
                                                  <option
                                                    key={i}
                                                    value={option.value}
                                                    className="placeholder_text"
                                                  >
                                                    {option.label}
                                                  </option>
                                                );
                                              }
                                            )}
                                          </Form.Select>
                                        </>

                                      )
                                        : (
                                          <Form.Group
                                            className="mb-3"
                                            controlId="formBasicEmail"
                                          >
                                            <Form.Control
                                              type={question.input_question_format.type}
                                              name={question.key}
                                              value={question?.answers[0]?.value}
                                              onChange={(e) =>
                                                handleInputChange(
                                                  e,
                                                  question_index,
                                                  main_index
                                                )
                                              }
                                            />
                                          </Form.Group>
                                        )}
                                  <div className="form_select_dropdown_icon"></div>
                                </Form.Group>
                              </Col>
                            );
                          })}
                        </Col>
                      </Row>
                    );
                  })}
                  {Array.isArray(statetaxData) && statetaxData?.some(item => item?.questions?.length !== 0) && (
                    <Col className="pb-4">
                      <div className="statetax_save_btn_container">
                        <Button
                          variant="primary"
                          type="submit"
                          className="save_btn"
                        >
                          Save
                        </Button>
                      </div>
                    </Col>
                  )}
                </Form>
                :
                <Markup
                  content={noData}
                />
            }

          </Row>
        </div >
      )
      }
      {loading && <Loader />}
    </div >
  );
};

export default StatetaxTab;
