import React, { useState } from "react";
import { Form, Row, Col, Container } from "react-bootstrap";
import logo from "../../assets/images/logo.png";
import Button from "../../components/Form/Button";
import { postData } from "../../api";
import { toast } from "react-toastify";
import LoginFooter from "../LoginFooter";
import {autocloseTiming} from '../../api/regex';

const ForgotPassword = () => {

  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [forgotPasswordObject, setForgotPassword] = useState({
    username: ""
  });


  const handleInput = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    delete errors[name];
    setForgotPassword((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // check form error
  const checkFormErrors = () => {
    const { username } = forgotPasswordObject;

    const newErrors = {};
    if (!username || username === "") newErrors.username = "Please enter your username.";
    return newErrors;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const newErrors = checkFormErrors();
    setValidated(true);
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      userForgotPassword();
    }
  };

  const userForgotPassword = async () => {
    setLoading(true);
    const res = await postData("forgot_password", {}, forgotPasswordObject);
    if (res.status === true) {
      setLoading(false);
      toast.success(res.message, { theme: "colored" ,autoClose: autocloseTiming });
      localStorage.setItem('forgotEmail', forgotPasswordObject.username);
    } else {
      setLoading(false);
       toast.error(res.message, { theme: "colored", autoClose: autocloseTiming });;
    }
  };

  return (
    <>
      <div className="login-body">
        <div className="login-header">
          <img src={logo} alt="logo" />
        </div>
        <section className="login-section">
          <Container>
            <Row className="justify-content-center align-items-center">
              <Col md={5}>
                <div className="login-box">
                  <div className="content">
                    <h5> We got your back </h5>
                    <div className="sub-title mb-3">
                      Please provide your registered username to receive the reset link
                    </div>

                    <Form noValidate validated={validated} onSubmit={handleSubmit} autoComplete="off">
                      <Form.Group className="custom-form mb-3" controlId="username">
                        <Form.Label> Username</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter username"
                          value={forgotPasswordObject.username}
                          onChange={handleInput}
                          isInvalid={!!errors.username}
                          name="username"
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.username}
                        </Form.Control.Feedback>
                      </Form.Group>

                      <div className="text-center mt-4">
                        <Button
                          type="submit"
                          disabled={loading}
                          loading={loading}
                          className="btn-primary text-white"
                        >
                          Send link
                        </Button>
                      </div>

                    </Form>

                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
      <LoginFooter />
    </>
  );
};

export default ForgotPassword;
