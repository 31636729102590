import React from "react";
import TimeOffCalender from "../../components/TimeOffCalender";

export default function TimeOffCalenderPage() {
  return (
    <>
      <TimeOffCalender />
    </>
  );
}
