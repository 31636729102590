import React from "react";
import MultiCardDetailComponent from "../../components/multiCardDetail";

export default function MultiCardDetail() {
  return (
    <div>
      <MultiCardDetailComponent />{" "}
    </div>
  );
}
