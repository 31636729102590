import React, { useState, useEffect } from "react";
import { Form, Row, Col } from "react-bootstrap";
import Button from "../../../components/Form/Button";
import Select from "react-select";
import InputGroup from 'react-bootstrap/InputGroup';
import { mustNumberswithPhone, emptySpace, onlyCharacterWithSpaceAndComma, mustNumbers, autocloseTiming } from "../../../api/regex";
import { postData, phoneNumberFormat } from "../../../api";
import { toast } from "react-toastify";
import Loader from "../../../components/Loader";


const AddAddress = ({ nextStep, onboardObject, stateList }) => {

    const [validated, setValidated] = useState(false);
    const [loading, setLoading] = useState(false);
    const [clientObject, setClientObject] = useState({
        address1: "",
        address2: "",
        city: "",
        state: "",
        zip: "",
        phone_number: "",
        mailing_address: "1",
        filing_address: "1"
    });
    const [errors, setErrors] = useState({});
    const [stateValue, setStateValue] = useState();

    useEffect(() => {
        if (onboardObject?.add_address) {
            setClientObject(onboardObject?.add_address);
            let data = {
                label: onboardObject?.add_address?.state,
                value: onboardObject?.add_address?.state,
            }
            setStateValue(data)
        }
    }, [onboardObject]);


    const handlePhoneFormatChange = (e) => {
        const name = e.target.name;
        e.target.value = phoneNumberFormat(e.target.value)
        setClientObject((prev) => ({
            ...prev,
            [name]: e.target.value,
        }));
    }

    const handlePhoneNumberKeyPress = (event) => {
        const keyCode = event.which || event.keyCode;
        const keyValue = String.fromCharCode(keyCode);
        const isValidKey = /^\d$/.test(keyValue);
        const inputValue = event.target.value?.replace(/-/g, ''); // Remove hyphens from input value
        const isInputValidLength = inputValue.length < 10;

        if (!isValidKey || !isInputValidLength) {
            event.preventDefault();
        }
    };
    const handleInput = (e) => {
        const value = e.target.value;
        const name = e.target.name;
        setClientObject((prev) => ({
            ...prev,
            [name]: value,
        }));
        if (!!errors[name])
            setErrors({
                ...errors,
                [name]: null,
            });
    };

    

    const handleCheckMailAddressChange = (e) => {
        const name = e.target.name;
        if (e.target.checked === true) {
            setClientObject((prev) => ({
                ...prev,
                mailing_address: "1",
            }));
            if (!!errors[name])
                setErrors({
                    ...errors,
                    mailing_address: null,
                });
        } else {
            setClientObject((prev) => ({
                ...prev,
                mailing_address: "0",
            }));
            if (!!errors[name])
                setErrors({
                    ...errors,
                    mailing_address: null,
                });
        }
    };

    const handleCheckFillAddressChange = (e) => {
        const name = e.target.name;
        if (e.target.checked === true) {
            setClientObject((prev) => ({
                ...prev,
                filing_address: "1",
            }));
            if (!!errors[name])
                setErrors({
                    ...errors,
                    filing_address: null,
                });
        } else {
            setClientObject((prev) => ({
                ...prev,
                filing_address: "0",
            }));
            if (!!errors[name])
                setErrors({
                    ...errors,
                    filing_address: null,
                });
        }
    };

    const formatSelectOptions = (data) => {
        let finalArr = [];
        if (data && data.length > 0) {
            data.forEach((item) => {
                finalArr.push({
                    value: item.name,
                    label: item.name,
                    name: item.name,
                });
            });
        }
        return finalArr;
    };

    const handleStateOnChange = (data) => {
        setStateValue(data)
        setClientObject((prev) => ({
            ...prev,
            state: data.value
        }));
    }

    const findFormErrors = () => {
        const { address1, address2, city, state, zip, phone_number, mailing_address, filing_address } = clientObject;
        const newErrors = {};
        if (!address1 || address1 === "") newErrors.address1 = "Please enter street 1.";
        else if (address1.length < 5) {
            newErrors.address1 = "The street name should be  5 to 250 characters long";
        } else if (address1.length > 250) {
            newErrors.address1 = "The street name should be  5 to 250 characters long";
        }
        if (address2 !== "" && address2 !== null && address2 !== undefined) {
            if (address2?.length < 5) {
                newErrors.address2 = "The street name should be  5 to 250 characters long";
            } else if (address2?.length > 250) {
                newErrors.address2 = "The street name should be  5 to 250 characters long";
            } else if (!address2?.match(emptySpace)) {
                newErrors.address2 = "The street name should be  5 to 250 characters long";
            }
        }
        if (!city || city === "") newErrors.city = "Please enter your city.";
        else if (!city?.match(onlyCharacterWithSpaceAndComma)) {
            newErrors.city = "The city name should be alphabetic.";
        }
        else if (city.length < 4) {
            newErrors.city = "The city name should be  4 to 20 characters long";
        } else if (city.length > 20) {
            newErrors.city = "The city name should be  4 to 20 characters long";
        }
        if (!state || state === "") newErrors.state = "Please enter your state.";
        if (!zip || zip === "") newErrors.zip = "Please enter your zip.";
        else if (!zip.toString()?.match(mustNumbers)) {
            newErrors.zip = "The zip should be numeric.";
        }
        else if (zip.length !== 5) {
            newErrors.zip = "The zip code should be 5 digit";
        }
        if (!phone_number || phone_number === "") newErrors.phone_number = "Please enter your phone number.";
        if (phone_number !== "") {
            const Phone_numbers_Only = phone_number?.replace(/\D/g, '')
            if (!Phone_numbers_Only?.match(mustNumberswithPhone)) {
                newErrors.phone_number = "The phone number should be numeric.";
            } else if (Phone_numbers_Only.length !== 10) {
                newErrors.phone_number = "Please enter a 10 digit phone number";
            }
        }
        if (!filing_address || filing_address === "" || filing_address === null || filing_address === undefined || filing_address === "0") newErrors.filing_address = "Please enter the filing address.";
        if (!mailing_address || mailing_address === "" || mailing_address === null || mailing_address === undefined || mailing_address === "0") newErrors.mailing_address = "Please enter the mailing address.";
        return newErrors;
    };


    const handleSubmit = (event) => {
        event.preventDefault();
        const newErrors = findFormErrors();
        setValidated(true);
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
        } else {
            addressOnboard();
        }
    };

    const addressOnboard = async () => {
        setLoading(true);
        let data = {
            stage: "add_address",
            ...clientObject
        }
        const res = await postData("client_onboarding_steps", {}, data);
        if (res.status === true) {
            setLoading(false);
            nextStep(res.next_steps);
            if (res.message) {
                toast.success(res.message, { theme: "colored", autoClose: autocloseTiming });
            }
        } else {
            toast.error(res.message, { theme: "colored", autoClose: autocloseTiming });;
            setLoading(false);
        }
    };

    return (
        <>
            {!loading && (
                <div className="board-right board-tab-sections payroll-client">
                    <h5 className="mb-3">Add addresses</h5>

                    <Form noValidate validated={validated} onSubmit={handleSubmit} autoComplete="off" className="custom-form">
                        <Row>
                            <Col md={4}>
                                <Form.Group className="mb-3" controlId="address1">
                                    <Form.Label>Street 1  <span className="asterisk">*</span> </Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter street 1"
                                        value={clientObject.address1}
                                        onChange={handleInput}
                                        isInvalid={!!errors.address1}
                                        name="address1"
                                        maxLength="250"
                                        required
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.address1}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col md={4}>
                                <Form.Group className="mb-3" controlId="address2">
                                    <Form.Label>Street 2 (Optional)</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter street 2 "
                                        value={clientObject.address2}
                                        onChange={handleInput}
                                        name="address2"
                                        isInvalid={!!errors.address2}
                                        maxLength="250"
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.address2}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col md={4}>
                                <Form.Group className="mb-3" controlId="city">
                                    <Form.Label>City  <span className="asterisk">*</span> </Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter city"
                                        value={clientObject.city}
                                        onChange={handleInput}
                                        isInvalid={!!errors.city}
                                        name="city"
                                        maxLength="20"
                                        required
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.city}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={4}>
                                <Form.Group className="mb-3" controlId="state">
                                    <Form.Label>State  <span className="asterisk">*</span> </Form.Label>
                                    <Select
                                        closeMenuOnSelect={true}
                                        hideSelectedOptions={false}
                                        options={formatSelectOptions(stateList)}
                                        onChange={(selectedOption) => handleStateOnChange(selectedOption)}
                                        value={stateValue}
                                        placeholder="Select state"
                                        className="custom-select"
                                    />
                                    {
                                        validated && clientObject?.state === '' &&
                                        <div className="err-feedback">Please enter state.</div>
                                    }

                                </Form.Group>
                            </Col>
                            <Col md={4}>
                                <Form.Group className="mb-3" controlId="zip">
                                    <Form.Label>Zip Code  <span className="asterisk">*</span> </Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter zip code"
                                        value={clientObject.zip}
                                        onChange={handleInput}
                                        isInvalid={!!errors.zip}
                                        name="zip"
                                        required
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.zip}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col md={4}>
                                <Form.Group className="mb-3" controlId="phone_number">
                                    <Form.Label>Phone number  <span className="asterisk">*</span>  </Form.Label>
                                    <InputGroup className="phone-group">
                                        <InputGroup.Text>
                                            +1
                                        </InputGroup.Text>

                                        <Form.Control
                                            type="text"
                                            placeholder="Enter phone number"
                                            value={clientObject.phone_number}
                                            onChange={handleInput}
                                            isInvalid={!!errors.phone_number}
                                            name="phone_number"
                                            // maxLength="10"
                                            onBlur={handlePhoneFormatChange}
                                            onKeyPress={handlePhoneNumberKeyPress}
                                            required
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.phone_number}
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <Form.Group className="mb-3" controlId="mailing_address">
                                    <Form.Label>Select address type(s)  <span className="asterisk">*</span> </Form.Label>
                                    <Form.Check
                                        label="Mailing address"
                                        name="mailing_address"
                                        type="checkbox"
                                        checked={clientObject.mailing_address === "1" ? true : false}
                                        value="1"
                                        onChange={(e) => handleCheckMailAddressChange(e)}
                                        id="mailing_address"
                                        required
                                    />
                                    <div className="check-subtitle mb-3">This is where you’d like to receive mail. it’s usually the same as your filing address.</div>
                                    {/* <Form.Control.Feedback type="invalid">
                                        {errors.mailing_address}
                                    </Form.Control.Feedback> */}
                                    {
                                        validated &&
                                        <div className="err-feedback">{errors.mailing_address}</div>
                                    }
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="filing_address">
                                    <Form.Check
                                        label="Filing address"
                                        name="filing_address"
                                        type="checkbox"
                                        checked={clientObject.filing_address === "1" ? true : false}
                                        value="2"
                                        onChange={(e) => handleCheckFillAddressChange(e)}
                                        id="filing_address"
                                        required
                                    />
                                    <div className="check-subtitle mb-3">This is your primary physical place of business. it can’t be a P.O. Box. The filing address should match the address you have on file with the IRS, which you can find federal EIN assignment form (form CP575). We’ll use it for local, state, and federal filings.</div>
                                    {/* <Form.Control.Feedback type="invalid">
                                        {errors.filing_address}
                                    </Form.Control.Feedback> */}
                                    {
                                        validated &&
                                        <div className="err-feedback">{errors.filing_address}</div>
                                    }
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12} className="my-3 w-60">
                                <p>
                                    We will need to collect and add any employee’s physical working address in the US including remote employees and employees who work from home
                                </p>
                            </Col>
                        </Row>
                        <div className="text-end mt-4">
                            <Button
                                type="submit"
                                disabled={loading}
                                loading={loading}
                                className="btn-primary text-white"
                            >
                                Save & Continue
                            </Button>
                        </div>

                    </Form>
                </div>
            )}
            {loading && <Loader />}

        </>
    )
}

export default AddAddress