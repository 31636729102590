import React, { useState, useEffect, useRef } from "react";
import FormModal from "../FormModal";
import { Form, Col, Row, Tab, Tabs } from "react-bootstrap";
import Button from "../Form/Button";
import SignaturePad from "react-signature-canvas";

const SignModal = (props) => {
  const { show, handleClose, getSignature, setSignatureError } = props;
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [signObject, setSignObject] = useState({
    uploadSign: "",
    drawSign: "",
  });
  const [selectedSignOption, setSelectedSignOption] = useState("draw");
  const sigCanvas = useRef({});

  useEffect(() => {
    signature();
  }, [signObject]); // eslint-disable-line react-hooks/exhaustive-deps

  const clearSign = () => {
    sigCanvas.current.clear();
  };

  const findFormErrors = () => {
    const { uploadSign } = signObject;
    const newErrors = {};
    if (selectedSignOption === "upload") {
      if (!uploadSign || uploadSign === "") {
        newErrors.uploadSign = "Please upload your signature .";
      }
    }
    if (selectedSignOption === "draw") {
      if (sigCanvas.current.isEmpty()) {
        newErrors.drawSign = "Please draw your signature .";
      }
    }
    return newErrors;
  };

  const handleSubmit = (event) => {
    if (selectedSignOption === "draw" && !sigCanvas.current.isEmpty()) {
      setErrors({});
      let drawSignature = sigCanvas.current
        .getTrimmedCanvas()
        .toDataURL("image/png");
      setSignObject({ ...signObject, drawSign: drawSignature, uploadSign: "" });
    }
    event.preventDefault();
    event.stopPropagation();
    const newErrors = findFormErrors();
    setValidated(true);
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      setTimeout(() => {
        event.stopPropagation();
        document.body.classList.remove("upload-modal-open");
        handleClose();
        setSignatureError("");
      }, 500);
    }
  };

  const signature = () => {
    getSignature(signObject);
  };

  return (
    <FormModal
      heading={"Signature"}
      show={show}
      onClose={handleClose}
      size="lg"
      backdrop="static"
      modalClassName="view_task_modal"
    >
      <Form
        noValidate
        validated={validated}
        onSubmit={handleSubmit}
        autoComplete="off"
        className="custom-form"
      >
        <Row>
          <Col md={12}>
            <Tabs
              activeKey={selectedSignOption}
              onSelect={(key) => setSelectedSignOption(key)}
              className="mt-3 sign-tab"
            >
              <Tab eventKey="draw" title="Draw">
                <Row>
                  <Col md={12}>
                    <Form.Group className="mb-3" controlId="drawSign">
                      <div className="mx-3">
                        <div
                          className="text-end clear-sign mb-2"
                          onClick={clearSign}
                        >
                          Clear Sign
                        </div>
                        <SignaturePad
                          ref={sigCanvas}
                          canvasProps={{
                            className: "signatureCanvas",
                          }}
                        />
                      </div>
                      <div className="err-feedback ms-3">
                        {" "}
                        {errors.drawSign}
                      </div>
                    </Form.Group>
                  </Col>
                </Row>
              </Tab>
            </Tabs>
          </Col>
        </Row>

        <div className="onboard-body">
          By signing this document with e-sign, I agree that such signature will
          be as valid as handwritten signatures.
        </div>
        <div className="text-end mt-3">
          <Button
            type="button"
            className="btn-default text-blacksix me-3 cancel-btn"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            disabled={loading}
            loading={loading}
            className="btn-primary text-white"
          >
            Save
          </Button>
        </div>
      </Form>
    </FormModal>
  );
};

export default SignModal;
