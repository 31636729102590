import React, { useState, useEffect } from "react";
import { Form, Row, Col } from "react-bootstrap";
import Button from "../../../components/Form/Button";
import { postData } from "../../../api";
import { toast } from "react-toastify";
import Select from "react-select";
import Loader from "../../../components/Loader";
import SuccessSubmitModal from './SuccessSubmitModal.js';
import { autocloseTiming } from '../../../api/regex';


const FileNewHireReport = ({ nextStep, onboardObject, stateList }) => {

    const [validated, setValidated] = useState(false);
    const [loading, setLoading] = useState(false);
    const [employeeObject, setEmployeeObject] = useState({
        file_hire_new_report: '1',
        state: ''
    });
    const [stateValue, setStateValue] = useState();
    const [errors, setErrors] = useState({});
    const [successSubmitShowModal, setSuccessSubmitShowModal] = useState(false);
    const [autoUrl, setAutoUrl] = useState();


    useEffect(() => {
        if (onboardObject?.file_hire_data) {
            setEmployeeObject(onboardObject?.file_hire_data);
            let data = {
                label: onboardObject?.file_hire_data?.state,
                value: onboardObject?.file_hire_data?.state,
            }
            setStateValue(data);

        }
    }, [onboardObject]);

    //success modal 
    const successSubmitShowModalClose = () => {
        setSuccessSubmitShowModal(false);
    };
    const formatSelectOptions = (data) => {
        let finalArr = [];
        if (data && data.length > 0) {
            data.forEach((item) => {
                finalArr.push({
                    value: item.name,
                    label: item.name,
                    name: item.name,
                });
            });
        }
        return finalArr;
    };

    const handleStateOnChange = (data) => {
        setStateValue(data)
        setEmployeeObject((prev) => ({
            ...prev,
            state: data.value
        }));
    }


    const handleReportChange = (e) => {
        setEmployeeObject((prev) => ({
            ...prev,
            file_hire_new_report: e.target.value,
        }));
    };

    const findFormErrors = () => {
        const { file_hire_new_report, state } = employeeObject;
        const newErrors = {};

        if (!file_hire_new_report || file_hire_new_report === "" || file_hire_new_report === null) newErrors.file_hire_new_report = "Please select file new hire report.";
        if (employeeObject?.file_hire_new_report?.toString() === '1') {
            if (!state || state === "") newErrors.state = "Please enter your state.";
        }

        return newErrors;
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const newErrors = findFormErrors();
        setValidated(true);
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
        } else {
            fileNewHireInfo();
        }
    };

    const fileNewHireInfo = async () => {
        setLoading(true);
        let data = {
            stage: "file_hire_new_report",
            ...employeeObject
        }
        const res = await postData("employee_onboarding_steps", {}, data);
        if (res.status === true) {
            setLoading(false);
            localStorage.setItem("guhroo_user", res.user_type)
            localStorage.setItem("guhroo_user_details", JSON.stringify(res));
            localStorage.setItem("guhroo_onbord_status", res.onboard_status);
            localStorage.setItem("user_completion_status ", res.user_completion_status);
            setAutoUrl(res.auto_url)
            setSuccessSubmitShowModal(true);
        } else {
            toast.error(res.message, { theme: "colored", autoClose: autocloseTiming });;
            setLoading(false);
        }
    };


    return (
        <>
            {!loading && (
                <>
                    <div className="board-right board-tab-sections">
                        <h5 className="mb-3">File new hire report</h5>
                        <div className="file-span mb-4">Most states require that employers file a new hire report for contractors within 20 days of their start date. Even if the report is overdue, it is generally better to file a late report and to risk penalties for
                            being out of compliance.</div>

                        <Form noValidate validated={validated} onSubmit={handleSubmit} autoComplete="off" className="custom-form">
                            <Row>
                                <Col md={12}>
                                    <Form.Group className="mb-3" controlId="file_hire_new_report">
                                        <Form.Label className="me-2">File new hire report</Form.Label>
                                        <div>
                                            <Form.Check
                                                inline
                                                label="Yes, file the report for me"
                                                name="file_hire_new_report"
                                                type="radio"
                                                checked={employeeObject.file_hire_new_report?.toString() === "1" ? true : false}
                                                value="1"
                                                onChange={(e) => handleReportChange(e)}
                                                id="Yes"
                                            />
                                            <Form.Check
                                                inline
                                                label="No, I have already filed or will file the report  myself"
                                                name="file_hire_new_report"
                                                type="radio"
                                                checked={employeeObject.file_hire_new_report?.toString() === "0" ? true : false}
                                                value="0"
                                                onChange={(e) => handleReportChange(e)}
                                                id="No"
                                            />
                                        </div>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.file_hire_new_report}
                                        </Form.Control.Feedback>

                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={4} className={`${employeeObject?.file_hire_new_report?.toString() === '1' ? 'd-block' : ' d-none'}`}>
                                    <Form.Group className="mb-3" controlId="state">
                                        <Form.Label>State <span className="asterisk">*</span></Form.Label>
                                        <Select
                                            closeMenuOnSelect={true}
                                            hideSelectedOptions={false}
                                            options={formatSelectOptions(stateList)}
                                            onChange={(selectedOption) => handleStateOnChange(selectedOption)}
                                            value={stateValue}
                                            placeholder="Select state"
                                            className="custom-select"
                                        />
                                        {
                                            validated && (employeeObject?.state === '' || employeeObject?.state === null) &&
                                            <div className="err-feedback">Please enter state.</div>
                                        }

                                    </Form.Group>
                                </Col>
                            </Row>

                            <div className="text-end mt-4">
                                <Button
                                    type="button"
                                    className="btn-outline-primary me-2"
                                    onClick={() => nextStep("direct_deposit")}
                                >
                                    Back
                                </Button>
                                <Button
                                    type="submit"
                                    disabled={loading}
                                    loading={loading}
                                    className="btn-primary text-white"
                                >
                                    Submit
                                </Button>
                            </div>

                        </Form>
                    </div>
                    {successSubmitShowModal && <SuccessSubmitModal show={successSubmitShowModal} onClose={successSubmitShowModalClose} autoUrl={autoUrl} user={onboardObject}/>}

                </>
            )}
            {loading && <Loader />}

        </>
    )
}

export default FileNewHireReport