import React from "react";
import ReportsComponent from "../../components/reports";

export default function ReportsPage() {
  return (
    <>
      <ReportsComponent />
    </>
  );
}
