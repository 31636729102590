import React, { useCallback, useEffect, useState } from "react";
import Select from "react-select";
// import { debounce } from "lodash";
import InfoIcon from "../../assets/images/time-off-cards-icons/carryover_info.svg";
import moment from "moment";

export default function LeaveTypeComponent(props) {
  const {
    selectedDateArray,
    userData,
    employeeOption,
    setEmployeeOption,
    defaultOption,
    setDefaultOption,
    policyDefaultOption,
    setPolicyDefaultOption,
    policyData,
    userInfo,
    setPolicyOption,
    policyOption,
    availableBalance,
    textareaValue,
    setTextareaValue,
    // setError,
    remainingHours,
    error,
    // handleValidation,
    policyError,
    remainingPrevbal,
    dropDownLoader,
  } = props;
  const [textareaLength, setTextareaLength] = useState(0);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  // const debouncedHandleValidation = useCallback(
  //   debounce(handleValidation, 2000),
  //   [textareaValue]
  // );

  const handleTextareaChange = (e) => {
    const newValue = e.target.value;
    setTextareaValue(newValue);
    setTextareaLength(newValue.length);
  };
  // useEffect(() => {
  //   if (textareaValue.length <= 100) {
  //     debouncedHandleValidation();
  //   }
  //   return () => {
  //     debouncedHandleValidation.cancel();
  //   };
  // }, [textareaValue, debouncedHandleValidation]);

  useEffect(() => {
    const mapOptions = () => {
      const mappedOptions = userData?.map((item) => ({
        value: item.id,
        label: item.name,
      }));
      setEmployeeOption(mappedOptions);
    };

    mapOptions();
  }, [setEmployeeOption, userData]);

  const handleEmployeeSelectChange = (e) => {
    const defaultOption = employeeOption.find(
      (option) => option.label === e.label
    );
    setDefaultOption(defaultOption);
    setPolicyDefaultOption("");
  };

  useEffect(() => {
    const mapPolicyOptions = () => {
      const mappedPolicyOptions = policyData?.map((item) => ({
        value: item.id,
        label: item.name,
      }));
      setPolicyOption(mappedPolicyOptions);
    };
    mapPolicyOptions();
  }, [policyData, setPolicyOption]);

  const handlePolicyOptions = (e) => {
    const defaultOption = policyOption.find(
      (option) => option.label === e.label
    );
    setPolicyDefaultOption(defaultOption);
  };
  return (
    <div className="time-off-common-border-wrapper">
      <div className="leave-type-wrapper">
        {userInfo?.group_id === 5 || userInfo?.group_id === 8 ? null : (
          <>
            <div className="leave-title-wrapper">
              <h5>
                Select employee<span>*</span>
              </h5>
            </div>
            <div className="select-type-wrapper pb-3">
              <Select
                placeholder="Select name"
                isDisabled={selectedDateArray.length === 0}
                closeMenuOnSelect={true}
                hideSelectedOptions={false}
                options={employeeOption}
                onChange={(e) => handleEmployeeSelectChange(e)}
                defaultValue={defaultOption}
              />
            </div>{" "}
          </>
        )}
        <div className="leave-title-wrapper">
          <h5>
            Select leave type<span>*</span>
          </h5>
        </div>
        <div className="select-type-wrapper">
          <Select
            placeholder="Select type"
            isDisabled={selectedDateArray.length === 0 || policyError}
            closeMenuOnSelect={true}
            hideSelectedOptions={false}
            options={policyOption}
            onChange={(e) => handlePolicyOptions(e)}
            value={policyDefaultOption}
            isLoading={dropDownLoader}
          />{" "}
          {selectedDateArray.length === 0 ? null : (
            <>
              {policyDefaultOption !== "" && (
                <>
                  {
                    availableBalance?.prev_key === 0 ? (
                      <>
                        {policyError ? null : (
                          <>
                            <div className="hours-text-wrapper">
                              <p>
                                Available hours:{" "}
                                {availableBalance?.available_balance === 0
                                  ? "0"
                                  : (
                                      availableBalance?.available_balance || "-"
                                    ).toFixed(2)}
                              </p>
                              <p
                                className={` ${
                                  remainingHours < 0 ? "negative-wrapper" : ""
                                }`}
                              >
                                Remaining hours:{" "} {remainingHours || "-"}
                              </p>
                            </div>
                          </>
                        )}
                      </>
                    ) : null

                    // <div className="hours-text-wrapper">
                    //   <p>Available hours : -</p>
                    //   <p className="">Remaining hours: -</p>
                    // </div>
                  }
                  {availableBalance?.prev_key === 1 ? (
                    <div className="carryover-wrapper">
                      <div className="carryover-title-wrapper">
                        <img src={InfoIcon} alt="info-icon" />
                        <h3>
                          Leave balance carry over happens at{" "}
                          <span>
                            {moment(availableBalance?.prev_date).format(
                              "DD-MM-YYYY"
                            )}
                          </span>
                        </h3>
                      </div>
                      <div className="carryover-sub-text-wrapper">
                        <p>
                          Available hours before:{" "}
                          {availableBalance?.prev_balance === 0
                            ? 0
                            : (availableBalance?.prev_balance || "-").toFixed(
                                2
                              )}{" "}
                        </p>
                        <p
                          className={` ${
                            remainingPrevbal < 0 ? "negative-wrapper" : ""
                          }`}
                        >
                          Remaining hours before:{" "} {remainingPrevbal || "-"}
                        </p>
                      </div>
                      <div className="carryover-sub-text-wrapper">
                        <p>
                          Available hours after:{" "}
                          {remainingPrevbal <= 0
                            ? "0"
                            : availableBalance?.carryover_type === "L"
                            ? availableBalance?.carryover_max < remainingPrevbal
                              ? availableBalance?.carryover_max
                              : remainingPrevbal
                            : availableBalance?.carryover_type === "U"
                            ? remainingPrevbal
                            : remainingPrevbal}
                        </p>
                        <p
                          className={` ${
                            remainingHours < 0 ? "negative-wrapper" : ""
                          }`}
                        >
                          Remaining hours after:{" "} {remainingHours || "-"}
                        </p>
                      </div>
                    </div>
                  ) : null}
                </>
              )}
            </>
          )}
          {policyError && (
            <p className="error-text">
              You cannot request time off as your organisation's leave policies
              are not yet set. Please contact your administrator.
            </p>
          )}
          {/* )} */}
        </div>

        {!policyError &&
          (error && error.includes("The policy id field is required.") ? (
            <p className="error-text">The policy id field is required.</p>
          ) : null)}
        <div className="comment-section-wrapper">
          <h5>
            Comment <span>*</span>
          </h5>
          <textarea
            maxlength="100"
            value={textareaValue}
            onChange={handleTextareaChange}
            disabled={selectedDateArray.length === 0}
          ></textarea>
          <p className="text-area-length">{textareaLength}/100</p>
          {error && error.includes("The comments field is required.") && (
            <p className="error-text">The comments field is required.</p>
          )}
        </div>
      </div>
    </div>
  );
}
