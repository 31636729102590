import React from "react";
import RightArrow from "../../assets/images/multi-card-approval/right_arrow.svg";
import LeftArrow from "../../assets/images/multi-card-approval/left_arrow.svg";
import moment from "moment";
import Select from "react-select";
import FilterSection from "./filterSection";

export default function FiltersCombined(props) {
  const {
    handlePtoStatus,
    frequencyData,
    handleFrequencySelect,
    selectTagValue,
    handleLeftArrowClick,
    dateRangeData,
    apiPageCount,
    handleRightArrowClick,
    ptoStatus,
  } = props;
  return (
    <div className="multi-card-select-sub-wrapper">
      <div className="select-frequency-wrapper">
        <p>Frequency</p>
        <Select
          placeholder="Select Frequency"
          closeMenuOnSelect={true}
          hideSelectedOptions={false}
          options={frequencyData.map((option) => ({
            label: option.label,
            value: option.id,
            ...option,
          }))}
          onChange={(e) => handleFrequencySelect(e)}
          value={
            selectTagValue && {
              label: selectTagValue.label,
              value: selectTagValue.id,
              ...selectTagValue,
            }
          }
        />
      </div>
      <div className="select-date-range-wrapper">
        <p>Date range</p>
        <div className="date-range-subwrapper">
          <img
            src={LeftArrow}
            alt="left-arrow"
            onClick={handleLeftArrowClick}
            className="arrow-image-cus"
          />
          <div className="d-flex align-items-center">
            <p>{moment(dateRangeData?.start_date).format("MM/DD/YYYY")} </p>-
            <p>{moment(dateRangeData?.end_date).format("MM/DD/YYYY")} </p>
          </div>
          <img
            src={RightArrow}
            alt="left-arrow"
            onClick={apiPageCount === 0 ? null : handleRightArrowClick}
            className={
              apiPageCount === 0 ? "date-right-arrow-cus" : "arrow-image-cus"
            }
          />
        </div>
      </div>
      <div>
        <FilterSection
          handlePtoStatus={handlePtoStatus}
          ptoStatus={ptoStatus}
        />
      </div>
    </div>
  );
}
