import React, { useContext, useEffect } from "react";
import { Nav, Navbar, Container, NavDropdown } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { getCurrentUser, cakePhpURL, reactURL } from '../../api';
import './style.scss'
import settings from '../../assets/images/settingsheader.svg'
import faq from '../../assets/images/faq.svg'
import noUser from '../../assets/images/no-user.svg'
import logout from '../../assets/images/logout.svg'
import { UserContext } from "../../hooks/Context/UserContext";
import { FaRegBell } from "react-icons/fa";

const Header = ({ headerMenus }) => {
  let navigate = useNavigate();
  const { userProfileImage, updateUserProfileImage } = useContext(UserContext);

  useEffect(() => {
    if (headerMenus?.userInfo?.profile_pic) {
      updateUserProfileImage(headerMenus.userInfo.profile_pic);
    } else {
      updateUserProfileImage(noUser);
    }
  }, [headerMenus?.userInfo?.profile_pic]);// eslint-disable-line react-hooks/exhaustive-deps



  const logOut = async () => {
    if (getCurrentUser() === null || getCurrentUser() === undefined || getCurrentUser() === "") {
      navigate("/login");
    } else {
      window.open(cakePhpURL + "users/logout", "_self");
    }
  }



  const handleNotificationsClick = () => {
    window.open(reactURL + "profile#signforms", "_self");
  };

  return (
    <>
      <div>
        <Navbar bg="light" expand="sm" className="header-nav-main">
          <Container fluid>
            <Nav className="ms-auto">
              {headerMenus?.header?.map((item, i) => (
                <a
                  href={item?.link}
                  className={`nav-link ${item.name === 'Notifications' && "notify_css"} ${item.name === 'FAQ' && "faq_css"}`}
                  key={i}
                  target={item.name === 'FAQ' ? '_blank' : '_self'}
                  onClick={item.name === 'Notifications' && item.link === '/profile#signforms' ? handleNotificationsClick : undefined}
                >
                  <div>
                    {item.name === "Settings" && <img src={settings} alt="side-icon" />}
                    {item.name === "FAQ" && <img src={faq} alt="side-icon" />}
                    {item.name === "Notifications" && <FaRegBell className="bell" />}
                  </div>
                  {item.name !== "Notifications" && <div>{item.name}</div>}
                </a>
              ))}
              <div className="ms-3">
                <img
                  src={userProfileImage ? userProfileImage : noUser}
                  alt="user"
                  className="userprofileImg img-circle"
                />
              </div>
              <NavDropdown title={headerMenus?.userInfo?.name} id="basic-nav-dropdown" className="header-dropdown name-text">
                <NavDropdown.Item onClick={() => logOut()}>
                  <img src={logout} alt="side-icon" className="me-1" />
                  Logout
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Container>
        </Navbar>
      </div >
    </>
  );
};

export default Header;
