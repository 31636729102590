import React, { useState, useEffect, useRef } from "react";
import { Table, Row, Col, Form, Button, Modal } from "react-bootstrap";
import { MdOutlineModeEditOutline } from "react-icons/md";
import { RiDeleteBin5Line } from "react-icons/ri";
import "./style.scss";
import documentSvg from "../../../assets/images/document.svg";
import { toast } from "react-toastify";
import { postUploadData, timeZoneOffset } from "../../../api";
import Loader from "../../../components/Loader";
import { format } from "date-fns";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FileUploader } from "react-drag-drop-files";
import FormModal from "../../../components/FormModal";
import moment from "moment";
import { autocloseTiming } from "../../../api/regex";

const DocumentTab = ({
  documentTab,
  clientRole,
  userid,
  hideForUser,
  hideForManager,
  actions,
}) => {
  // state and functions to handle delete document modal
  const [deleteShow, setDeleteShow] = useState(false);
  const [uploadShow, setUploadShow] = useState(false);
  const [editShow, setEditShow] = useState(false);
  const [documentData, setDocumentData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const handleDeleteClose = () => setDeleteShow(false);
  const [deleteId, setDeleteId] = useState();
  const initialFormState = {
    id: null,
    document_name: "",
    expire: null,
    document: "",
    hidden: "",
  };
  const [dateInputs, setDateInputs] = useState({
    expire: null,
  });
  const [documentTabData, setDocumentTabData] = useState(initialFormState);
  const focusDate = useRef(null);
  const [validated, setValidated] = useState(false);
  const [readonly, setReadonly] = useState([]);
  const [errors, setErrors] = useState({});
  const [checkedUser, setCheckedUser] = useState(false);
  const [checkedManager, setCheckedManager] = useState(false);
  const [file, setFile] = useState([]);
  const fileTypes = [
    "JPEG",
    "PNG",
    "PDF",
    "DOCX",
    "SVG",
    "JPG",
    "WEBP",
    "GIF",
    "CSV",
    "XLSX",
    "ZIP",
  ];

  useEffect(() => {
    if (documentTab && Object.keys(documentTab).length !== 0) {
      setDocumentData(documentTab);
    }
  }, [documentTab]);

  // state and functions to handle upload document modal
  const handleUploadClose = () => {
    setUploadShow(false);
    setErrors({});
  };

  const handleUploadShow = () => {
    setDocumentTabData(initialFormState);
    setDateInputs({
      expire: null,
    });
    setFile("");
    setUploadShow(true);
    setErrors({});
    setCheckedUser(false);
  };
  const handleEditClose = () => {
    setEditShow(false);
    setErrors({});
  };

  const handleDeleteShow = (id) => {
    setDeleteId(id);
    setDeleteShow(true);
  };

  const findFormErrors = () => {
    const { document_name } = documentTabData;
    // const { expire } = dateInputs;

    const newErrors = {};
    if (!document_name || document_name === "")
      newErrors.document_name = "Please enter the document name";
    else if (document_name.length < 4) {
      newErrors.document_name = "The document name should be 4 characters long";
    }
    if (uploadShow) {
      if (!file || file === null || file === "")
        newErrors.file = "Please upload the file";
    }

    return newErrors;
  };

  const handleEditShow = (data) => {
    setDocumentTabData({
      id: data.id,
      document_name: data.document_name,
      hidden: data.hidden,
    });
    if (data?.expire !== null) {
      setDateInputs({
        // expire: data?.expire !== null ? moment.utc(data?.expire).add(timeZoneOffset, 'minutes').toDate() : null,
        expire: data?.expire !== null ? new Date(data?.expire) : null,
      });
    } else {
      setDateInputs({
        expire: null,
      });
    }
    if (data.hidden === 0) {
      setCheckedUser(false);
      setCheckedManager(false);
    } else if (data.hidden === 1) {
      setCheckedUser(true);
      setCheckedManager(false);
    } else if (data.hidden === 2) {
      setCheckedUser(false);
      setCheckedManager(true);
    } else {
      setCheckedUser(true);
      setCheckedManager(true);
    }
    setEditShow(true);
    setErrors({});
  };

  function toggleUser(value) {
    setCheckedUser(!value);
    return !value;
  }

  function toggleManager(value) {
    setCheckedManager(!value);
    return !value;
  }

  const selectUploadDt = (dateSel, name) => {
    const date = new Date(`${dateSel}`);
    const chkDt = date.getDate();

    if (chkDt > 0) {
      setDateInputs((prev) => ({
        ...prev,
        [name]: dateSel,
      }));
    }
  };

  const handleFocusDate = () => {
    const datepickerElement = focusDate.current;
    datepickerElement.setFocus(true);
  };

  const deleteDocument = async () => {
    setDeleteShow(false);
    setLoading(true);
    let data = new FormData();
    data.append("user_id", userid);
    data.append("id", deleteId);
    data.append("stage", "documents");
    data.append("type", "delete");
    const res = await postUploadData("profile", {}, data);
    if (res.status === true) {
      setDocumentData(res?.data.documents);
      toast.success(res.message, {
        theme: "colored",
        autoClose: autocloseTiming,
      });
      setLoading(false);
    } else {
      setLoading(false);
      toast.error(res.message, {
        theme: "colored",
        autoClose: autocloseTiming,
      });
    }
  };

  const handleUploadInput = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setDocumentTabData({
      ...documentTabData,
      [name]: value,
    });
    if (!!errors[name])
      setErrors({
        ...errors,
        [name]: null,
      });
  };

  const uploadSubmit = async (e) => {
    e.preventDefault();
    const newErrors = findFormErrors();
    setValidated(true);
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      setModalLoading(true);
      let hidden_value = "";
      if (checkedUser === true && checkedManager === true) {
        hidden_value = 3;
      } else if (checkedUser === true && checkedManager === false) {
        hidden_value = 1;
      } else if (checkedUser === false && checkedManager === true) {
        hidden_value = 2;
      } else {
        hidden_value = 0;
      }

      let data = new FormData();
      data.append("user_id", userid);
      data.append("stage", "documents");
      data.append("document_name", documentTabData.document_name);
      data.append(
        "expire",
        dateInputs?.expire !== null
          ? format(dateInputs?.expire, "MM/dd/yyyy")
          : ""
      );
      data.append("hidden", hidden_value);
      data.append("document", file);
      const res = await postUploadData("profile", {}, data);
      if (res.status === true) {
        toast.success(res.message, {
          theme: "colored",
          autoClose: autocloseTiming,
        });
        setModalLoading(false);
        setUploadShow(false);
        setDocumentData(res?.data.documents);
        setValidated(false);
      } else {
        setModalLoading(false);
        toast.error(res.message, {
          theme: "colored",
          autoClose: autocloseTiming,
        });
      }
    }
  };

  const editSubmit = async (e) => {
    e.preventDefault();
    const newErrors = findFormErrors();
    setValidated(true);
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      setModalLoading(true);
      let hidden_value = "";
      if (checkedUser === true && checkedManager === true) {
        hidden_value = 3;
      } else if (checkedUser === true && checkedManager === false) {
        hidden_value = 1;
      } else if (checkedUser === false && checkedManager === true) {
        hidden_value = 2;
      } else {
        hidden_value = 0;
      }
      let data = new FormData();
      data.append("user_id", userid);
      data.append("id", documentTabData.id);
      data.append("stage", "documents");
      data.append("type", "update");
      data.append("document_name", documentTabData.document_name);
      data.append(
        "expire",
        dateInputs?.expire !== null
          ? format(dateInputs?.expire, "MM/dd/yyyy")
          : ""
      );
      data.append("hidden", hidden_value);
      const res = await postUploadData("profile", {}, data);
      if (res.status === true) {
        toast.success(res.message, {
          theme: "colored",
          autoClose: autocloseTiming,
        });
        setModalLoading(false);
        setEditShow(false);
        setDocumentData(res?.data.documents);
      } else {
        setModalLoading(false);
        toast.error(res.message, {
          theme: "colored",
          autoClose: autocloseTiming,
        });
      }
    }
  };

  const handleDocumentChange = (item) => {
    setFile(item);
  };
  const handleSizeError = (fileErr) => {
    toast.error(fileErr, { theme: "colored", autoClose: autocloseTiming });
  };

  const handleFileTypeError = (fileErr) => {
    toast.error(fileErr, { theme: "colored", autoClose: autocloseTiming });
  };
  const options = { year: "numeric", month: "numeric", day: "numeric" };

  return (
    <div className="document_container">
      <div>
        <Row className="mb-3">
          <Col>
            <h5 className="tabs_main_heading">
              <span className="me-1">
                <img src={documentSvg} alt="" srcSet="" />
              </span>
              Document
            </h5>
          </Col>
        </Row>
        {/* Document table starts */}
        <Row>
          <Col xs={12} className="table_mobile_scroll ">
            <Table responsive className="mt-4 header_bg_table ">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Document</th>
                  <th>Type</th>
                  <th>Expire</th>
                  {clientRole !== "Employee" && clientRole !== "Contractor" && (
                    <th>Uploader</th>
                  )}
                  {clientRole !== "Employee" && clientRole !== "Contractor" && (
                    <th>User</th>
                  )}
                  {clientRole !== "Employee" && clientRole !== "Contractor" && (
                    <th>Hidden</th>
                  )}
                  {actions === true && <th></th>}
                </tr>
              </thead>
              {documentData?.length !== 0 ? (
                <tbody>
                  {documentData?.map((document, i) => {
                    if (!document?.document_name) {
                      return null;
                    }
                    return (
                      <tr key={i}>
                        <td>
                          {new Date(document?.date).toISOString().split("T")[0]}
                        </td>
                        <td>
                          <a
                            href={document?.document_url}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="document-td"
                          >
                            {document?.document_name}
                          </a>
                        </td>
                        <td>{document?.document_type}</td>
                        <td>
                          {document.expire !== null
                            ? new Date(document?.expire).toLocaleDateString(
                                "en-US",
                                options
                              )
                            : "-"}
                        </td>
                        {clientRole !== "Employee" &&
                          clientRole !== "Contractor" && (
                            <td>{document?.uploader_name}</td>
                          )}
                        {clientRole !== "Employee" &&
                          clientRole !== "Contractor" && (
                            <td>{document?.user_name}</td>
                          )}
                        {clientRole !== "Employee" &&
                          clientRole !== "Contractor" && (
                            <td>{document?.hidden ? "Hidden" : "Shown"}</td>
                          )}
                        {actions === true && (
                          <td>
                            <div className="edit_delete_container d-flex">
                              <div
                                className="d-flex flex-column align-items-center me-3"
                                onClick={() => {
                                  handleEditShow(document);
                                }}
                              >
                                <MdOutlineModeEditOutline />
                                <p>Edit</p>
                              </div>

                              <div
                                className=" d-flex flex-column align-items-center"
                                onClick={() => {
                                  handleDeleteShow(document?.id);
                                }}
                              >
                                <RiDeleteBin5Line />
                                <p>Delete</p>
                              </div>
                            </div>
                          </td>
                        )}
                      </tr>
                    );
                  })}
                </tbody>
              ) : (
                <tbody>
                  <tr>
                    <td colSpan="7">
                      <h4 className="no-data mb-0">No data found</h4>
                    </td>
                  </tr>
                </tbody>
              )}
            </Table>
            {loading && <Loader />}
          </Col>
        </Row>
        {/* Document table ends */}
        <Row className="d-flex justify-content-end pb-4">
          <Col sm={12} md={4} className="d-flex justify-content-end">
            <Button
              variant="primary"
              className="save_btn"
              onClick={handleUploadShow}
            >
              Upload Document
            </Button>
          </Col>
        </Row>

        {/* Upload Document modal starts */}
        <Row>
          <Col className="d-flex justify-content-end">
            <Modal
              show={uploadShow}
              onHide={handleUploadClose}
              className="upload_document_modal"
            >
              <Form noValidate validated={validated} onSubmit={uploadSubmit}>
                <Modal.Body>
                  <Row>
                    <Col xs={12}>
                      <span className="edit-modal-header">Upload document</span>
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <Col lg={6}>
                      <Form.Group className="mb-3" controlId="document_name">
                        <Form.Label>
                          Document title <span className="asterisk">*</span>{" "}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          value={documentTabData?.document_name}
                          name="document_name"
                          onChange={handleUploadInput}
                          placeholder="Enter document title"
                          required
                          isInvalid={!!errors.document_name}
                          className={`${
                            errors.document_name
                              ? "invalid_input"
                              : "normal_input"
                          }`}
                        />

                        <Form.Control.Feedback type="invalid">
                          {errors.document_name}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col lg={6}>
                      <Form.Group
                        className="mb-3 position-relative"
                        controlId="expire"
                      >
                        <Form.Label>Expiration date </Form.Label>
                        <DatePicker
                          ref={focusDate}
                          // className=""
                          selected={
                            dateInputs?.expire ? dateInputs?.expire : null
                          }
                          onChange={(date) => selectUploadDt(date, "expire")}
                          name="expire"
                          dateFormat="MM-dd-yyyy"
                          dropdownMode="select"
                          showMonthDropdown
                          showYearDropdown
                          calendarIcon={true}
                          closeCalendar={true}
                          clearIcon={true}
                          disabled={readonly?.includes("expire")}
                          required
                          className={`datepicker-payscheme if_invalid form-control normal_input`}
                        />
                        <span
                          className="calendar-icon"
                          onClick={handleFocusDate}
                        ></span>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} className="dropfile_realtive">
                      <FileUploader
                        multiple={false}
                        handleChange={handleDocumentChange}
                        onSizeError={handleSizeError}
                        onTypeError={handleFileTypeError}
                        name="file"
                        label={
                          file.length !== 0
                            ? "Uploaded Successfully!."
                            : " Drop your files here or browse."
                        }
                        types={fileTypes}
                        classes="employee-document-upload"
                        maxSize="10"
                        required
                      />
                      {validated && (file === null || file === "") && (
                        <div className="err-feedback"> {errors?.file}</div>
                      )}
                    </Col>
                    <Col className="mt-3">
                      <Form>
                        {hideForUser === true && (
                          <Form.Check
                            inline
                            label="Hide from user"
                            checked={checkedUser}
                            onChange={() => setCheckedUser(toggleUser)}
                            name="hide_user"
                            type="checkbox"
                            id="hide_user"
                          />
                        )}
                        {hideForManager === true && (
                          <Form.Check
                            inline
                            label="Hide from manager"
                            name="hide_manager"
                            checked={checkedManager}
                            onChange={() => setCheckedManager(toggleManager)}
                            type="checkbox"
                            id="hide_manager"
                          />
                        )}
                      </Form>
                    </Col>
                  </Row>
                </Modal.Body>
                {!modalLoading && (
                  <Modal.Footer className="border-0">
                    <Button
                      variant="secondary"
                      onClick={handleUploadClose}
                      className="btn_border_clr btn-default cancel-btn"
                    >
                      Cancel
                    </Button>
                    <Button
                      className="btn_clr_white"
                      type="submit"
                      variant="primary"
                    >
                      Save
                    </Button>
                  </Modal.Footer>
                )}
                {modalLoading && <Loader />}
              </Form>
            </Modal>
          </Col>
        </Row>
        {/* Upload Document modal ends */}

        {/* Delete Document modal starts */}
        {deleteShow && (
          <FormModal
            heading="Are you sure you want to delete ?"
            show={deleteShow}
            size="sm"
            onClose={handleDeleteClose}
            backdrop="static"
            modalClassName="confirmation_modal"
          >
            <div className="text-center">
              <Button
                variant="secondary"
                onClick={handleDeleteClose}
                className="btn_border_clr btn-default cancel-btn me-2"
              >
                Cancel
              </Button>
              <Button
                className="btn_clr_white"
                variant="primary"
                onClick={deleteDocument}
              >
                Delete
              </Button>
            </div>
          </FormModal>
        )}

        {/* Delete Document modal ends */}
        {/* Edit Document modal starts */}
        <Row>
          <Col className="d-flex justify-content-end">
            <Modal
              show={editShow}
              onHide={handleEditClose}
              className="upload_document_modal"
            >
              <Form>
                <Modal.Body>
                  <Row>
                    <Col xs={12}>
                      <span className="edit-modal-header">Edit document</span>
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <Col lg={6}>
                      <Form.Group className="mb-3" controlId="document_name">
                        <Form.Label>
                          Document title <span className="asterisk">*</span>{" "}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          value={documentTabData?.document_name}
                          onChange={handleUploadInput}
                          name="document_name"
                          required
                          isInvalid={!!errors.document_name}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.document_name}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col lg={6}>
                      <Form.Group
                        className="mb-3 position-relative"
                        controlId="expire"
                      >
                        <Form.Label>Expiration date</Form.Label>
                        <DatePicker
                          ref={focusDate}
                          className="datepicker-payscheme"
                          selected={
                            dateInputs?.expire ? dateInputs?.expire : null
                          }
                          onChange={(date) => selectUploadDt(date, "expire")}
                          name="expire"
                          dateFormat="MM-dd-yyyy"
                          dropdownMode="select"
                          showMonthDropdown
                          showYearDropdown
                          calendarIcon={true}
                          closeCalendar={true}
                          clearIcon={true}
                          disabled={readonly?.includes("expire")}
                          required
                        />
                        <span
                          className="calendar-icon"
                          onClick={handleFocusDate}
                        ></span>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="mt-3">
                      <Form>
                        {hideForUser === true && (
                          <Form.Check
                            inline
                            label="Hide from user"
                            checked={checkedUser}
                            onChange={() => setCheckedUser(toggleUser)}
                            name="hide_user"
                            type="checkbox"
                            id="hide_user"
                          />
                        )}
                        {hideForManager === true && (
                          <Form.Check
                            inline
                            label="Hide from manager"
                            checked={checkedManager}
                            onChange={() => setCheckedManager(toggleManager)}
                            name="hide_manager"
                            type="checkbox"
                            id="hide_manager"
                          />
                        )}
                      </Form>
                    </Col>
                  </Row>
                </Modal.Body>
                {!modalLoading && (
                  <Modal.Footer className="border-0">
                    <Button
                      variant="secondary"
                      onClick={handleEditClose}
                      className="btn_border_clr btn-default cancel-btn"
                    >
                      Cancel
                    </Button>
                    <Button
                      className="btn_clr_white"
                      variant="primary"
                      onClick={editSubmit}
                    >
                      Save
                    </Button>
                  </Modal.Footer>
                )}
                {modalLoading && <Loader />}
              </Form>
            </Modal>
          </Col>
        </Row>
        {/* Edit Document modal ends */}
      </div>
    </div>
  );
};

export default DocumentTab;
