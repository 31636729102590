import React, { useState, useRef, useEffect } from "react";
import { Col, Row, Form, Button, Table, InputGroup } from "react-bootstrap";
import "./style.scss";
import {
  postUploadData,
  socialSecurityNumberFormat,
  convertUTCToLocalDate,
} from "../../../api";
import { toast } from "react-toastify";
import workIcon from "../../../assets/images/work.svg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import {
  mustNumbers,
  onlyCharacterWithSpace,
  emptySpace,
  maskNumber,
  FormatHypen,
  fotmatNumber,
  FloattingNo,
  ssnRegex,
  characterWithCommaDotHyphenAndApos,
  autocloseTiming,
  salaryValidation,
} from "../../../api/regex";
import { FiEye, FiEyeOff } from "react-icons/fi";
import Loader from "../../../components/Loader";
import moment from "moment";

const JobTabTwo = ({
  jobData,
  clientRole,
  managers,
  compensationList,
  flsaClassificationList,
  salaryHistory,
  userid,
  jobCodeList,
  jobLocationList,
  gustoUser,
  groupId,
}) => {
  const focusHireDate = useRef(null);
  const focusTerminationDate = useRef(null);
  const [validated, setValidated] = useState(false);
  const [jobTabData, setJobTabData] = useState({
    assigned_to: "",
    compensation: "",
    ein: "",
    emp_type: "",
    employee_number: "",
    flsa_classification: "",
    hire_date: "",
    history: "",
    jobcode: [],
    joblocation: [],
    pay_frequency_id: "",
    salary: "",
    status: 999,
    str_soc_sec_num: "",
    taxpayer_identification_number: "",
    termination_date: "",
    title: "",
  });
  const [showAll, setShowAll] = useState(false);
  const [visibleRowCount, setVisibleRowCount] = useState(3);
  const [errors, setErrors] = useState({});
  const [readonly, setReadonly] = useState([]);
  const [viewonly, setViewonly] = useState([]);
  const [dateInputs, setDateInputs] = useState({
    birthday: null,
    hire_date: null,
    termination_date: null,
  });
  const [jobCodeValue, setJobCodeValue] = useState();
  const [jobLocationValue, setJobLocationValue] = useState();
  const [maskedSocialNo, setMaskedSocialNo] = useState("");
  const [maskShown, setMaskShown] = useState(true);
  const [loading, setLoading] = useState(false);
  const [showSocNoEye, setShowSocNoEye] = useState(false);
  const [empShowSocNo, setEmpShowSocNo] = useState(false);
  const [disableEmpType, setDisableEmpType] = useState(false);
  const [empTypeList, setEmpTypeList] = useState();

  const showMoreRows = () => {
    setVisibleRowCount(visibleRowCount + 10);
  };

  const handleInput = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setJobTabData({
      ...jobTabData,
      [name]: value,
    });
    if (!!errors[name])
      setErrors({
        ...errors,
        [name]: null,
      });
  };

  useEffect(() => {
    if (jobData && Object.keys(jobData).length !== 0) {
      setJobTabData(jobData);
      setReadonly(jobData.readonly);
      setViewonly(jobData.viewonly);
      setEmpTypeList(jobData.employmentType);
      if (
        jobData?.birthday !== null ||
        jobData?.hire_date !== null ||
        jobData?.hire_date !== null
      ) {
        setDateInputs({
          birthday:
            jobData?.birthday !== null
              ? convertUTCToLocalDate(jobData?.birthday)
              : null,
          hire_date:
            jobData?.hire_date !== null
              ? convertUTCToLocalDate(jobData?.hire_date)
              : null,
          termination_date:
            jobData?.termination_date !== null
              ? convertUTCToLocalDate(jobData?.termination_date)
              : null,
        });
      } else {
        setDateInputs({
          birthday: null,
          hire_date: null,
          termination_date: null,
        });
      }

      // if (!jobData?.emp_type || jobData?.emp_type === "" || jobData?.emp_type === null) {
      //   setDisableEmpType(false);
      // } else if (jobData?.readonly?.includes("emp_type")) {
      //   setDisableEmpType(true);
      // }
      // else {
      //   setDisableEmpType(true)
      // }

      let jobCodeArr = [];
      jobData?.jobcode.forEach((item, i) => {
        let jobCodeData = {
          value: item.id,
          label: item.title,
          id: item.id,
          title: item.title,
        };
        jobCodeArr.push(jobCodeData);
      });
      setJobCodeValue(jobCodeArr);

      let jobLocationArr = [];
      jobData?.joblocation.forEach((item, i) => {
        let jobLocationData = {
          value: item.id,
          label: item.title,
          id: item.id,
          title: item.title,
        };
        jobLocationArr.push(jobLocationData);
      });
      setJobLocationValue(jobLocationArr);
    }
  }, [jobData]);

  useEffect(() => {
    if (clientRole === "ClientAdmin" || clientRole === "ClientManager") {
      if (jobData?.ssn_masked) {
        setShowSocNoEye(true);
      } else {
        setShowSocNoEye(false);
      }
    } else if (clientRole === "Employee" || clientRole === "Contractor") {
      setEmpShowSocNo(true);
    }
  }, [clientRole]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (jobData?.ssn_masked) {
      let socialSecNo = jobData?.str_soc_sec_num?.replace(FormatHypen, "");
      let maskNo = socialSecNo?.replace(maskNumber, "X");
      setMaskedSocialNo(maskNo);
    }
  }, [jobData?.ssn_masked]); // eslint-disable-line react-hooks/exhaustive-deps

  const findFormErrors = () => {
    const {
      employee_number,
      str_soc_sec_num,
      title,
      emp_type,
      hire_date,
      joblocation,
      flsa_classification,
      compensation,
      pay_frequency_id,
      salary,
    } = jobTabData;
    const newErrors = {};
    if (!readonly?.includes("employee_number")) {
      if (employee_number !== "" && employee_number !== null) {
        if (!employee_number.toString()?.match(mustNumbers)) {
          newErrors.employee_number = "The employee number should be numbers";
        }
      }
    }
    if (
      !readonly?.includes("str_soc_sec_num") &&
      jobTabData.taxpayer_identification_number === "ssn"
    ) {
      let newSocialNumber = str_soc_sec_num?.replaceAll("-", "");
      if (!newSocialNumber || newSocialNumber === "") {
        newErrors.str_soc_sec_num = "Please enter your social security number.";
      } else if (!newSocialNumber.toString()?.match(mustNumbers)) {
        newErrors.str_soc_sec_num =
          "The social security number should be numeric.";
      } else if (newSocialNumber.length !== 9) {
        newErrors.str_soc_sec_num =
          "The social security number should be 9 characters";
      } else if (!str_soc_sec_num.toString()?.match(ssnRegex)) {
        newErrors.str_soc_sec_num =
          "Please enter a valid  social security number";
      }
    }

    if (!readonly?.includes("joblocation") && gustoUser) {
      if (
        !joblocation ||
        joblocation === "" ||
        joblocation?.length === 0 ||
        jobLocationValue?.length === 0
      )
        newErrors.joblocation = "Please enter your  job location.";
    }

    if (!readonly?.includes("title")) {
      if (title !== "" && title !== null) {
        if (!title?.match(characterWithCommaDotHyphenAndApos)) {
          newErrors.title =
            "Only letters and special characters ( - ), ( , ) , ( ' ) ,( . ) are allowed";
        } else if (!title?.match(emptySpace)) {
          newErrors.title = "The title should be alphabetic";
        }
      }
    }

    if (!readonly?.includes("emp_type")) {
      if (!emp_type || emp_type === "" || emp_type === null)
        newErrors.emp_type = "Please enter your employee title.";
    }

    if (!readonly?.includes("hire_date")) {
      if (!hire_date || hire_date === "" || hire_date === null)
        newErrors.hire_date = "Please enter your hire date.";
    }

    if (!readonly?.includes("flsa_classification") && groupId !== 8) {
      if (
        !flsa_classification ||
        flsa_classification === "" ||
        flsa_classification === null
      )
        newErrors.flsa_classification =
          "Please enter your flsa classification.";
    }

    if (!readonly?.includes("compensation")) {
      if (!compensation || compensation === "" || compensation === null)
        newErrors.compensation = "Please enter your compensation.";
    }

    if (
      !readonly?.includes("pay_frequency_id") &&
      viewonly?.includes("pay_frequency_id")
    ) {
      if (
        !pay_frequency_id ||
        pay_frequency_id === "" ||
        pay_frequency_id === null
      )
        newErrors.pay_frequency_id = "Please enter your pay frequency.";
    }

    if (!readonly?.includes("salary")) {
      if (!salary || salary === "" || salary === null) {
        newErrors.salary = "Please enter your salary.";
      } else if (!salary?.toString()?.match(salaryValidation)) {
        newErrors.salary = "The salary should be numbers";
      } else if (salary > 1000000) {
        newErrors.salary = "Salary cannot exceed $10 lakhs";
      }
    }

    return newErrors;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const newErrors = findFormErrors();
    setValidated(true);
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      jobSubmit();
    }
  };

  const jobSubmit = async () => {
    setLoading(true);
    let data = new FormData();
    data.append("user_id", userid);
    data.append("stage", "job");
    data.append("assigned_to", jobTabData.assigned_to);
    data.append("compensation", jobTabData.compensation);
    data.append("ein", jobTabData.ein);
    data.append("emp_type", jobTabData.emp_type);
    data.append("employee_number", jobTabData.employee_number);
    data.append("flsa_classification", jobTabData.flsa_classification);
    data.append("hire_date", jobTabData.hire_date);
    data.append("pay_frequency_id", jobTabData.pay_frequency_id);
    data.append("salary", jobTabData.salary);
    data.append("status", jobTabData.status);
    data.append("str_soc_sec_num", jobTabData.str_soc_sec_num);
    data.append(
      "taxpayer_identification_number",
      jobTabData.taxpayer_identification_number
    );
    data.append("termination_date", jobTabData.termination_date);
    data.append("title", jobTabData.title);

    jobCodeValue.forEach((item, i) => {
      data.append("job_code[" + i + "]", item?.id);
    });

    jobLocationValue.forEach((item, i) => {
      data.append("job_location[" + i + "]", item?.id);
    });

    const res = await postUploadData("profile", {}, data);
    if (res.status === true) {
      toast.success(res.message, {
        theme: "colored",
        autoClose: autocloseTiming,
      });
      setLoading(false);
      let data = res?.data;
      setEmpTypeList(data?.employmentType);
      setErrors({});
      window.location.reload();
    } else {
      toast.error(res.message, {
        theme: "colored",
        autoClose: autocloseTiming,
      });
      setLoading(false);
    }
  };

  const minTwoDigit = (n) => {
    return (n < 10 ? "0" : "") + n;
  };

  const formatDates = (date) => {
    const day = minTwoDigit(date.getDate());
    const month = minTwoDigit(date.getMonth() + 1);
    const year = date.getFullYear();
    let val = `${year}-${month}-${day}`;
    return val;
  };

  const selectUploadDt = (dateSel, name) => {
    const date = new Date(`${dateSel}`);
    const chkDt = date.getDate();

    if (chkDt > 0) {
      let val = formatDates(date);
      setJobTabData((prev) => ({
        ...prev,
        [name]: val,
      }));
      setDateInputs((prev) => ({
        ...prev,
        [name]: dateSel,
      }));
      if (!!errors[name])
        setErrors({
          ...errors,
          [name]: null,
        });
    }
  };

  const handleFocusHireDate = () => {
    const datepickerElement = focusHireDate.current;
    datepickerElement.setFocus(true);
  };

  const handleFocusTerminationDate = () => {
    const datepickerElement = focusTerminationDate.current;
    datepickerElement.setFocus(true);
  };

  const formatSelectOptions = (data) => {
    let finalArr = [];
    if (data && data.length > 0) {
      data.forEach((item) => {
        finalArr.push({
          value: item.id,
          label: item.title,
          id: item.id,
          title: item.title,
        });
      });
    }
    return finalArr;
  };

  const handleJobCodeOnChange = (data) => {
    setJobCodeValue(data);
    setJobTabData((prev) => ({
      ...prev,
      jobcode: data,
    }));
    setErrors({
      ...errors,
      jobcode: null,
    });
  };

  const handleJobLocationOnChange = (data) => {
    setJobLocationValue(data);
    setJobTabData((prev) => ({
      ...prev,
      joblocation: data,
    }));
    setErrors({
      ...errors,
      jobcode: null,
    });
  };
  const handleStatus = (e) => {
    setJobTabData((prev) => ({
      ...prev,
      status: Number(e.target.value),
    }));
  };

  const handleTaxChange = (e) => {
    setJobTabData((prev) => ({
      ...prev,
      taxpayer_identification_number: e.target.value,
    }));
  };

  const toggleMaskShownVisiblity = () => {
    setMaskShown(maskShown ? false : true);
  };
  const handleFormatChange = (e) => {
    const name = e.target.name;
    e.target.value = socialSecurityNumberFormat(e.target.value);
    setJobTabData((prev) => ({
      ...prev,
      [name]: e.target.value,
    }));
  };

  const handleEinFormatChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    if (name === "ein") {
      let ein_val = value?.replace(fotmatNumber, "");
      let employeeId = ein_val.slice(0, 2) + "-" + ein_val.slice(2, 9);
      if (value.length === 9) {
        setJobTabData((prev) => ({
          ...prev,
          [name]: employeeId,
        }));
      }
    }
  };

  const handleKeyPress = (event) => {
    const keyCode = event.which || event.keyCode;
    const keyValue = String.fromCharCode(keyCode);
    const isValidKey = /^\d$/.test(keyValue);
    const inputValue = event.target.value?.replace(/-/g, ""); // Remove hyphens from input value
    const isInputValidLength = inputValue.length < 9;

    if (!isValidKey || !isInputValidLength) {
      event.preventDefault();
    }
  };

  return (
    <>
      {!loading && (
        <div className="jobs_container">
          <div>
            <h5 className="tabs_main_heading">
              <span className="me-1">
                <img src={workIcon} alt="" srcSet="" />
              </span>
              Job
            </h5>

            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <Row>
                <Col
                  md={4}
                  className={
                    viewonly?.includes("status") ? "d-block" : "d-hide"
                  }
                >
                  <Form.Group className="mb-3" controlId="status">
                    <Form.Label className="me-2">status</Form.Label>
                    <div>
                      <Form.Check
                        inline
                        label="Active"
                        name="status"
                        type="radio"
                        checked={jobTabData.status === 1 ? true : false}
                        value={1}
                        onChange={(e) => handleStatus(e)}
                        id="active"
                      />
                      <Form.Check
                        inline
                        label="Inactive"
                        name="status"
                        type="radio"
                        checked={jobTabData.status === 0 ? true : false}
                        value={0}
                        onChange={(e) => handleStatus(e)}
                        id="inactive"
                      />
                    </div>
                  </Form.Group>
                </Col>
                <Col
                  lg={4}
                  className={
                    viewonly?.includes("employee_number") ? "d-block" : "d-hide"
                  }
                >
                  <Form.Group className="mb-3" controlId="employee_number">
                    <Form.Label>Employee Number</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Employee Number"
                      name="employee_number"
                      onChange={handleInput}
                      value={jobTabData.employee_number}
                      isInvalid={!!errors.employee_number}
                      disabled={readonly?.includes("employee_number")}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.employee_number}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col
                  md={4}
                  className={
                    viewonly?.includes("taxpayer_identification_number")
                      ? "d-block"
                      : "d-hide"
                  }
                >
                  <Form.Group
                    className="tax-payer-check mb-3"
                    controlId="taxpayer_identification_number"
                  >
                    <Form.Label className="me-2">
                      Taxpayer Identification Number
                    </Form.Label>
                    <div>
                      <Form.Check
                        inline
                        label="SSN"
                        name="taxpayer_identification_number"
                        type="radio"
                        checked={
                          jobTabData.taxpayer_identification_number === "ssn"
                            ? true
                            : false
                        }
                        value="ssn"
                        onChange={(e) => handleTaxChange(e)}
                        id="ssn"
                        disabled={clientRole === "Contractor"}
                      />
                      <Form.Check
                        inline
                        label="EIN"
                        name="taxpayer_identification_number"
                        type="radio"
                        checked={
                          jobTabData.taxpayer_identification_number === "ein"
                            ? true
                            : false
                        }
                        value="ein"
                        onChange={(e) => handleTaxChange(e)}
                        id="ein"
                        disabled={clientRole === "Contractor"}
                      />
                    </div>
                    <Form.Control.Feedback type="invalid">
                      {errors.taxpayer_identification_number}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                {/* To place the ssn and ein field by clients position */}
                {viewonly?.includes("taxpayer_identification_number") && (
                  <Col lg={8}></Col>
                )}
                {jobTabData.taxpayer_identification_number === "ssn" && (
                  <Col
                    lg={4}
                    className={
                      viewonly?.includes("str_soc_sec_num")
                        ? "d-block"
                        : "d-hide"
                    }
                  >
                    {showSocNoEye ? (
                      <Form.Group
                        className="mb-3 first"
                        controlId="str_soc_sec_num"
                      >
                        <Form.Label>
                          Social Security Number{" "}
                          <span className="asterisk">*</span>{" "}
                        </Form.Label>
                        <InputGroup className="toggle-password">
                          <Form.Control
                            placeholder="Social Security Number"
                            name="str_soc_sec_num"
                            type={"text"}
                            onChange={handleInput}
                            value={
                              maskShown
                                ? maskedSocialNo
                                : jobTabData.str_soc_sec_num
                            }
                            isInvalid={!!errors.str_soc_sec_num}
                            required
                            disabled={readonly?.includes("str_soc_sec_num")}
                            onBlur={handleFormatChange}
                            onKeyPress={handleKeyPress}
                          />
                          <InputGroup.Text onClick={toggleMaskShownVisiblity}>
                            {maskShown ? <FiEyeOff /> : <FiEye />}
                          </InputGroup.Text>
                        </InputGroup>
                        {validated && (
                          <div className="err-feedback">
                            {errors?.str_soc_sec_num}
                          </div>
                        )}
                      </Form.Group>
                    ) : (
                      <Form.Group
                        className={`mb-3 second ${
                          clientRole === "Employee" ||
                          clientRole === "Contractor"
                            ? "d-none"
                            : "d-block"
                        }`}
                        controlId="str_soc_sec_num"
                      >
                        <Form.Label>
                          {" "}
                          Social Security Number{" "}
                          <span className="asterisk">*</span>{" "}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Soc Sec Number"
                          onChange={handleInput}
                          name="str_soc_sec_num"
                          value={jobTabData.str_soc_sec_num}
                          isInvalid={!!errors.str_soc_sec_num}
                          disabled={readonly?.includes("str_soc_sec_num")}
                          onBlur={handleFormatChange}
                          onKeyPress={handleKeyPress}
                          required
                        />

                        {validated && (
                          <div className="err-feedback">
                            {errors?.str_soc_sec_num}
                          </div>
                        )}
                      </Form.Group>
                    )}
                    {empShowSocNo ? (
                      <Form.Group
                        className="mb-3 emp"
                        controlId="str_soc_sec_num"
                      >
                        <Form.Label>
                          Social Security Number{" "}
                          <span className="asterisk">*</span>{" "}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Soc Sec Number"
                          onChange={handleInput}
                          name="str_soc_sec_num"
                          value={jobTabData.str_soc_sec_num}
                          isInvalid={!!errors.str_soc_sec_num}
                          disabled={readonly?.includes("str_soc_sec_num")}
                          onBlur={handleFormatChange}
                          onKeyPress={handleKeyPress}
                          required
                        />

                        {validated && (
                          <div className="err-feedback">
                            {errors?.str_soc_sec_num}
                          </div>
                        )}
                      </Form.Group>
                    ) : (
                      ""
                    )}
                  </Col>
                )}
                {jobTabData.taxpayer_identification_number === "ein" && (
                  <Col
                    lg={4}
                    className={viewonly?.includes("ein") ? "d-block" : "d-hide"}
                  >
                    <Form.Group className="mb-3" controlId="ein">
                      <Form.Label>
                        {" "}
                        Employee Identification Number{" "}
                        <span className="asterisk">*</span>{" "}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="EIN Number"
                        onChange={handleInput}
                        name="ein"
                        value={jobTabData.ein}
                        isInvalid={!!errors.ein}
                        disabled={readonly?.includes("ein")}
                        onBlur={handleEinFormatChange}
                        onKeyPress={handleKeyPress}
                        required
                      />
                    </Form.Group>
                    <Form.Control.Feedback type="invalid">
                      {errors.ein}
                    </Form.Control.Feedback>
                  </Col>
                )}
              </Row>
              <Row>
                <Col
                  lg={4}
                  className={viewonly?.includes("title") ? "d-block" : "d-hide"}
                >
                  <Form.Group className="mb-3" controlId="title">
                    <Form.Label>Title</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter title"
                      onChange={handleInput}
                      name="title"
                      value={jobTabData.title}
                      isInvalid={!!errors.title}
                      disabled={readonly?.includes("title")}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.title}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col
                  lg={4}
                  className={
                    viewonly?.includes("emp_type") ? "d-block" : "d-hide"
                  }
                >
                  <Form.Group className="mb-3" controlId="emp_type">
                    <Form.Label>
                      Employment type <span className="asterisk">*</span>{" "}
                    </Form.Label>
                    <Form.Select
                      onChange={handleInput}
                      name="emp_type"
                      value={jobTabData?.emp_type}
                      required
                      size="lg"
                      isInvalid={!!errors.emp_type}
                      // disabled={disableEmpType}
                      disabled={readonly?.includes("emp_type")}
                    >
                      <option value="">Select type</option>
                      {empTypeList &&
                        empTypeList.map((item, index) => (
                          <option key={item.id} value={item.value}>
                            {item.name}
                          </option>
                        ))}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      {errors.emp_type}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col
                  lg={4}
                  className={
                    viewonly?.includes("hire_date") ? "d-block" : "d-hide"
                  }
                >
                  <Form.Group
                    className="mb-3 position-relative"
                    controlId="hire_date"
                  >
                    <Form.Label>
                      Hire Date <span className="asterisk">*</span>{" "}
                    </Form.Label>
                    <DatePicker
                      ref={focusHireDate}
                      className="datepicker-payscheme"
                      selected={
                        dateInputs?.hire_date ? dateInputs?.hire_date : null
                      }
                      onChange={(date) => selectUploadDt(date, "hire_date")}
                      name="hire_date"
                      dateFormat="MM-dd-yyyy"
                      dropdownMode="select"
                      showMonthDropdown
                      showYearDropdown
                      calendarIcon={true}
                      closeCalendar={true}
                      clearIcon={true}
                      disabled={readonly?.includes("hire_date")}
                    />
                    <span
                      className="calendar-icon"
                      onClick={handleFocusHireDate}
                    ></span>
                    {validated && jobTabData?.hire_date === null && (
                      <div className="err-feedback">{errors?.hire_date}</div>
                    )}
                  </Form.Group>
                </Col>
                <Col
                  lg={4}
                  className={
                    viewonly?.includes("termination_date")
                      ? "d-block"
                      : "d-hide"
                  }
                >
                  <Form.Group
                    className="mb-3 position-relative"
                    controlId="termination_date"
                  >
                    <Form.Label>Termination Date</Form.Label>
                    <DatePicker
                      ref={focusTerminationDate}
                      className="datepicker-payscheme"
                      selected={
                        dateInputs?.termination_date
                          ? dateInputs?.termination_date
                          : null
                      }
                      onChange={(date) =>
                        selectUploadDt(date, "termination_date")
                      }
                      name="termination_date"
                      dateFormat="MM-dd-yyyy"
                      dropdownMode="select"
                      showMonthDropdown
                      showYearDropdown
                      calendarIcon={true}
                      closeCalendar={true}
                      clearIcon={true}
                      disabled={readonly?.includes("termination_date")}
                    />
                    <span
                      className="calendar-icon"
                      onClick={handleFocusTerminationDate}
                    ></span>
                  </Form.Group>
                </Col>
                <Col
                  lg={4}
                  className={
                    viewonly?.includes("assigned_to") ? "d-block" : "d-hide"
                  }
                >
                  <Form.Group className="mb-3" controlId="assigned_to">
                    <Form.Label>Manager</Form.Label>
                    <Form.Select
                      onChange={handleInput}
                      name="assigned_to"
                      value={jobTabData.assigned_to}
                      size="lg"
                      isInvalid={!!errors.assigned_to}
                      disabled={readonly?.includes("assigned_to")}
                    >
                      <option value="">Select manager</option>
                      {managers &&
                        managers.map((item, index) => (
                          <option key={item.id} value={item.id}>
                            {item.full_name}
                          </option>
                        ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col
                  lg={4}
                  className={
                    viewonly?.includes("joblocation") ? "d-block" : "d-hide"
                  }
                >
                  <Form.Group className="mb-3" controlId="joblocation">
                    <Form.Label>
                      Job Location{" "}
                      {gustoUser && <span className="asterisk">*</span>}{" "}
                    </Form.Label>
                    <Select
                      closeMenuOnSelect={true}
                      hideSelectedOptions={false}
                      options={formatSelectOptions(jobLocationList)}
                      onChange={(selectedOption) =>
                        handleJobLocationOnChange(selectedOption)
                      }
                      value={jobLocationValue}
                      placeholder="Select job location"
                      className="custom-select"
                      isMulti
                    />
                    {gustoUser &&
                      validated &&
                      (jobTabData?.joblocation?.length === 0 ||
                        jobLocationValue?.length === 0) && (
                        <div className="err-feedback">
                          Please enter job location.
                        </div>
                      )}
                  </Form.Group>
                </Col>
                <Col
                  lg={4}
                  className={
                    viewonly?.includes("jobcode") ? "d-block" : "d-hide"
                  }
                >
                  <Form.Group className="mb-3" controlId="jobcode">
                    <Form.Label>Job Code</Form.Label>
                    <Select
                      closeMenuOnSelect={true}
                      hideSelectedOptions={false}
                      options={formatSelectOptions(jobCodeList)}
                      onChange={(selectedOption) =>
                        handleJobCodeOnChange(selectedOption)
                      }
                      value={jobCodeValue}
                      placeholder="Select job code"
                      className="custom-select"
                      isMulti
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col
                  lg={4}
                  className={
                    viewonly?.includes("flsa_classification")
                      ? "d-block"
                      : "d-hide"
                  }
                >
                  <Form.Group className="mb-3" controlId="flsa_classification">
                    <Form.Label>
                      FLSA Classification{" "}
                      {groupId !== 8 && <span className="asterisk">*</span>}{" "}
                    </Form.Label>
                    <Form.Select
                      onChange={handleInput}
                      name="flsa_classification"
                      placeholder="Select FLSA Classification"
                      value={jobTabData.flsa_classification}
                      required={groupId !== 8}
                      size="lg"
                      isInvalid={groupId !== 8 && !!errors.flsa_classification}
                      disabled={readonly?.includes("flsa_classification")}
                    >
                      <option value="">Select FLSA classification</option>
                      {flsaClassificationList &&
                        flsaClassificationList.map((item, index) => (
                          <option key={item.id} value={item.value}>
                            {item.name}
                          </option>
                        ))}
                    </Form.Select>
                    {groupId !== 8 && (
                      <Form.Control.Feedback type="invalid">
                        {errors.flsa_classification}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                </Col>
                <Col
                  lg={4}
                  className={`compensation ${
                    viewonly?.includes("compensation") ? "d-block" : "d-hide"
                  }`}
                >
                  <Form.Group className="mb-3" controlId="compensation">
                    <Form.Label>
                      Compensation rate type <span className="asterisk">*</span>{" "}
                    </Form.Label>
                    <Form.Select
                      onChange={handleInput}
                      name="compensation"
                      value={jobTabData.compensation}
                      required
                      size="lg"
                      disabled={readonly?.includes("compensation")}
                      isInvalid={!!errors.compensation}
                    >
                      <option value="">Select type</option>
                      {compensationList &&
                        compensationList.map((item, index) => (
                          <option key={item.id} value={item.value}>
                            {item.name}
                          </option>
                        ))}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      {errors.compensation}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col
                  lg={4}
                  className={
                    viewonly?.includes("pay_frequency_id")
                      ? "d-block"
                      : "d-hide"
                  }
                >
                  <Form.Group className="mb-3" controlId="pay_frequency_id">
                    {gustoUser === true ? (
                      <>
                        {jobData?.pay_frequency_id === null ? (
                          <Form.Label>
                            Choose pay frequency{" "}
                            <span className="asterisk">*</span>{" "}
                          </Form.Label>
                        ) : (
                          <Form.Label>Pay frequency</Form.Label>
                        )}
                      </>
                    ) : (
                      <Form.Label>
                        Choose pay frequency <span className="asterisk">*</span>{" "}
                      </Form.Label>
                    )}
                    <Form.Select
                      onChange={handleInput}
                      name="pay_frequency_id"
                      value={jobTabData.pay_frequency_id}
                      required
                      size="lg"
                      disabled={
                        jobData?.pay_frequency_id !== null && gustoUser === true
                      }
                      isInvalid={!!errors.pay_frequency_id}
                    >
                      <option value="">Select pay frequency</option>
                      {jobTabData?.pay_frequency &&
                        jobTabData?.pay_frequency.map((item, index) => (
                          <option key={item.id} value={item.id}>
                            {item.name}
                          </option>
                        ))}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      {errors.pay_frequency_id}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col
                  lg={4}
                  className={
                    viewonly?.includes("salary") ? "d-block" : "d-hide"
                  }
                >
                  <Form.Group className="mb-3" controlId="salary">
                    <Form.Label>
                      Salary/Rate <span className="asterisk">*</span>{" "}
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="salary"
                      value={jobTabData?.salary}
                      onChange={handleInput}
                      maxLength={10}
                      name="salary"
                      required
                      isInvalid={!!errors.salary}
                      disabled={readonly?.includes("salary")}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.salary}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              {clientRole === "ClientAdmin" && (
                <>
                  <h6 className="mt-3">Salary change history</h6>
                  <Row>
                    <Col xs={12} className="table_mobile_scroll">
                      <Table responsive bordered className="header_bg_table">
                        <thead>
                          <tr>
                            <th>Description</th>
                            <th>Old Value</th>
                            <th>New Value</th>
                            <th>Modified by</th>
                            <th>Modified date</th>
                          </tr>
                        </thead>
                        {salaryHistory.length !== 0 ? (
                          <tbody>
                            {salaryHistory
                              .slice(0, visibleRowCount)
                              ?.map((salary, i) => {
                                return (
                                  <tr key={i}>
                                    <td>{salary.description}</td>
                                    <td>{salary.old_value}</td>
                                    <td>{salary.new_value}</td>
                                    <td>{salary.modified_by} </td>
                                    <td>
                                      {moment(salary.created).format(
                                        "MM/DD/YYYY"
                                      )}
                                    </td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        ) : (
                          <tbody>
                            <tr>
                              <td colSpan="5">
                                <h4 className="no-data mb-0">
                                  Changes have not been made yet!
                                </h4>
                              </td>
                            </tr>
                          </tbody>
                        )}
                      </Table>
                      {salaryHistory.length > 3 && !showAll && (
                        <button
                          type="button"
                          className="view-more-job"
                          onClick={showMoreRows}
                        >
                          View more
                        </button>
                      )}
                    </Col>
                  </Row>
                </>
              )}
              {jobTabData?.save_action ? (
                <Row className="d-flex justify-content-end">
                  <Col sm={12} md={4} className="d-flex justify-content-end">
                    <Button
                      variant="primary"
                      className="save_btn"
                      type="submit"
                    >
                      Save
                    </Button>
                  </Col>
                </Row>
              ) : (
                ""
              )}
            </Form>
          </div>
        </div>
      )}
      {loading && <Loader />}
    </>
  );
};

export default JobTabTwo;
