
import React, { useState, useEffect } from "react";
import { Form, Row, Col, } from "react-bootstrap";
import "./style.scss";
import Loader from "../../components/Loader";

const CompanyDetails = ({ companySettingsObject }) => {

  
    const [loading, setLoading] = useState(false);
    const [clientObject, setClientObject] = useState({
        admin_name: "",
        email: "",
        company_name: "",
        trade_name: "",
        ein: "",
        user_id: ''
    });

    useEffect(() => {
        if (companySettingsObject?.company_details) {
            setClientObject(companySettingsObject?.company_details);
        }
    }, [companySettingsObject]);


    return (
        <>
            {!loading && (
                <div className="board-right">
                    <Form noValidate  autoComplete="off" className="custom-form">
                        <Row>
                            <Col md={6}>
                                <Form.Group className="mb-3" controlId="admin">
                                    <Form.Label>Admin name <span className="asterisk">*</span>  </Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter admin name"
                                        value={clientObject.admin_name}
                                        name="admin_name"
                                        required
                                        disabled
                                    />
                                </Form.Group>
                            </Col>

                            <Col md={6}>
                                <Form.Group className="mb-3" controlId="email">
                                    <Form.Label>Email <span className="asterisk">*</span> </Form.Label>
                                    <Form.Control
                                        type="email"
                                        placeholder="Enter email"
                                        value={clientObject.email}
                                        name="email"
                                        required
                                        disabled
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <Form.Group className="mb-3" controlId="company">
                                    <Form.Label>Company name <span className="asterisk">*</span>  </Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter company name"
                                        value={clientObject.company_name}
                                        name="company_name"
                                        minLength="4"
                                        maxLength="100"
                                        required
                                        disabled
                                    />
                                </Form.Group>
                            </Col>

                            <Col md={6}>
                                <Form.Group className="mb-3" controlId="trade">
                                    <Form.Label>Trade name <span className="asterisk">*</span>  </Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter trade name"
                                        value={clientObject.trade_name}
                                        name="trade_name"
                                        disabled
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Form>
                </div>
            )
            }
            {loading && <Loader />}

        </>
    )
};

export default CompanyDetails;
