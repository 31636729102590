import React, { useEffect, useState } from "react";
import "./style.scss";
import BackButtonComponent from "../backButton";
import TimeOffCardsCalendar from "./timeOffCardsCalendar";
import SelectedDatesTile from "./selectedDatesTile";
import LeaveTypeComponent from "./leaveType";
import { postData } from "../../api";
import Loader from "../Loader";
import { toast } from "react-toastify";
import { autocloseTiming } from "../../api/regex";
import { Spinner } from "react-bootstrap";

export default function TimeOffCardsComponent() {
  const [dateSelected, setDateSelected] = useState(false);
  const [value, setValue] = useState(8.0);
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [selectedDateArray, setSelectedDateArray] = useState([]);
  const [userData, setUserData] = useState([]);
  const [employeeOption, setEmployeeOption] = useState([]);
  const [defaultOption, setDefaultOption] = useState("Select name");
  const [policyData, setPolicyData] = useState([]);
  const [policyDefaultOption, setPolicyDefaultOption] = useState("Select type");
  const [loading, setLoading] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  const [policyOption, setPolicyOption] = useState([]);
  const [availableBalance, setAvailableBalance] = useState();
  const [textareaValue, setTextareaValue] = useState("");
  const [error, setError] = useState("");
  const [hoursArray, setHoursArray] = useState([]);
  const [remainingHours, setRemainingHours] = useState(0);
  const [noError, setNoErrors] = useState(false);
  const [individualError, setIndividualError] = useState([]);
  const [insufficientError, setInsufficientError] = useState([]);
  const [policyError, setPolicyError] = useState(false);
  const [remainingPrevbal, setRemainingBal] = useState(0);
  const [dropDownLoader, setDropDownLoader] = useState(false);
  const [btnLoader, setBtnLoader] = useState(false);
  const handleGetUserInfo = async () => {
    setLoading(true);
    const res = await postData("get_user_details", {}, {});
    try {
      if (res?.status === true) {
        setLoading(false);
        setUserInfo(res?.data);
      } else {
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };
  const handlePostUserData = async () => {
    // setLoading(true);
    const res = await postData("get_user", {}, {});
    try {
      if (res?.status === true) {
        setUserData(res?.data);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const handlePolicyData = async () => {
    setDropDownLoader(true);
    let obj = {
      user_id:
        userInfo?.group_id === 5 || userInfo?.group_id === 8
          ? userInfo?.user_id
          : defaultOption?.value,
      client_id: userInfo?.client_id,
    };
    const res = await postData("get_policy", {}, obj);
    try {
      if (res?.status === true) {
        setPolicyData(res?.data);
        setDropDownLoader(false);
        if (res?.data?.length === 0) {
          setPolicyError(true);
        } else {
          setPolicyError(false);
        }
      } else {
        setDropDownLoader(false);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };
  const sortedDates = selectedDateArray
    ? [...selectedDateArray].sort(
        (a, b) =>
          new Date(a.year, a.month - 1, a.day) -
          new Date(b.year, b.month - 1, b.day)
      )
    : [];

  const formatDate = (date) => {
    const year = date.year;
    const month = String(date.month).padStart(2, "0");
    const day = String(date.day).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const firstDate = sortedDates.length > 0 ? formatDate(sortedDates[0]) : null;
  const lastDate =
    sortedDates.length > 0
      ? formatDate(sortedDates[sortedDates.length - 1])
      : null;

  const handleGetBalance = async () => {
    // setLoading(true);
    let obj = {
      employee_id: defaultOption?.value,
      policy_id: policyDefaultOption?.value,
      start_date: firstDate,
      end_date: lastDate,
    };
    if (defaultOption?.value) {
      obj.employee_id = defaultOption?.value;
    }
    const res = await postData("get-balance", {}, obj);
    try {
      if (res?.status === true) {
        setAvailableBalance(res?.data);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  useEffect(() => {
    handlePostUserData();
    handleGetUserInfo();
  }, []);
  useEffect(() => {
    if (
      Object.keys(defaultOption).length > 0 ||
      Object.keys(userInfo).length > 0
    ) {
      handlePolicyData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo, defaultOption]);

  const formatRange = () => {
    return selectedDateArray.map((date, index) => {
      const formattedDate = `${date.year}-${String(date.month).padStart(
        2,
        "0"
      )}-${String(date.day).padStart(2, "0")}`;
      const formattedHours = parseFloat(hoursArray[index]).toFixed(2);
      return { date: formattedDate, hrs: formattedHours };
    });
  };
  const handleSubmit = async () => {
    setLoading(true);
    setBtnLoader(true);
    let obj = {
      employee_id: defaultOption?.value,
      policy_id: policyDefaultOption?.value,
      range: formatRange(),
      holiday: 0,
      comments: textareaValue,
      avaliabe_balance: availableBalance?.available_balance,
      remaining_balance: remainingHours,
    };
    const res = await postData("create_pto", {}, obj);
    try {
      if (res?.status === true) {
        setLoading(false);
        window.history.go(-1);
        setBtnLoader(false);
        toast.success(res.message, {
          theme: "colored",
          autoClose: autocloseTiming,
        });
        setTimeout(() => {
          window.history.go(-1);
        }, 3000);
      } else {
        setLoading(false);
        setBtnLoader(false);
        toast.error(res.message, {
          theme: "colored",
          autoClose: autocloseTiming,
        });
      }
    } catch (err) {
      console.error(err);
      setBtnLoader(false);
      toast.error(err, {
        theme: "colored",
        autoClose: autocloseTiming,
      });
    }
  };
  useEffect(() => {
    if (
      hoursArray.length > 0 &&
      availableBalance &&
      !isNaN(availableBalance.available_balance)
    ) {
      const prevDateParts = availableBalance?.prev_date
        ? availableBalance.prev_date.split("-")
        : [];
      const prevYear =
        prevDateParts.length > 0 ? parseInt(prevDateParts[0], 10) : null;
      const prevMonth =
        prevDateParts.length > 1 ? parseInt(prevDateParts[1], 10) : null;
      const prevDay =
        prevDateParts.length > 2 ? parseInt(prevDateParts[2], 10) : null;

      let totalHoursBeforePrevDate = 0;
      let totalHoursOnOrAfterPrevDate = 0;

      selectedDateArray.forEach((date, index) => {
        const hours = isNaN(hoursArray[index]) ? 0 : Number(hoursArray[index]);

        // Check if prev_date is empty ("")
        if (availableBalance.prev_date === "") {
          totalHoursOnOrAfterPrevDate += hours;
        } else {
          const isBeforePrevDate =
            date.year < prevYear ||
            (date.year === prevYear && date.month < prevMonth) ||
            (date.year === prevYear &&
              date.month === prevMonth &&
              date.day < prevDay);

          if (isBeforePrevDate) {
            totalHoursBeforePrevDate += hours;
          } else {
            totalHoursOnOrAfterPrevDate += hours;
          }
        }
      });

      let remainingHours = 0;

      // Calculate remaining hours based on conditions
      if (availableBalance.prev_date === "") {
        const totalHours = hoursArray.reduce(
          (accumulator, currentValue) =>
            accumulator + (isNaN(currentValue) ? 0 : Number(currentValue)),
          0
        );
        remainingHours = availableBalance.available_balance - totalHours;
      } else if (availableBalance?.carryover_type === "L") {
        const remainingHoursOnOrAfterPrevDate =
          remainingPrevbal > 0
            ? availableBalance?.carryover_max - totalHoursOnOrAfterPrevDate
            : 0 - totalHoursOnOrAfterPrevDate;

        remainingHours = remainingHoursOnOrAfterPrevDate;
      } else {
        const remainingHoursBeforePrevDate =
          availableBalance.available_balance - totalHoursBeforePrevDate;
        const remainingHoursOnOrAfterPrevDate =
          remainingPrevbal > 0
            ? remainingPrevbal - totalHoursOnOrAfterPrevDate
            : 0 - totalHoursOnOrAfterPrevDate;

        remainingHours = remainingHoursOnOrAfterPrevDate;
      }

      setRemainingHours(remainingHours.toFixed(2));
    }
  }, [hoursArray, availableBalance, selectedDateArray, remainingPrevbal]);

  const handleValidation = async () => {
    let obj = {
      employee_id: defaultOption?.value,
      policy_id: policyDefaultOption?.value,
      range: formatRange(),
      comments: textareaValue,
      holiday: 0,
    };
    setBtnLoader(true);
    const res = await postData("timeoff-validation", {}, obj);
    try {
      if (res?.status === true) {
        setNoErrors(true);
        setError("");
        setBtnLoader(false);
        handleSubmit();
      } else if (res?.status === false) {
        setError(res?.message);
        setIndividualError(res?.range);
        setNoErrors(false);
        setInsufficientError(res?.messages);
        setBtnLoader(false);
      } else if (res?.status === false && res?.messages.length === 0) {
        setInsufficientError(null);
        setBtnLoader(false);
      }
    } catch (err) {
      console.err(err);
      setLoading(false);
      setNoErrors(false);
    }
  };
  useEffect(() => {
    if (policyDefaultOption === "Select type") {
      setNoErrors(false);
    } else {
      if (
        selectedDateArray.length > 0 &&
        Object.keys(policyDefaultOption).length > 0
      ) {
        handleGetBalance();
        // setTimeout(() => {
        //   handleValidation();
        // }, 2000);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [policyDefaultOption, selectedDateArray.length]);
  const handleCancel = () => {
    window.history.go(-1);
  };
  useEffect(() => {
    if (availableBalance?.prev_date) {
      const prevDate = availableBalance.prev_date.split("-");
      const prevYear = parseInt(prevDate[0], 10);
      const prevMonth = parseInt(prevDate[1], 10);
      const prevDay = parseInt(prevDate[2], 10);

      const totalHours = selectedDateArray.reduce((sum, date, index) => {
        const currentDate = new Date(date.year, date.month - 1, date.day);
        const targetDate = new Date(prevYear, prevMonth - 1, prevDay);

        if (currentDate < targetDate) {
          // Changed comparison to strictly less than
          const hours = isNaN(hoursArray[index])
            ? 0
            : parseFloat(hoursArray[index]);
          return sum + hours;
        }
        return sum;
      }, 0);

      const remainingBalance = availableBalance?.prev_balance - totalHours;
      const formattedRemainingBalance = remainingBalance.toFixed(2);
      setRemainingBal(formattedRemainingBalance);
    }
  }, [selectedDateArray, hoursArray, availableBalance]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="time-off-card-container">
          <div>
            <BackButtonComponent hasHistoryback />
          </div>
          <div className="time-off-card-main-wrapper">
            <div className="time-off-title-wrapper">
              <h3>Time off request</h3>
              <p>
                Select day(s) for which you wish to apply for Time off request.
              </p>
            </div>

            <div className="time-off-main-content-wrapper">
              <div className="time-off-calender-wrapper">
                <TimeOffCardsCalendar
                  dateRange={dateRange}
                  setDateRange={setDateRange}
                  setSelectedDateArray={setSelectedDateArray}
                  setDefaultOption={setDefaultOption}
                  setPolicyDefaultOption={setPolicyDefaultOption}
                />
                <LeaveTypeComponent
                  selectedDateArray={selectedDateArray}
                  userData={userData}
                  setEmployeeOption={setEmployeeOption}
                  employeeOption={employeeOption}
                  defaultOption={defaultOption}
                  setDefaultOption={setDefaultOption}
                  setPolicyDefaultOption={setPolicyDefaultOption}
                  policyDefaultOption={policyDefaultOption}
                  policyData={policyData}
                  userInfo={userInfo}
                  setPolicyOption={setPolicyOption}
                  policyOption={policyOption}
                  handleGetBalance={handleGetBalance}
                  availableBalance={availableBalance}
                  textareaValue={textareaValue}
                  setTextareaValue={setTextareaValue}
                  setError={setError}
                  remainingHours={remainingHours}
                  error={error}
                  handleValidation={handleValidation}
                  policyError={policyError}
                  setPolicyError={setPolicyError}
                  remainingPrevbal={remainingPrevbal}
                  dropDownLoader={dropDownLoader}
                />
              </div>
              <div className="time-off-selected-dates-wrapper">
                <SelectedDatesTile
                  dateSelected={dateSelected}
                  setDateSelected={setDateSelected}
                  setValue={setValue}
                  value={value}
                  selectedDateArray={selectedDateArray}
                  setSelectedDateArray={setSelectedDateArray}
                  dateRange={dateRange}
                  setDateRange={setDateRange}
                  hoursArray={hoursArray}
                  setHoursArray={setHoursArray}
                  handleValidation={handleValidation}
                  individualError={individualError}
                  insufficientError={insufficientError}
                  userInfo={userInfo}
                  setRemainingHours={setRemainingHours}
                />
              </div>
            </div>
          </div>
          <div className="time-off-footer-wrapper">
            <div className="footer-btn-wrapper">
              <button className="cance-btn-wrapper" onClick={handleCancel}>
                Cancel
              </button>
              <button
                className="submit-btn-wrapper"
                // {`submit-btn-wrapper ${!noError ? "disabled" : ""}`}
                onClick={
                  // !noError ? "" :
                  btnLoader ? "" : handleValidation
                }
                // disabled={!noError}
              >
                {btnLoader ? (
                  <Spinner animation="border" size="sm" />
                ) : (
                  "Submit request"
                )}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
