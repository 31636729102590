import React from "react";
import logo from "../../assets/images/logo.png";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { useNavigate } from "react-router-dom";
import {  getCurrentUser, isLogedIn, cakePhpURL } from '../../api';
import './style.scss'
const HeaderForm = () => {
    let navigate = useNavigate();

    const logOut = async () => {
        if (getCurrentUser() === null || getCurrentUser() === undefined || getCurrentUser() === "") {
            navigate("/login");
        } else {
            window.open(cakePhpURL + "users/logout", "_self");
        }

    }


    return (
        <>
            <Navbar variant="dark" expanded className="header-form">
                <Container fluid>
                    <div className="d-flex justify-content-between w-100">
                        <div className="d-flex align-items-center logo-section">
                            <img src={logo} alt="logo" />
                        </div>
                        <div className={`${isLogedIn() ? "d-block" : 'd-none'}`}>
                            <Nav className="align-items-center">
                                <Navbar.Text >
                                    <div className="cursor" onClick={() => logOut()}>
                                        Logout
                                    </div>
                                </Navbar.Text>
                            </Nav>
                        </div>
                    </div>
                </Container>
            </Navbar>
        </>
    );
};

export default HeaderForm;
