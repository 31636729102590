import React from "react";
import MultiCard from "./multiCard";


export default function MultiCardApprovalComponent() {

  return (
    <div className="multi-card-main-container">
      <MultiCard />
    </div>
  );
}
