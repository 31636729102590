import React from "react";

export default function TabSection(props) {
  const { reportStatus, handleReportsChange } = props;

  return (
    <div className="tab-section-cus-wrapper">
      <div className="btn-pto-wrapper">
        <button
          className={
            reportStatus === "standard"
              ? "tab-change-btn-wrapper border-left-class-cus active"
              : "tab-change-btn-wrapper border-left-class-cus"
          }
          onClick={() => handleReportsChange("standard")}
        >
          Standard reports
        </button>
        <button
          className={
            reportStatus === "custom"
              ? "tab-change-btn-wrapper border-right-class-cus active"
              : "tab-change-btn-wrapper border-right-class-cus"
          }
          onClick={() => handleReportsChange("custom")}
        >
          Custom reports
        </button>
      </div>{" "}
    </div>
  );
}
