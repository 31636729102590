import React from "react";

export default function ReportsDetailFooter(props) {
  const { handleConfirmModalToggle } = props;
  return (
    <div className="reports-footer-wrapper">
      <button onClick={() => handleConfirmModalToggle()}>
        Save as template
      </button>
    </div>
  );
}
