import React, { useState, useEffect } from "react";
import { Col, Row, Table, Modal, Button, Form } from "react-bootstrap";
import TimeOffSvg from "../../../assets/images/time_off.svg";
import editIcon from "../../../assets/images/edit.svg";
import leftArrowSvg from "../../../assets/images/lef_arrow.svg";
import "./style.scss";
import { format } from "date-fns";
import { toast } from "react-toastify";
import { postUploadData } from "../../../api";
import Loader from "../../../components/Loader";
import moment from "moment";
import { autocloseTiming } from "../../../api/regex";
import Infoicon from "../../../assets/images/timekeeping_icons/info.svg";
// import Tooltip from "rc-tooltip";
// import "rc-tooltip/assets/bootstrap.css";

const TimeOff = (props) => {
  const { timeOffDatalist, userid, clientRole, userName, timeOffYearList } =
    props;
  const [editShowModal, setEditShowModal] = useState(false);
  const [balanceShowModal, setBalanceShowModal] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [timeoffDetails, setTimeoffDetails] = useState([]);
  const [selectedtimeoffData, setSelectedtimeoffData] = useState();
  const [timeOffData, setTimeOffData] = useState([]);
  const [filteredYear, setFilteredYear] = useState({ selectedYear: "" });
  const [errors, setErrors] = useState({});
  const initialFormState = {
    id: null,
    timeoff_date: format(new Date(), "MM/dd/yyyy"),
    policy: "",
    adjustment_amount: "",
    adjustment_balance: "",
    old_balance: "",
    comments: "",
  };
  const [timeoffTabData, setTimeoffTabData] = useState(initialFormState);
  const [commentsData, setCommentsData] = useState("");
  const [ptoStatus, setPtoStatus] = useState("currentPto");
  const [upcomingPtoData, setUpcomingPtoData] = useState([]);
  const handleShow = (row) => {
    setShow(true);
    setCommentsData(row);
  };

  const openEditModalHandler = (timeoffData) => {
    setTimeoffTabData({
      id: timeoffData.pto_policy_id,
      timeoff_date: format(new Date(), "MM/dd/yyyy"),
      policy: timeoffData.policy_name,
      adjustment_amount: "",
      adjustment_balance: timeoffData.ending_balance,
      old_balance: timeoffData.ending_balance,
      comments: "",
    });
    setEditShowModal(true);
    setErrors({});
    setValidated(false);
  };

  const showTimeoffDetails = async (timeoffData) => {
    setLoading(true);
    setSelectedtimeoffData(timeoffData);
    setFilteredYear({ selectedYear: timeoffData?.show_year });
    setTimeoffTabData({
      id: timeoffData.pto_policy_id,
    });
    let data = new FormData();
    data.append("user_id", userid);
    data.append("stage", "time_off");
    data.append("type", "time_off_balances");
    data.append("pto_policy_id", timeoffData.pto_policy_id);
    data.append("year", timeoffData?.show_year);
    const res = await postUploadData("profile", {}, data);
    if (res.status === true) {
      setTimeoffDetails(res.data);
      setLoading(false);
      setBalanceShowModal(true);
    } else {
      setLoading(false);
      toast.error(res.message, {
        theme: "colored",
        autoClose: autocloseTiming,
      });
    }
  };

  const submitFilter = async (e) => {
    e.preventDefault();
    setLoading(true);
    setTimeoffDetails([]);
    let data = new FormData();
    data.append("user_id", userid);
    data.append("stage", "time_off");
    data.append("type", "time_off_balances");
    data.append("pto_policy_id", selectedtimeoffData.pto_policy_id);
    if (filteredYear.selectedYear !== "all") {
      data.append("year", filteredYear.selectedYear);
    }
    const res = await postUploadData("profile", {}, data);
    if (res.status === true) {
      setTimeoffDetails(res.data);
      setLoading(false);
      setBalanceShowModal(true);
    } else {
      setLoading(false);
      toast.error(res.message, {
        theme: "colored",
        autoClose: autocloseTiming,
      });
    }
  };

  const closeEditModalHandler = (e) => {
    e.preventDefault();
    setEditShowModal(false);
    setErrors({});
  };

  const handleInput = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setTimeoffTabData({
      ...timeoffTabData,
      [name]: value,
    });
    if (!!errors[name])
      setErrors({
        ...errors,
        [name]: null,
      });
    if (name === "adjustment_amount") {
      calc(value);
    }
  };

  useEffect(() => {
    if (timeOffDatalist && Object.keys(timeOffDatalist).length !== 0) {
      setTimeOffData(timeOffDatalist);
    }
  }, [timeOffDatalist]);

  const calc = (value) => {
    if (value === "") {
      setTimeoffTabData({
        ...timeoffTabData,
        adjustment_balance: timeoffTabData.old_balance,
        adjustment_amount: "",
      });
    } else {
      // var total = parseInt(value) + parseInt(timeoffTabData.old_balance);
      var total = parseFloat(value) + parseFloat(timeoffTabData.old_balance);
      var formattedTotal = total?.toFixed(2);
      setTimeoffTabData({
        ...timeoffTabData,
        adjustment_balance: formattedTotal,
        adjustment_amount: value,
      });
    }
  };

  const handleFilterInput = (e) => {
    const { name, value } = e.target;
    setFilteredYear({ ...filteredYear, [name]: value });
  };

  const findFormErrors = () => {
    const { adjustment_amount } = timeoffTabData;
    const newErrors = {};
    if (!adjustment_amount || adjustment_amount === "")
      newErrors.adjustment_amount = "Please enter adjustment amount.";
    return newErrors;
  };

  const editTimeoff = (e) => {
    e.preventDefault();
    const newErrors = findFormErrors();
    setValidated(true);
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      timeoffSubmit();
    }
  };
  const timeoffSubmit = async () => {
    setLoading(true);
    let data = new FormData();
    data.append("user_id", userid);
    data.append("stage", "time_off");
    data.append("pto_policy_id", timeoffTabData.id);
    data.append("hours", timeoffTabData.adjustment_amount);
    data.append("comments", timeoffTabData.comments);
    const res = await postUploadData("profile", {}, data);
    if (res.status === true) {
      setTimeOffData(res.data);
      setLoading(false);
      toast.success(res.message, {
        theme: "colored",
        autoClose: autocloseTiming,
      });
      setEditShowModal(false);
    } else {
      setLoading(false);
      toast.error(res.message, {
        theme: "colored",
        autoClose: autocloseTiming,
      });
    }
  };
  // const handleUpcomingPtoData = async (value) => {
  //   setLoading(true);
  //   let data = new FormData();
  //   data.append("user_id", userid);
  //   data.append("stage", "time_off");
  //   data.append("pto_policy_id", value.pto_policy_id);
  //   data.append("hours", timeoffTabData.adjustment_amount);
  //   data.append("comments", timeoffTabData.comments);
  //   const res = await postUploadData("upcoming-pto", {}, data);
  //   if (res.status === true) {
  //     setUpcomingPtoData(res.data);
  //     setLoading(false);
  //     setEditShowModal(false);
  //   } else {
  //     setLoading(false);
  //     toast.error(res.message, {
  //       theme: "colored",
  //       autoClose: autocloseTiming,
  //     });
  //   }
  // };
  // useEffect(() => {
  //   handleUpcomingPtoData();
  // }, []);
  const handlePtoStatus = (e) => {
    setPtoStatus(e);
  };
  const timeOffMainArray =
    ptoStatus === "currentPto" ? timeoffDetails : upcomingPtoData;
  const renderTooltip = (
    <div>
      <p className="paragraph-tooltip-wrapper">
        Please note that the balance will be updated once the PTO is approved.
      </p>
    </div>
  );
  return (
    <>
      {!loading && (
        <div className="timeoff_container">
          <div>
            <Row className="mb-3">
              <Col>
                <h5 className="tabs_main_heading">
                  <span className="me-1">
                    <img src={TimeOffSvg} alt="" srcSet="" />
                  </span>
                  Time Off Balances
                </h5>
              </Col>
            </Row>
            {balanceShowModal ? (
              <>
                <Row className="mb-3">
                  <Col>
                    <div
                      onClick={() => setBalanceShowModal(false)}
                      className="btn-back"
                    >
                      <span>
                        <img src={leftArrowSvg} alt="" srcSet="" />
                      </span>
                      <span className="ms-2 primary-color">Back</span>
                    </div>
                  </Col>
                </Row>
                <Form onSubmit={submitFilter}>
                  <Row className="year_dropdown">
                    <Col xs={6} lg={2}>
                      <Form.Group className="mb-3">
                        <Form.Label>Select the year</Form.Label>
                        <Form.Select
                          aria-label="Year"
                          defaultValue="Option 1"
                          onChange={handleFilterInput}
                          value={filteredYear.selectedYear}
                          name="selectedYear"
                          className="timeoff_modal_select"
                        >
                          {timeOffYearList &&
                            timeOffYearList.map((item) => (
                              <option key={item.id} value={item.value}>
                                {item.name}
                              </option>
                            ))}
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                          This field is required
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col xs={6} lg={2}>
                      <button className="btn btn-primary dropdown_button clr_white mb-3">
                        Apply
                      </button>
                    </Col>
                  </Row>
                </Form>
                {/* <div className="btn-pto-wrapper">
                  <button
                    className={
                      ptoStatus === "currentPto"
                        ? "pto-log-current-btn active"
                        : "pto-log-current-btn"
                    }
                    onClick={() => handlePtoStatus("currentPto")}
                  >
                    PTO log
                  </button>
                  <button
                    className={
                      ptoStatus === "upcomingPto"
                        ? "pto-log-upcoming-btn border-right-wrapper active"
                        : "pto-log-upcoming-btn border-right-wrapper"
                    }
                    onClick={() => handlePtoStatus("upcomingPto")}
                  >
                    Upcoming
                  </button>
                </div> */}
                <div className="table_mobile_scroll">
                  <Table className="header_bg_table">
                    <thead>
                      <tr>
                        {/* <th>Accrual/Approval Date</th> */}
                        <th>PTO Requested For /Accrual</th>
                        <th>PTO Requested On</th>
                        <th>Type</th>
                        <th>Hours</th>
                        <th>Balance</th>
                        <th>Status</th>
                        <th>Comments</th>
                      </tr>
                    </thead>
                    <tbody>
                      {timeOffMainArray.length !== 0 ? (
                        timeOffMainArray?.map((data, i) => {
                          return (
                            <tr
                              key={i}
                              className={
                                data?.highlight === 1 ? "bg-cus-wrapper" : ""
                              }
                            >
                              {/* <td>{data?.pto_approved_on}</td> */}
                              <td>{data?.pto_requested_on}</td>
                              <td>{data?.pto_requested_for}</td>
                              <td>{data?.type}</td>
                              <td>
                                {Number.isInteger(data?.hours)
                                  ? data?.hours
                                  : parseFloat(data?.hours).toFixed(2)}
                              </td>
                              <td>
                                {Number.isInteger(data?.total_balance)
                                  ? data?.total_balance
                                  : parseFloat(data?.total_balance).toFixed(2)}
                              </td>
                              <td>{data?.status}</td>
                              <td>
                                {data.comments.length <= 20 ? (
                                  <>
                                    {data.comments.length === 0 ? (
                                      <p> - </p>
                                    ) : (
                                      <>
                                        {data?.created_username !== "" &&
                                        data?.created_username !== null &&
                                        data?.created_username !== undefined ? (
                                          <p>By : {data?.created_username}</p>
                                        ) : (
                                          <p> - </p>
                                        )}
                                        <p>{data?.comments}</p>
                                      </>
                                    )}
                                  </>
                                ) : (
                                  <>
                                    <p className="m-0">
                                      {data?.comments.slice(0, 15)}...
                                      <span
                                        onClick={() => {
                                          handleShow(data);
                                        }}
                                        className="primary-color btn-readmore"
                                      >
                                        {" "}
                                        Read more
                                      </span>
                                    </p>
                                  </>
                                )}
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <td colSpan="5">
                          <h4 className="no-data mb-0">No data found</h4>
                        </td>
                      )}
                    </tbody>
                  </Table>
                </div>
              </>
            ) : (
              <>
                <div className="table_mobile_scroll">
                  <Table className="header_bg_table">
                    <thead>
                      <tr>
                        <th>Policy</th>
                        <th>Category</th>
                        <th>Balance</th>
                        {clientRole !== "Employee" && <th></th>}
                      </tr>
                    </thead>
                    {timeOffData.length !== 0 ? (
                      <tbody>
                        {timeOffData?.map((data, i) => {
                          return (
                            <tr key={i}>
                              <td>{data?.policy_name}</td>
                              <td>{data?.category_abbrevation}</td>
                              <td
                                onClick={() => {
                                  showTimeoffDetails(data);
                                  // handleUpcomingPtoData(data);
                                }}
                              >
                                <a
                                  href="#"
                                  style={{
                                    textDecoration: "underline",
                                    color: "#2773B1",
                                  }}
                                  rel="noopener noreferrer"
                                >
                                  {data?.ending_balance}
                                </a>
                              </td>
                              {clientRole !== "Employee" && (
                                <td>
                                  <div
                                    className="text-center icon-edit"
                                    onClick={() => {
                                      openEditModalHandler(data);
                                    }}
                                  >
                                    <img src={editIcon} alt="" srcSet="" />
                                    <p className="m-0 mt-1">Edit</p>
                                  </div>
                                </td>
                              )}
                            </tr>
                          );
                        })}
                      </tbody>
                    ) : (
                      <tbody>
                        <tr>
                          <td colSpan="4">
                            <h4 className="no-data mb-0">No data found</h4>
                          </td>
                        </tr>
                      </tbody>
                    )}
                  </Table>
                </div>
              </>
            )}
            <Modal
              show={editShowModal}
              onHide={closeEditModalHandler}
              className="time_off_modal"
            >
              <Modal.Body>
                <h4>Manual Adjustment</h4>
                <p>{userName}</p>
                <Form noValidate validated={validated} onSubmit={editTimeoff}>
                  <Row>
                    <Col lg={6}>
                      <Form.Group controlId="formFile" className="mb-3">
                        <Form.Label>Date</Form.Label>
                        <Form.Control
                          type="text"
                          value={timeoffTabData.timeoff_date}
                          name="timeoff_date"
                          disabled
                        />
                      </Form.Group>
                    </Col>
                    <Col lg={6}>
                      <Form.Group controlId="formFile" className="mb-3">
                        <Form.Label>Policy</Form.Label>
                        <Form.Control
                          type="text"
                          name="policy"
                          value={timeoffTabData.policy}
                          disabled
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6}>
                      <Form.Group
                        controlId="adjustment_amount"
                        className="mb-3"
                      >
                        <Form.Label>
                          Adjustment Amount <span className="asterisk">*</span>{" "}
                        </Form.Label>
                        <Form.Control
                          type="number"
                          onChange={handleInput}
                          value={timeoffTabData?.adjustment_amount}
                          name="adjustment_amount"
                          required
                          isInvalid={!!errors.adjustment_amount}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.adjustment_amount}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col lg={6}>
                      <Form.Group controlId="formFile" className="mb-3">
                        <Form.Label>Adjustment Balance</Form.Label>
                        <Form.Control
                          type="number"
                          value={timeoffTabData?.adjustment_balance}
                          name="adjustment_balance"
                          disabled
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12}>
                      <Form.Group controlId="formFile" className="mb-3">
                        <Form.Label>Comments</Form.Label>
                        <Form.Control
                          as="textarea"
                          onChange={handleInput}
                          value={timeoffTabData?.comments}
                          rows={3}
                          name="comments"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      lg={12}
                      style={{ textAlign: "right" }}
                      className="mt-3"
                    >
                      <Button
                        variant="outlined"
                        className="cancel_btn btn-default"
                        onClick={() => {
                          setEditShowModal(false);
                          setErrors({});
                          setValidated(false);
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="primary"
                        className="save_btn btn_clr_white ms-2"
                        type="submit"
                      >
                        Save
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Modal.Body>
            </Modal>
            {show && (
              <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                  <Modal.Title>Comments</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <p>By: {commentsData?.created_username}</p>
                  <p className="user_comments">{commentsData?.comments}</p>
                </Modal.Body>
              </Modal>
            )}
          </div>
        </div>
      )}
      {loading && <Loader />}
    </>
  );
};

export default TimeOff;