import React, { useEffect, useState } from "react";
import BackButtonComponent from "../backButton";
import { Button, Col, Container, Form, Row, Spinner } from "react-bootstrap";
import logo from "../../assets/images/logo.png";
import LoginFooter from "../../pages/LoginFooter";
import "./style.scss";
import "react-phone-number-input/style.css";
import { cakePhpURL, getData, postData } from "../../api";
import Loader from "../Loader";
import { toast } from "react-toastify";

export default function MobileVerification(props) {
  const { setPhoneNumber, phoneNumber } = props;
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const formattedPhoneNumber = phoneNumber ? phoneNumber.slice(0) : "";
  const [btnLoading, setBtnLoading] = useState(false);
  const [apiGetResponseData, setGetApiResponseData] = useState({});
  const [apiGetNumber, setGetApiNumber] = useState();

  const handleChange = (event) => {
    const value = event.target.value;
    setPhoneNumber(value);
    setGetApiNumber(value);
    if (value) {
      if (value.length !== 10) {
        setErrorMessage("Phone number must be exactly 10 digits");
      } else if (!/^\d+$/.test(value)) {
        setErrorMessage("Phone number must only contain digits");
      } else {
        setErrorMessage("");
      }
    } else {
      setErrorMessage("Phone number required");
    }
  };
  const resPhoneNumber = apiGetNumber ? apiGetNumber : formattedPhoneNumber;
  const handleOnSubmit = async () => {
    setLoading(true);
    setBtnLoading(true);
    let obj = {
      phone_number: apiGetNumber ? apiGetNumber : formattedPhoneNumber,
    };
    try {
      const res = await postData("update-mobile-number", {}, obj);
      if (res?.status === true) {
        setLoading(false);
        setBtnLoading(false);
        localStorage.setItem("guhroo_token", res.token);
        localStorage.setItem("user_tel_number", resPhoneNumber);
        window.open(res.redirectUrl, "_self");
        localStorage.setItem("userTelNumber", resPhoneNumber);
      } else if (
        res?.status === false &&
        res?.message === "Please wait 2 minutes before requesting a new OTP"
      ) {
        localStorage.setItem("guhroo_token", res.token);
        setLoading(false);
        setBtnLoading(true);
        setTimeout(() => {
          setBtnLoading(false);
        }, 120000);
        toast.error(res.message, {
          theme: "colored",
          autoClose: 10000,
          style: {
            borderRadius: "16px",
          },
        });
      } else if (
        res.status === false &&
        res.message ===
          "You've reached the limit for OTP requests. Please start the login process again"
      ) {
        setLoading(false);
        setBtnLoading(false);
        localStorage.setItem("guhroo_token", res.token);
        toast.error(res.message, {
          theme: "colored",
          autoClose: 10000,
          style: {
            borderRadius: "16px",
          },
        });
        setBtnLoading(true);
        setTimeout(() => {
          localStorage.clear();
          window.open(cakePhpURL + "users/logout", "_self");
        }, 5000);
      }
    } catch (err) {
      setLoading(false);
    }
  };
  const handleBacktoLogin = () => {
    localStorage.clear();
    window.open(cakePhpURL + "users/logout", "_self");
  };

  const handleGetData = async () => {
    setLoading(true);
    const res = await getData("fetch-mobile", {});
    if (res.status === true) {
      setLoading(false);
      setGetApiResponseData(res);
      setGetApiNumber(res.phone_number);
      setPhoneNumber(res.phoneNumber);
      localStorage.setItem("guhroo_token", res.token);
      localStorage.setItem("userTelNumber", res.phone_number);
    } else {
      toast.error(res.message, {
        theme: "colored",
        autoClose: 10000,
      });
      setLoading(false);
    }
  };
  // const clearStorage = () => {
  //   localStorage.clear();
  //   window.open(cakePhpURL + "users/logout", "_self");
  //   sessionStorage.setItem("tabClosed", "true");
  // };
  // useEffect(() => {
  //   const handleBeforeUnload = () => {
  //     clearStorage();
  //   };
  //   window.addEventListener("beforeunload", handleBeforeUnload);
  //   return () => {
  //     window.removeEventListener("beforeunload", handleBeforeUnload);
  //   };
  // }, []);
  const handlePhoneNumberKeyPress = (event) => {
    const keyCode = event.which || event.keyCode;
    const keyValue = String.fromCharCode(keyCode);
    const isValidKey = /^\d$/.test(keyValue);
    const inputValue = event.target.value?.replace(/-/g, "");
    const isInputValidLength = inputValue.length < 10;

    if (!isValidKey || !isInputValidLength) {
      event.preventDefault();
    }
  };
  useEffect(() => {
    handleGetData();
  }, []);
  return (
    <>
      <div className="login-body">
        <div className="login-header">
          <img src={logo} alt="logo" />
        </div>
        <section className="login-section">
          <Container>
            <Row className="justify-content-center align-items-center main-container-wrapper">
              <Col md={6}>
                <div className="login-box mobile-verification-wrapper">
                  <div className="pt-4 ps-4 width-cus">
                    <BackButtonComponent hasHistoryback={true} />{" "}
                  </div>
                  <div className="mobile-info-wrapper px-4">
                    <h5>Two factor authentication</h5>
                    <p>
                      Setup a two factor authentication to go in line with our
                      security guidelines
                    </p>
                  </div>
                  <div>
                    <label className="px-4 pt-3 pb-2">Mobile number</label>
                    <div
                      className={
                        errorMessage ? "phonenumbererror" : "phonenumberinput"
                      }
                    >
                      <span>+1</span>
                      <Form.Control
                        type="text"
                        placeholder="Enter your 10 digit registered mobile number"
                        pattern="[0-9]{10}"
                        value={apiGetNumber}
                        onChange={handleChange}
                        onKeyPress={handlePhoneNumberKeyPress}
                        required
                      />
                    </div>
                  </div>
                  {errorMessage && (
                    <div className="validation-text">{errorMessage}</div>
                  )}
                  <div className="text-center mt-4 px-4">
                    <Button
                      type="submit"
                      className="btn-primary text-white"
                      disabled={btnLoading || !apiGetNumber}
                      loading={btnLoading}
                      onClick={() => {
                        if (!errorMessage) {
                          handleOnSubmit();
                        }
                      }}
                    >
                      {btnLoading ? (
                        <Spinner animation="border" size="sm" />
                      ) : (
                        "Verify"
                      )}
                    </Button>
                  </div>
                  <div className="my-3 back-to-login pb-5">
                    <p onClick={handleBacktoLogin}>Back to Login</p>
                  </div>
                </div>
              </Col>
            </Row>
            {loading && <Loader />}
          </Container>
        </section>
      </div>
      <LoginFooter />
    </>
  );
}
