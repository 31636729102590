import React, { useState, useRef, useEffect, useContext } from "react";
import {
  Col,
  Row,
  Form,
  Button,
  DropdownButton,
  InputGroup,
  Dropdown
} from "react-bootstrap";
import lockReset from "../../../assets/images/lock_reset.svg";
import deleteIcon from "../../../assets/images/delete.svg";
import editIcon from "../../../assets/images/edit_square.svg";
import noUserPhoto from "../../../assets/images/no-user-photo.png";
import personalIconSvg from "../../../assets/images/personalIconSvg.svg";
import "./style.scss";
import { postUploadData, convertUTCToLocalDate, phoneNumberFormat, cakePhpURL } from "../../../api";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import Select from "react-select";
import "react-datepicker/dist/react-datepicker.css";
import Loader from "../../../components/Loader";
import { emailRegx, mustNumbers, mustNumberswithPhone, emptySpace, onlyCharacterWithSpace, onlyCharacterWithSpaceAndComma, autocloseTiming,characterWithCommaDotHyphenAndApos } from "../../../api/regex";
import FormModal from "../../../components/FormModal";
import { UserContext } from "../../../hooks/Context/UserContext";
import loginAsIcon from "../../../assets/images/login_as.svg";
// import moment from 'moment'

const Personal = ({ personalData, clientRole, userid, stateList, activeStatus }) => {
  const focusDate = useRef(null);
  const [validated, setValidated] = useState(false);
  const [profilePicture, setProfilePicture] = useState("");
  const [personalTabData, setPersonalTabData] = useState({
    first_name: "",
    preferred_name: "",
    middle_name: "",
    last_name: "",
    personal_email: "",
    birthday: "",
    gender: "",
    address: "",
    city: "",
    state: "",
    zip: "",
    phone_number: "",
  });
  const [showChangeUserName, setShowChangeUserName] = useState(false);
  const [showResetPassword, setShowResetPassword] = useState(false);
  const [showDeleteUser, setShowDeleteUser] = useState(false);
  const [newUsername, setNewUsername] = useState('');
  const [readonly, setReadonly] = useState([]);
  const [viewonly, setViewonly] = useState([]);
  const [errors, setErrors] = useState({});
  const [stateValue, setStateValue] = useState();
  const [dateInputs, setDateInputs] = useState({
    birthday: null,
  });
  const [selectedImage, setSelectedImage] = useState();
  const [loading, setLoading] = useState(false);
  const { updateUserProfileImage } = useContext(UserContext);
  // const timeZoneOffset = new Date().getTimezoneOffset();
  const currentDate = new Date();

  const handleResetOpen = () => {
    setShowResetPassword(true);
  };
  const handleResetClose = () => {
    setShowResetPassword(false);
  };

  const handleUserNameOpen = () => {
    setShowChangeUserName(true);
  };
  const handleUserNameClose = () => {
    setShowChangeUserName(false);
    setNewUsername('');
  };

  const handleDeleteUserOpen = () => {
    setShowDeleteUser(true);
  };
  const handleDeleteUserClose = () => {
    setShowDeleteUser(false);
  };

  const handleInput = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setPersonalTabData({
      ...personalTabData,
      [name]: value,
    });
    if (!!errors[name])
      setErrors({
        ...errors,
        [name]: null,
      });
  };


  useEffect(() => {
    if (personalData && Object.keys(personalData).length !== 0) {


      if (personalData?.birthday !== null) {
        setDateInputs({
          // birthday: new Date(personalData?.birthday)
          // birthday: moment.utc(personalData?.birthday).add(timeZoneOffset, 'minutes').toDate()
          birthday: convertUTCToLocalDate(personalData?.birthday)
        });

      } else {
        setDateInputs({ birthday: null })
      }
      setSelectedImage(personalData?.profile_photo)
      setPersonalTabData(personalData);
      setReadonly(personalData.readonly)
      setViewonly(personalData.viewonly)
      let data = {
        label: personalData?.state,
        value: personalData?.state,
      }
      setStateValue(data)

    }
  }, [personalData]);


  const fileChange = (e) => {
    let file = e.target.files[0];
    setProfilePicture(file);
    setPersonalTabData((prev) => ({
      ...prev,
      profile_photo: window.URL.createObjectURL(file)
    }));

  };

  const handleImageSelect = (event) => {
    const file = event.target.files[0];
    setSelectedImage(URL.createObjectURL(file));
    setPersonalTabData((prev) => ({
      ...prev,
      profile_photo: URL.createObjectURL(file),
    }));
  };


  const formatSelectOptions = (data) => {
    let finalArr = [];
    if (data && data.length > 0) {
      data.forEach((item) => {
        finalArr.push({
          value: item.id,
          label: item.name,
          name: item.name,
        });
      });
    }
    return finalArr;
  };

  const handleStateOnChange = (data) => {
    setStateValue(data)
    setPersonalTabData((prev) => ({
      ...prev,
      state: data.name
    }));
  }

  const minTwoDigit = (n) => {
    return (n < 10 ? "0" : "") + n;
  };

  const formatDates = (date) => {
    const day = minTwoDigit(date.getDate());
    const month = minTwoDigit(date.getMonth() + 1);
    const year = date.getFullYear();
    let val = `${year}-${month}-${day}`;
    return val;
  };

  const selectUploadDt = (dateSel, name) => {
    const date = new Date(`${dateSel}`);
    const chkDt = date.getDate();

    if (chkDt > 0) {
      let val = formatDates(date);
      setPersonalTabData((prev) => ({
        ...prev,
        [name]: val
      }));
      setDateInputs((prev) => ({
        ...prev,
        [name]: dateSel,
      }));
      if (!!errors[name])
        setErrors({
          ...errors,
          [name]: null,
        });
      // setDateInputs((prev) => ({
      //   ...prev,
      //   [name]: new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())),
      // }));
    }
  };


  const handlePhoneFormatChange = (e) => {
    const name = e.target.name;
    e.target.value = phoneNumberFormat(e.target.value)
    setPersonalTabData((prev) => ({
      ...prev,
      [name]: e.target.value,
    }));
  }

  const handlePhoneNumberKeyPress = (event) => {
    const keyCode = event.which || event.keyCode;
    const keyValue = String.fromCharCode(keyCode);
    const isValidKey = /^\d$/.test(keyValue);
    const inputValue = event.target.value?.replace(/-/g, ''); // Remove hyphens from input value
    const isInputValidLength = inputValue.length < 10;

    if (!isValidKey || !isInputValidLength) {
      event.preventDefault();
    }
  };

  const handleFocusDate = () => {
    const datepickerElement = focusDate.current;
    datepickerElement.setFocus(true);
  };


  const findFormErrors = () => {
    const {
      primary_email,
      personal_email,
      first_name,
      preferred_name,
      middle_name,
      username,
      last_name,
      phone_number,
      birthday,
      address,
      city,
      state,
      zip,
    } = personalTabData;
    const newErrors = {};
    if (!readonly?.includes("primary_email")) {
      if (!primary_email || primary_email === "")
        newErrors.primary_email = "Please enter a valid email.";
      else if (!primary_email?.match(emailRegx)) {
        newErrors.primary_email = "Please enter a valid email address.";
      }
    }

    if (!readonly?.includes("personal_email")) {
      if (personal_email !== "" && personal_email !== null) {
        if (!personal_email?.match(emailRegx)) {
          newErrors.personal_email = "Please enter a valid email address.";
        }
      }
    }



    if (!readonly?.includes("first_name")) {
      if (!first_name || first_name === "")
        newErrors.first_name = "Please enter your first name.";
      else if (first_name?.length < 3) {
        newErrors.first_name =
          "The first name should be  3 to 70 characters long";
      } else if (first_name?.length > 70) {
        newErrors.first_name =
          "The first name should be  3 to 70 characters long";
      } else if (!first_name?.match(characterWithCommaDotHyphenAndApos)) {
        newErrors.first_name = "Only letters and special characters ( - ), ( , ) , ( ' ) ,( . ) are allowed";
      }
    }

    if (!readonly?.includes("preferred_name")) {
      if (preferred_name !== "" && preferred_name !== null) {
        if (preferred_name?.length < 3) {
          newErrors.preferred_name =
            "The preferred name should be  3 to 70 characters long";
        } else if (preferred_name?.length > 70) {
          newErrors.preferred_name =
            "The preferred name should be  3 to 70 characters long";
        } else if (!preferred_name?.match(characterWithCommaDotHyphenAndApos)) {
          newErrors.preferred_name = "Only letters and special characters ( - ), ( , ) , ( ' ) ,( . ) are allowed";
        }
      }
    }

    if (!readonly?.includes("middle_name")) {
      if (middle_name !== "" && middle_name !== null) {
        if (middle_name?.length < 3) {
          newErrors.middle_name =
            "The middle name should be  3 to 50 characters long";
        } else if (middle_name?.length > 50) {
          newErrors.middle_name =
            "The middle name should be  3 to 50 characters long";
        } else if (!middle_name?.match(characterWithCommaDotHyphenAndApos)) {
          newErrors.middle = "Only letters and special characters ( - ), ( , ) , ( ' ) ,( . ) are allowed";
        }
      }
    }

    if (!readonly?.includes("last_name")) {
      if (!last_name || last_name === "")
        newErrors.last_name = "Please enter your last name.";
      // else if (last_name.length < 3) {
      //   newErrors.last_name =
      //     "The last name should be  3 to 70 characters long";
      // } else if (last_name.length > 70) {
      //   newErrors.last_name =
      //     "The last name should be  3 to 70 characters long";
      // } 
      else if (!last_name?.match(characterWithCommaDotHyphenAndApos)) {
        newErrors.last_name = "Only letters and special characters ( - ), ( , ) , ( ' ) ,( . ) are allowed";
      }
      else if (!last_name?.match(emptySpace)) {
        newErrors.last_name = "Please enter your last name.";
      }
    }

    if (!readonly?.includes("username")) {
      if (!username || username === "")
        newErrors.username = "Please enter your user name.";
      else if (username?.length < 3) {
        newErrors.username =
          "The user name should be  3 to 255 characters long";
      } else if (username?.length > 255) {
        newErrors.username =
          "The user name should be  3 to 255 characters long";
      }
    }

    if (!readonly?.includes("phone_number")) {
      if (!phone_number || phone_number === "")
        newErrors.phone_number = "Please enter your phone number.";
      const Phone_numbers_Only = phone_number?.replace(/\D/g, '');
      if (!Phone_numbers_Only?.match(mustNumberswithPhone)) {
        newErrors.phone_number = "The phone number should be numeric.";
      } else if (Phone_numbers_Only?.length !== 10) {
        newErrors.phone_number = "Please enter a 10 digit phone number";
      }
    }

    if (!readonly?.includes("birthday")) {
      if (!birthday || birthday === "" || birthday === null) {
        newErrors.birthday = "Please enter your date of birth.";
      } else if (
        currentDate.getFullYear() - new Date(birthday).getFullYear() < 13 ||
        (currentDate.getFullYear() - new Date(birthday).getFullYear() === 13 && currentDate.getMonth() < new Date(birthday).getMonth()) ||
        (currentDate.getFullYear() - new Date(birthday).getFullYear() === 13 && currentDate.getMonth() === new Date(birthday).getMonth() && currentDate.getDate() < new Date(birthday).getDate())

      ) {
        newErrors.birthday = "The age must be at least 13 years old."
      }
    }

    if (!readonly?.includes("address")) {
      if (!address || address === "")
        newErrors.address = "Please enter street 1.";
      else if (address?.length < 5) {
        newErrors.address =
          "The street name should be  5 to 250 characters long";
      } else if (address?.length > 250) {
        newErrors.address =
          "The street name should be  5 to 250 characters long";
      }
    }

    if (!readonly?.includes("city")) {
      if (!city || city === "") newErrors.city = "Please enter your city.";
      else if (!city?.match(onlyCharacterWithSpaceAndComma)) {
        newErrors.city = "The city name should be alphabetic.";
      } else if (city?.length < 3) {
        newErrors.city = "The city name should be  3 to 20 characters long";
      } else if (city?.length > 20) {
        newErrors.city = "The city name should be  3 to 20 characters long";
      }
    }

    if (!readonly?.includes("state")) {
      if (!state || state === "") newErrors.state = "Please enter your state.";
    }

    if (!readonly?.includes("zip")) {
      if (!zip || zip === "") newErrors.zip = "Please enter your zip.";
      else if (!zip.toString()?.match(mustNumbers)) {
        newErrors.zip = "The zip should be numeric.";
      } else if (zip?.length !== 5) {
        newErrors.zip = "The zip code should be 5 digit";
      }
    }

    return newErrors;
  };

  const handleSubmit = (event) => {

    event.preventDefault();
    const newErrors = findFormErrors();
    setValidated(true);
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      personalSubmit();
    }

  };
  const personalSubmit = async () => {
    setLoading(true);
    //  personalTabData null value to empty string
    for (const key in personalTabData) {
      if (personalTabData.hasOwnProperty(key)) {
        if (personalTabData[key] === null) {
          personalTabData[key] = "";
        }
      }
    }

    let data = new FormData();
    data.append("user_id", userid);
    data.append("stage", "personal_info");
    data.append("address", personalTabData.address);
    data.append("birthday", personalTabData.birthday);
    data.append("city", personalTabData.city);
    data.append("first_name", personalTabData.first_name);
    data.append("preferred_name", personalTabData.preferred_name);
    data.append("gender", personalTabData.gender);
    data.append("last_name", personalTabData.last_name);
    data.append("middle_name", personalTabData.middle_name);
    data.append("personal_email", personalTabData.personal_email);
    data.append("phone_number", personalTabData.phone_number);
    data.append("primary_email", personalTabData.primary_email);
    data.append("profile_photo", profilePicture);
    data.append("state", personalTabData.state);
    data.append("zip", personalTabData.zip);
    const res = await postUploadData("profile", {}, data);
    if (res.status === true) {
      setLoading(false);
      toast.success(res.message, { theme: "colored", autoClose: autocloseTiming });
      if (res?.profile_pic !== null && res?.profile_pic !== '') {
        updateUserProfileImage(res?.profile_pic);
      }
    } else {
      setLoading(false);
      toast.error(res.message, { theme: "colored", autoClose: autocloseTiming });;
    }
  };

  const handleResetSubmit = async () => {
    setLoading(true);
    let data = new FormData();
    data.append("user_id", userid);
    data.append("stage", "personal_info");
    data.append("type", "reset_password");
    data.append("username", personalTabData.username);

    const res = await postUploadData("profile", {}, data);
    if (res.status === true) {
      setShowResetPassword(false);
      toast.success(res.message, { theme: "colored", autoClose: autocloseTiming });
      setLoading(false);
    } else {
      toast.error(res.message, { theme: "colored", autoClose: autocloseTiming });;
      setLoading(false);
    }
  }

  const handleUsernameInput = (e) => {
    const value = e.target.value;
    setNewUsername(value)
  };


  const handleUserNameSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
    if (form.checkValidity() === true) {
      let data = new FormData();
      data.append("user_id", userid);
      data.append("stage", "personal_info");
      data.append("type", "change_username");
      data.append("username", newUsername);
      const res = await postUploadData("profile", {}, data);
      if (res.status === true) {
        setShowChangeUserName(false);
        setNewUsername('')
        toast.success(res.message, { theme: "colored", autoClose: autocloseTiming });
        setLoading(false);
      } else {
        toast.error(res.message, { theme: "colored", autoClose: autocloseTiming });;
        setNewUsername('')
        setLoading(false);
      }
    }

  }

  const handleDeleteUserSubmit = async () => {
    setLoading(true);
    let data = new FormData();
    data.append("user_id", userid);
    data.append("stage", "personal_info");
    data.append("type", "delete");
    const res = await postUploadData("profile", {}, data);
    if (res.status === true) {
      setShowDeleteUser(false);
      toast.success(res.message, { theme: "colored", autoClose: autocloseTiming });
      if (res?.myTeamUrl) {
        window.open(res?.myTeamUrl, "_self");
      }
      setLoading(false);
    } else {
      toast.error(res.message, { theme: "colored", autoClose: autocloseTiming });;
      setLoading(false);
    }
  }

  const handleLoginAs = () => {
    window.open(cakePhpURL + "clients/switch_employee/" + userid, "_self");
  }
  return (
    <>
      {!loading && (
        <div className="personal_container">
          <Row className="d-flex manage_dropdown_container justify-content-between align-items-start row_gutter_x_0">
            <Col xs={6} lg={2}>
              <h5 className="tabs_main_heading">
                <span className="me-1">
                  <img src={personalIconSvg} alt="" srcSet="" />
                </span>
                Personal
              </h5>
            </Col>
            {clientRole === "ClientAdmin" && personalData?.manage && (
              <Col xs={6} lg={2} className="manage_dropdown">
                <InputGroup className="mb-3">
                  <DropdownButton
                    variant="outline-secondary"
                    title="Manage"
                    id="input-group-dropdown-1"
                  >
                    {
                      activeStatus !== 3 &&
                      <Dropdown.Item
                        className="manage_dropdown_items"
                        href="#"
                        onClick={handleLoginAs}
                      >
                        <span className="me-2">
                          <img src={loginAsIcon} alt="" srcSet="" />
                        </span>
                        Login As
                      </Dropdown.Item>
                    }
                    <Dropdown.Item
                      className="manage_dropdown_items"
                      href="#"
                      onClick={handleResetOpen}
                    >
                      <span className="me-2">
                        <img src={lockReset} alt="" srcSet="" />
                      </span>
                      Reset Password
                    </Dropdown.Item>
                    <Dropdown.Item
                      className="manage_dropdown_items"
                      href="#"
                      onClick={handleUserNameOpen}
                    >
                      <span className="me-2">
                        <img src={editIcon} alt="" srcSet="" />
                      </span>
                      Change username
                    </Dropdown.Item>
                    <Dropdown.Item
                      className="manage_dropdown_items"
                      href="#"
                      onClick={handleDeleteUserOpen}
                    >
                      <span className="me-2">
                        <img src={deleteIcon} alt="" srcSet="" />
                      </span>
                      Delete
                    </Dropdown.Item>
                  </DropdownButton>
                </InputGroup>
              </Col>
            )}
          </Row>
          <Row className="profile_image_container mt-3 mb-3 row_gutter_x_0">
            {
              <Col className={`profile_image ${viewonly?.includes("profile_photo") ? 'd-block' : 'd-hide'}`} lg={2}>
                {readonly?.includes("profile_photo") ?
                  <div className="profile_photo_img">
                    <img src={personalData?.profile_photo ? personalData?.profile_photo : noUserPhoto}
                      alt="profile" className="profile_personal_image" />
                  </div>
                  :
                  <div
                    onClick={() => document.getElementById("imageInput").click()}
                    className="profile_photo_img" onChange={(e) => fileChange(e)}
                  >
                    {selectedImage ? (
                      <img src={selectedImage} alt="Selected" />
                    ) : (
                      <img src={noUserPhoto} alt="Selected" />
                    )}
                    <input
                      id="imageInput"
                      type="file"
                      accept="image/*"
                      onChange={handleImageSelect}
                      style={{ display: "none" }}
                    />
                  </div>}
              </Col>
            }
          </Row>
          <Form noValidate className="personal_form" validated={validated} onSubmit={handleSubmit}>
            <Row>
              <Col
                lg={4}
                className={
                  viewonly?.includes("first_name") ? "d-block" : "d-hide"
                }
              >
                <Form.Group className="mb_3" controlId="first_name">
                  <Form.Label>First Name <span className="asterisk">*</span> </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="First Name"
                    value={personalTabData?.first_name}
                    onChange={handleInput}
                    name="first_name"
                    required
                    maxLength="70"
                    isInvalid={!!errors.first_name}
                    disabled={readonly?.includes("first_name")}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.first_name}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col
                lg={4}
                className={
                  viewonly?.includes("preferred_name") ? "d-block" : "d-hide"
                }
              >
                <Form.Group className="mb_3" controlId="preferred_name">
                  <Form.Label>Preferred Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Preferred Name"
                    value={personalTabData?.preferred_name}
                    onChange={handleInput}
                    name="preferred_name"
                    maxLength="70"
                    isInvalid={!!errors.preferred_name}
                    disabled={readonly?.includes("preferred_name")}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.preferred_name}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col
                lg={4}
                className={
                  viewonly?.includes("middle_name") ? "d-block" : "d-hide"
                }
              >
                <Form.Group className="mb_3" controlId="middle_name">
                  <Form.Label>Middle Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Middle Name"
                    value={personalTabData?.middle_name}
                    onChange={handleInput}
                    name="middle_name"
                    maxLength="50"
                    isInvalid={!!errors.middle_name}
                    disabled={readonly?.includes("middle_name")}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.middle_name}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col
                lg={4}
                className={viewonly?.includes("last_name") ? "d-block" : "d-hide"}
              >
                <Form.Group className="mb_3" controlId="last_name">
                  <Form.Label>Last Name <span className="asterisk">*</span> </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Last Name"
                    value={personalTabData?.last_name}
                    onChange={handleInput}
                    name="last_name"
                    required
                    // maxLength="70"
                    isInvalid={!!errors.last_name}
                    disabled={readonly?.includes("last_name")}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.last_name}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col
                lg={4}
                className={viewonly?.includes("birthday") ? "d-block" : "d-hide"}
              >
                <Form.Group
                  className="mb_3 position-relative"
                  controlId="birthday"
                >
                  <Form.Label>Birthday <span className="asterisk">*</span> </Form.Label>
                  <DatePicker
                    ref={focusDate}
                    className="datepicker-payscheme"
                    selected={dateInputs?.birthday ? dateInputs?.birthday : null}
                    onChange={(date) => selectUploadDt(date, "birthday")}
                    name="birthday"
                    dateFormat="MM-dd-yyyy"
                    dropdownMode="select"
                    showMonthDropdown
                    showYearDropdown
                    calendarIcon={true}
                    closeCalendar={true}
                    clearIcon={true}
                    disabled={readonly?.includes("birthday")}
                    maxDate={new Date()}
                  />
                  <span
                    className="calendar-icon"
                    onClick={handleFocusDate}
                  ></span>
                  {validated && errors?.birthday && (
                    <div className="err-feedback">{errors?.birthday}</div>
                  )}
                </Form.Group>
              </Col>
              <Col
                lg={4}
                className={
                  viewonly?.includes("phone_number") ? "d-block" : "d-hide"
                }
              >
                <Form.Group controlId="phone_number" className="mb_3">
                  <Form.Label>Phone number <span className="asterisk">*</span> </Form.Label>
                  <InputGroup className="phone-group personal-phone">
                    <InputGroup.Text>+1</InputGroup.Text>
                    <Form.Control
                      type="text"
                      placeholder="Enter phone number"
                      value={personalTabData.phone_number}
                      onChange={handleInput}
                      isInvalid={!!errors.phone_number}
                      name="phone_number"
                      // maxLength="10"
                      required
                      onBlur={handlePhoneFormatChange}
                      onKeyPress={handlePhoneNumberKeyPress}
                      disabled={readonly?.includes("phone_number")}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.phone_number}
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col
                lg={4}
                className={viewonly?.includes("gender") ? "d-block" : "d-hide"}
              >
                <Form.Group className="mb_3" controlId="gender">
                  <Form.Label>Gender <span className="asterisk">*</span> </Form.Label>
                  <Form.Select
                    aria-label="Gender"
                    defaultValue="Option 1"
                    onChange={handleInput}
                    value={personalTabData?.gender}
                    name="gender"
                    required
                    disabled={readonly?.includes("gender")}
                  >
                    <option value="">Select gender</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Others">Others</option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    This field is required
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col
                lg={4}
                className={viewonly?.includes("address") ? "d-block" : "d-hide"}
              >
                <Form.Group className="mb_3" controlId="address">
                  <Form.Label>Address <span className="asterisk">*</span> </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Address"
                    value={personalTabData?.address}
                    onChange={handleInput}
                    name="address"
                    required
                    maxLength="250"
                    isInvalid={!!errors.address}
                    disabled={readonly?.includes("address")}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.address}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col
                lg={4}
                className={viewonly?.includes("city") ? "d-block" : "d-hide"}
              >
                <Form.Group className="mb_3" controlId="city">
                  <Form.Label>City <span className="asterisk">*</span> </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="City"
                    value={personalTabData?.city}
                    onChange={handleInput}
                    name="city"
                    required
                    isInvalid={!!errors.city}
                    maxLength="20"
                    disabled={readonly?.includes("city")}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.city}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col
                lg={4}
                className={viewonly?.includes("state") ? "d-block" : "d-hide"}
              >
                <Form.Group className="mb_3" controlId="stateSelect">
                  <Form.Label>State <span className="asterisk">*</span> </Form.Label>
                  <Select
                    closeMenuOnSelect={true}
                    hideSelectedOptions={false}
                    options={formatSelectOptions(stateList)}
                    onChange={(selectedOption) =>
                      handleStateOnChange(selectedOption)
                    }
                    value={stateValue}
                    placeholder="Select state"
                    className="custom-select"
                  />
                  {validated && (personalTabData?.state === null || personalTabData?.state === '' || personalTabData?.state === undefined) && (
                    <div className="err-feedback">Please enter state.</div>
                  )}
                </Form.Group>
              </Col>
              <Col
                lg={4}
                className={viewonly?.includes("zip") ? "d-block" : "d-hide"}
              >
                <Form.Group className="mb_3" controlId="zip">
                  <Form.Label>Zip Code <span className="asterisk">*</span> </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter zip code"
                    value={personalTabData.zip}
                    onChange={handleInput}
                    isInvalid={!!errors.zip}
                    name="zip"
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.zip}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col
                lg={4}
                className={
                  viewonly?.includes("primary_email") ? "d-block" : "d-hide"
                }
              >
                <Form.Group className="mb_3" controlId="primary_email">
                  <Form.Label>Primary Email <span className="asterisk">*</span> </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Primary Email"
                    value={personalTabData?.primary_email}
                    onChange={handleInput}
                    name="primary_email"
                    required
                    isInvalid={!!errors.primary_email}
                    disabled={readonly?.includes("personal_email")}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.primary_email}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col
                lg={4}
                className={
                  viewonly?.includes("personal_email") ? "d-block" : "d-hide"
                }
              >
                <Form.Group className="mb-3" controlId="personal_email">
                  <Form.Label>Personal Email</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Personal Email"
                    value={personalTabData?.personal_email}
                    onChange={handleInput}
                    name="personal_email"
                    isInvalid={!!errors.personal_email}
                    disabled={readonly?.includes("personal_email")}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.personal_email}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            {
              personalTabData?.save_action ?
                <Row className="d-flex justify-content-end">
                  <Col sm={12} md={4} className="d-flex justify-content-end">
                    <Button variant="primary" className="save_btn" type="submit">
                      Save
                    </Button>
                  </Col>
                </Row>
                : ''
            }

          </Form>
          {showResetPassword && (
            <FormModal heading="" show={showResetPassword} size="md" onClose={handleResetClose} backdrop="static" modalClassName="view_task_modal personal_container_modal">
              <Form noValidate validated={validated} onSubmit={handleResetSubmit} autoComplete="off" className="mt-3">

                <h4 className="del-profile">
                  Are you sure you want to reset the password?
                </h4>
                <div className="text-center">
                  <Button
                    type="button"
                    variant="secondary"
                    className="btn-default me-3 cancel-btn"
                    onClick={handleResetClose}
                  >
                    Cancel
                  </Button>
                  <Button variant="primary" className="btn_clr_white" type="submit">
                    Reset
                  </Button>
                </div>
              </Form>
            </FormModal>
          )}

          {showChangeUserName && (
            <FormModal heading="Change Username" show={showChangeUserName} size="md" onClose={handleUserNameClose} backdrop="static" modalClassName="view_task_modal personal_container_modal">
              <Form noValidate validated={validated} onSubmit={handleUserNameSubmit} autoComplete="off" className="mt-3">

                <Row>
                  <Col md={8}>
                    <Form.Group className="mb-3" controlId="username">
                      <Form.Label>User Name <span className="asterisk">*</span> </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="User Name"
                        name="username"
                        onChange={handleUsernameInput}
                        value={newUsername}
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        This field is required
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <div className="text-end">
                  <Button
                    type="button"
                    variant="secondary"
                    className="btn-default me-3 cancel-btn"
                    onClick={handleUserNameClose}
                  >
                    Cancel
                  </Button>
                  <Button variant="primary" className="btn_clr_white" type="submit">
                    Change UserName
                  </Button>
                </div>
              </Form>
            </FormModal>
          )}
          {showDeleteUser && (
            <FormModal heading="" show={showDeleteUser} size="md" onClose={handleDeleteUserClose} backdrop="static" modalClassName="view_task_modal personal_container_modal">
              <Form noValidate validated={validated} onSubmit={handleDeleteUserSubmit} autoComplete="off" className="mt-3">

                <h4 className="del-profile">
                  Are you sure you want to delete this account? This action
                  cannot be undone.
                </h4>
                <div className="text-center">
                  <Button
                    type="button"
                    variant="secondary"
                    className="btn-default me-3 cancel-btn"
                    onClick={handleDeleteUserClose}
                  >
                    Cancel
                  </Button>
                  <Button variant="primary" className="btn_clr_white" type="submit">
                    Delete account
                  </Button>
                </div>
              </Form>
            </FormModal>
          )}

        </div>
      )}
      {loading && <Loader />}
    </>
  );
};

export default Personal;
