import React from "react";
import "./style.scss";


const LoginFooter = () => {

    return (
        <>
            <footer className="login-footer">
                <div>Copyright © {new Date().getFullYear()} Guhroo, Inc. <br />
                    <div>Privacy Policy</div>
                </div>

            </footer>
        </>
    );
};

export default LoginFooter;
