import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import { postUploadData, getCurrentUser } from '../../api';
import Loader from '../../components/Loader'

const Logout = () => {

    let navigate = useNavigate();
    const [loading, setLoading] = useState(true);



    useEffect(() => {
        logOut()
    }, [])// eslint-disable-line react-hooks/exhaustive-deps

    const logOut = async () => {
        if (getCurrentUser() === null || getCurrentUser() === undefined || getCurrentUser() === "") {
            navigate("/login");
        } else {
            const res = await postUploadData("userlogout", {});
            if (res.status === true) {
                localStorage.clear();
                setLoading(false)
                navigate("/login");
            }
        }

    }

    return (
        <>
            {loading && <Loader />}
        </>
    )
}

export default Logout