import React, { useState, useEffect } from "react";
import FormModal from "../../../components/FormModal";
import { Form } from "react-bootstrap";
import Button from "../../../components/Form/Button";
import Signature from "../../../components/Signature";
import { postUploadData } from "../../../api";
import { toast } from "react-toastify";
import Loader from "../../../components/Loader";
import {autocloseTiming} from '../../../api/regex';

const ViewHandbookModal = ({ show, handleClose, handbook, acknowledgedShow, eSign }) => {

    const [validated, setValidated] = useState(false);
    const [loading, setLoading] = useState(false);
    const [ackShow, setAckShow] = useState(true);
    const [signObject, setSignObject] = useState();

    useEffect(() => {
        if (eSign !== null && eSign !== '' && eSign !== undefined) {
            setAckShow(false)
        }
    }, [eSign]);

    useEffect(() => {
        if (signObject === undefined) {
            setSignObject(eSign)
        }
    }, [signObject]);// eslint-disable-line react-hooks/exhaustive-deps

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        let formData = new FormData();
        formData.append('stage', "onboarding_tasks");
        formData.append('type', "handbook");
        formData.append('signature_data', signObject);
        formData.append('task_id', handbook?.task_id);
        const res = await postUploadData("employee_onboarding_steps", {}, formData);
        if (res.status === true) {
            setLoading(false);
            acknowledgedShow(true)
            handleClose();
        } else {
             toast.error(res.message, { theme: "colored", autoClose: autocloseTiming });;
            setLoading(false);
        }

    };

    const acknowledgeShow = (data) => {
        setAckShow(data)
    }
    const signatureObj = (data) => {
        if (data?.drawSign !== '' || data?.uploadSign !== '') {
            if (data?.drawSign !== '') {
                setSignObject(data?.drawSign)
            } else {
                setSignObject(data?.uploadSign[0])
            }
        } else {
            setAckShow(true)
        }
    }



    return (
        <>
            {!loading && (
                <FormModal heading={'Handbook'} show={show} onClose={handleClose} size="lg" backdrop="static1" modalClassName="view_task_modal view-hand-book-modal">

                    <div className="preview-tool">
                        <iframe src={handbook?.url !== undefined && handbook?.url} className="mb-4 " name="pdf-view" height="263px" width="100%" title="Iframe Example"></iframe>
                    </div>

                    <div className="onboard-body mb-3">
                        I have read and agreed to the terms in the above document (Please draw or upload your signature to agree)
                    </div>
                    <Form noValidate validated={validated} onSubmit={handleSubmit} autoComplete="off" className="custom-form">
                        <Signature className="mb-3" acknowledgeShow={acknowledgeShow} signatureObj={signatureObj} eSign={eSign} />
                        <div className="text-end">
                            <Button
                                type="button"
                                className="btn-default text-blacksix me-3 cancel-btn"
                                onClick={handleClose}
                            >
                                Cancel
                            </Button>
                            <Button
                                type="submit"
                                disabled={ackShow}
                                loading={loading}
                                className="btn-primary text-white"
                            >
                                Acknowledge
                            </Button>
                        </div>
                    </Form>
                </FormModal>
            )
            }
            {loading && <Loader />}

        </>
    );
};

export default ViewHandbookModal;
