import React, { useState } from "react";
import FormModal from "../../../components/FormModal";
import Button from "../../../components/Form/Button";
import { useNavigate } from "react-router-dom";
import { getCurrentUser, cakePhpURL} from '../../../api';
import checkCircle from '../../../assets/images/check_circle.svg'
import Loader from "../../../components/Loader";

const ClientSuccessModal = ({ show, handleClose, title, subtitle }) => {
    let navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const logOut = async () => {
        if (getCurrentUser() === null || getCurrentUser() === undefined || getCurrentUser() === "") {
            navigate("/login");
        } else {
            window.open(cakePhpURL + "users/logout", "_self");
        }
    }

    return (
        <>
            {!loading && (
                <FormModal show={show} onClose={handleClose} size="md" backdrop="static" modalClassName="successModal">

                    <div>
                        <div><img src={checkCircle} alt="icon" /></div>
                        {/* <h5>{title}</h5> */}
                        <div className="sub-title py-2">{subtitle}</div>
                        <div className="my-2 text-center">
                            <Button
                                type="button"
                                className="btn-primary text-white"
                                onClick={() => logOut()}
                            >
                                Log out
                            </Button>
                            {/* <Button
                                type="button"
                                className="btn-primary text-white"
                                onClick={() => dashboard()}
                            >
                                View dashboard
                            </Button> */}
                        </div>
                    </div>
                </FormModal>
            )
            }
            {loading && <Loader />}
        </>
    );
};

export default ClientSuccessModal;
