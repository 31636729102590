import React, { useState, useEffect } from "react";
import "./style.scss";
import { postData } from "../../../api";
import Loader from "../../../components/Loader";
import { Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Badge from 'react-bootstrap/Badge';

const EmployeeSignDocuments = ({ nextStep }) => {

    let navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [signDocumentsList, setSignDocumentsList] = useState([]);
    const [companyName, setCompanyName] = useState('');
    const [missingRequirement, setMissingRequirement] = useState([]);
    const [missingTitle, setMissingTitle] = useState('');
    const [missingSubTitle, setMissingSubTitle] = useState('');


    useEffect(() => {
        getSignDocumentsList();
    }, []);

    const getSignDocumentsList = async () => {
        setLoading(true);
        let obj = {
            stage: "employee-forms"
        };
        try {
            const res = await postData("get-all-forms", {}, obj);
            if (res?.status === true) {
                setSignDocumentsList(res?.data);
                setCompanyName(res?.company_name);
                setMissingRequirement(res?.missing_requirement)
                setMissingTitle(res?.missing_title)
                setMissingSubTitle(res?.missing_subtitle);
                setLoading(false);
            }
        } catch (err) { }
    };

    return (
        <>
            {!loading && (
                <div className="paystub-view">
                    {
                        (signDocumentsList !== undefined && signDocumentsList.length !== 0) ?
                            <div className="sign-doc-table">
                                {
                                    missingRequirement?.length !== 0 &&
                                    <div className="inside-table-sign-error">
                                        <div className="sign-no-data">
                                            <h4>{missingTitle}</h4>
                                            <div>{missingSubTitle}</div>
                                            <div className="sign-error my-2">
                                                <ul>
                                                    {
                                                        missingRequirement?.map((data, i) => {
                                                            return (
                                                                <React.Fragment key={i}>
                                                                    {
                                                                        data?.link_to === 'job' &&
                                                                        <li>
                                                                            <div className="sign-doc-name"
                                                                                onClick={() => nextStep('job')}
                                                                            >
                                                                                {data?.title}
                                                                            </div>
                                                                        </li>
                                                                    }
                                                                    {
                                                                        data?.link_to === 'personal_info' &&
                                                                        <li>
                                                                            <div className="sign-doc-name"
                                                                                onClick={() => nextStep('personal')}
                                                                            >
                                                                                {data?.title}
                                                                            </div>
                                                                        </li>
                                                                    }
                                                                    {
                                                                        data?.link_to === 'statetax' &&
                                                                        <li>
                                                                            <div className="sign-doc-name"
                                                                                onClick={() => nextStep('statetax')}
                                                                            >
                                                                                {data?.title}
                                                                            </div>
                                                                        </li>
                                                                    }
                                                                    {
                                                                        data?.link_to === 'signforms' &&
                                                                        <li>
                                                                            <div className="sign-doc-name" onClick={() => nextStep('signforms')}>
                                                                                {data?.title}
                                                                            </div>
                                                                        </li>
                                                                    }
                                                                    {
                                                                        data?.link_to === 'banking' &&
                                                                        <li>
                                                                            <div className="sign-doc-name" onClick={() => nextStep('banking')}>
                                                                                {data?.title}
                                                                            </div>
                                                                        </li>
                                                                    }
                                                                    {
                                                                        data?.link_to === '#' &&
                                                                        <li>
                                                                            <div className="sign-doc-name same-sign-link" >
                                                                                {data?.title}
                                                                            </div>
                                                                        </li>
                                                                    }
                                                                </React.Fragment>
                                                            )

                                                        })
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                }

                                <Table responsive className="mt-4 header_bg_table">
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Description</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    {signDocumentsList?.length !== 0 ? (
                                        <tbody>
                                            {signDocumentsList?.map((row, i) => {
                                                return (
                                                    <tr key={i}>
                                                        <td>
                                                            <div className="sign-doc-name" onClick={() => navigate('/sign-documents-details', { state: { row, companyName } })}>
                                                                {row?.title}
                                                            </div>
                                                        </td>
                                                        <td>{row?.description}</td>
                                                        <td>
                                                            {
                                                                !row.requires_signing ?
                                                                    <Badge pill bg="success" className="sign-status sign-complete-status">  Completed </Badge>
                                                                    :
                                                                    <Badge pill bg="success" className="sign-status sign-incomplete-status">  Incomplete </Badge>
                                                            }
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    ) :
                                        <tbody>
                                            <tr>
                                                <td colSpan="3">
                                                    <h4 className="no-data mb-0">No data found</h4>
                                                </td>
                                            </tr>
                                        </tbody>
                                    }
                                </Table>
                            </div>
                            :

                            <div>
                                <div className="sign-no-data">
                                    <h4>{missingTitle}</h4>
                                    <div>{missingSubTitle}</div>
                                    <div className="sign-error my-2">
                                        <ul>
                                            {
                                                missingRequirement?.map((data, i) => {
                                                    return (
                                                        <React.Fragment key={i}>
                                                            {
                                                                data?.link_to === 'job' &&
                                                                <li>
                                                                    <div className="sign-doc-name"
                                                                        onClick={() => nextStep('job')}
                                                                    >
                                                                        {data?.title}
                                                                    </div>
                                                                </li>
                                                            }
                                                            {
                                                                data?.link_to === 'personal_info' &&
                                                                <li>
                                                                    <div className="sign-doc-name"
                                                                        onClick={() => nextStep('personal')}
                                                                    >
                                                                        {data?.title}
                                                                    </div>
                                                                </li>
                                                            }
                                                            {
                                                                data?.link_to === 'statetax' &&
                                                                <li>
                                                                    <div className="sign-doc-name"
                                                                        onClick={() => nextStep('statetax')}
                                                                    >
                                                                        {data?.title}
                                                                    </div>
                                                                </li>
                                                            }
                                                            {
                                                                data?.link_to === 'signforms' &&
                                                                <li>
                                                                    <div className="sign-doc-name" onClick={() => nextStep('signforms')}>
                                                                        {data?.title}
                                                                    </div>
                                                                </li>
                                                            }
                                                            {
                                                                data?.link_to === 'banking' &&
                                                                <li>
                                                                    <div className="sign-doc-name" onClick={() => nextStep('banking')}>
                                                                        {data?.title}
                                                                    </div>
                                                                </li>
                                                            }
                                                            {
                                                                data?.link_to === '#' &&
                                                                <li>
                                                                    <div className="sign-doc-name same-sign-link" >
                                                                        {data?.title}
                                                                    </div>
                                                                </li>
                                                            }
                                                        </React.Fragment>
                                                    )

                                                })
                                            }
                                        </ul>
                                    </div>
                                </div>

                            </div>
                    }
                </div >
            )}
            {loading && <Loader />}
        </>
    )
}


export default EmployeeSignDocuments