import React from "react";
import TwoFactor from "../../../components/EmployeeTwoFactorAuth/twoFactor";

export default function MobileVerificationPage() {
  return (
    <div>
      <TwoFactor />
    </div>
  );
}
