import React, { useEffect, useRef, useState } from "react";
import "./style.scss";
import OnboardHeading from "../OnboardHeading";
import TitleComponent from "../titleComponent/titleComponent";
import MultiSelectSection from "./multiSelectSection";
import BackButtonComponent from "../backButton";
import ReportsDetailFooter from "./reportsDetailFooter";
import SaveModal from "./saveModal";
import { getData, postData } from "../../api";
import { autocloseTiming } from "../../api/regex";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Loader from "../Loader";

export default function ReportsDetailComponent() {
  const navigate = useNavigate();
  const [multiSelectionData, setMultiSelectionData] = useState([]);
  const [selectedId, setSelectedId] = useState([]);
  const [showSaveModal, setShowSaveModal] = useState(false);
  const [modalReportsData, setModalReportsData] = useState({
    reportName: {
      value: "",
      charCount: 0,
    },
    reportDescription: {
      value: "",
      charCount: 0,
    },
    selectAllResellers: false,
    selectAllClients: false,
    selectedResellerValues: [],
    selectedClientValues: [],
  });
  const [errors, setErrors] = useState({
    reportName: "",
    reportDescription: "",
    selectedId: "",
    selectedResellerValues: "",
    selectedClientValues: "",
  });
  const [saveBtnLoading, setSaveBtnLoading] = useState(false);
  const [apiCallsInProgress, setApiCallsInProgress] = useState(0);
  const [, setReportsDetailLoading] = useState(false);
  const [groupId, setGroupId] = useState(0);
  const [modalOptions, setModalOptions] = useState({
    resellerOptions: [],
    clientOptions: [],
  });
  const [dropdownLoading, setDropdownLoading] = useState(false);
  const requiredKeys = useRef([]);
  // Handler for modal toggle
  const handleConfirmModalToggle = () => {
    if (selectedId.length <= 2) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        selectedId:
          "Please ensure that atleast one field other than the default fields is selected*",
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        selectedId: "",
      }));
      setShowSaveModal(true);
    }
  };
  const handleCloseModal = () => {
    setShowSaveModal(false);
    setSaveBtnLoading(false);
    setModalReportsData({
      reportName: {
        value: "",
        charCount: 0,
      },
      reportDescription: {
        value: "",
        charCount: 0,
      },
      selectAllResellers: false,
      selectAllClients: false,
      selectedResellerValues: [],
      selectedClientValues: [],
    });
    setErrors({});
  };

  const handleError = (message) => {
    handleApiEnd();
    toast.error(message, { theme: "colored", autoClose: autocloseTiming });
  };
  const handleSuccess = (message) => {
    toast.success(message, { theme: "colored", autoClose: 3000 });
  };

  const handleApiStart = () => {
    setApiCallsInProgress((prev) => prev + 1);
    setReportsDetailLoading(true);
  };

  const handleApiEnd = () => {
    setApiCallsInProgress((prev) => {
      const newCount = prev - 1;
      if (newCount === 0) {
        setReportsDetailLoading(false);
      }
      return newCount;
    });
  };

  const getCheckboxData = async () => {
    handleApiStart();
    const res = await getData("custom-fields", {});
    try {
      if (res.status === true) {
        setMultiSelectionData(res.data);
        const requiredKeysArray = res.data
          .flatMap((section) => section.checkboxes)
          .filter((checkbox) => checkbox.required === 1)
          .map((checkbox) => checkbox.key);

        requiredKeys.current = requiredKeysArray;
        setSelectedId(requiredKeysArray);
      }
    } catch (err) {
      handleError(res.message);
    } finally {
      handleApiEnd();
    }
  };

  const updateSelectedId = (updatedIds) => {
    setSelectedId((prevKeys) => {
      const newIds = [...new Set([...requiredKeys.current, ...updatedIds])];
      return newIds;
    });
  };

  useEffect(() => {
    getCheckboxData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Handler for selecting all checkboxes in a section
  const handleSelectAllChange = (sectionIndex, isChecked) => {
    const updatedData = [...multiSelectionData];
    const selectedKeysInSection = updatedData[sectionIndex].checkboxes.map(
      (option) => option.key
    );

    updatedData[sectionIndex].checkboxes = updatedData[
      sectionIndex
    ].checkboxes.map((option) => ({
      ...option,
      checked: isChecked,
    }));

    setMultiSelectionData(updatedData);
    if (isChecked) {
      updateSelectedId([...selectedId, ...selectedKeysInSection]);
    } else {
      updateSelectedId(
        selectedId.filter((key) => !selectedKeysInSection.includes(key))
      );
    }
  };

  // Handler for individual checkbox change
  const handleIndividualChange = (sectionIndex, optionKey, isChecked) => {
    const updatedData = [...multiSelectionData];
    updatedData[sectionIndex].checkboxes = updatedData[
      sectionIndex
    ].checkboxes.map((option) =>
      option.key === optionKey ? { ...option, checked: isChecked } : option
    );
    setMultiSelectionData(updatedData);

    if (isChecked) {
      updateSelectedId([...selectedId, optionKey]);
    } else {
      updateSelectedId(selectedId.filter((key) => key !== optionKey));
    }
  };

  const createNewCustomReport = async () => {
    let hasErrors = false;
    if (modalReportsData.reportName.charCount === 0) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        reportName: "Please enter the report name to create a template*",
      }));
      hasErrors = true;
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        reportName: "",
      }));
    }
    if (modalReportsData.reportDescription.charCount === 0) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        reportDescription:
          "Please enter the report description to create a template*",
      }));
      hasErrors = true;
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        reportDescription: "",
      }));
    }
    // if (modalReportsData?.selectedResellerValues?.length === 0) {
    //   setErrors((prevErrors) => ({
    //     ...prevErrors,
    //     selectedResellerValues:
    //       "Please select a reseller to create a template*",
    //   }));
    // } else if (modalReportsData?.selectedClientValues?.length > 0) {
    //   setErrors((prevErrors) => ({
    //     ...prevErrors,
    //     selectedResellerValues: "",
    //   }));
    // } else {
    //   setErrors((prevErrors) => ({
    //     ...prevErrors,
    //     selectedResellerValues: "",
    //   }));
    // }
    if (modalReportsData?.selectedClientValues?.length === 0) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        selectedClientValues: "Please select a client to create a template*",
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        selectedClientValues: "",
      }));
    }
    if (hasErrors) {
      return;
    }
    setSaveBtnLoading(true);
    let createObj = {
      name: modalReportsData?.reportName?.value,
      description: modalReportsData?.reportDescription?.value,
      report_coloumns: selectedId,
      clients: modalReportsData?.selectedClientValues,
    };
    const res = await postData("create-custom-report", {}, createObj);
    try {
      if (res?.status === true) {
        setSaveBtnLoading(false);
        handleSuccess(res.message);
        handleCloseModal();
        navigate("/reports?status=custom");
      } else if (
        res?.status === false &&
        res?.message ===
          "A report already exists in the same name. Please rename it to create the report."
      ) {
        setSaveBtnLoading(false);
        setErrors((prevErrors) => ({
          ...prevErrors,
          reportName: res.message,
        }));
      } else {
        setSaveBtnLoading(false);
        handleError(res.message);
        // handleCloseModal();
      }
    } catch (err) {
      setSaveBtnLoading(false);
      handleError(err);
      handleCloseModal();
    }
  };
  const handleGetUserInfo = async () => {
    handleApiStart();
    const res = await postData("get_user_details", {}, {});
    try {
      if (res?.status === true) {
        setGroupId(res?.data?.group_id);
      }
    } catch (err) {
      console.log(err);
    } finally {
      handleApiEnd();
    }
  };

  const getResellerDropdownOptions = async () => {
    handleApiStart();
    const res = await postData("get-gusto-reseller", {}, {});
    try {
      if (res?.status === true) {
        setModalOptions((prevData) => ({
          ...prevData,
          resellerOptions: res?.data,
        }));
      }
    } catch (err) {
      console.log(err);
    } finally {
      handleApiEnd();
    }
  };
  const getClientDropdownOptions = async () => {
    setDropdownLoading(true);
    const clientApiPayload = {
      reseller: modalReportsData?.selectedResellerValues,
    };
    const res = await postData("get-gusto-clients", {}, clientApiPayload);
    try {
      if (res?.status === true) {
        setModalOptions((prevData) => ({
          ...prevData,
          clientOptions: res?.data,
        }));
        setDropdownLoading(false);
      }
    } catch (err) {
      console.log(err);
      setDropdownLoading(false);
    } finally {
      // handleApiEnd();
      setDropdownLoading(false);
    }
  };

  useEffect(() => {
    handleGetUserInfo();
    getResellerDropdownOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    getClientDropdownOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalReportsData.selectedResellerValues]);

  return (
    <>
      {apiCallsInProgress > 0 ? (
        <Loader />
      ) : (
        <div className="report-details-wrapper">
          <div>
            <OnboardHeading title="NEW CUSTOM REPORTS" />
            <div className="back-btn-cus-width-wrapper">
              <BackButtonComponent
                reDirectionpath={
                  groupId === 1 || groupId === 6
                    ? "/reports?status=custom"
                    : "/reports?status=standard"
                }
              />{" "}
            </div>
          </div>
          <div className="reports-multi-select-wrapper">
            <div>
              <h1>Custom reports</h1>
              <TitleComponent
                title="Add columns"
                subTitle="Add or remove the columns you want to see in the report"
                h3Tag
              />
            </div>
            {multiSelectionData.map((section, index) => (
              <div key={index}>
                <MultiSelectSection
                  title={section.title}
                  subTitle={section.subtitle}
                  options={section.checkboxes}
                  onSelectAllChange={(isChecked) =>
                    handleSelectAllChange(index, isChecked)
                  }
                  onIndividualChange={(optionKey, isChecked) =>
                    handleIndividualChange(index, optionKey, isChecked)
                  }
                />
              </div>
            ))}
            {errors.selectedId !== "" ? (
              <div style={{ color: "red" }}>{errors.selectedId}</div>
            ) : null}
          </div>
          <ReportsDetailFooter
            handleConfirmModalToggle={handleConfirmModalToggle}
          />
          <SaveModal
            handleCloseModal={handleCloseModal}
            showSaveModal={showSaveModal}
            saveBtnLoading={saveBtnLoading}
            createNewCustomReport={createNewCustomReport}
            modalReportsData={modalReportsData}
            setModalReportsData={setModalReportsData}
            errors={errors}
            setErrors={setErrors}
            groupId={groupId}
            resellerOptions={modalOptions?.resellerOptions}
            clientOptions={modalOptions?.clientOptions}
            getClientDropdownOptions={getClientDropdownOptions}
            dropdownLoading={dropdownLoading}
          />
        </div>
      )}
    </>
  );
}
