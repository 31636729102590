import React from "react";
import TitleComponent from "../titleComponent/titleComponent";

export default function MultiSelectSection(props) {
  const { title, subTitle, options, onSelectAllChange, onIndividualChange } =
    props;

  const allChecked = options.every((option) => option.checked);
  const someChecked = options.some((option) => option.checked);

  const handleSelectAllChange = (event) => {
    const isChecked = event.target.checked;
    onSelectAllChange(isChecked);
  };

  const handleIndividualChange = (event, optionKey) => {
    const isChecked = event.target.checked;
    onIndividualChange(optionKey, isChecked);
  };

  return (
    <div className="multi-select-container">
      <div className="multi-section-wrapper">
        <TitleComponent title={title} subTitle={subTitle} />
        <div className="select-all-wrapper">
          <label>
            <input
              type="checkbox"
              checked={allChecked}
              onChange={handleSelectAllChange}
              indeterminate={someChecked && !allChecked}
            />
            <span>
              Select all
              {options.length > 4 && (
                <p className="responsive-scroll-text">
                  (Scroll to see more)
                </p>
              )}
            </span>
          </label>
        </div>
      </div>
      <div
        className={
          options.length > 4
            ? "select-options-main-wrapper grid-column-non-condition-wrapper"
            : "select-options-main-wrapper grid-column-condition-wrapper"
        }
      >
        {options
          .sort((a, b) => a.value.localeCompare(b.value))
          .map((option) => (
            <div className="select-options-wrapper" key={option.key}>
              <label
                onChange={(event) => handleIndividualChange(event, option.key)}
              >
                <input
                  type="checkbox"
                  checked={option.checked || option.required === 1}
                  disabled={option.required === 1}
                  onChange={(event) =>
                    handleIndividualChange(event, option.key)
                  }
                />
                <span>{option.value}</span>
              </label>
            </div>
          ))}
      </div>
    </div>
  );
}
