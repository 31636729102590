/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import "./style.scss";
import { postData, getRevertUser, baseURL, getCurrentUser } from "../../api";
import { toast } from "react-toastify";
import Loader from "../../components/Loader";
import { Button } from "react-bootstrap";
import Buttons from "../../components/Form/Button";
import { Col, Row, Container, Card, Modal } from "react-bootstrap";
import OnboardHeading from "../../components/OnboardHeading";
import ServerSideTable from "../../components/ServerSideTable";
import FormModal from "../../components/FormModal";
import AlertIcon from "../../assets/images/alert.svg";
import revertIcon from "../../assets/images/fa_refresh.svg";
import { Markup } from "interweave";
import payrollIcon from "../../assets/images/payroll_icon.svg";
import payrollInfoIcon from "../../assets/images/payroll_info.svg";
import backIcon from "../../assets/images/back_icon.svg";
import errorIcon from "../../assets/images/errorIcon.svg";
import downloadIcon from "../../assets/images/download_icon.svg";
import RunPayroll from "./runPayroll";
import moment from "moment";
import ApiLoader from "../../components/Loader/apiLoader";
import { type } from "@testing-library/user-event/dist/type";
import NotificationIcon from "../../assets/images/dashboard/notification_icon.svg";
import UpArrowIcon from "../../assets/images/dashboard/arrow_outward.svg";
import CheckCircle from "../../assets/images/dashboard/check_circle.svg";
import ManagePop from "../../assets/images/dashboard/manage-pop-icon.svg";
import CloseIcon from "../../assets/images/dashboard/close.svg";

const Payroll = () => {
  const [loading, setLoading] = useState(false);
  const [companyName, setCompanyName] = useState("test");
  const [showWaitModal, setShowWaitModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showErrorWaitingModal, setShowErrorWaitingModal] = useState(false);
  // const [firstPayroll, setFirstPayroll] = useState();
  const [runPayrollLink, setRunPayrollLink] = useState("");
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizeperPage] = useState(25);
  const [totalSize, setTotalSize] = useState(0);
  const [processPayrollList, setProcessPayrollList] = useState([]);
  const [completePayrollData, setCompletePayrollData] = useState({});
  const [auto_refresh, setAuto_refresh] = useState(false);
  const [errSync, setErrSync] = useState(false);
  const [payrollErrors, setPayrollErrors] = useState("");
  const [apiStages, setApiStages] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [managePtoData, setManagePtoData] = useState({});

  const getProcessPayrollList = async (
    page,
    sizePerPage,
    search,
    apiData,
    validationType
  ) => {
    setLoading(true);
    let obj = {
      page: page,
      page_size: sizePerPage,
      search: search,
      stage: apiData,
      validation_type: validationType,
    };
    try {
      const res = await postData("get-payroll-history-new", {}, obj);
      if (res?.status === true) {
        setProcessPayrollList(res?.data);
        setTotalSize(res?.count);
        setCompanyName(res?.company_name);
        setLoading(false);
        setCompletePayrollData(res);
        setAuto_refresh(res?.auto_refresh);
      } else {
        setLoading(false);
        toast.error(res?.message, { theme: "colored" });
        setShowErrorModal(true);
      }
    } catch (err) {}
  };

  const downloadReport = async (row) => {
    try {
      // Define your payload data
      const payloadData = {
        payroll_uuid: row.payroll_uuid,
        client_id: row.client_id,
      };

      // Make a POST request with the payload
      const response = await fetch(`${baseURL}payroll-process-report`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCurrentUser()}`,
        },
        body: JSON.stringify(payloadData),
      });

      if (!response.ok) {
        throw new Error("Failed to download file");
      }

      // Extract the filename from the Content-Disposition header
      // const filename = response.headers.get('Content-Disposition').split('filename=')[1];
      const filename = "report";
      // Convert the response to a blob
      const blob = await response.blob();

      // Create a temporary URL for the blob
      const url = window.URL.createObjectURL(blob);

      // Create a link element and trigger a click to download the file
      const link = document.createElement("a");
      link.href = url;
      link.download = filename || "downloadedFile.txt";
      document.body.appendChild(link);
      link.click();

      // Cleanup: remove the temporary link and URL
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };
  const handleRevertUser = () => {
    window.open(getRevertUser(), "_self");
  };

  const handleShowWaitModalOpen = () => {
    setShowWaitModal(true);
  };
  const handleShowWaitModalClose = () => {
    setShowWaitModal(false);
  };

  const handleShowErrorModalOpen = () => {
    setShowErrorModal(true);
  };
  const handleShowErrortModalClose = () => {
    setShowErrorModal(false);
    setErrSync(false);
  };

  const handleShowErrorWaitingtModalOpen = () => {
    setShowErrorWaitingModal(true);
  };
  const handleShowErrorWaitingtModalClose = () => {
    setShowErrorWaitingModal(false);
  };

  const onFilter = (page, sizePerPage, search) => {
    setPage(page);
    setSizeperPage(sizePerPage);
    getProcessPayrollList(page, sizePerPage, search, "history", "yes");
  };
  const payrollReportFormatter = (cell, row) => {
    return (
      <div>
        {row.processed && (
          <img
            src={downloadIcon}
            alt="appraisalIcon"
            className="cursor-pointer"
            onClick={() => {
              downloadReport(row);
            }}
          />
        )}
      </div>
    );
  };
  const columns = [
    {
      dataField: "sl.no",
      text: "ID",
      headerStyle: { width: "5%", textAlign: "left" },
      style: { textAlign: "left" },
      formatter: (cell, row, rowIndex, formatExtraData) => rowIndex + 1,
    },
    {
      dataField: "pay_period",
      text: "Start Date",
      formatter: (cell) => {
        const startDate = moment(cell.start_date, "YYYY-MM-DD").format(
          "MM-DD-YYYY"
        );
        return startDate;
      },
      // headerStyle: { width: "20%", textAlign: "center" },
      // style: { textAlign: "center" },
    },
    {
      dataField: "pay_period",
      text: "End Date",
      formatter: (cell) => {
        const endDate = moment(cell.end_date, "YYYY-MM-DD").format(
          "MM-DD-YYYY"
        );
        return endDate;
      },
      // headerStyle: { width: "20%", textAlign: "center" },
      // style: { textAlign: "center" },
    },
    {
      dataField: "payroll_processing_date",
      text: "Processed Date",
      // headerStyle: { width: "20%", textAlign: "center" },
      // style: { textAlign: "center" },
      formatter: (cell) => {
        const formattedDate = moment(cell, "DD-MM-YYYY HH:mm:ss").format(
          "MM-DD-YYYY"
        );
        return formattedDate;
      },
    },
    {
      dataField: "processed",
      text: "Status",
      // headerStyle: { width: "20%", textAlign: "center" },
      // style: { textAlign: "center" },
      formatter: (cell) => {
        const processStatus = cell ? "Processed" : "Not Processed";
        return processStatus;
      },
    },
    {
      dataField: "payroll_report",
      text: "Report",
      // headerStyle: { width: "20%", textAlign: "center" },
      // style: { textAlign: "center" },
      formatter: payrollReportFormatter,
    },
  ];

  const latestEntry = processPayrollList[0];

  useEffect(() => {
    getProcessPayrollList(page, sizePerPage, "", "history", "yes");
  }, []);

  const handleStageChange = () => {
    setApiStages("process");
    setShowWaitModal(true);
    getAutoRefreshProcessPayrollList(page, sizePerPage, "", "process", "yes");
  };
  // const handleModalStageChange = () => {
  //   setApiStages("process");
  //   setShowWaitModal(true);
  //   setShowModal(false);
  //   getAutoRefreshProcessPayrollList(page, sizePerPage, "", "process", "yes");
  // };
  const getAutoRefreshProcessPayrollList = async (
    page,
    sizePerPage,
    search,
    apiData,
    validationType
  ) => {
    setLoading(true);
    let obj = {
      page: page,
      page_size: sizePerPage,
      search: search,
      stage: apiData,
      validation_type: validationType,
    };
    try {
      const res = await postData("get-payroll-history-new", {}, obj);
      if (res?.status === true) {
        setAuto_refresh(res?.auto_refresh);
        setCompletePayrollData(res);
        if (res?.is_pto_payweek === true) {
          setShowModal(true);
          setManagePtoData(res.data);
        }
        if (res?.auto_refresh === false) {
          setRunPayrollLink(res?.flow_url);
          setShowWaitModal(false);
          setPayrollErrors(res?.onboarding_errors);
          if (!res?.isfirstpayroll && !res.hasOwnProperty("flow_url")) {
            setErrSync(true);
          } else setErrSync(false);
        }
        setLoading(false);
        // }
      } else {
        toast.error(res?.message, { theme: "colored" });
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (apiStages === "process") {
      const interval = setInterval(() => {
        if (auto_refresh) {
          getAutoRefreshProcessPayrollList(
            page,
            sizePerPage,
            "",
            "history",
            "yes"
          );
        } else {
          clearInterval(interval);
        }
      }, 30000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [auto_refresh]);

  function convertTo12HourFormat(time) {
    const formattedTime = moment(time, "HH:mm:ss").format("hh:mm A");
    return formattedTime;
  }
  const backFunction = () => {
    if (completePayrollData?.data?.length !== 0) {
      getProcessPayrollList(page, sizePerPage, "", "history", "yes");
      setRunPayrollLink(null);
    } else {
      getProcessPayrollList(page, sizePerPage, "", "history", "yes");
      setRunPayrollLink(null);
    }
  };
  function formatDate(dateString) {
    const formattedDate = moment(dateString, "DD-MM-YYYY HH:mm:ss").format(
      "MM-DD-YYYY"
    );
    return formattedDate;
  }
  const handleModalCloseApi = () => {
    setShowModal(false);
    // setApiStages("process");
    setShowWaitModal(false);
    // getAutoRefreshProcessPayrollList(page, sizePerPage, "", "process", "no");
  };
  return (
    <>
      {!loading && (
        <Container fluid className="new_payroll">
          <div className="paystub-view">
            <Row>
              <Col md={getRevertUser() ? 10 : 12} sm={getRevertUser() ? 8 : 12}>
                <OnboardHeading title="Payroll" subtitle={companyName} />
              </Col>
              {getRevertUser() ? (
                <Col
                  md={2}
                  sm={getRevertUser() ? 4 : 2}
                  className="d-flex align-items-center justify-content-end mb-4 mb-md-0"
                >
                  <div className="revert">
                    <Button
                      variant="danger"
                      className="revert_btn"
                      onClick={handleRevertUser}
                    >
                      <img
                        src={revertIcon}
                        alt="revert"
                        className="revert-img me-1"
                      />
                      Revert User
                    </Button>
                  </div>
                </Col>
              ) : (
                ""
              )}
            </Row>
            {runPayrollLink && !auto_refresh && (
              <button
                className="d-flex mb-4 align-items-center back_btn"
                onClick={backFunction}
              >
                <img src={backIcon} alt="back_icon" className="back_icon" />
                <p className="m-0 p-0 f-16 fw-600">Back</p>
              </button>
            )}
            {completePayrollData?.onboarding_errors ? (
              <div className=" alert-card d-flex align-items-center flex-column justify-content-center">
                {/* <div className="alert-round">
                  <img
                    src={AlertIcon}
                    className="alert-icon"
                    alt="alert-icon"
                  />
                </div> */}
                <p className="alert-text m-0 p-0">Payroll Alert!</p>
                <hr className="alert-hr" />
                <div
                  dangerouslySetInnerHTML={{
                    __html: completePayrollData?.onboarding_errors,
                  }}
                ></div>
              </div>
            ) : completePayrollData?.isfirstpayroll ||
              (completePayrollData?.isfirstpayroll === false &&
                completePayrollData?.data?.length === 0 &&
                Object.keys(completePayrollData.payroll_logs_data).length !==
                  0 &&
                runPayrollLink === "") ||
              (completePayrollData?.isfirstpayroll === false &&
                completePayrollData?.data?.length === 0 &&
                Object.keys(completePayrollData.payroll_logs_data).length !==
                  0 &&
                runPayrollLink === null) ||
              (completePayrollData?.data?.length === 0 && auto_refresh) ? (
              <>
                {completePayrollData?.isfirstpayroll === false &&
                completePayrollData?.data?.length === 0 &&
                Object.keys(completePayrollData.payroll_logs_data).length !==
                  0 ? (
                  <div className="payroll-content mb-5 px-3">
                    <div className="initial-payroll py-3">
                      <div className="payroll-icon">
                        <img src={payrollIcon} alt="payroll-icon" />
                      </div>
                      <div className="payroll-title">Prepare payroll</div>
                      <div className="payroll-subtitle">
                        Click the button below to prepare your payroll for
                        processing.
                      </div>
                    </div>
                    <div className="payroll-instructions">
                      <div className="heading py-3 mb-2">
                        What happens when you click this button?
                      </div>
                      <ol>
                        <li>
                          Your hours and PTO are pulled in from timekeeping (if
                          you are using it).
                        </li>
                        <li>
                          Your taxes and salaries are calculated as appropriate.
                        </li>
                        <li>
                          Your payroll is started so you can get your people
                          paid!
                        </li>
                      </ol>
                    </div>
                    <div className="text-center py-3 pb-3">
                      <Buttons
                        type="button"
                        className="btn-primary text-white prepare-btn"
                        loading={loading}
                        disabled={auto_refresh}
                        onClick={handleStageChange}
                      >
                        Prepare payroll
                      </Buttons>
                    </div>
                    <div>
                      <Markup content={payrollErrors} />
                    </div>
                    {auto_refresh ? (
                      <div className="waiting-text pb-5 payroll-info-container">
                        <img
                          src={payrollInfoIcon}
                          alt="payroll_icon"
                          className="me-2"
                        />
                        <p>
                          Payroll is processing. Please don’t refresh your
                          screen. This may take a minute.
                        </p>
                      </div>
                    ) : null}
                    {!auto_refresh ? (
                      <div className="heading f-18 w-100 text-center pb-3 text-primary">
                        Your payroll is successfully prepared. You can proceed
                        to process and run it now.
                      </div>
                    ) : null}
                  </div>
                ) : (
                  <div className="payroll-content mb-5 px-3">
                    <div className="initial-payroll py-3">
                      <div className="payroll-icon">
                        <img src={payrollIcon} alt="payroll-icon" />
                      </div>
                      <div className="payroll-title">Prepare payroll</div>
                      <div className="payroll-subtitle">
                        Click the button below to prepare your payroll for
                        processing.
                      </div>
                    </div>
                    <div className="payroll-instructions">
                      <div className="heading py-3 mb-2">
                        What happens when you click this button?
                      </div>
                      <ol>
                        <li>
                          Your hours and PTO are pulled in from timekeeping (if
                          you are using it).
                        </li>
                        <li>
                          Your taxes and salaries are calculated as appropriate.
                        </li>
                        <li>
                          Your payroll is started so you can get your people
                          paid!
                        </li>
                      </ol>
                    </div>
                    <div className="text-center py-3 pb-3">
                      <Buttons
                        type="button"
                        className="btn-primary text-white prepare-btn"
                        loading={loading}
                        disabled={auto_refresh}
                        onClick={handleStageChange}
                      >
                        Prepare payroll
                      </Buttons>
                    </div>
                    <div>
                      <Markup content={payrollErrors} />
                    </div>
                    {auto_refresh ? (
                      <div className="waiting-text pb-5">
                        <img
                          src={payrollInfoIcon}
                          alt="payroll_icon"
                          className="me-2"
                        />
                        Payroll is processing. Please don’t refresh your screen.
                        This may take a minute.
                      </div>
                    ) : null}
                  </div>
                )}
              </>
            ) : (
              <>
                <div className="exiting-payroll mb-5 px-1">
                  <div className="header mb-3">
                    <Row>
                      <Col md={12}>
                        <Card>
                          <Card.Header>Prepare payroll</Card.Header>
                          <Card.Body>
                            <Card.Text className=" m-0">
                              <div className="d-flex w-100 align-items-center m-0 justify-content-space-between mb-6">
                                {processPayrollList.length ? (
                                  <div className="heading f-18 w-75">
                                    Payroll preparation was started by
                                    <span>
                                      &nbsp;{latestEntry?.name}&nbsp;
                                    </span>{" "}
                                    on
                                    <span>
                                      &nbsp;
                                      {formatDate(
                                        latestEntry?.payroll_processing_date
                                      )}
                                      &nbsp;
                                    </span>
                                    . Do you want to start the preparation
                                    process again?
                                  </div>
                                ) : (
                                  <p className="p-0 m-0 heading f-18">
                                    Your payroll is successfully prepared. You
                                    can proceed to process and run it now.
                                  </p>
                                )}
                                <Buttons
                                  type="button"
                                  className="btn-primary text-white float-right prepare-btn"
                                  disabled={auto_refresh}
                                  loading={loading}
                                  onClick={handleStageChange}
                                >
                                  Prepare payroll
                                </Buttons>
                              </div>
                            </Card.Text>
                            {auto_refresh && (
                              <div className="waiting-text text-left payroll-data-info-container">
                                <img
                                  src={payrollInfoIcon}
                                  alt="payroll_icon"
                                  className="me-2"
                                />
                                {/* <ApiLoader/> */}
                                Payroll is processing. This may take under few
                                mins please wait!
                              </div>
                            )}
                          </Card.Body>
                        </Card>
                      </Col>
                    </Row>
                  </div>
                </div>
                {runPayrollLink ? (
                  <RunPayroll
                    name={"Run Payroll"}
                    runPayrollLink={runPayrollLink}
                  />
                ) : (
                  <>
                    <h4 className="payroll-add-title my-2">Process History </h4>
                    <div className="process-payroll my-3">
                      <ServerSideTable
                        columns={columns}
                        data={processPayrollList}
                        page={page}
                        sizePerPage={sizePerPage}
                        totalSize={totalSize}
                        onFilter={onFilter}
                        loading={loading}
                      ></ServerSideTable>
                    </div>
                  </>
                )}
              </>
            )}
            {/* modal contents */}
            {!showModal && (
              <>
                {showWaitModal && (
                  <FormModal
                    heading=""
                    show={showWaitModal}
                    size="md"
                    onClose={handleShowWaitModalClose}
                    backdrop="static"
                    modalClassName="personal_container_modal waiting_modal"
                  >
                    <Row>
                      <Col md={12}>
                        <div className="showWait">
                          {/* <div>
                        <img src={loadingIcon} alt="wait" />
                      </div> */}
                          <ApiLoader />

                          <div>Payroll is processing. </div>
                          <div>
                            Please don’t refresh your screen. This may take a
                            minute.
                          </div>
                          <Buttons
                            type="button"
                            className="btn-primary text-white prepare-btn my-3"
                            onClick={() => handleShowWaitModalClose()}
                          >
                            OK
                          </Buttons>
                        </div>
                      </Col>
                    </Row>
                  </FormModal>
                )}
              </>
            )}
            {/* error prepare btn modal */}
            {(showErrorModal || errSync) && (
              <FormModal
                heading=""
                show={showErrorModal || errSync}
                size="md"
                onClose={handleShowErrortModalClose}
                backdrop="static"
                modalClassName="personal_container_modal waiting_modal"
              >
                <Row>
                  <Col md={12}>
                    <div className="show-error-conetnt">
                      <div>
                        <img
                          src={errorIcon}
                          alt="payroll_icon"
                          className="me-2"
                        />
                      </div>
                      <div className="py-3">
                        {errSync
                          ? "An error occurred while processing the payroll. Kindly attempt the operation again. "
                          : "An error occurred while processing the payroll. Kindly attempt the operation again."}
                      </div>
                    </div>
                  </Col>
                  <Col md={12} className="text-center my-3">
                    <Button
                      type="button"
                      className="btn-default text-blacksix me-3 cancel-payroll"
                      onClick={handleShowErrortModalClose}
                    >
                      {errSync ? "Close" : "Cancel"}
                    </Button>
                    {showErrorModal && (
                      <Button
                        type="submit"
                        className="btn-primary text-white prepare-btn"
                        disabled={loading || auto_refresh}
                        loading={loading}
                      >
                        Prepare payroll
                      </Button>
                    )}
                  </Col>
                </Row>
              </FormModal>
            )}

            {/* error wait modal */}
            {showErrorWaitingModal && (
              <FormModal
                heading=""
                show={showErrorWaitingModal}
                size="md"
                onClose={handleShowErrorWaitingtModalClose}
                backdrop="static"
                modalClassName="personal_container_modal waiting_modal"
              >
                <Row>
                  <Col md={12}>
                    <div className="show-error-conetnt">
                      <div>
                        <img
                          src={errorIcon}
                          alt="payroll_icon"
                          className="me-2"
                        />
                      </div>
                      <div className="py-3">
                        {" "}
                        An error occurred while processing the payroll. Kindly
                        attempt the operation again.
                      </div>
                    </div>
                  </Col>
                  <Col md={12} className="text-center">
                    <Buttons
                      type="button"
                      className="btn-primary text-white prepare-btn my-3"
                      onClick={() => handleShowErrorWaitingtModalClose()}
                    >
                      OK
                    </Buttons>
                  </Col>
                </Row>
              </FormModal>
            )}
            {/* <div className="payroll-gusto-pto-modal-wrapper"> */}
            <Modal
              show={showModal}
              onHide={() => setShowModal(false)}
              className="modal-email-wrapper payroll-gusto-pto-modal-wrapper"
            >
              <Modal.Header className="modal-manage-header">
                <h3>Manage pending requests!</h3>
                <img
                  src={CloseIcon}
                  alt="close-icon"
                  onClick={handleModalCloseApi}
                />
              </Modal.Header>
              <Modal.Body className="manage-payroll-modal">
                <p>
                  To ensure accurate PTO balances, please manage the pending
                  time off requests before processing payroll
                </p>
                <div className="manage-pop-content">
                  <div className="manage-pop-sub-wrapper">
                    <div className="manage-pop-title">
                      <img src={ManagePop} alt="manage-pop" />
                      <h5>Manage time card request</h5>
                    </div>
                    {managePtoData?.is_timecard === true ? (
                      <div className="manage-pop-incomplete">
                        <a href="/multi-card-approval">
                          <h5>Go to time keeping</h5>
                          <img src={UpArrowIcon} alt="check-circle" />
                        </a>
                      </div>
                    ) : (
                      <div className="manage-pop-completed">
                        <h5>Completed</h5>
                        <img src={CheckCircle} alt="check-circle" />
                      </div>
                    )}
                  </div>
                  <div className="manage-pop-sub-wrapper">
                    <div className="manage-pop-title">
                      <img src={ManagePop} alt="manage-pop" />
                      <h5>Manage time off request</h5>
                    </div>
                    {managePtoData?.is_pto === true ? (
                      <div className="manage-pop-incomplete">
                        <a href="/time-off-calender">
                          <h5>Go to time off</h5>
                          <img src={UpArrowIcon} alt="check-circle" />
                        </a>
                      </div>
                    ) : (
                      <div className="manage-pop-completed">
                        <h5>Completed</h5>
                        <img src={CheckCircle} alt="check-circle" />
                      </div>
                    )}
                  </div>
                </div>
              </Modal.Body>
              {/* <Modal.Footer className="modal-payroll-manage-footer">
                <Button
                  variant="primary"
                  onClick={
                    managePtoData?.is_pto === true ||
                    managePtoData?.is_timecard === true
                      ? null
                      : handleModalStageChange
                  }
                  className="modal-manage-payroll-button"
                  disabled={
                    managePtoData?.is_pto === true ||
                    managePtoData?.is_timecard === true
                  }
                >
                  PREPARE PAYROLL
                </Button>
              </Modal.Footer> */}
            </Modal>
          </div>
          {/* </div> */}
        </Container>
      )}
      {loading && <Loader />}
    </>
  );
};

export default Payroll;
