import React from "react";
import "./style.scss";
import OnboardHeading from "../OnboardHeading";
import { useNavigate } from "react-router-dom";

export default function TitleSection(props) {
  const { isGustoUser, groupId } = props;
  const navigate = useNavigate();
  const handleAddCustomReport = () => {
    navigate("/report-detail");
  };

  return (
    <div className="reports-title-wrapper">
      <OnboardHeading title="REPORTS" />
      {(isGustoUser === 1 && groupId !== 4) ||
      groupId === 1 ||
      groupId === 6 ? (
        <button className="custom-btn-wrapper" onClick={handleAddCustomReport}>
          ADD CUSTOM REPORT
        </button>
      ) : null}
    </div>
  );
}
