import React from "react";
import { Modal } from "react-bootstrap";
// import CloseButton from "react-bootstrap/CloseButton";
import "../FormModal/style.scss";
import "./style.scss";

export default function PayPeriodModal(props) {
  const {
    show,
    onClose,
    size,
    modalClassName,
    className,
    modalData,
    setIsModalOpen,
  } = props;
  const handleClose = () => {
    setIsModalOpen(false);
  };
  return (
    <div>
      <Modal
        show={show}
        centered
        onHide={onClose}
        size={size}
        className={modalClassName}
      >
        <Modal.Header className="modal-pay-cus-header">
          <div className="form-modal">
            <Modal.Title className="modal-title-cus">
              {modalData.userName
                ? `Are you sure you want to ${modalData.data} ${modalData.userName}'s time cards?`
                : `Are you sure you want to ${modalData.data} time cards?`}
            </Modal.Title>
          </div>
        </Modal.Header>
        <Modal.Body className="modal-pay-cus-body">
          <div className="modal-btn-wrapper d-flex button-gap justify-content-center">
            <button
              className="deny-all-button deny-active-btn"
              onClick={handleClose}
            >
              Cancel
            </button>
            <button
              className="approve-all-button active-btn"
              onClick={handleClose}
            >
              Confirm
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
