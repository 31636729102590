import React from "react";
import PayRecordIcon from "../../assets/images/timekeeping_icons/payRecord.svg";
import "./style.scss";

export default function PayRecord() {
  return (
    <div className="payrecord-container">
      <div className="title-wrapper">
        <img src={PayRecordIcon} alt="pay-record" />
        <h5>PAYRECORD</h5>
      </div>
      <div className="subtitle-wrapper">
        <h3>Approve or deny the time cards of the employees </h3>
        <div className="d-flex justify-content-between card-section-wrapper">
          <div className="d-flex gap-class card-sub-section-wrapper">
            <div className="request-cards-wrapper total-cards ">
              <p>Total requests</p>
              <h5>200</h5>{" "}
            </div>
            <div className="request-cards-wrapper pending-cards">
              <p>Pending request</p>
              <h5>120</h5>{" "}
            </div>
            <div className="request-cards-wrapper approved-cards">
              <p>Approved request</p>
              <h5>80</h5>{" "}
            </div>
          </div>
          <div>
            <button className="btn btn-primary button-text-wrapper">
              View all payperiods
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
