import React from "react";
import SuccessPage from "../../components/EmployeeTwoFactorAuth/successPage";

export default function OtpSuccess() {
  return (
    <div>
      <SuccessPage />
    </div>
  );
}
