import React from "react";
import WarningIcon from "../../assets/images/timekeeping_icons/warning_icon.svg";

export default function ReportsCSVTable(props) {
  const {
    tableData,
    startIndex,
    endIndex,
    rowsPerPage,
    currentPage,
    totalPages,
    handlePageChange,
    handleRowsPerPageChange,
  } = props;

  return (
    <>
      <div className="reports-download-scroll-wrapper">
        <table className="report-table reports-download-table-scroll-wrapper">
          <thead>
            <tr className="reports-download-table-header">
              {tableData.headings.map((heading, index) => (
                <th key={index}>{heading}</th>
              ))}
            </tr>
          </thead>
          {tableData.tableArrayData.length > 0 ? (
            <tbody>
              {tableData.tableArrayData
                .slice(startIndex, endIndex)
                .map((data, index) => (
                  <tr key={index}>
                    {tableData.headings.map((heading, headerIndex) => (
                      <td key={headerIndex}>{data[heading]}</td>
                    ))}
                  </tr>
                ))}
              {tableData?.grandTotalRow && (
                <tr>
                  {tableData?.grandTotalRow?.map((value, index) => (
                    <td key={index}>{value}</td>
                  ))}
                </tr>
              )}
            </tbody>
          ) : (
            <tbody>
              <tr>
                <td colSpan={12}>
                  <div className="no-data-text">
                    <img src={WarningIcon} alt="warning" />
                    <h5>No data yet.</h5>
                  </div>
                </td>
              </tr>
            </tbody>
          )}
        </table>
      </div>
      {tableData.tableArrayData.length > rowsPerPage && (
        <div className="d-flex justify-content-between py-3 px-3">
          <div>
            <select value={rowsPerPage} onChange={handleRowsPerPageChange}>
              <option value={10}>10</option>
              <option value={25}>25</option>
              <option value={30}>30</option>
              <option value={50}>50</option>
            </select>
          </div>
          <div>
            {currentPage !== 0 && (
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                className="non-active-pagination-btn"
              >
                &lt;
              </button>
            )}
            {Array.from({ length: totalPages }, (_, i) => i).map((page) => (
              <button
                key={page}
                disabled={currentPage === page}
                onClick={() => handlePageChange(page)}
                className={
                  currentPage === page
                    ? "active-pagination-btn "
                    : "non-active-pagination-btn"
                }
              >
                {page + 1}
              </button>
            ))}
            {currentPage !== totalPages - 1 && (
              <button
                disabled={currentPage === totalPages - 1}
                onClick={() => handlePageChange(currentPage + 1)}
                className="non-active-pagination-btn"
              >
                &gt;
              </button>
            )}
          </div>
        </div>
      )}
    </>
  );
}
