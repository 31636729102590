import React from "react";
import "./style.scss";
import nofoundImg from "../../assets/images/nofound_page.svg";

const NotFound = () => {
  return (
    <div className="not-found-container">
      <img src={nofoundImg} alt="nofound image" className="not-found-image" />
    </div>
  );
};

export default NotFound;
