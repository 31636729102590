import React from "react";
import EmployeeTwoFactorAuthComp from "../../components/EmployeeTwoFactorAuth";

export default function EmployeeTwoFactorAuthPage() {
  return (
    <div>
      <EmployeeTwoFactorAuthComp />
    </div>
  );
}
