import React, { useState } from "react";
import {  Row, Col } from "react-bootstrap";
import TablePagination from "./tablePagination";
import documentIconSvg from "../../../assets/images/paytubIcon.svg"
import "./index.scss";

const PaysTub = ({ userid, paystubTabData, w2TabData }) => {
  const [showPaystub, setShowPaystub] = useState(true);
  const [showW2, setShowW2] = useState(false);
  const [paystubData, setPaystubData] = useState(paystubTabData);
  const [w2Data, setW2Data] = useState(w2TabData);

  const valueCallback = (value) => {};
  return (
    <div className="paystub_container mb-3">
      <div>
        <Row className="mb-3">
          <Col className="d-flex align-items-center">
                <h5 className="tabs_main_heading">
                  <span className="me-1">
                    <img src={documentIconSvg} alt="icon"/>
                  </span>
                  Paystub/W2
                </h5>
          </Col>
        </Row>
        <Row>
          <Col lg={4} className="d-flex">
            <div
              className={`paystub_btn  ${showPaystub ? "active" : ""}`}
              onClick={() => {
                setShowPaystub(true);
                setShowW2(false);
              }}
            >
              Paystub
            </div>
            <div
              className={`paystub_btn  ${showW2 ? "active" : ""}`}
              onClick={() => {
                setShowPaystub(false);
                setShowW2(true);
              }}
            >
              W2
            </div>
          </Col>
        </Row>
        {showPaystub && (
          <Row>
            <Col>
             <TablePagination
                data={paystubData}
                onClickHandler={valueCallback}
                type={"paystub"}
              />
            </Col>
          </Row>
        )}
        {showW2 &&  (
          <Row>
            <Col>
              <TablePagination data={w2Data} onClickHandler={valueCallback} type={"w2"} />
            </Col>
          </Row>
        )}
      </div>
    </div>
  );
};

export default PaysTub;
