import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Form,
  Tooltip,
  OverlayTrigger,
  Modal,
  Table,
} from "react-bootstrap";
import AssesmentIcon from "../../../assets/images/assesment_icon.svg";
import "./style.scss";
import Loader from "../../../components/Loader";
import { postData, postUploadData } from "../../../api";
import { toast } from "react-toastify";
import Button from "../../../components/Form/Button";
import {autocloseTiming} from '../../../api/regex';

const AssesmentsTab = ({ assessmentProfileData, userid, clientRole }) => {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [validated, setValidated] = useState(false);
  const [assessmentsList, setAssessmentsList] = useState([]);
  const [modalShow, setModalShow] = useState(false)


  const modalOpen = () => {
    setModalShow(true)
  }

  const modalClose = () => {
    setModalShow(false)
  }


  useEffect(() => {
    if (assessmentProfileData) {
      setAssessmentsList(assessmentProfileData);
    }
  }, [assessmentProfileData]);

  const handleInput = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setAssessmentsList({
      ...assessmentsList,
      [name]: value,
    });
    if (!!errors[name])
      setErrors({
        ...errors,
        [name]: null,
      });
  };


  const handleOnchangeInput = (e, q_index) => {
    let copyAssessmentsListData = { ...assessmentsList };
    copyAssessmentsListData.data.employeeQuestions[q_index].user_answer = {}
    if (copyAssessmentsListData.data.employeeQuestions[q_index].user_answer) {
      copyAssessmentsListData.data.employeeQuestions[q_index].user_answer.answer = e.target.value;
    }
    setAssessmentsList(copyAssessmentsListData);
  }

  const handleOnchangeManagerInput = (e, q_index) => {
    let copyAssessmentsListData = { ...assessmentsList };
    copyAssessmentsListData.data.managerQuestions[q_index].user_answer = {}
    if (copyAssessmentsListData.data.managerQuestions[q_index].user_answer) {
      copyAssessmentsListData.data.managerQuestions[q_index].user_answer.answer = e.target.value;
    }
    setAssessmentsList(copyAssessmentsListData);
  }


  useEffect(() => {
    if (assessmentsList?.review_date !== null && assessmentsList?.review_date !== '' && assessmentsList?.review_date !== undefined) {
      assessmentDetails();
    }
  }, [assessmentsList?.review_date,userid]);// eslint-disable-line react-hooks/exhaustive-deps

  const assessmentDetails = async () => {
    setLoading(true);
    let data = {
      user_id: userid,
      date: assessmentsList?.review_date,
      type: 'view',
      stage: 'assessments'

    }
    const res = await postData("profile", {}, data);
    if (res.status === true) {
      setLoading(false);
      setAssessmentsList(res?.data)
      toast.success(res.message, { theme: "colored" ,autoClose: autocloseTiming });
    } else {
       toast.error(res.message, { theme: "colored", autoClose: autocloseTiming });;
      setLoading(false);
    }
  }
  const toolTipData = [
    {
      title: "Level 1:Consistency below Expectations",
      desc: "Performance is below expectations for most asepects of the job",
    },
    {
      title: "Level 2: Occasionally Below Expectations",
      desc: "Performance occasionally does not meet expectations for some aspects of the job. Objectives occasionally are not met or are met with a minimum level of acceptability.",
    },
    {
      title: "Level 3: Consistently Meets Expectations",
      desc: "Performance fully meets the expectations for all aspects of the job. The employee's performance is consistent, reliable, and effective. Employee is able to accomplish all established objectives.",
    },
    {
      title: "Level 4:Frequently Exceeds Expectations",
      desc: "Performance frequently exceeds the expectations for most aspects of the job on a sustained basis. Employee performs complex, difficult parts of the job competently and thoroughly, including extra or unique tasks assigned.",
    },
    {
      title: "Level 5:Consistently Surpasses Expectations",
      desc: "Employee excels in virtually all aspects of the job, having nearly reached the ultimate in job performance on a sustained basis. Performance is of a quality rarely achieved.",
    },
  ];

  const tooltip_1 = (
    <Tooltip id="tooltip">
      <strong>
        {toolTipData[0].desc}
      </strong>
    </Tooltip>
  );
  const tooltip_2 = (
    <Tooltip id="tooltip">
      <strong>
        {toolTipData[1].desc}
      </strong>
    </Tooltip>
  );
  const tooltip_3 = (
    <Tooltip id="tooltip">
      <strong>
        {toolTipData[2].desc}
      </strong>
    </Tooltip>
  );
  const tooltip_4 = (
    <Tooltip id="tooltip">
      <strong>
        {toolTipData[3].desc}
      </strong>
    </Tooltip>
  );
  const tooltip_5 = (
    <Tooltip id="tooltip">
      <strong>
        {toolTipData[4].desc}
      </strong>
    </Tooltip>
  );

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
    if (form.checkValidity() === true) {
      let empdata = new FormData();
      empdata.append('stage', 'assessments');
      empdata.append('user_id', userid);
      assessmentsList?.data?.employeeQuestions.forEach((item, i) => {
        empdata.append('data[' + (i) + ']' + '[question_id]', item?.id);
        empdata.append('data[' + (i) + ']' + '[initial_review_date]', assessmentsList?.review_date);
        empdata.append('data[' + (i) + ']' + '[answer]', item?.user_answer?.answer);
        empdata.append('data[' + (i) + ']' + '[type]', item?.type);
        empdata.append('data[' + (i) + ']' + '[question]', item?.question);
      });
      employeeSave(empdata)
    }
  };

  const handleManagerSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
    if (form.checkValidity() === true) {
      let empdata = new FormData();
      empdata.append('stage', 'assessments');
      empdata.append('user_id', userid);
      assessmentsList?.data?.managerQuestions.forEach((item, i) => {
        empdata.append('data[' + (i) + ']' + '[question_id]', item?.id);
        empdata.append('data[' + (i) + ']' + '[initial_review_date]', assessmentsList?.review_date);
        empdata.append('data[' + (i) + ']' + '[answer]', item?.user_answer?.answer);
        empdata.append('data[' + (i) + ']' + '[type]', item?.type);
        empdata.append('data[' + (i) + ']' + '[question]', item?.question);
      });
      employeeSave(empdata)
    }
  }
  const employeeSave = async (data) => {
    setLoading(true);


    const res = await postUploadData("profile", {}, data);
    if (res.status === true) {
      setLoading(false);
      toast.success(res.message, { theme: "colored" ,autoClose: autocloseTiming });
      setAssessmentsList(res?.data);
      setValidated(false)
    } else {
       toast.error(res.message, { theme: "colored", autoClose: autocloseTiming });;
      setLoading(false);
    }
  }
  return (
    <>
      {!loading && (
        <div className="assesment_container">
          <>
            <Row className="mb-3">
              <Col className="d-flex align-items-center">
                <h5 className="tabs_main_heading">
                  <span className="me-1">
                    <img src={AssesmentIcon} alt="" srcSet="" />
                  </span>
                  Assessments
                </h5>
              </Col>
            </Row>
            {
              assessmentsList?.periods?.length !== 0 ?
                <>
                  <Row>
                    <Col lg={6}>
                      <a href="#" onClick={modalOpen} rel="noopener noreferrer">Click here to view 1-5 rating scale details</a>
                    </Col>
                    <Col lg={6}>
                      <Row>
                        <Col lg={6}></Col>
                        <Col lg={6}>
                          <Row>
                            <p className="mb-1 select_year_font">Select the year</p>
                            <Col lg={12}>
                              <Form.Group className="mb-3" controlId="review_date">
                                <Form.Select
                                  onChange={handleInput}
                                  name="review_date"
                                  value={assessmentsList?.review_date}
                                  required
                                  size="lg"
                                >
                                  <option value="">Select year</option>
                                  {assessmentsList?.periods &&
                                    assessmentsList?.periods.map((item, index) => (
                                      <option key={item.date} value={item.date}>
                                        {item.date}
                                      </option>
                                    ))}
                                </Form.Select>
                              </Form.Group>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  {assessmentsList?.data?.employeeQuestions.length !== 0 ? <Row className="assesment_table_container pb-4">
                    <Col
                      xs={12}
                      lg={assessmentsList?.manager_assessment !== true ? 12 : 6}
                    >
                      <Row
                        className={
                          assessmentsList?.self_assessment === true
                            ? "d-block"
                            : "d-hide"
                        }
                      >
                        <Col xs={12} lg={12} className="assesment_header">
                          Self Assessments
                        </Col>
                      </Row>
                      <div
                        className={
                          assessmentsList?.self_assessment === true
                            ? "d-block"
                            : "d-hide"
                        }
                      >
                        <Form
                          noValidate
                          validated={validated}
                          onSubmit={handleSubmit}
                        >
                          <Row className="custom_assesment_row">
                            <Col xs={12} lg={12}>
                              {assessmentsList?.data?.employeeQuestions.map(function (
                                item,
                                q_index
                              ) {
                                return (
                                  <div key={q_index}>
                                    <Form.Group>
                                      <Form.Label className="title">
                                        {item?.question} <span className="asterisk">*</span>
                                      </Form.Label>
                                      {item?.type === "1-5" ? (
                                        <>
                                          <Form.Group
                                            controlId={`user-answer-${q_index}`}
                                          >
                                            <div className="d-flex form_radio_btn_container mb-2">
                                              <OverlayTrigger
                                                placement="bottom"
                                                overlay={tooltip_1}
                                              >
                                                <Form.Check
                                                  type="radio"
                                                  id="default-radio"
                                                  className="me-4 radio_bdr_clr"
                                                  label="1"
                                                  disabled={
                                                    assessmentsList?.data
                                                      ?.employee_view_only
                                                      ? true
                                                      : false
                                                  }
                                                  checked={
                                                    item?.user_answer?.answer == "1"
                                                  }
                                                  value="1"
                                                  name={"answerQuestion" + item.id}
                                                  onChange={(e) =>
                                                    handleOnchangeInput(e, q_index)
                                                  }
                                                  required
                                                />
                                              </OverlayTrigger>
                                              <OverlayTrigger
                                                placement="bottom"
                                                overlay={tooltip_2}
                                              >
                                                <Form.Check
                                                  type="radio"
                                                  id="default-radio"
                                                  className="me-4 radio_bdr_clr"
                                                  label="2"
                                                  disabled={
                                                    assessmentsList?.data
                                                      ?.employee_view_only
                                                      ? true
                                                      : false
                                                  }
                                                  checked={
                                                    item?.user_answer?.answer == "2"
                                                  }
                                                  value="2"
                                                  name={"answerQuestion" + item.id}
                                                  onChange={(e) =>
                                                    handleOnchangeInput(e, q_index)
                                                  }
                                                  required
                                                />
                                              </OverlayTrigger>
                                              <OverlayTrigger
                                                placement="bottom"
                                                overlay={tooltip_3}
                                              >
                                                <Form.Check
                                                  type="radio"
                                                  id="default-radio"
                                                  className="me-4 radio_bdr_clr"
                                                  label="3"
                                                  disabled={
                                                    assessmentsList?.data
                                                      ?.employee_view_only
                                                      ? true
                                                      : false
                                                  }
                                                  checked={
                                                    item?.user_answer?.answer == "3"
                                                  }
                                                  value="3"
                                                  name={"answerQuestion" + item.id}
                                                  onChange={(e) =>
                                                    handleOnchangeInput(e, q_index)
                                                  }
                                                  required
                                                />
                                              </OverlayTrigger>
                                              <OverlayTrigger
                                                placement="bottom"
                                                overlay={tooltip_4}
                                              >
                                                <Form.Check
                                                  type="radio"
                                                  id="default-radio"
                                                  className="me-4 radio_bdr_clr"
                                                  label="4"
                                                  disabled={
                                                    assessmentsList?.data
                                                      ?.employee_view_only
                                                      ? true
                                                      : false
                                                  }
                                                  checked={
                                                    item?.user_answer?.answer == "4"
                                                  }
                                                  value="4"
                                                  name={"answerQuestion" + item.id}
                                                  onChange={(e) =>
                                                    handleOnchangeInput(e, q_index)
                                                  }
                                                  required
                                                />
                                              </OverlayTrigger>
                                              <OverlayTrigger
                                                placement="bottom"
                                                overlay={tooltip_5}
                                              >
                                                <Form.Check
                                                  type="radio"
                                                  id="default-radio"
                                                  className="me-4 radio_bdr_clr"
                                                  label="5"
                                                  name={"answerQuestion" + item.id}
                                                  disabled={
                                                    assessmentsList?.data
                                                      ?.employee_view_only
                                                      ? true
                                                      : false
                                                  }
                                                  checked={
                                                    item?.user_answer?.answer == "5"
                                                  }
                                                  value="5"
                                                  onChange={(e) =>
                                                    handleOnchangeInput(e, q_index)
                                                  }
                                                  required
                                                />
                                              </OverlayTrigger>
                                            </div>
                                            {
                                              validated && item?.user_answer?.answer === undefined &&
                                              <div className="err-feedback">This field is required</div>
                                            }
                                          </Form.Group>
                                        </>
                                      ) : (
                                        <>
                                          <div>
                                            <Form.Group
                                              controlId={`user-answer-${q_index}`}
                                            >
                                              <Form.Control
                                                as="textarea"
                                                value={
                                                  assessmentsList?.data
                                                    ?.employee_view_only === true &&
                                                    item?.user_answer == null
                                                    ? "NA"
                                                    : item?.user_answer?.answer
                                                }
                                                placeholder="Leave a comment here"
                                                rows={4}
                                                className="mb-2"
                                                onChange={(e) =>
                                                  handleOnchangeInput(e, q_index)
                                                }
                                                disabled={
                                                  assessmentsList?.data
                                                    ?.employee_view_only
                                                    ? true
                                                    : false
                                                }
                                                required
                                              />
                                              <Form.Control.Feedback type="invalid">
                                                This field is required
                                              </Form.Control.Feedback>
                                            </Form.Group>
                                          </div>
                                        </>
                                      )}
                                    </Form.Group>
                                  </div>
                                );
                              })}
                            </Col>
                          </Row>
                          {assessmentsList?.data?.employee_view_only === false && (
                            <div className="col-md-12 text-end mt-2">
                              <Button
                                type="submit"
                                className="btn-primary text-white"
                              >
                                Save
                              </Button>
                            </div>
                          )}
                        </Form>
                      </div>
                    </Col>
                    <Col xs={12} lg={6}>
                      <Row
                        className={
                          assessmentsList?.manager_assessment === true
                            ? "d-block"
                            : "d-hide"
                        }
                      >
                        <Col xs={12} lg={12} className="assesment_header white_bg">
                          Manager Assessments
                        </Col>
                      </Row>
                      <div
                        className={
                          assessmentsList?.manager_assessment === true
                            ? "d-block"
                            : "d-hide"
                        }
                      >
                        <Form
                          noValidate
                          validated={validated}
                          onSubmit={handleManagerSubmit}
                        >
                          <Row className="custom_assesment_row">
                            <Col xs={12} lg={12}>
                              {assessmentsList?.data?.managerQuestions.map(function (
                                item,
                                q_index
                              ) {
                                return (
                                  <div key={q_index}>
                                    <Form.Group>
                                      <Form.Label className="title">
                                        {item?.question}
                                        <span className="asterisk">*</span>
                                      </Form.Label>
                                      {item?.type === "1-5" ? (
                                        <>
                                          <Form.Group
                                            controlId={`maanager-answer-${q_index}`}
                                          >
                                            <div className="d-flex form_radio_btn_container mb-2">
                                              <OverlayTrigger
                                                placement="bottom"
                                                overlay={tooltip_1}
                                              >
                                                <Form.Check
                                                  type="radio"
                                                  id="default-radio"
                                                  className="me-4 radio_bdr_clr"
                                                  label="1"
                                                  disabled={
                                                    assessmentsList?.data
                                                      ?.manager_view_only
                                                      ? true
                                                      : false
                                                  }
                                                  checked={
                                                    item?.user_answer?.answer === "1"
                                                  }
                                                  value="1"
                                                  onChange={(e) =>
                                                    handleOnchangeManagerInput(
                                                      e,
                                                      q_index
                                                    )
                                                  }
                                                  name={"answerQuestion" + item.id}
                                                  required
                                                />
                                              </OverlayTrigger>
                                              <OverlayTrigger
                                                placement="bottom"
                                                overlay={tooltip_2}
                                              >
                                                <Form.Check
                                                  type="radio"
                                                  id="default-radio"
                                                  className="me-4 radio_bdr_clr"
                                                  label="2"
                                                  disabled={
                                                    assessmentsList?.data
                                                      ?.manager_view_only
                                                      ? true
                                                      : false
                                                  }
                                                  checked={
                                                    item?.user_answer?.answer === "2"
                                                  }
                                                  value="2"
                                                  onChange={(e) =>
                                                    handleOnchangeManagerInput(
                                                      e,
                                                      q_index
                                                    )
                                                  }
                                                  name={"answerQuestion" + item.id}
                                                  required
                                                />
                                              </OverlayTrigger>
                                              <OverlayTrigger
                                                placement="bottom"
                                                overlay={tooltip_3}
                                              >
                                                <Form.Check
                                                  type="radio"
                                                  id="default-radio"
                                                  className="me-4 radio_bdr_clr"
                                                  label="3"
                                                  disabled={
                                                    assessmentsList?.data
                                                      ?.manager_view_only
                                                      ? true
                                                      : false
                                                  }
                                                  checked={
                                                    item?.user_answer?.answer === "3"
                                                  }
                                                  value="3"
                                                  onChange={(e) =>
                                                    handleOnchangeManagerInput(
                                                      e,
                                                      q_index
                                                    )
                                                  }
                                                  name={"answerQuestion" + item.id}
                                                  required
                                                />
                                              </OverlayTrigger>
                                              <OverlayTrigger
                                                placement="bottom"
                                                overlay={tooltip_4}
                                              >
                                                <Form.Check
                                                  type="radio"
                                                  id="default-radio"
                                                  className="me-4 radio_bdr_clr"
                                                  label="4"
                                                  disabled={
                                                    assessmentsList?.data
                                                      ?.manager_view_only
                                                      ? true
                                                      : false
                                                  }
                                                  checked={
                                                    item?.user_answer?.answer === "4"
                                                  }
                                                  value="4"
                                                  onChange={(e) =>
                                                    handleOnchangeManagerInput(
                                                      e,
                                                      q_index
                                                    )
                                                  }
                                                  name={"answerQuestion" + item.id}
                                                  required
                                                />
                                              </OverlayTrigger>
                                              <OverlayTrigger
                                                placement="bottom"
                                                overlay={tooltip_5}
                                              >
                                                <Form.Check
                                                  type="radio"
                                                  id="default-radio"
                                                  className="me-4 radio_bdr_clr"
                                                  label="5"
                                                  disabled={
                                                    assessmentsList?.data
                                                      ?.manager_view_only
                                                      ? true
                                                      : false
                                                  }
                                                  checked={
                                                    item?.user_answer?.answer === "5"
                                                  }
                                                  value="5"
                                                  onChange={(e) =>
                                                    handleOnchangeManagerInput(
                                                      e,
                                                      q_index
                                                    )
                                                  }
                                                  name={"answerQuestion" + item.id}
                                                  required
                                                />
                                              </OverlayTrigger>
                                            </div>
                                            {
                                              validated && item?.user_answer?.answer === undefined &&
                                              <div className="err-feedback">This field is required</div>
                                            }
                                          </Form.Group>
                                        </>
                                      ) : (
                                        <>
                                          <div>
                                            <Form.Group
                                              controlId={`maanager-answer-${q_index}`}
                                            >
                                              <Form.Control
                                                as="textarea"
                                                value={item?.user_answer?.answer}
                                                placeholder="Leave a comment here"
                                                rows={4}
                                                className="mb-2"
                                                onChange={(e) =>
                                                  handleOnchangeManagerInput(
                                                    e,
                                                    q_index
                                                  )
                                                }
                                                disabled={
                                                  assessmentsList?.data
                                                    ?.manager_view_only
                                                    ? true
                                                    : false
                                                }
                                                required
                                              />
                                              <Form.Control.Feedback type="invalid">
                                                This field is required
                                              </Form.Control.Feedback>
                                            </Form.Group>
                                          </div>
                                        </>
                                      )}
                                    </Form.Group>
                                  </div>
                                );
                              })}
                            </Col>
                          </Row>
                          {assessmentsList?.data?.manager_view_only === false && (
                            <div className="col-md-12 text-end mt-2">
                              <Button
                                type="submit"
                                className="btn-primary text-white save_btn"
                              >
                                Save
                              </Button>
                            </div>
                          )}
                        </Form>
                      </div>
                    </Col>
                  </Row> : <h4 className="no-data mb-0">No data found</h4>}
                </>
                :
                <h4 className="no-data mb-0">No data found</h4>
            }

          </>
        </div>
      )}
      <Modal show={modalShow} onHide={modalClose} className="assesment-modal">
        <Modal.Header closeButton>
          <Modal.Title className="text-center">1-5 Rating Scale</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row id="scale-page">
            <Col lg={12}>
              <p>
                Create your assessment or review below. You can ask questions
                that are either a free form text response like: "What are some
                things I do well" or create questions with a rating of 1 to 5
                based on the rating scale below:
              </p>
              <Table striped bordered hover responsive>
                <thead>
                  <tr>
                    <th>
                      <strong>Level 1:</strong>
                      <br /> Consistently Below Expectations
                    </th>
                    <th>
                      <strong>Level 2:</strong>
                      <br /> Occasionally Below Expectations
                    </th>
                    <th>
                      <strong>Level 3:</strong>
                      <br /> Consistently Meets Expectations
                    </th>
                    <th>
                      <strong>Level 4:</strong>
                      <br /> Frequently Exceeds Expectations
                    </th>
                    <th>
                      <strong>Level 5:</strong>
                      <br /> Consistently Surpasses Expectations
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <p>
                        Performance is below expectations for most aspects of
                        the job.
                      </p>
                    </td>
                    <td>
                      <p>
                        Performance occasionally does not meet expectations for
                        some aspects of the job. Objectives occasionally are not
                        met or are met with a minimum level of acceptability.
                      </p>
                    </td>
                    <td>
                      <p>
                        Performance fully meets the expectations for all aspects
                        of the job. The employee's performance is consistent,
                        reliable, and effective. Employee is able to accomplish
                        all established objectives.
                      </p>
                    </td>
                    <td>
                      <p>
                        Performance frequently exceeds the expectations for most
                        aspects of the job on a sustained basis. Employee
                        performs complex, difficult parts of the job competently
                        and thoroughly, including extra or unique tasks
                        assigned.
                      </p>
                    </td>
                    <td>
                      <p>
                        Employee excels in virtually all aspects of the job,
                        having nearly reached the ultimate in job performance on
                        a sustained basis. Performance is of a quality rarely
                        achieved.
                      </p>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
      {loading && <Loader />}
    </>
  );
};

export default AssesmentsTab;
