import React from "react";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { DateRangePicker } from "react-date-range";
import { enUS } from "date-fns/locale";

export default function TimeOffCardsCalendar(props) {
  const {
    dateRange,
    setDateRange,
    setSelectedDateArray,
    setPolicyDefaultOption,
  } = props;
  const handleSelect = (ranges) => {
    setDateRange([ranges.selection]);
    const selectedDates = [];
    let currentDate = new Date(ranges.selection.startDate);
    while (currentDate <= ranges.selection.endDate) {
      selectedDates.push({
        day: currentDate.getDate(),
        month: currentDate.getMonth() + 1,
        year: currentDate.getFullYear(),
      });
      currentDate.setDate(currentDate.getDate() + 1);
    }
    setSelectedDateArray(selectedDates);
    setPolicyDefaultOption("");
    // handleValidation();
  };

  return (
    <div className="time-off-common-border-wrapper calender-container">
      <div className="">
        <div className="calender-title-wrapper">
          <h5>Pick a date</h5>
        </div>
        <div className="calender-content-wrapper">
          <DateRangePicker
            ranges={dateRange}
            onChange={handleSelect}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            months={1}
            direction="horizontal"
            locale={enUS}
            className="custom-date-range-picker"
          />
        </div>
      </div>
    </div>
  );
}
