import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import "./style.scss";
import { postData, getRevertUser } from "../../api";
import { toast } from "react-toastify";
import Loader from "../../components/Loader";
import Buttons from "../../components/Form/Button";
import { Col, Row, Container, Form, Button } from "react-bootstrap";
import OnboardHeading from "../../components/OnboardHeading";
import revertIcon from "../../assets/images/fa_refresh.svg";
import documentIcon from "../../assets/images/documentIcon.svg";
import { onlyCharacterWithSpaceAndDot  ,autocloseTiming} from "../../api/regex";

// import { Document, Page, pdfjs } from 'react-pdf';

// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const SignDocumentDetails = () => {

    let navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const { state } = useLocation();
    const documentData = state?.row;
    const companyName = state?.companyName;
    const [validated, setValidated] = useState(false);
    const [signDetailsObject, setSignDetailsObject] = useState({
        signature_text: "",
        acknowledge: "0"
    });
    const [errors, setErrors] = useState({});
    const [documentObject, setDocumentObject] = useState()

    const [isIframeLoading, setIsIframeLoading] = useState(true);
    // const [numPages, setNumPages] = useState(null);
    // const [containerSize, setContainerSize] = useState({ width: 0, height: 0 });

    // const updateContainerSize = () => {
    //     const containerWidth = window.innerWidth * 0.8; // Adjust the width as needed
    //     const containerHeight = window.innerHeight * 0.8; // Adjust the height as needed
    //     setContainerSize({ width: containerWidth, height: containerHeight });
    // };

    // useEffect(() => {
    //     // Update container size on initial mount and window resize
    //     updateContainerSize();
    //     window.addEventListener('resize', updateContainerSize);
    //     return () => {
    //         window.removeEventListener('resize', updateContainerSize);
    //     };
    // }, []);


    // const handleLoadSuccess = ({ numPages }) => {
    //     setNumPages(numPages);
    //     setIsIframeLoading(false);
    // };

    const handleIframeLoad = () => {
        setIsIframeLoading(false);
    };

    const handleRevertUser = () => {
        window.open(getRevertUser(), "_self");
    }

    useEffect(() => {
        getSignDocument();
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    const getSignDocument = async () => {
        setLoading(true);
        let obj = {
            stage: documentData?.stage,
            uuid: documentData?.uuid
        };
        try {
            const res = await postData("get-form-pdf-url", {}, obj);
            if (res?.status === true) {
                setDocumentObject(res?.data);
                setLoading(false);
            }
        } catch (err) { }
    };

    const getSignDocumentDownload = async () => {
        setLoading(true);
        let obj = {
            stage: documentData?.stage,
            uuid: documentData?.uuid
        };
        try {
            const res = await postData("get-form-pdf-url", {}, obj);
            if (res?.status === true) {
                window.open(res?.data?.document_url, "_blank");
                setLoading(false);
            }
        } catch (err) { }
    };

    const handleInput = (e) => {
        const value = e.target.value;
        const name = e.target.name;
        setSignDetailsObject((prev) => ({
            ...prev,
            [name]: value,
        }));
        if (!!errors[name])
            setErrors({
                ...errors,
                [name]: null,
            });
    };


    const handleAcknowledgeChange = (e) => {
        if (e.target.checked === true) {
            setSignDetailsObject((prev) => ({
                ...prev,
                acknowledge: "1",
            }));
        } else {
            setSignDetailsObject((prev) => ({
                ...prev,
                acknowledge: "0",
            }));
        }
    };

    const findFormErrors = () => {
        const { signature_text, acknowledge } = signDetailsObject;
        const newErrors = {};
        if (!signature_text || signature_text === "") newErrors.signature_text = "You can only sign the document if you enter the Signature text";
        else if (!signature_text?.match(onlyCharacterWithSpaceAndDot)) {
            newErrors.signature_text = "The signature name should be alphabetic";
        }
        if (!acknowledge || acknowledge === "" || acknowledge === null || acknowledge === undefined || acknowledge === "0") newErrors.acknowledge = "Please acknowlege";
        return newErrors;
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const newErrors = findFormErrors();
        setValidated(true);
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
        } else {
            signDocument();
        }
    };

    const signDocument = async () => {
        setLoading(true);
        let data = {
            stage: documentData?.stage,
            ...signDetailsObject,
            ...documentData
        }
        const res = await postData("sign-form", {}, data);
        if (res.status === true) {
            setLoading(false);
            documentData?.stage === 'company-forms' ? navigate('/sign-documents') : navigate('/profile', { state: "signforms" })
        } else {
             toast.error(res.message, { theme: "colored", autoClose: autocloseTiming });;
            setLoading(false);
        }
    };

    return (
        <>
            {!loading && (
                <Container fluid>
                    <div className="paystub-view">
                        <Row>
                            <Col md={getRevertUser() ? 10 : 12} sm={getRevertUser() ? 8 : 12}>
                                <OnboardHeading title="Document Details" subtitle={companyName} />
                            </Col>
                            {
                                getRevertUser() ?
                                    <Col md={2} sm={getRevertUser() ? 4 : 2} className="d-flex align-items-center justify-content-end mb-4 mb-md-0">
                                        <div className="revert">
                                            <Button
                                                variant="danger"
                                                className="revert_btn"
                                                onClick={handleRevertUser}
                                            >
                                                <img src={revertIcon} alt="revert" className="revert-img me-1" />
                                                Revert User
                                            </Button>
                                        </div>
                                    </Col>
                                    : ''
                            }
                        </Row>
                        <div className="sign-doc-view">
                            <Row className="justify-content-center">
                                <Col md={12} className="sign-details-name mb-3">
                                    <div className="mb-2">{documentData?.title}</div>
                                    <div><Button className="sign-download-btn" onClick={() => getSignDocumentDownload()} ><img src={documentIcon} alt="doc" className="me-2" />Download document</Button></div>
                                </Col>
                            </Row>
                            <Row className="justify-content-center">
                                <Col md={8}>
                                    <div className="sign-frame mb-4">
                                        {
                                            isIframeLoading && <div className="iframe-loading">
                                                Loading...
                                            </div>
                                        }

                                        <iframe src={`${documentObject?.document_url}#toolbar=0`} name="sign-details--iframe-view" height="100%" width="100%" title="Iframe Example" onLoad={handleIframeLoad}></iframe>


                                        {/* <div
                                            style={{
                                                overflowY: 'auto',
                                                ...containerSize,
                                            }}
                                        >

                                            <Document
                                                file={{ url: documentObject?.document_url }}
                                                onLoadSuccess={handleLoadSuccess}
                                                renderMode="canvas"
                                            >
                                                {numPages &&
                                                    Array.from(new Array(numPages), (el, index) => (
                                                        <Page key={index} pageNumber={index + 1} />
                                                    ))}
                                            </Document>
                                        </div> */}


                                    </div>
                                </Col>
                            </Row>
                            <Row className="justify-content-center">
                                <Col md={8}>
                                    {
                                        documentData?.requires_signing ?

                                            <Form noValidate validated={validated} onSubmit={handleSubmit} autoComplete="off">
                                                <Form.Group className="mb-3" controlId="signature_text">
                                                    <Form.Label>Signature text</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        value={signDetailsObject.signature_text}
                                                        onChange={handleInput}
                                                        isInvalid={!!errors.signature_text}
                                                        name="signature_text"
                                                        className="sign-text"
                                                        required
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.signature_text}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group className="mb-3" controlId="acknowledge">
                                                    <Form.Check
                                                        label={documentData?.stage === 'company-forms' ? "I am the signatory and I agree to sign electronically" : "I agree to sign electronically"}
                                                        name="acknowledge"
                                                        type="checkbox"
                                                        checked={signDetailsObject.acknowledge === "1" ? true : false}
                                                        value="2"
                                                        onChange={(e) => handleAcknowledgeChange(e)}
                                                        id="acknowledge"
                                                        required
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.acknowledge}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                                <div className="sign-doc-btn text-end mt-4">
                                                    <Buttons
                                                        type="button"
                                                        className="btn-outline-primary me-2"
                                                        onClick={() => documentData?.stage === 'company-forms' ? navigate('/sign-documents') : navigate('/profile', { state: "signforms" })}
                                                    >
                                                        Back
                                                    </Buttons>
                                                    <Buttons
                                                        type="submit"
                                                        disabled={loading || isIframeLoading}
                                                        loading={loading}
                                                        className="btn-primary text-white"
                                                    >
                                                        Sign
                                                    </Buttons>
                                                </div>
                                            </Form>
                                            :
                                            <div className="sign-doc-btn text-end mt-4">
                                                <Buttons
                                                    type="button"
                                                    className="btn-outline-primary me-2"
                                                    onClick={() => documentData?.stage === 'company-forms' ? navigate('/sign-documents') : navigate('/profile', { state: "signforms" })}
                                                >
                                                    Back
                                                </Buttons>
                                            </div>
                                    }
                                </Col>
                            </Row>
                        </div>

                    </div>
                </Container >
            )}
            {loading && <Loader />}
        </>
    )
}


export default SignDocumentDetails