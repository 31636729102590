import React from "react";
import Select from "react-select";
import RightArrow from "../../assets/images/multi-card-approval/right_arrow.svg";
import LeftArrow from "../../assets/images/multi-card-approval/left_arrow.svg";
import Tooltip from "rc-tooltip";
import "rc-tooltip/assets/bootstrap.css";
import "./style.scss";
import BackButtonComponent from "../backButton";
import moment from "moment";
import MultiDetailCardTable from "./multiDetailCardTable";
import FilterSection from "../multiCardApproval/filterSection";

export default function EmployeeDetails(props) {
  const {
    tableDataArray,
    checkedValues,
    handleMainCheckboxChange,
    handleIndividualCheckboxChange,
    handleAddModalOpen,
    setApproveDeny,
    handleModalOpen,
    setEditAddEntry,
    ptoStatus,
    groupId,
    handleHolidayModalOpen,
    frequencyId,
    dateRangeData,
    setLoading,
    getEmployeeCardData,
    addModalErrors,
    calcTotalhrs,
    btnLoading,
    handleApproveData,
    editAddEntry,
    addModalIndividualData,
    modalInputData,
    handleOnChange,
    resetModalInputData,
    clockOutModal,
    setClockOutModal,
    setAddModalErrors,
    ptoPendingData,
    ptoApprovedData,
    ptoDeniedData,
    employeeOptions,
    selectedEmployee,
    handleEmployeeChange,
    row,
    leftArrowDisable,
    handleLeftArrowClick,
    handleRightArrowClick,
    apiCusPageCount,
    totalHoursData,
    allIds,
    setApproveText,
    setModalDiff,
    handleDelModalOpen,
    handlePtoStatus,
    selectedAllIds,
    handleApiStart,
    handleApiEnd,
  } = props;

  const frequencyUrlId = frequencyId?.pay_frequency_id || 0;

  return (
    <div className="individual-card-main-wrapper">
      <div className="detail-back-btn-wrapper">
        {groupId === 5 || groupId === 8 ? null : (
          <div>
            <BackButtonComponent
              reDirectionpath={`/multi-card-approval?frequency=${frequencyUrlId}&page=${apiCusPageCount}`}
            />{" "}
          </div>
        )}
      </div>
      <div>
        <div className="multi-filter-container">
          <div className="detail-select-wrapper">
            <div className="multi-card-select-sub-wrapper">
              <div className="select-frequency-wrapper">
                <p>Frequency</p>
                <Tooltip
                  placement="top"
                  overlay={
                    <div style={{ wordBreak: "break-word" }}>
                      {row?.frequency_name
                        ? row?.frequency_name
                        : frequencyId?.frequency_label}
                    </div>
                  }
                  overlayClassName="timeoff-calender-tooltip"
                >
                  <div className="select-tag-wrapper">
                    <Select
                      placeholder="Select Frequency"
                      isDisabled={true}
                      closeMenuOnSelect={true}
                      hideSelectedOptions={false}
                      value={{
                        value: row?.frequency_name
                          ? row?.frequency_name
                          : frequencyId?.frequency_label,
                        label: row?.frequency_name
                          ? row?.frequency_name
                          : frequencyId?.frequency_label > 15
                          ? `${row?.frequency_name.substring(0, 15)}...`
                          : row?.frequency_name
                          ? row?.frequency_name
                          : frequencyId?.frequency_label,
                      }}
                    />
                  </div>
                </Tooltip>
              </div>
              <div className="select-date-range-wrapper">
                <p>Date range</p>
                <div className="date-range-subwrapper">
                  <img
                    src={LeftArrow}
                    alt="left-arrow"
                    onClick={leftArrowDisable ? null : handleLeftArrowClick}
                    className={
                      leftArrowDisable
                        ? "date-right-arrow-cus"
                        : "arrow-image-cus"
                    }
                  />
                  <div className="d-flex align-items-center">
                    <p>
                      {moment(dateRangeData?.start_date).format("MM/DD/YYYY")}{" "}
                    </p>
                    -
                    <p>
                      {moment(dateRangeData?.end_date).format("MM/DD/YYYY")}{" "}
                    </p>
                  </div>
                  <img
                    src={RightArrow}
                    alt="left-arrow"
                    onClick={
                      apiCusPageCount === 0 ? null : handleRightArrowClick
                    }
                    className={
                      apiCusPageCount === 0
                        ? "date-right-arrow-cus"
                        : "arrow-image-cus"
                    }
                  />
                </div>
              </div>
              {/* <div className="multi-card-status-wrapper"> */}
              <FilterSection
                ptoStatus={ptoStatus}
                handlePtoStatus={handlePtoStatus}
                detailPage
                groupId={groupId}
              />
              {/* </div> */}
            </div>
          </div>
          {groupId === 5 || groupId === 8 ? null : (
            <>
              {(ptoStatus === "all" ||
                ptoStatus === "pending" ||
                ptoStatus === "editRequest") && (
                <div className="d-flex detail-all-btn-wrapper">
                  <button
                    className={
                      allIds.length > 0
                        ? "approve-btn-active"
                        : "approve-timeoff-btn disabled-button"
                    }
                    onClick={() => {
                      if (allIds.length > 0) {
                        setApproveDeny(true);
                        setApproveText("approve");
                        setModalDiff(false);
                        handleDelModalOpen(
                          `Are you sure you want to approve the selected time card ${
                            allIds.length > 1 ? "entries" : "entry"
                          } for ${
                            row?.name
                              ? row?.name
                              : totalHoursData?.emp_data?.name
                          }?`
                        );
                      }
                    }}
                  >
                    APPROVE
                  </button>
                  <button
                    className={
                      allIds.length > 0
                        ? "deny-btn-active"
                        : "deny-timeoff-btn disabled-button"
                    }
                    onClick={() => {
                      if (allIds.length > 0) {
                        setApproveDeny(true);
                        setApproveText("reject");
                        setModalDiff(false);
                        handleDelModalOpen(
                          `Are you sure you want to reject the selected time card${
                            allIds.length > 1 ? "entries" : "entry"
                          } for ${
                            row?.name
                              ? row?.name
                              : totalHoursData?.emp_data?.name
                          }?`
                        );
                      }
                    }}
                  >
                    REJECT
                  </button>
                </div>
              )}
            </>
          )}
        </div>
        <div className="multi-card-search-approve-wrapper">
          <div className="responsive-width-cus-wrapper">
            <div className="select-frequency-wrapper">
              <p>Employee name</p>
              <div className="select-tag-wrapper">
                <Select
                  placeholder="Employee name"
                  closeMenuOnSelect={true}
                  isDisabled={groupId === 5 || groupId === 8}
                  hideSelectedOptions={false}
                  options={employeeOptions.map((option) => ({
                    label: option.name,
                    value: option.user_id,
                    ...option,
                  }))}
                  value={selectedEmployee}
                  onChange={handleEmployeeChange}
                />
              </div>
            </div>
          </div>
          <div className="detail-hours-wrapper">
            <div className="total-overtime-hours">
              <p>Regular</p>
              <h5>{totalHoursData?.regular || "-"}</h5>
            </div>
            <div className="border-cus-class-detail"></div>
            <div className="total-overtime-hours">
              <p>Overtime</p>
              <h5>{totalHoursData?.overtime || "-"}</h5>
            </div>{" "}
            <div className="border-cus-class-detail"></div>
            <div className="total-detail-pending-hours">
              <p>Pending</p>
              <h5>{totalHoursData?.pending || "-"}</h5>
            </div>
          </div>
        </div>

        <>
          <MultiDetailCardTable
            tableDataArray={tableDataArray}
            checkedValues={checkedValues}
            handleMainCheckboxChange={handleMainCheckboxChange}
            handleIndividualCheckboxChange={handleIndividualCheckboxChange}
            handleAddModalOpen={handleAddModalOpen}
            setApproveDeny={setApproveDeny}
            handleModalOpen={handleModalOpen}
            handleHolidayModalOpen={handleHolidayModalOpen}
            setEditAddEntry={setEditAddEntry}
            ptoStatus={ptoStatus}
            groupId={groupId}
            frequencyId={frequencyId}
            dateRangeData={dateRangeData}
            setLoading={setLoading}
            getEmployeeCardData={getEmployeeCardData}
            addModalErrors={addModalErrors}
            calcTotalhrs={calcTotalhrs}
            handleApproveData={handleApproveData}
            btnLoading={btnLoading}
            editAddEntry={editAddEntry}
            addModalIndividualData={addModalIndividualData}
            modalInputData={modalInputData}
            handleOnChange={handleOnChange}
            resetModalInputData={resetModalInputData}
            setClockOutModal={setClockOutModal}
            clockOutModal={clockOutModal}
            setAddModalErrors={setAddModalErrors}
            ptoPendingData={ptoPendingData}
            ptoApprovedData={ptoApprovedData}
            ptoDeniedData={ptoDeniedData}
            selectedAllIds={selectedAllIds}
            handleApiStart={handleApiStart}
            handleApiEnd={handleApiEnd}
            totalHoursData={totalHoursData}
          />
        </>
      </div>
    </div>
  );
}
