import React, { useState } from "react";
import FormModal from "../../../components/FormModal";
import { Form, Col, Row } from "react-bootstrap";
import Button from "../../../components/Form/Button";
import { FileUploader } from "react-drag-drop-files";
import { postUploadData } from "../../../api";
import { toast } from "react-toastify";
import Loader from "../../../components/Loader";
import { autocloseTiming } from '../../../api/regex';

const ViewTaskModal = ({ show, onClose, selectedTaskDetails }) => {

    const [validated, setValidated] = useState(false);
    const [loading, setLoading] = useState(false);
    const [file, setFile] = useState();
    const [errors, setErrors] = useState({});
    const fileTypes = ["JPEG", "PNG", "PDF", "DOCX", "JPG"];

    const findFormErrors = () => {
        const newErrors = {};
        // if (selectedTaskDetails?.file_upload) {
        //     if (file === undefined) {
        //         newErrors.fileupload = "Please upload the file .";
        //     }
        // }
        return newErrors;
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const newErrors = findFormErrors();
        setValidated(true);
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
        } else {
            viewTaskModal();
        }

    };
    const viewTaskModal = async () => {
        setLoading(true);
        let formData = new FormData();
        formData.append('stage', "onboarding_tasks");
        formData.append('type', selectedTaskDetails?.type);
        formData.append('documents', file);
        formData.append('task_id', selectedTaskDetails?.task_id);
        const res = await postUploadData("employee_onboarding_steps", {}, formData);
        if (res.status === true) {
            setLoading(false);
            onClose()
        } else {
            toast.error(res.message, { theme: "colored", autoClose: autocloseTiming });;
            setLoading(false);
        }
    };

    const handleChange = (file) => {
        setFile(file);
        setErrors([])
    };

    const handleSizeError = (fileErr) => {
        toast.error(fileErr, { theme: "colored", autoClose: autocloseTiming });;
      }
    
      const handleFileTypeError = (fileErr) => {
        toast.error(fileErr, { theme: "colored", autoClose: autocloseTiming });;
      }
    return (
        <>
            {!loading && (
                <FormModal heading={'Task details'} show={show} onClose={onClose} size="lg" backdrop="static" modalClassName="view_task_modal">
                    <div className="onboard-task-title my-1">
                        {selectedTaskDetails?.name}
                        <div className="onboard-sub-title my-2">Due date :  {selectedTaskDetails?.due_date} </div>
                        {
                            selectedTaskDetails?.completion === 1 &&
                            <div className="onboard-sub-title my-2"> Completed date :  {selectedTaskDetails?.completed} </div>
                        }

                    </div>
                    <div className="onboard-body my-3">
                        {selectedTaskDetails?.description}
                        {
                            !selectedTaskDetails?.file_upload &&
                            <div className="my-3">  Complete document: <a href="#" target="_blank" rel="noopener noreferrer"> {selectedTaskDetails?.custom_documents?.document_name}</a></div>
                        }
                        {
                            selectedTaskDetails?.documents.length !== 0 &&
                            <div className="my-3 d-flex">   Attachments :
                                <ul className="attachment-ul">
                                    {selectedTaskDetails.documents.map((data, index) => {
                                        return (
                                            <li className="mb-2" key={index}>
                                                <a href={data?.document_url} target="_blank" rel="noopener noreferrer"> {data?.document_name}</a>
                                            </li>

                                        )
                                    })}
                                </ul>
                            </div>
                        }


                    </div>

                    <Form noValidate validated={validated} onSubmit={handleSubmit} autoComplete="off" className="custom-form">
                        {selectedTaskDetails?.file_upload && selectedTaskDetails?.completion === 0 ?
                            <Row>
                                <Col md={12}>
                                    <Form.Group className="mb-3" controlId="task_doc">
                                        <Form.Label>Upload your documents:</Form.Label>
                                        <div className="m-3">
                                            <FileUploader
                                                multiple={false}
                                                handleChange={handleChange}
                                                onSizeError={handleSizeError}
                                                onTypeError={handleFileTypeError}
                                                name="file"
                                                types={fileTypes}
                                                classes="employee-document-upload sign-upload-doc"
                                                maxSize="10"
                                                label="Drop your files here or browse."
                                            />
                                        </div>
                                        {/* {
                                            validated &&
                                            <div className="err-feedback">{errors.fileupload}</div>
                                        } */}
                                    </Form.Group>
                                </Col>
                            </Row>
                            : ''}
                        <div className="text-end">
                            <Button
                                type="button"
                                className="btn-default text-blacksix me-3 cancel-btn"
                                onClick={onClose}
                            >
                                Cancel
                            </Button>
                            <Button
                                type="submit"
                                disabled={loading}
                                loading={loading}
                                className={`btn-primary text-white ${selectedTaskDetails?.completion === 0 ? 'd-inline' : 'd-none'}`}
                            >
                                Save
                            </Button>
                        </div>
                    </Form>

                </FormModal>
            )
            }
            {loading && <Loader />}
        </>
    );
};

export default ViewTaskModal;
