
import React, { useState, useEffect } from "react";
import "./style.scss";
import OnboardHeading from "../../components/OnboardHeading";
import HeaderForm from "../../components/HeaderForm";
import { Form, Row, Col, Card } from "react-bootstrap";
import Button from "../../components/Form/Button";
import ViewOfferLetterModal from "./ViewOfferLetterModal";
import { useNavigate } from "react-router-dom";
import { postData } from "../../api";
import { toast } from "react-toastify";
import Loader from "../../components/Loader";
import { autocloseTiming } from '../../api/regex';

const Offerletter = () => {

    let navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [validated, setValidated] = useState(false);
    const [employeeObject, setEmployeeObject] = useState({
        offerletter: {},
    });
    const [acknowledged, setAcknowledged] = useState(false);

    const [viewOfferLetterShowModal, setViewOfferLetterShowModal] = useState(false);


    useEffect(() => {
        getOfferLetter()
    }, [!viewOfferLetterShowModal]);// eslint-disable-line react-hooks/exhaustive-deps

    const getOfferLetter = async () => {
        setLoading(true);
        let data = {
            stage: "offer_preview",
        }
        const res = await postData("employee_offer_letter", {}, data);
        if (res.status === true) {
            setLoading(false);
            setEmployeeObject(res?.data?.offer_letter)
            if (res?.data?.offer_letter?.completion === 1) {
                setErrors({})
            }
        } else {
            toast.error(res.message, { theme: "colored", autoClose: autocloseTiming });;
            setLoading(false);
        }
    }

    // OfferLetter
    const ViewOfferLetterClick = () => {
        setViewOfferLetterShowModal(true);
    };

    const viewOfferLetterModalClose = () => {
        setViewOfferLetterShowModal(false);
    };


    const findFormErrors = () => {
        const newErrors = {};
        if (employeeObject?.completion === 0) {
            newErrors.review = "Please sign the offer letter .";
        }
        return newErrors;
    };

    const acknowledgedShow = (data) => {
        setAcknowledged(data)
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        const newErrors = findFormErrors();
        setValidated(true);
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
        } else {
            navigate("/employee-onboard-sections");
        }
    };




    return (
        <>

            <HeaderForm />
            <div className="boarding-layout board-main-section">
                <OnboardHeading title="offerletter" />
                <div className="create-board">
                    <div className="board-container">
                        <div className="board-right board-tab-sections">
                            <h5 className="mb-3">Offer letter </h5>
                        </div>
                        {!loading && (
                            <>
                                <Form noValidate validated={validated} onSubmit={handleSubmit} autoComplete="off" className="custom-form">

                                    <Row>
                                        <Col md="12">
                                            <Card className="task_card white_bg_card">
                                                <Card.Body>
                                                    <div className="d-flex align-items-center">
                                                        <div className="task_detail">
                                                            <p className="task_title">Offer letter <span className="asterisk">*</span> </p>
                                                        </div>
                                                        <div className="task_action text-end">
                                                            {
                                                                employeeObject?.completion === 1 ?
                                                                    <a href={employeeObject?.document_path} target="_blank" rel="noopener noreferrer" className="btn-success text-white success-btn offer-view-link">
                                                                        View & Download</a>
                                                                    :
                                                                    <Button
                                                                        type="button"
                                                                        className="btn-primary text-white"
                                                                        onClick={() => ViewOfferLetterClick()}
                                                                    >
                                                                        Review & Sign
                                                                    </Button>
                                                            }
                                                        </div>
                                                    </div>

                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </Row>
                                    {
                                        <div className="err-feedback">{errors.review}</div>
                                    }
                                    <div className="text-end mt-4">
                                        <Button
                                            type="submit"
                                            disabled={loading}
                                            loading={loading}
                                            className="btn-primary text-white"
                                        >
                                            Continue
                                        </Button>
                                    </div>
                                </Form>
                            </>
                        )}
                        {loading && <Loader />}
                    </div>
                </div>
            </div>
            {viewOfferLetterShowModal && <ViewOfferLetterModal show={viewOfferLetterShowModal} handleClose={viewOfferLetterModalClose} offerletter={employeeObject} acknowledgedShow={acknowledgedShow} />}

        </>
    )
}

export default Offerletter