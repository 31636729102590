import React from "react";
import "./style.scss";

export default function TitleComponent(props) {
  const { title, subTitle, h3Tag } = props;
  return (
    <div className="title-common-wrapper">
      {h3Tag ? <h3>{title}</h3> : <h2>{title}</h2>}
      <p>{subTitle}</p>{" "}
    </div>
  );
}
