import React, { useState, useEffect } from "react";
import "../EmployerStateTax/style.scss";
import { postData, getRevertUser } from "../../api";
import { Row, Col, Container } from "react-bootstrap";
import { toast } from "react-toastify";
import Loader from "../../components/Loader";
import OnboardHeading from "../../components/OnboardHeading";
import { autocloseTiming } from "../../api/regex";
import BackButtonComponent from "../../components/backButton";

const CustomReport = () => {
  const [loading, setLoading] = useState(false);
  const [stateTaxLink, setStateTaxLink] = useState("");
  const companyName = localStorage.getItem("company_name");
  const [isIframeLoading, setIsIframeLoading] = useState(true);

  useEffect(() => {
    if (window.location.pathname === "/custom-report") {
      document.body.classList.add("feedback-remove");
    } else {
      document.body.classList.remove("feedback-remove");
    }
  }, [window.location.pathname === "/custom-report"]);

  useEffect(() => {
    getEmployerStateTax();
  }, []);

  const getEmployerStateTax = async () => {
    setLoading(true);
    const res = await postData("client-custom-report", {});

    if (res.status === true) {
      setLoading(false);
      setStateTaxLink(res?.data?.url);
    } else {
      toast.error(res.message, {
        theme: "colored",
        autoClose: autocloseTiming,
      });
      setLoading(false);
    }
  };

  const handleIframeLoad = () => {
    setIsIframeLoading(false);
  };

  return (
    <>
      {!loading && (
        <>
          <Container fluid>
            <Row>
              <Col md={getRevertUser() ? 10 : 12} sm={getRevertUser() ? 8 : 12}>
                <OnboardHeading title="Custom Report" subtitle={companyName} />
                <BackButtonComponent reDirectionpath="https://qa.guhroo.co/reports" />
              </Col>
            </Row>
            <div className="emp-state-tax-view">
              {isIframeLoading && (
                <div className="emp-iframe-loading">
                  <Loader />
                </div>
              )}
              <iframe
                src={stateTaxLink}
                name="emp-state-tax-view"
                height="100%"
                width="100%"
                title="Iframe Example"
                onLoad={handleIframeLoad}
              ></iframe>
            </div>
          </Container>
        </>
      )}
      {loading && <Loader />}
    </>
  );
};

export default CustomReport;
