import React, { useState } from "react";
import MobileVerification from "./mobileVerification";
import OtpVerification from "./otpVerification";
import SuccessPage from "./successPage";

export default function TwoFactor() {
  const [showOtp, setShowOtp] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [showSuccessPage, setShowSuccessPage] = useState(false);
  return (
    <>
      {showOtp ? (
        <div>
          {showSuccessPage ? (
            <SuccessPage />
          ) : (
            <OtpVerification
              phoneNumber={phoneNumber}
              setShowSuccessPage={setShowSuccessPage}
            />
          )}
        </div>
      ) : (
        <div>
          <MobileVerification
            setShowOtp={setShowOtp}
            setPhoneNumber={setPhoneNumber}
            phoneNumber={phoneNumber}
          />
        </div>
      )}
    </>
  );
}
