import React, { useState, useEffect } from "react";
import FormModal from "../../components/FormModal";
import Button from "../../components/Form/Button";
import { postData } from "../../api";
import { toast } from "react-toastify";
import Loader from "../../components/Loader";
import { Col, Form, InputGroup, Row } from "react-bootstrap";
import {autocloseTiming} from '../../api/regex';


const MicroDepositModal = ({ show, onClose, onVerificationData }) => {

    const [userData, setUserData] = useState({
        bank_deposit_1: '',
        bank_deposit_2: ''
    })
    const [validated, setValidated] = useState(false);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);

    // testing purpose api call for production it should be hided (getDepositAmount)
    useEffect(() => {
        // getDepositAmount();
    }, [])


    const getDepositAmount = async () => {
        setLoading(true);
        const res = await postData("send_test_deposits", {}, {});
        if (res.status === true) {
            setLoading(false);
            setUserData({
                bank_deposit_1: res?.data?.deposit_1,
                bank_deposit_2: res?.data?.deposit_2
            })
        } else {
             toast.error(res.message, { theme: "colored", autoClose: autocloseTiming });;
            setLoading(false);
        }
    };

    const handleInput = (e) => {
        let { value, name } = e.target
        setUserData(prevState => ({
            ...prevState,
            [name]: value
        }))
        if (errors[name])
            setErrors({
                ...errors,
                [name]: null,
            });
    }

    const findFormErrors = () => {
        const { bank_deposit_1, bank_deposit_2 } = userData;
        let newErrors = {};
        if (!bank_deposit_1 || bank_deposit_1 === "") {
            newErrors.bank_deposit_1 = 'Please enter your amount 1. ';
        } else if (!bank_deposit_1 || bank_deposit_1 < 0) {
            newErrors.bank_deposit_1 = 'Please enter a valid amount for amount 1.';
        }
        if (!bank_deposit_2 || bank_deposit_2 === "") {
            newErrors.bank_deposit_2 = 'Please enter your amount 2.';
        } else if (!bank_deposit_2 || bank_deposit_2 < 0) {
            newErrors.bank_deposit_2 = 'Please enter a valid amount for amount 2.';
        }
        return newErrors;
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const newErrors = findFormErrors();
        setValidated(true);
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
        } else {
            addMicroDeposit()
        }
    };



    const addMicroDeposit = async () => {
        setLoading(true);
        let data = {
            ...userData
        }

        const res = await postData("verify_bank_account", {}, data);
        if (res.status === true) {
            setLoading(false);
            onVerificationData(res);
            toast.success(res.message, { theme: "colored" ,autoClose: autocloseTiming });
            onClose();
        } else {
             toast.error(res.message, { theme: "colored", autoClose: autocloseTiming });;
            setLoading(false);
            onClose();
        }
    };


    return (
        <FormModal heading={'Verify account'} show={show} onClose={onClose} size="md" backdrop="static" modalClassName="view_task_modal">

            <div className="text-center mt-4">
                To verify your account,enter two micro deposits below.If you don't see them, they should arrive in 1-3 business days.
            </div>
            <Form noValidate validated={validated} onSubmit={handleSubmit} autoComplete="off" className="custom-form mt-3">
                <Row>
                    <Col md={12}>
                        <Form.Group className="mb-3" controlId="bank_deposit_1">
                            <Form.Label className="mb-1">Amount 1  <span className="asterisk">*</span> </Form.Label>
                            <div className="micro-span mb-2">Enter first deposit amount in dollars , e.g. 0.02 for 2 cents</div>
                            <InputGroup className="mb-3">
                                <InputGroup.Text id="basic-addon1">$</InputGroup.Text>
                                <Form.Control
                                    type="number"
                                    aria-describedby="basic-addon1"
                                    onChange={handleInput}
                                    name="bank_deposit_1"
                                    value={userData.bank_deposit_1}
                                    isInvalid={!!errors.bank_deposit_1}
                                    required
                                />

                                <Form.Control.Feedback type="invalid">
                                    {errors?.bank_deposit_1}
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>
                    </Col>
                    <Col md={12}>
                        <Form.Group className="mb-3" controlId="bank_deposit_2">
                            <Form.Label className="mb-1">Amount 2  <span className="asterisk">*</span> </Form.Label>
                            <div className="micro-span mb-2">Enter second deposit amount in dollars , e.g. 0.02 for 2 cents</div>
                            <InputGroup className="mb-3">
                                <InputGroup.Text id="basic-addon2">$</InputGroup.Text>
                                <Form.Control
                                    type="number"
                                    required
                                    aria-describedby="basic-addon2"
                                    onChange={handleInput}
                                    value={userData.bank_deposit_2}
                                    isInvalid={!!errors.bank_deposit_2}
                                    name="bank_deposit_2"
                                />

                                <Form.Control.Feedback type="invalid">
                                    {errors?.bank_deposit_2}
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>
                    </Col>
                    <Col md={12} className="text-center">
                        <Button
                            type="button"
                            className="btn-default text-blacksix me-3 cancel-btn"
                            onClick={onClose}
                        >
                            Cancel
                        </Button>
                        <Button
                            type="submit"
                            className="btn-primary text-white create-btn"
                            disabled={loading}
                            loading={loading}
                        >
                            Verify
                        </Button>
                    </Col>
                </Row>
            </Form>

        </FormModal>
    );
};

export default MicroDepositModal;
