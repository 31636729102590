import React from "react";
import CalenderIcon from "../../assets/images/timekeeping_icons/event_available.svg";
import "./style.scss";
const detailsArray = [
  {
    profileName: "Murali tharan",
    hoursWorked: "0 hour 5 minutes",
    periodic: "Monthly",
  },
  {
    profileName: "Siam SiamTest One",
    hoursWorked: "24 hour 6 minutes",
    periodic: "Weekly",
  },
];

export default function WhosWorking() {
  return (
    <div>
      <div className="title-wrapper">
        <img src={CalenderIcon} alt="pay-record" />
        <h5>Who's Working</h5>
      </div>
      <div className="whos-working-main-wrapper">
        <div className="d-flex whos-working-title border-class">
          <h4>All Employees</h4>
          <h5>On The Clock</h5>
        </div>
        <div className="profile-name-main-wrapper">
          {detailsArray.map((item, index) => (
            <div className="d-flex flex-column">
              <div className="d-flex justify-content-between border-bottom-cus profile-name-wrapper">
                <h5 className="header-caps">{item.profileName}</h5>
                <h5>{item.hoursWorked}</h5>
              </div>
              <div>
                <h5 className="text-info-cus">{item.periodic}</h5>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
