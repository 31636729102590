import React, { useEffect, useState } from "react";
import "./style.scss";
// import ExpandIcon from "../../assets/images/timekeeping_icons/add_circle.svg";
// import CollapseIcon from "../../assets/images/timekeeping_icons/minus-icon.svg";
import PayPeriodModal from "./payPeriodModal";
import ExpandDetail from "./expandDetail";

export default function SelectAllEmployees(props) {
  const { sampleArrayData, expandDetailData, employeeData, payPeriodData } =
    props;
  const [isChecked, setIsChecked] = useState(false);
  const [individualCheckboxes, setIndividualCheckboxes] = useState(
    Array(sampleArrayData.length).fill(false)
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [expandState, setExpandState] = useState(
    Array(sampleArrayData.length).fill(false)
  );
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  // const [ptoStatus, setPtoStatus] = useState("");
  
  const handleMainCheckboxChange = (e) => {
    const { checked } = e.target;
    setIsChecked(checked);
    setIndividualCheckboxes(Array(sampleArrayData.length).fill(checked));
  };
  const handleIndividualCheckboxChange = (index) => {
    const newCheckboxes = [...individualCheckboxes];
    newCheckboxes[index] = !newCheckboxes[index];
    setIndividualCheckboxes(newCheckboxes);
    setIsChecked(newCheckboxes.every((checkbox) => checkbox));
  };
  const handleModalOpen = (data, userName) => {
    if (checkedCount > 1) {
      setIsModalOpen(true);
      setModalData({ data, userName });
    } else {
      setIsModalOpen(false);
    }
  };
  const handleExpand = (index) => {
    const newExpandState = expandState.map((item, i) => {
      if (i === index) {
        return !item;
      } else {
        return item;
      }
    });
    setExpandState(newExpandState);
  };
  const checkedCount = individualCheckboxes.filter(
    (checkbox) => checkbox
  ).length;
  function formatTime(totalMinutes) {
    const hours = Math.floor(totalMinutes / 60);
    const remainingMinutes = totalMinutes % 60;
    return `${hours}hrs ${remainingMinutes}mins`;
  }
  // useEffect(() => {
  //   if (employeeData.length > 0) {
  //     const allStatuses = employeeData?.map((obj) => obj.status);
  //     setPtoStatus(allStatuses);
  //   }
  // }, [payPeriodData, employeeData]);
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const handleRowsPerPageChange = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(0);
  };

  const startIndex = currentPage * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const totalPages = Math.ceil(employeeData?.length / rowsPerPage);
  return (
    <>
      <div className="select-all-employee-container">
        <h4>Approve or deny the time cards of the employees</h4>
        <div className="d-flex all-employee-container justify-content-between align-items-center">
          <div className="d-flex">
            <input
              type="checkbox"
              id="vehicle1"
              name="vehicle1"
              value="Bike"
              className="select-check-box"
              checked={isChecked}
              onChange={handleMainCheckboxChange}
            />
            <label for="squareRadio" className="select-employee-heading">
              Select all employees
            </label>
          </div>
          <div className="button-wrapper">
            <button
              className={`approve-all-button ${
                checkedCount > 1 ? "active-btn" : "disable-btn"
              }`}
              onClick={() => handleModalOpen("approve all")}
            >
              Approve
            </button>
            {/* <button
              className={`deny-all-button ${
                isChecked ? "deny-active-btn" : "deny-disable-btn"
              }`}
              onClick={() => handleModalOpen("deny all")}
            >
              Deny All
            </button> */}
          </div>
        </div>
        {employeeData?.slice(startIndex, endIndex).map((cardData, index) => (
          <>
            <div
              className={
                cardData?.status === "pending"
                  ? "d-flex justify-content-between align-items-center select-individual-employee active-approve-btn-wrapper"
                  : "d-flex justify-content-between align-items-center select-individual-employee"
              }
            >
              <div className="d-flex">
                <input
                  type="checkbox"
                  id={`employee${index}`}
                  name={`employee${index}`}
                  value={cardData.name}
                  className="select-check-box"
                  checked={individualCheckboxes[index]}
                  onChange={() => handleIndividualCheckboxChange(index)}
                />
                <div key={index} className="d-flex flex-column">
                  <div className="d-flex align-items-center">
                    <label for="squareRadio">{cardData.user_name}</label>
                    <p>
                      Total hours:&nbsp;
                      {formatTime(
                        cardData.regular_minutes + cardData.overtime_minutes
                      )}{" "}
                    </p>
                  </div>
                  <h5
                    className={
                      cardData?.status === "pending"
                        ? "pending-alert-text"
                        : "completed-alert-text"
                    }
                  >
                    {cardData?.status === "pending"
                      ? "Pending request"
                      : "Completed"}
                  </h5>
                </div>
              </div>
              <div className="button-wrapper">
                <div className="d-flex approve-and-deny-btn">
                  {cardData?.status === "pending" ? (
                    <button
                      className="approve-btn"
                      onClick={() =>
                        handleModalOpen("approve", cardData.user_name)
                      }
                    >
                      Approve
                      {/* {ptoStatus === "pending" ? "Approve" : "Edit & Approve"} */}
                    </button>
                  ) : null}
                  {/* <button
                    className="deny-btn"
                    onClick={() => handleModalOpen("unapprove", cardData.name)}
                  >
                    Deny
                  </button> */}
                  <button
                    className="collapse-btn"
                    // onClick={() => handleExpand(index)}
                  >
                    <a
                      href={`https://qa.guhroo.co/timers/employee_timecard/${cardData?.user_id}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      View all
                    </a>
                    {/* {expandState[index] ? "Collapse" : "Expand"} */}
                    {/* <img
                      src={expandState[index] ? CollapseIcon : ExpandIcon}
                      alt="add-icon"
                    /> */}
                  </button>
                </div>
              </div>
            </div>
            {expandState[index] && (
              <ExpandDetail expandDetailData={expandDetailData} />
            )}
          </>
        ))}
      </div>
      {employeeData?.length > 5 && (
        <div className="d-flex justify-content-between py-3 px-3">
          <div>
            <select value={rowsPerPage} onChange={handleRowsPerPageChange}>
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={25}>25</option>
              <option value={50}>50</option>
            </select>
          </div>
          <div>
            {currentPage !== 0 && (
              <button
                // disabled={currentPage === 0}
                onClick={() => handlePageChange(currentPage - 1)}
                className="non-active-pagination-btn"
              >
                &lt;
              </button>
            )}
            {Array.from({ length: totalPages }, (_, i) => i).map((page) => (
              <button
                key={page}
                disabled={currentPage === page}
                onClick={() => handlePageChange(page)}
                className={
                  currentPage === page
                    ? "active-pagination-btn "
                    : "non-active-pagination-btn"
                }
              >
                {page + 1}
              </button>
            ))}
            {currentPage !== totalPages - 1 && (
              <button
                disabled={currentPage === totalPages - 1}
                onClick={() => handlePageChange(currentPage + 1)}
                className="non-active-pagination-btn"
              >
                &gt;
              </button>
            )}
          </div>
        </div>
      )}
      {isModalOpen && (
        <PayPeriodModal
          heading="Pay Period Summary"
          show={isModalOpen}
          modalData={modalData}
          setIsModalOpen={setIsModalOpen}
        />
      )}
    </>
  );
}
