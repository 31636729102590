import React, { useState, useRef, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  InputGroup,
  Button
} from "react-bootstrap";
import DatePicker from "react-datepicker";
import revertIcon from "../../assets/images/fa_refresh.svg";
import "./style.scss";
import { postData, getData, getRevertUser, convertUTCToLocalDate } from "../../api";
import { toast } from "react-toastify";
import Loader from "../../components/Loader";
import Select from "react-select";
import OnboardHeading from "../../components/OnboardHeading";
import FormModal from "../../components/FormModal";
import infoIcon from "../../assets/images/info.svg";
// import moment from 'moment';
import {autocloseTiming} from '../../api/regex';
import BackButtonComponent from "../../components/backButton";

const Signatory = () => {
  const [isShowChangeSignatory, setIsShowSignatory] = useState(false);
  const [errors, setErrors] = useState({});
  const [signatory, setSignatory] = useState({
    primary_email: "",
    first_name: "",
    last_name: "",
    username: "",
    title: "",
    phone_number: '',
    birthday: '',
    str_soc_sec_num: '',
    address: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
    user_id: '',
    preferred_name: "",
    company_name: ""
  });
  const [stateValue, setStateValue] = useState();
  const [dateInputs, setDateInputs] = useState({
    birthday: null,
  });
  const focusDate = useRef(null);
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [titleList, setTitleList] = useState();
  const [stateList, setStateList] = useState();
  const [userId, setUserId] = useState('');
  const [showUserSign, setShowUserSign] = useState(false);
  const [usersList, setUsersList] = useState([]);
  // const timeZoneOffset = new Date().getTimezoneOffset();
  const handleClose = () => {
    setIsShowSignatory(false);
    setShowUserSign(false);
  }
  const handleShow = () => setIsShowSignatory(true);

  useEffect(() => {
    getSignatory();
    getStaticList();
  }, []);

  useEffect(() => {
    if (window.location.pathname === '/signatory') {
        document.body.classList.add('feedback-remove');
    } else {
        document.body.classList.remove('feedback-remove');
    }
}, [window.location.pathname === '/signatory']);

  const getSignatory = async () => {
    setLoading(true);
    const res = await getData("signatory", {});
    if (res.status === true) {
      setLoading(false);
      setSignatory(res?.data?.signatory);
      setUsersList(res?.data?.signatoryUsers);
      let data = {
        label: res?.data?.signatory?.state,
        value: res?.data?.signatory?.state,
      }
      setStateValue(data);
      if (res?.data?.signatory?.birthday !== null) {
        setDateInputs({
          // birthday: res?.data?.signatory?.birthday !== null
          //   ? moment.utc(res?.data?.signatory?.birthday).add(timeZoneOffset, 'minutes').toDate()
          //   : null
          // birthday: res?.data?.signatory?.birthday !== null ? new Date(res?.data?.signatory?.birthday) : null

          birthday: res?.data?.signatory?.birthday !== null
            ? convertUTCToLocalDate(res?.data?.signatory?.birthday)
            : null
        });
      } else {
        setDateInputs({
          birthday: null
        })
      }
    } else {
       toast.error(res.message, { theme: "colored", autoClose: autocloseTiming });;
      setLoading(false);
    }
  };

  const getStaticList = async () => {
    setLoading(true);
    const res = await postData("get_constant", {});
    if (res.status === true) {
      setStateList(res.data.stateList)
      setTitleList(res.data.titleList)
      setLoading(false);
    } else {
       toast.error(res.message, { theme: "colored", autoClose: autocloseTiming });;
      setLoading(false);
    }
  }

  const signatoryDetails = async () => {
    setLoading(true);
    let data = {
      user_id: userId
    }
    const res = await postData("signatory", {}, data);
    if (res.status === true) {
      setLoading(false);
      setIsShowSignatory(false);
      getSignatory();
      toast.success(res.message, { theme: "colored" ,autoClose: autocloseTiming });
    } else {
      setIsShowSignatory(false);
       toast.error(res.message, { theme: "colored", autoClose: autocloseTiming });;
      setLoading(false);
    }
  };

  const handleInput = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setSignatory((prev) => ({
      ...prev,
      [name]: value,
    }));
    if (!!errors[name])
      setErrors({
        ...errors,
        [name]: null,
      });
  };

  const handleSignatoryInput = (e) => {
    setUserId(e.target.value)
    setErrors({
      ...errors,
      userId: null,
    });
  }
  const handleStateOnChange = (data) => {
    setStateValue(data)
    setSignatory((prev) => ({
      ...prev,
      state: data.value
    }));
  }

  const minTwoDigit = (n) => {
    return (n < 10 ? "0" : "") + n;
  };

  const formatDates = (date) => {
    const day = minTwoDigit(date.getDate());
    const month = minTwoDigit(date.getMonth() + 1);
    const year = date.getFullYear();
    let val = `${year}-${month}-${day}`;
    return val;
  };

  const selectUploadDt = (dateSel, name) => {
    const date = new Date(`${dateSel}`);
    const chkDt = date.getDate();

    if (chkDt > 0) {
      let val = formatDates(date);
      setSignatory((prev) => ({
        ...prev,
        [name]: val
      }));
      setDateInputs((prev) => ({
        ...prev,
        [name]: dateSel,
      }));
      // setDateInputs((prev) => ({
      //   ...prev,
      //   [name]: new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())),
      // }));
    }

  };

  const findFormErrors = () => {
    const newErrors = {};
    if (!userId || userId === "" || userId === null) newErrors.userId = "Please select signatatory.";
    return newErrors;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const newErrors = findFormErrors();
    setValidated(true);
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      signatoryDetails();
    }
  };


  const formatSelectOptions = (data) => {
    let finalArr = [];
    if (data && data.length > 0) {
      data.forEach((item) => {
        finalArr.push({
          value: item.name,
          label: item.name,
          name: item.name,
        });
      });
    }
    return finalArr;
  };
  const handleFocusDate = () => {
    const datepickerElement = focusDate.current;
    datepickerElement.setFocus(true);
  };

  const handleChangeSign = (e) => {
    setShowUserSign(true);
    setIsShowSignatory(true);
  }

  const handleRevertUser = () => {
    window.open(getRevertUser(), "_self");
  }

  return (
    <>
      {!loading && (
        <div className="signatory_container new_signatory">
          <Container fluid>
            <Row>
              <Col md={getRevertUser() ? 10 : 12} sm={getRevertUser() ? 8 : 12}>
                <OnboardHeading title="Signatory" subtitle={signatory?.company_name} />
                <BackButtonComponent reDirectionpath="/settings"/>
              </Col>
              {
                getRevertUser() ?
                  <Col md={2} sm={getRevertUser() ? 4 : 2} className="d-flex align-items-center justify-content-end mb-4 mb-md-0">
                    <div className="revert">
                      <Button
                        variant="danger"
                        className="revert_btn"
                        onClick={handleRevertUser}
                      >
                        <img src={revertIcon} alt="revert" className="revert-img me-1" />
                        Revert User
                      </Button>
                    </div>
                  </Col>
                  : ''
              }
            </Row>

            <Row className="signatory_details_container">
              <Form className="custom-form mb-4">
                <Row>
                  <Col md={6}>
                    <Form.Group className="mb-3" controlId="first_name">
                      <Form.Label>First name <span className="asterisk">*</span> </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter first name"
                        value={signatory.first_name}
                        onChange={handleInput}
                        name="first_name"
                        maxLength="70"
                        required
                        disabled
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-3" controlId="preferred_name">
                      <Form.Label>Preferred name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter preferred name"
                        value={signatory.preferred_name}
                        onChange={handleInput}
                        name="preferred_name"
                        maxLength="70"
                        disabled
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-3" controlId="last_name">
                      <Form.Label>Last name <span className="asterisk">*</span> </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter last name"
                        value={signatory.last_name}
                        onChange={handleInput}
                        name="last_name"
                        // maxLength="70"
                        required
                        disabled
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-3" controlId="username">
                      <Form.Label>User name <span className="asterisk">*</span> </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Create user name"
                        value={signatory.username}
                        onChange={handleInput}
                        name="username"
                        maxLength="255"
                        required
                        disabled
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-3" controlId="primary_email">
                      <Form.Label>Email <span className="asterisk">*</span> </Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="Enter email"
                        value={signatory.primary_email}
                        onChange={handleInput}
                        name="primary_email"
                        required
                        disabled
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-3" controlId="title">
                      <Form.Label>Title <span className="asterisk">*</span> </Form.Label>
                      <Form.Select
                        onChange={handleInput}
                        name="title"
                        value={signatory.title}
                        required
                        size="lg"
                        disabled
                      >
                        <option value="">Select title</option>
                        {titleList &&
                          titleList.map((item, index) => (
                            <option key={item.id} value={item.name}>
                              {item.name}
                            </option>
                          ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-3" controlId="phone_number">
                      <Form.Label>Phone number <span className="asterisk">*</span> </Form.Label>
                      <InputGroup className="phone-group">
                        <InputGroup.Text>
                          +1
                        </InputGroup.Text>
                        <Form.Control
                          type="text"
                          placeholder="Enter phone number"
                          value={signatory.phone_number}
                          onChange={handleInput}
                          name="phone_number"
                          // maxLength="10"
                          required
                          disabled
                        />
                      </InputGroup>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="position-relative mb-3" controlId="birthday">
                      <Form.Label>Date of birth <span className="asterisk">*</span> </Form.Label>
                      <DatePicker
                        ref={focusDate}
                        className="datepicker-payscheme"
                        selected={dateInputs?.birthday ? dateInputs?.birthday : null}
                        onChange={(date) =>
                          selectUploadDt(date, "birthday")
                        }
                        name="birthday"
                        dateFormat="MM-dd-yyyy"
                        dropdownMode="select"
                        showMonthDropdown
                        showYearDropdown
                        calendarIcon={true}
                        closeCalendar={true}
                        clearIcon={true}
                        maxDate={new Date()}
                        disabled
                      />
                      <span className="calendar-icon" onClick={handleFocusDate}></span>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-3" controlId="str_soc_sec_num">
                      <Form.Label>Social security number <span className="asterisk">*</span></Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter social security number"
                        value={signatory.str_soc_sec_num}
                        onChange={handleInput}
                        name="str_soc_sec_num"
                        required
                        disabled
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <h5 className="mb-3">Signatory home address</h5>

                <Row>
                  <Col md={6}>
                    <Form.Group className="mb-3" controlId="address">
                      <Form.Label>Street 1 <span className="asterisk">*</span> </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter street 1"
                        value={signatory.address}
                        onChange={handleInput}
                        name="address"
                        maxLength="250"
                        required
                        disabled
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-3" controlId="address2">
                      <Form.Label>Street 2 (Optional)</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter street 2 "
                        value={signatory.address2}
                        onChange={handleInput}
                        name="address2"
                        maxLength="250"
                        disabled
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-3" controlId="city">
                      <Form.Label>City <span className="asterisk">*</span> </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter city"
                        value={signatory.city}
                        onChange={handleInput}
                        name="city"
                        maxLength="20"
                        required
                        disabled
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-3" controlId="state">
                      <Form.Label>State <span className="asterisk">*</span> </Form.Label>
                      <Select
                        closeMenuOnSelect={true}
                        hideSelectedOptions={false}
                        options={formatSelectOptions(stateList)}
                        onChange={(selectedOption) => handleStateOnChange(selectedOption)}
                        value={stateValue}
                        placeholder="Select state"
                        className="custom-select"
                        isDisabled
                      />

                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-3" controlId="zip">
                      <Form.Label>Zip Code <span className="asterisk">*</span> </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter zip code"
                        value={signatory.zip}
                        onChange={handleInput}
                        name="zip"
                        required
                        disabled
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <div className="text-end mt-4">
                  <Button
                    type="button"
                    disabled={loading}
                    loading={loading}
                    className="btn-primary text-white"
                    onClick={handleShow}
                  >
                    Change Signatory
                  </Button>
                </div>

              </Form>
            </Row>
          </Container>

          <FormModal heading="Change Signatory" show={isShowChangeSignatory} onClose={handleClose} size="md" backdrop="static" modalClassName="change_signatory_warning_modal">
            {
              showUserSign ?
                <Form noValidate validated={validated} onSubmit={handleSubmit} autoComplete="off" className="custom-form">
                  <div className="signatory-help-text mb-3 d-flex">
                    <div> <img src={infoIcon} alt="info" className="me-2" /></div>
                    <div> The signatory will only be displayed if they have successfully completed the onboarding process.</div>
                  </div>
                  <Form.Group className="mb-3" controlId="userId">
                    <Form.Label>Select the signatory <span className="asterisk">*</span> </Form.Label>
                    <Form.Select
                      onChange={handleSignatoryInput}
                      name="userId"
                      value={userId}
                      isInvalid={!!errors.userId}
                      required
                      size="lg"
                    >
                      <option value="">Select user</option>
                      {usersList &&
                        usersList.map((item, index) => (
                          <option key={item.id} value={item.id}>
                            {item.full_name}
                          </option>
                        ))}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      {errors.userId}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <div className="text-end">
                    <Button
                      type="button"
                      variant="secondary"
                      className="btn-default me-3 cancel-btn"
                      onClick={handleClose}
                    >
                      Close
                    </Button>
                    <Button variant="primary" className="btn_clr_white" type="submit" >
                      Change Signatory
                    </Button>
                  </div>
                </Form>
                :
                <>
                  <p>Are you sure do you want to change the signatory?</p>
                  <div className="text-end">
                    <Button
                      type="button"
                      variant="secondary"
                      className="btn-default me-3 cancel-btn"
                      onClick={handleClose}
                    >
                      Close
                    </Button>
                    <Button variant="primary" className="btn_clr_white" type="button" onClick={handleChangeSign}>
                      Yes
                    </Button>
                  </div>
                </>
            }

          </FormModal>
        </div>
      )}
      {loading && <Loader />}


    </>
  );
};

export default Signatory;
