import React, { useState } from "react";
import GreenCircle from "../../assets/images/settings_icons/check_circle.svg";
import { Button, Col, Container, Row, Spinner } from "react-bootstrap";
import logo from "../../assets/images/logo.png";
import LoginFooter from "../../pages/LoginFooter";
import "./style.scss";
import Loader from "../Loader";
import { autocloseTiming } from "../../api/regex";
import { toast } from "react-toastify";
import { postData } from "../../api";
export default function SuccessPage() {
  const [loading, setLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const handleLoginUser = async () => {
    setLoading(true);
    setBtnLoading(true);
    const res = await postData("otp-success", {});
    if (res.status === true) {
      setLoading(false);
      setBtnLoading(false);
      localStorage.setItem("guhroo_user", res.user_type);
      localStorage.setItem("guhroo_user_details", JSON.stringify(res));
      localStorage.setItem("guhroo_onbord_status", res.onboard_status);
      localStorage.setItem(
        "user_completion_status ",
        res.user_completion_status
      );
      localStorage.setItem("company_name", res.company_name);
      if (res.auto_login_url) {
        window.open(res.auto_login_url, "_self");
      }
    } else {
      toast.error(res.message, {
        theme: "colored",
        autoClose: autocloseTiming,
      });
    }
  };
  return (
    <>
      <div className="login-body">
        <div className="login-header">
          <img src={logo} alt="logo" />
        </div>
        <section className="login-section">
          <Container>
            <Row className="justify-content-center align-items-center main-container-wrapper">
              <Col md={6}>
                <div className="login-box mobile-verification-wrapper">
                  <div className="px-4 success-card-wrapper">
                    <img src={GreenCircle} alt="success" className="pb-4" />
                    <h5 className="pb-1">Successfully verified </h5>
                    <p>
                      Your two factor authentication has been successfully
                      verified{" "}
                    </p>
                  </div>

                  <div className="text-center mt-4 px-4 pb-5">
                    <Button
                      type="submit"
                      className="btn-primary text-white"
                      onClick={handleLoginUser}
                      loading={btnLoading}
                      disabled={btnLoading}
                    >
                      {btnLoading ? (
                        <Spinner animation="border" size="sm" />
                      ) : (
                        "Proceed"
                      )}
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>{" "}
            {loading && <Loader />}
          </Container>
        </section>
      </div>
      <LoginFooter />
    </>
  );
}
