import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Button,
  Table,
  Modal,
  Form,
} from "react-bootstrap";
import notesSvgIcon from "../../../assets/images/taskIconSvg.svg";
import CKEditor from "react-ckeditor-component";
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import "./style.scss";
import FormModal from "../../../components/FormModal";
import Loader from "../../../components/Loader";
import { postData } from "../../../api";
import { toast } from "react-toastify";
import { MdOutlineModeEditOutline } from "react-icons/md";
import { RiDeleteBin5Line } from "react-icons/ri";
import { Markup } from "interweave";
import { autocloseTiming } from '../../../api/regex';

const Notes = ({ notesData, userid, clientRole }) => {
  const [show, setShow] = useState(false);
  const [showAddNotesModal, setShowAddNotesModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [addNotesData, setAddNotesData] = useState({
    hidden: "0",
    note: "",
  });
  const [notesList, setNotesList] = useState([]);
  const [errors, setErrors] = useState({});
  const [validated, setValidated] = useState(false);
  const [showNotes, setShowNotes] = useState("");
  const [editNotesModal, setEditNotesModal] = useState(false);
  const [deleteShow, setDeleteShow] = useState(false);
  const [deleteRow, setDeletedRow] = useState([]);


  const handleClose = () => setShow(false);

  const handleDeleteClose = () => setDeleteShow(false);

  const handleAddNotesClose = () => {
    setShowAddNotesModal(false);
    setErrors({})
  };
  const handleAddNotesOpen = () => {
    setEditNotesModal(false);
    setShowAddNotesModal(true);
    setValidated(false)
    setErrors({})
    setAddNotesData({
      hidden: "0",
      note: "",
    });
  };

  let config = {
    toolbarGroups: [
      {
        name: "editing",
        groups: ["find", "selection", "spellchecker", "editing"],
      },
      { name: "basicstyles", groups: ["basicstyles", "cleanup"] },
      {
        name: "paragraph",
        groups: ["list", "indent", "blocks", "bidi", "paragraph"],
      },
      { name: "cancel", groups: ["undo", "redo"] },
    ],
    removeButtons:
      "Save,NewPage,Preview,Print,Templates,Cut,Copy,Paste,PasteText,PasteFromWord,Find,SelectAll,Scayt,Replace,Form,Checkbox,Textarea,Select,Button,ImageButton,HiddenField,CreateDiv,BidiLtr,BidiRtl,Language,Flash,Smiley,SpecialChar,PageBreak,Iframe,Anchor,ShowBlocks,About,CopyFormatting",
    fontSize_sizes: "16/16px;24/24px;48/48px;",
    font_names:
      "Arial/Arial, Helvetica, sans-serif;" +
      "Times New Roman/Times New Roman, Times, serif;" +
      "Verdana",
    allowedContent: true,
    disableNativeSpellChecker: false,
  };

  useEffect(() => {
    if (notesData && Object.keys(notesData).length !== 0) {
      setNotesList(notesData);
    }
  }, [notesData]);

  const onChange = (evt) => {
    var newContent = evt.editor.getData();
    setAddNotesData((prev) => ({
      ...prev,
      note: newContent,
    }));
  };

  const findFormErrors = () => {
    const { note } = addNotesData;
    const newErrors = {};

    if (!note || note === "") newErrors.note = "Please enter your note.";

    return newErrors;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const newErrors = findFormErrors();
    setValidated(true);
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      addNotes();
    }
  };

  const addNotes = async () => {
    setLoading(true);
    let data;
    if (editNotesModal) {
      data = {
        user_id: userid,
        stage: "notes",
        type: "edit",
        ...addNotesData,
      };
    } else {
      data = {
        user_id: userid,
        stage: "notes",
        ...addNotesData,
      };
    }

    const res = await postData("profile", {}, data);
    if (res.status === true) {
      setLoading(false);
      setShowAddNotesModal(false);
      let loadedData = res?.data;
      setNotesList(loadedData);
      toast.success(res.message, { theme: "colored", autoClose: autocloseTiming });
    } else {
      setShowAddNotesModal(false);
      toast.error(res.message, { theme: "colored", autoClose: autocloseTiming });;
      setLoading(false);
    }
  };

  const handleHidden = (e) => {
    if (e.target.checked === true) {
      setAddNotesData((prev) => ({
        ...prev,
        hidden: "1",
      }));
    } else {
      setAddNotesData((prev) => ({
        ...prev,
        hidden: "0",
      }));
    }
  };

  const showModalNotes = (row) => {
    setShow(true);
    setShowNotes(row?.note);
  };

  const editNotes = (row) => {
    setShowAddNotesModal(true);
    setEditNotesModal(true);
    setAddNotesData((prev) => ({
      ...prev,
      hidden: row?.hidden,
      note: row?.note,
      id: row?.id,
    }));
  };

  const handleDeleteNotes = async (row) => {
    setDeleteShow(true);
    setDeletedRow(row);
  };

  const deleteNotes = async () => {
    setDeleteShow(false);
    setLoading(true);
    let data = {
      user_id: userid,
      type: "delete",
      stage: "notes",
      ...deleteRow,
    };
    const res = await postData("profile", {}, data);
    if (res.status === true) {
      setLoading(false);
      let loadedData = res?.data;
      setNotesList(loadedData);
      toast.success(res.message, { theme: "colored", autoClose: autocloseTiming });
    } else {
      toast.error(res.message, { theme: "colored", autoClose: autocloseTiming });;
      setLoading(false);
    }
  };

  return (
    <>
      {!loading && (
        <div className="notes_container">
          <div>
            <Row >
              <Col className="d-flex align-items-center">
                <h5 className="tabs_main_heading">
                  <span className="me-1">
                    <img src={notesSvgIcon} alt="" srcSet="" />
                  </span>
                  Notes
                </h5>
              </Col>
            </Row>
            <Row className="table_mobile_scroll mt-3">
              <Table responsive className="header_bg_table">
                <thead>
                  <tr>
                    <th>Date </th>
                    <th>Author</th>
                    <th>Notes</th>
                    {clientRole !== "Contractor" &&
                      clientRole !== "Employee" && <th>Hidden</th>}

                    <th></th>
                  </tr>
                </thead>

                {notesList?.notes?.length !== 0 ?
                  <tbody>
                    {notesList?.notes?.map((notes) => {
                      return (
                        <tr key={notes?.id}>
                          <td>{notes?.date}</td>
                          <td>{notes?.author_id}</td>
                          <td>
                            <Markup
                              content={notes?.note?.substring(0, 50)}
                            />
                            {notes?.note?.length > 30 && (
                              <span
                                className="read-more-note"
                                onClick={() => showModalNotes(notes)}
                              >
                                Read more
                              </span>
                            )}
                          </td>
                          {clientRole !== "Contractor" &&
                            clientRole !== "Employee" && (
                              <td>{notes?.hidden === 1 && "Hidden"}</td>
                            )}
                          <td>
                            <div className="edit_delete_container d-flex">
                              <div
                                className={
                                  notes?.actions?.includes("Edit")
                                    ? "d-block"
                                    : "d-hide"
                                }
                              >
                                <div
                                  className="d-flex flex-column align-items-center me-3 d-block"
                                  onClick={() => editNotes(notes)}
                                >
                                  <MdOutlineModeEditOutline className="edit-notes" />
                                  <p>Edit</p>
                                </div>
                              </div>
                              <div
                                className={
                                  notes?.actions?.includes("Delete")
                                    ? "d-block"
                                    : "d-hide"
                                }
                              >
                                <div
                                  className={`d-flex flex-column align-items-center ${notes?.actions?.includes("Delete")
                                    ? "d-block"
                                    : "d-hide"
                                    }`}
                                  onClick={() => handleDeleteNotes(notes)}
                                >
                                  <RiDeleteBin5Line className="edit-notes" />
                                  <p>Delete</p>
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                  :
                  <tbody>
                    <tr>
                      <td colSpan="5">
                        <h4 className="no-data mb-0">No data found</h4>
                      </td>
                    </tr>
                  </tbody>
                }
              </Table>
            </Row>
            <Row>
              <Col xs={12} lg={12} className="d-flex add_notes_btn mt-2">
                <Button
                  variant="primary"
                  className="save_btn btn_clr_white"
                  onClick={handleAddNotesOpen}
                >
                  Add Notes
                </Button>
              </Col>
            </Row>
            <Modal show={show} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>Notes</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p>
                  {" "}
                  <Markup content={showNotes} />
                </p>
              </Modal.Body>
            </Modal>
            <FormModal
              heading="Comments"
              show={showAddNotesModal}
              size="lg"
              onClose={handleAddNotesClose}
              backdrop="static"
              modalClassName="view_task_modal notes_modal_dialog_box"
            >
              <Form
                noValidate
                validated={validated}
                onSubmit={handleSubmit}
                autoComplete="off"
                className="mt-3"
              >
                <Row>
                  <Col md={12}>
                    <Form.Group className="mb-3" controlId="note">
                      <CKEditor
                        activeClass="p10"
                        content={addNotesData?.note}
                        editor={ClassicEditor}
                        config={config}
                        data="<p>Hello from the first editor working with the context!</p>"
                        events={{
                          change: onChange,
                        }}
                      />
                      {validated && addNotesData?.note === "" && (
                        <div className="err-feedback">Please enter notes</div>
                      )}
                    </Form.Group>
                  </Col>
                  {notesList?.hideforuser ? (
                    <Col md={12}>
                      <Form.Group className="mb-3" controlId="hidden">
                        <div>
                          <Form.Check
                            inline
                            label="Hide from user"
                            name="hidden"
                            type="checkbox"
                            checked={addNotesData?.hidden?.toString() === "1" ? true : false}
                            value="1"
                            onChange={(e) => handleHidden(e)}
                            id="hidden"
                          />
                        </div>
                      </Form.Group>
                    </Col>
                  ) : null}
                </Row>
                <div className="text-end">
                  <Button
                    type="button"
                    variant="secondary"
                    className=" me-3 cancel_btn btn-default"
                    onClick={handleAddNotesClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="primary"
                    className="btn_clr_white save_btn"
                    type="submit"
                  >
                    Save
                  </Button>
                </div>
              </Form>
            </FormModal>
          </div>
        </div>
      )}

      {/* delete notes */}
      {
        deleteShow &&
        <FormModal heading="Are you sure you want to delete ?" show={deleteShow} size="sm" onClose={handleDeleteClose} backdrop="static" modalClassName="confirmation_modal">
          <div className="text-center">
            <Button
              variant="secondary"
              onClick={handleDeleteClose}
              className="btn_border_clr btn-default cancel-btn me-2"
            >
              Cancel
            </Button>
            <Button
              className="btn_clr_white"
              variant="primary"
              onClick={deleteNotes}
            >
              Delete
            </Button>
          </div>
        </FormModal>
      }
      {/* delete notes end */}
      {loading && <Loader />}
    </>
  );
};

export default Notes;
