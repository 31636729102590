import React from "react";
import ReportsDetailComponent from "../../components/reportsDetail";

export default function ReportsDetailPage() {
  return (
    <>
      <ReportsDetailComponent />{" "}
    </>
  );
}
