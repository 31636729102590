import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";
import { Row, Col } from "react-bootstrap";
import Loader from "../../components/Loader";
import ViewInput from "../../components/Form/Input/viewInput";
import { postData } from "../../api";
import Button from "../../components/Form/Button";
import { everyWeek, twiceMonth } from "./fieldArray";
import "./style.scss";

const PayrollScheme = ({ companySettingsObject }) => {
  let navigate = useNavigate();
  const [step, setStep] = useState();
  const [flowUrl, setFlowUrl] = useState(null);
  const [loading, setLoading] = useState(false);

  const handlePayFrequency = async () => {
    setLoading(true);
    const ResPayFrequency = await postData("flow_link_managepay", {});
    if (ResPayFrequency?.status === true) {
      setLoading(true);
      setFlowUrl(ResPayFrequency?.data?.url);
      const urlObj = {
        name: "Pay Frequency",
        url: ResPayFrequency?.data?.url,
        title: "PAYROLL SCHEDULE",
        subTitle: "Guhroo",
      };
      navigate("/manage-pay-schedule", { state: urlObj });
    } else setLoading(false);
  };

  const switchPayroll = (val) => {
    if (val === "Twice a month: Custom") {
      return twiceMonth;
    } else return everyWeek;
  };

  return (
    <>
      {!loading && (
        <div className="board-right board-tab-sections">
          <div className="payroll-sub-title">
            Why do we need to ask for this? We need to know when to pay your
            employees. Some states have{" "}
            <a
              href="https://www.dol.gov/agencies/whd/state/payday"
              target="_blank"
              rel="noopener noreferrer"
            >
              laws around when you must pay your employees.{" "}
            </a>{" "}
            Please choose pay schedules that are legal for your employees.
          </div>
          <div className="row mul-box">
            <Row className="mb-2">
              <Col md={6}>
                <h4 className="add-title">Pay frequency </h4>
              </Col>
              <Col md={6} className=" w-auto ml-auto">
                <Button
                  type="button"
                  onClick={handlePayFrequency}
                  className="btn btn-payFrequency"
                >
                  MANAGE PAY FREQUENCY
                </Button>
              </Col>
            </Row>
            <div className="pay-frequency">
              <Accordion activeKey={step} onSelect={(key) => setStep(key)}>
                {companySettingsObject.payroll?.map((item, index) => (
                  <Accordion.Item eventKey={item.id}>
                    {/* { ? ( */}
                    <Accordion.Header>
                      <div className="accordion-title-wrapper">
                        <p>{item.type}</p>
                        <div
                          className={
                            item.active === true
                              ? "active-status-success"
                              : "active-status-errors"
                          }
                        >
                          {item.active === true ? "In Use" : "Not In Use"}
                        </div>
                      </div>
                    </Accordion.Header>
                    {/* ) : null} */}
                    <Accordion.Body>
                      <div>
                        <Row>
                          {switchPayroll(item.type).map(
                            ({ label, type, name }) => (
                              <Col md={4}>
                                <ViewInput
                                  size="w-100"
                                  type={type}
                                  label={label}
                                  value={
                                    companySettingsObject.payroll[index][name]
                                  }
                                />
                              </Col>
                            )
                          )}
                        </Row>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            </div>
          </div>
          {/* </Form> */}
        </div>
      )}
      {loading && <Loader />}
    </>
  );
};

export default PayrollScheme;
