import React, { useState, useEffect, useRef, useContext } from "react";
import { Form, Row, Col } from "react-bootstrap";
import Button from "../../../components/Form/Button";
import Select from "react-select";
import { postData } from "../../../api";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment'
import { mustAlphaNumeric ,autocloseTiming} from '../../../api/regex';
import Signature from '../../../components/Signature';
import Loader from "../../../components/Loader";
import { SignContext } from "../../../hooks/Context/SignContext";

const EligibilityForm = ({ nextStep, onboardObject, eligibilityList, eSign, countryList }) => {
    const focusDate = useRef(null);
    const [validated, setValidated] = useState(false);
    const [loading, setLoading] = useState(false);
    const [employeeObject, setEmployeeObject] = useState({
        cityzen_status: "",
        multiple_option: 'align_number',
        e_sign: ''
    });
    const [dateInputs, setDateInputs] = useState({
        birthday: null,
    });
    const [errors, setErrors] = useState({});
    const [countryValue, setCountryValue] = useState();
    const [ackShow, setAckShow] = useState(true);
    const [signObject, setSignObject] = useState();
    const eSignValueNew = useContext(SignContext);



    const handleInput = (e) => {
        const value = e.target.value;
        const name = e.target.name;
        setEmployeeObject((prev) => ({
            ...prev,
            [name]: value,
        }));
        if (!!errors[name])
            setErrors({
                ...errors,
                [name]: null,
            });


    };


    useEffect(() => {
        if (onboardObject?.eligibility) {
            setEmployeeObjectData(onboardObject?.eligibility);
            if (onboardObject?.eligibility?.e_sign !== null && onboardObject?.eligibility?.e_sign !== '' && onboardObject?.eligibility?.e_sign !== undefined) {
                setAckShow(false)
                setEmployeeObject((prev) => ({
                    ...prev,
                    signature_data: onboardObject?.eligibility?.e_sign,

                }));
            }
        }
    }, [onboardObject]);



    useEffect(() => {
        if (eSign !== null && eSign !== '' && eSign !== undefined) {
            setAckShow(false)
        }
    }, [eSign]);

    useEffect(() => {
        if (signObject === undefined) {
            setSignObject(eSign)
        }
    }, [signObject]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (eSignValueNew?.eSignValue !== null && eSignValueNew?.eSignValue !== '' && eSignValueNew?.eSignValue !== undefined) {
            setAckShow(false)
            setEmployeeObject((prev) => ({
                ...prev,
                signature_data: eSignValueNew?.eSignValue,
                e_sign:eSignValueNew?.eSignValue
            }));
       
        }
    }, [eSignValueNew]);
    const setEmployeeObjectData = (data) => {
        if (data?.cityzen_status == '4') {
            if (data.foreign_pp_num) {
                data.multiple_option = 'passport_number';
                let country = {
                    label: data?.pp_issuance_country,
                    value: data?.pp_issuance_country,
                }
                setCountryValue(country);
            }
            if (data.authorize_exp_date) {
                data.multiple_option = 'align_number';
            }
            if (data.i94_admission_num) {
                data.multiple_option = 'admission_number';
            }
        }
        setEmployeeObject(data);
    }
    const handleJobsChange = (e) => {
        setEmployeeObject((prev) => ({
            ...prev,
            multiple_option: e.target.value,
        }));

    };
    const findFormErrors = () => {
        const { cityzen_status, multiple_option } = employeeObject;
        const newErrors = {};
        if (!cityzen_status || cityzen_status === "") newErrors.cityzen_status = "Select Citizenship status";
        if (cityzen_status === '3' && (!employeeObject?.alien_uscis_num || employeeObject?.alien_uscis_num === '')) newErrors.alien_uscis_num = 'This field is required';
        else if (cityzen_status === '3' && (employeeObject?.alien_uscis_num !== '' && (employeeObject?.alien_uscis_num.length < 7 || employeeObject?.alien_uscis_num.length > 9))) newErrors.alien_uscis_num = 'The Alien registration number/ USCIS number should be 7 to 9 digits';
        if (cityzen_status && cityzen_status === '4') {
            if (multiple_option === 'align_number') {
                if (!employeeObject.alien_uscis_num || employeeObject.alien_uscis_num === '') newErrors.alien_uscis_num = 'This field is required';
                if (!employeeObject.authorize_exp_date || employeeObject.authorize_exp_date === '') newErrors.authorize_exp_date = 'This field is required';
                else if (employeeObject?.alien_uscis_num !== '' && (employeeObject?.alien_uscis_num.length < 7 || employeeObject?.alien_uscis_num.length > 9)) newErrors.alien_uscis_num = 'The Alien registration number/ USCIS number should be 7 to 9 digits';
            }
            if (multiple_option === 'admission_number') {
                if (!employeeObject.i94_admission_num || employeeObject.i94_admission_num === '') newErrors.i94_admission_num = 'This field is required';
                else if (employeeObject.i94_admission_num !== '' && employeeObject.i94_admission_num.length !== 11) newErrors.i94_admission_num = 'The Form I-94 Admission number should 11 digits';
            }
            if (multiple_option === 'passport_number') {
                if (!employeeObject.foreign_pp_num || employeeObject.foreign_pp_num === '') newErrors.foreign_pp_num = 'This field is required';
                else if (employeeObject.foreign_pp_num.length < 4) {
                    newErrors.foreign_pp_num = "The foreign passport number should be  4 to 25 characters long";
                } else if (employeeObject.foreign_pp_num.length > 25) {
                    newErrors.foreign_pp_num = "The foreign passport number should be  4 to 25 characters long";
                }
                else if (!employeeObject.foreign_pp_num?.match(mustAlphaNumeric)) {
                    newErrors.foreign_pp_num = "The foreign passport number should be alphabetic";
                }
                if (!employeeObject.pp_issuance_country || employeeObject.pp_issuance_country === '' || employeeObject.pp_issuance_country === undefined) newErrors.pp_issuance_country = 'This field is required';
            }
        }
        return newErrors;
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const newErrors = findFormErrors();
        setValidated(true);
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
        } else {
            eligibilityFormSubmit();
        }
    };
    const eligibilityObjFormat = () => {
        let eligibilityObjCopy = { ...employeeObject };
        if (eligibilityObjCopy.cityzen_status === '4') {
            if (eligibilityObjCopy.multiple_option === 'align_number') {
                delete eligibilityObjCopy.i94_admission_num;
                delete eligibilityObjCopy.foreign_pp_num;
                delete eligibilityObjCopy.pp_issuance_country;
            }
            if (eligibilityObjCopy.multiple_option === 'admission_number') {
                delete eligibilityObjCopy.foreign_pp_num;
                delete eligibilityObjCopy.pp_issuance_country;
                delete eligibilityObjCopy.alien_uscis_num;
                delete eligibilityObjCopy.authorize_exp_date;
            }
            if (eligibilityObjCopy.multiple_option === 'passport_number') {
                delete eligibilityObjCopy.i94_admission_num;
                delete eligibilityObjCopy.alien_uscis_num;
                delete eligibilityObjCopy.authorize_exp_date;
            }
        }
        else if (eligibilityObjCopy.cityzen_status === '3') {
            delete eligibilityObjCopy.i94_admission_num;
            delete eligibilityObjCopy.foreign_pp_num;
            delete eligibilityObjCopy.pp_issuance_country;
            delete eligibilityObjCopy.authorize_exp_date;
        }
        else {
            delete eligibilityObjCopy.i94_admission_num;
            delete eligibilityObjCopy.foreign_pp_num;
            delete eligibilityObjCopy.pp_issuance_country;
            delete eligibilityObjCopy.authorize_exp_date;
            delete eligibilityObjCopy.alien_uscis_num;
        }
        delete eligibilityObjCopy.multiple_option;
        return eligibilityObjCopy;
    }
    const eligibilityFormSubmit = async () => {
        let eligibilityObj = eligibilityObjFormat();
        setLoading(true);
        let data = {
            stage: "eligibility_acknowledge",
            ...eligibilityObj
        }

        const res = await postData("employee_onboarding_steps", {}, data);
        if (res.status === true) {
            setLoading(false);
            setEmployeeObject((prev) => ({
                ...prev,
                e_sign: res?.data?.eligibility?.e_sign,
            }));
            nextStep(res.next_steps);

        } else {
             toast.error(res.message, { theme: "colored", autoClose: autocloseTiming });;
            setLoading(false);
        }
    }
    const handleFocusDate = () => {
        const datepickerElement = focusDate.current;
        datepickerElement.setFocus(true);
    };

    const selectUploadDt = (dateSel, name) => {
        const date = new Date(`${dateSel}`);
        const chkDt = date.getDate();

        if (chkDt > 0) {
            let val = formatDates(date);
            setEmployeeObject((prev) => ({
                ...prev,
                [name]: val
            }));
            setDateInputs((prev) => ({
                ...prev,
                [name]: dateSel,
            }));
        }
        if (!!errors[name])
            setErrors({
                ...errors,
                [name]: null,
            });

    };
    const minTwoDigit = (n) => {
        return (n < 10 ? "0" : "") + n;
    };

    const formatDates = (date) => {
        const day = minTwoDigit(date.getDate());
        const month = minTwoDigit(date.getMonth() + 1);
        const year = date.getFullYear();
        let val = `${year}-${month}-${day}`;
        return val;
    };
    const formatSelectOptions = (data) => {
        let finalArr = [];
        if (data && data.length > 0) {
            data.forEach((item) => {
                finalArr.push({
                    value: item.name,
                    label: item.name,
                    name: item.name,
                });
            });
        }
        return finalArr;
    };
    const handleStateOnChange = (data) => {
        setCountryValue(data)
        setEmployeeObject((prev) => ({
            ...prev,
            pp_issuance_country: data.value
        }));

    }

    // sign


    const acknowledgeShow = (data) => {
        setAckShow(data)
    }
    const signatureObj = (data) => {
        if (data?.drawSign !== '' || data?.uploadSign !== '') {
            if (data?.drawSign !== '') {
                setEmployeeObject((prev) => ({
                    ...prev,
                    signature_data: data?.drawSign
                }));
                // setSignObject(data?.drawSign)
            } else {
                setEmployeeObject((prev) => ({
                    ...prev,
                    signature_data: data?.uploadSign[0]
                }));
                // setSignObject(data?.uploadSign[0])
            }
        } else {
            setAckShow(true)
        }
    }


    return (
        <>
            {!loading && (
                <div className="board-right board-tab-sections">
                    <h5 className="mb-3"> Eligibility acknowledgement </h5>
                    <p>I am aware that federal law provides for imprisonment and/ or fines for false statement or use of false documents in connection with the completion of this form. </p>

                    <p>I attest under penalty of perjury, that I am (Select one of the following):</p>
                    <Form noValidate validated={validated} onSubmit={handleSubmit} autoComplete="off" className="custom-form">
                        <Row>
                            <Col md={6}>
                                <Form.Group className="mb-3" controlId="cityzen_status">
                                    <Form.Label>Citizenship status <span className="asterisk">*</span> </Form.Label>
                                    <Form.Select
                                        onChange={handleInput}
                                        name="cityzen_status"
                                        value={employeeObject?.cityzen_status}
                                        required
                                        size="lg"
                                    >
                                        <option value="">Select Citizenship status</option>
                                        {eligibilityList &&
                                            eligibilityList.map((item, index) => (
                                                <option key={item.id} value={item.value}>
                                                    {item.name}
                                                </option>
                                            ))}
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        {errors?.cityzen_status}
                                    </Form.Control.Feedback>

                                </Form.Group>
                            </Col>
                            {employeeObject?.cityzen_status && employeeObject.cityzen_status == '3' ? (
                                <>
                                    <Col md={6}>
                                        <Form.Group className="mb-3" controlId="alien_uscis_num">
                                            <Form.Label className="me-2">Alien registration number/ USCIS number <span className="asterisk">*</span></Form.Label>
                                            <Form.Control
                                                type="number"
                                                placeholder="Enter alien registration number/ USCIS number"
                                                value={employeeObject.alien_uscis_num}
                                                onChange={handleInput}
                                                isInvalid={!!errors.alien_uscis_num}
                                                name="alien_uscis_num"
                                                required
                                                maxLength="9"
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.alien_uscis_num}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                </>
                            ) : ''}
                        </Row>
                        {
                            employeeObject?.cityzen_status && employeeObject?.cityzen_status == '4' ? (
                                <>
                                    <Row>
                                        <Col md={12}>
                                            <Form.Group className="eligible-check mb-3" controlId="jobs">
                                                <Form.Label className="me-2">Verify using <span className="asterisk">*</span> </Form.Label>

                                                <div>
                                                    <Form.Check
                                                        inline
                                                        label="Alien registration number/ USCIS number"
                                                        name="jobs"
                                                        type="radio"
                                                        checked={employeeObject.multiple_option === "align_number" ? true : false}
                                                        value="align_number"
                                                        onChange={(e) => handleJobsChange(e)}
                                                        id="align_number"
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="Form I-94 Admission number"
                                                        name="jobs"
                                                        type="radio"
                                                        checked={employeeObject.multiple_option === "admission_number" ? true : false}
                                                        value="admission_number"
                                                        onChange={(e) => handleJobsChange(e)}
                                                        id="admission_number"
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="Foreign passport number"
                                                        name="jobs"
                                                        type="radio"
                                                        checked={employeeObject.multiple_option === "passport_number" ? true : false}
                                                        value="passport_number"
                                                        onChange={(e) => handleJobsChange(e)}
                                                        id="passport_number"
                                                    />
                                                </div>
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.jobs}
                                                </Form.Control.Feedback>

                                            </Form.Group>
                                        </Col>
                                        { }
                                    </Row>
                                </>
                            ) : ''
                        }
                        {employeeObject.cityzen_status == '4' && employeeObject.multiple_option === "align_number" ? (
                            <>
                                <Row>
                                    <Col md={6}>
                                        <Form.Group className="mb-3" controlId="alien_uscis_num">
                                            <Form.Label>Alien registration number/ USCIS number <span className="asterisk">*</span></Form.Label>
                                            <Form.Control
                                                type="number"
                                                placeholder="Enter alien registration number/ USCIS number"
                                                value={employeeObject.alien_uscis_num}
                                                onChange={handleInput}
                                                isInvalid={!!errors.alien_uscis_num}
                                                name="alien_uscis_num"
                                                required
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.alien_uscis_num}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <Form.Group className="position-relative mb-3" controlId="authorize_exp_date">
                                            <Form.Label>Authorized to work until <span className="asterisk">*</span></Form.Label>
                                            <DatePicker
                                                ref={focusDate}
                                                className="datepicker-payscheme"
                                                selected={dateInputs?.authorize_exp_date ? dateInputs?.authorize_exp_date : null}
                                                onChange={(date) =>
                                                    selectUploadDt(date, "authorize_exp_date")
                                                }
                                                name="authorize_exp_date"
                                                dateFormat="MM-dd-yyyy"
                                                dropdownMode="select"
                                                minDate={moment().toDate()}
                                                showMonthDropdown
                                                showYearDropdown
                                                calendarIcon={true}
                                                closeCalendar={true}
                                                clearIcon={true}
                                            />
                                            <span className="calendar-icon" onClick={handleFocusDate}></span>
                                            {
                                                validated && errors?.authorize_exp_date &&
                                                <div className="err-feedback">{errors?.authorize_exp_date}</div>
                                            }
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </>
                        ) : null}
                        {employeeObject.cityzen_status == '4' && employeeObject.multiple_option === "admission_number" ? (
                            <>
                                <Row>
                                    <Col md={6}>
                                        <Form.Group className="mb-3" controlId="i94_admission_num">
                                            <Form.Label>Form I-94 Admission number <span className="asterisk">*</span></Form.Label>
                                            <Form.Control
                                                type="number"
                                                placeholder="Enter form I-94 Admission number"
                                                value={employeeObject.i94_admission_num}
                                                onChange={handleInput}
                                                isInvalid={!!errors.i94_admission_num}
                                                name="i94_admission_num"
                                                required
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.i94_admission_num}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>

                                </Row>
                            </>
                        ) : null}
                        {employeeObject.cityzen_status == '4' && employeeObject.multiple_option === "passport_number" ? (
                            <>
                                <Row>
                                    <Col md={6}>
                                        <Form.Group className="mb-3" controlId="foreign_pp_num">
                                            <Form.Label>Foreign passport number <span className="asterisk">*</span></Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Enter Foreign passport number"
                                                value={employeeObject.foreign_pp_num}
                                                onChange={handleInput}
                                                isInvalid={!!errors.foreign_pp_num}
                                                name="foreign_pp_num"
                                                required
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.foreign_pp_num}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <Form.Group className="mb-3" controlId="pp_issuance_country">
                                            <Form.Label>Country of issuance <span className="asterisk">*</span> </Form.Label>
                                            <Select
                                                closeMenuOnSelect={true}
                                                hideSelectedOptions={false}
                                                options={formatSelectOptions(countryList)}
                                                onChange={(selectedOption) => handleStateOnChange(selectedOption)}
                                                value={countryValue}
                                                placeholder="Select country"
                                                className="custom-select"
                                            />

                                            {
                                                validated && (employeeObject?.pp_issuance_country === '' || employeeObject?.pp_issuance_country === undefined) &&
                                                <div className="err-feedback">{errors.pp_issuance_country}</div>
                                            }
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </>
                        ) : null}

                        <Row>
                            <Col md={6}>
                                <Signature className="mb-3" acknowledgeShow={acknowledgeShow} signatureObj={signatureObj} eSign={employeeObject?.e_sign} />
                            </Col>
                            {
                                <div className="err-feedback">{errors.signed}</div>
                            }
                        </Row>
                        <div className="text-end mt-4">
                            <Button
                                type="button"
                                className="btn-outline-primary me-2"
                                onClick={() => nextStep("direct_deposit")}
                            >
                                Back
                            </Button>
                            <Button
                                type="submit"
                                disabled={ackShow}
                                loading={loading}
                                className="btn-primary text-white"
                            >
                                Save & Continue
                            </Button>
                        </div>

                    </Form>


                </div>
            )}
            {loading && <Loader />}


        </>
    )
}

export default EligibilityForm