import React, { useState, useEffect } from "react";
import "./style.scss";
import { postData, getRevertUser, reactURL } from "../../api";
import { toast } from "react-toastify";
import Loader from "../../components/Loader";
import { Button } from "react-bootstrap";
import { Col, Row, Container } from "react-bootstrap";
import OnboardHeading from "../../components/OnboardHeading";
import ServerSideTable from "../../components/ServerSideTable";
import revertIcon from "../../assets/images/fa_refresh.svg";

const EmployeeStatus = () => {

    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [sizePerPage, setSizeperPage] = useState(10);
    const [totalSize, setTotalSize] = useState(0);
    const [employeeStatusList, setEmployeeStatusList] = useState([]);
    const [companyName, setCompanyName] = useState('');



    useEffect(() => {
        getEmployeeStatusList(page, sizePerPage, "");
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    const getEmployeeStatusList = async (page, sizePerPage, search) => {
        setLoading(true);
        let obj = {
            page: page,
            page_size: sizePerPage,
            search: search
        };
        try {
            const res = await postData("onboard-pending-users", {}, obj);
            if (res?.status === true) {
                setEmployeeStatusList(res?.data);
                setTotalSize(res?.total_items);
                setCompanyName(res?.company_name);
                setLoading(false);
            } else {
                setLoading(false);
                toast.error(res?.message, { theme: "colored" });
            }
        } catch (err) { }
    };


    const handleLinkUser = (userid) => {
     

        // window.open("http://localhost:3000/" + "profile/" + userid + "#statetax", "_self");
        window.open(reactURL + "profile/" + userid + "#statetax", "_self");
    }
    const currentStatusFormatter = (cell, row) => {
        return (
            <>
                {
                    row?.status?.length === 1 ?
                        <div>{row?.status[0]}</div>
                        :
                        row?.status?.length === 0 ?
                            <div>{"N/A"}</div>
                            :

                            <ul className="m-0 p-0 py-2">
                                {row?.status?.map((data) => {
                                    return (
                                        <li>{data}</li>
                                    )
                                })}
                            </ul>
                }
            </>
        )
    }

    const ActionFormatter = (cell, row) => {
        return (
            <>
                {
                    <div className="emp-action-status">
                        <Button onClick={() => handleLinkUser(row?.user_id)}>{row?.link}</Button>
                    </div>
                }
            </>
        )
    }

    const columns = [
        {
            dataField: "name",
            text: "User Name",
            headerStyle: { width: "20%", textAlign: "left" },
            style: { textAlign: "left" },
        },
        {
            dataField: "email",
            text: "Email ID",
            headerStyle: { width: "20%", textAlign: "left" },
            style: { textAlign: "left" }
        },
        {
            dataField: "status",
            text: "Status",
            headerStyle: { width: "16%", textAlign: "left" },
            style: { textAlign: "left" },
            formatter: currentStatusFormatter
        },
        {
            dataField: "link",
            text: "Action Required",
            headerStyle: { width: "16%", textAlign: "left" },
            style: { textAlign: "left" },
            formatter: ActionFormatter
        }
    ];
    const onFilter = (page, sizePerPage, search) => {
        setPage(page);
        setSizeperPage(sizePerPage);
        getEmployeeStatusList(page, sizePerPage, search);
    };

    const handleRevertUser = () => {
        window.open(getRevertUser(), "_self");
    }

    return (
        <>
            {!loading && (
                <Container fluid className="new_payroll">
                    <div className="paystub-view">
                        <Row>
                            <Col md={getRevertUser() ? 10 : 12} sm={getRevertUser() ? 8 : 12}>
                                <OnboardHeading title="Employee status" subtitle={companyName} />
                            </Col>
                            {
                                getRevertUser() ?
                                    <Col md={2} sm={getRevertUser() ? 4 : 2} className="d-flex align-items-center justify-content-end mb-4 mb-md-0">
                                        <div className="revert">
                                            <Button
                                                variant="danger"
                                                className="revert_btn"
                                                onClick={handleRevertUser}
                                            >
                                                <img src={revertIcon} alt="revert" className="revert-img me-1" />
                                                Revert User
                                            </Button>
                                        </div>
                                    </Col>
                                    : ''
                            }
                        </Row>

                        <div className="my-3 employee-status">
                            <ServerSideTable
                                columns={columns}
                                data={employeeStatusList}
                                page={page}
                                sizePerPage={sizePerPage}
                                totalSize={totalSize}
                                onFilter={onFilter}
                                loading={loading}
                            >
                            </ServerSideTable>
                        </div>
                    </div>
                </Container>
            )}
            {loading && <Loader />}


        </>
    )
}

export default EmployeeStatus