import React, { useState, useEffect } from "react";
import { Form, Row, Col } from "react-bootstrap";
import Button from "../../../components/Form/Button";
import { postUploadData } from "../../../api";
import { toast } from "react-toastify";
import { FileUploader } from "react-drag-drop-files";
import SuccessSubmitModal from './SuccessSubmitModal.js'
import { RiCloseLine } from 'react-icons/ri';
import Loader from "../../../components/Loader";
import { autocloseTiming } from '../../../api/regex';

const DocumentUpload = ({ nextStep, onboardObject }) => {
    const [validated, setValidated] = useState(false);
    const [loading, setLoading] = useState(false);
    const [employeeObject, setEmployeeObject] = useState({
        eligibility_in_person: "offline",
        learn_more: ''
    });
    const [errors, setErrors] = useState({});
    const [file, setFile] = useState([]);
    const [successSubmitShowModal, setSuccessSubmitShowModal] = useState(false);
    const [autoUrl, setAutoUrl] = useState();
    const fileTypes = ["JPEG", "PNG", "PDF", "DOCX", "SVG", "JPG"];
    let arr = [];

    useEffect(() => {
        if (onboardObject?.doc_upload) {
            let document_uplaod_copy = { ...onboardObject.doc_upload }
            document_uplaod_copy.eligibility_in_person = document_uplaod_copy?.eligibility_in_person == 1 ? 'offline' : 'online'
            setEmployeeObject(document_uplaod_copy);
        }
    }, [onboardObject]);


    //success modal 
    const successSubmitShowModalClose = () => {
        setSuccessSubmitShowModal(false);
    };

    const findFormErrors = () => {
        const { eligibility_in_person, acknowledge } = employeeObject;
        const newErrors = {};
        if (!eligibility_in_person || eligibility_in_person === "") newErrors.eligibility_in_person = "Please fill this fields .";
        if (!acknowledge) newErrors.acknowledge = "Please acknowledge.";
        if (file.length === 0 && eligibility_in_person === "online") {
            newErrors.file = "Please upload the file.";
        }

        return newErrors;
    };

    const handleChange = (item) => {
        Object.keys(item).forEach(key => {
            arr.push(item[key]);
        });
        if (arr.length > 5) {
            toast.error("Exceeds limit.First 5 files only considered", { theme: "colored", autoClose: autocloseTiming });
        }
        setFile([...file, ...arr.slice(0, 5 - file.length)]);
        setErrors({
            ...errors,
            file: null,
        });
    }

    const clearFileUpload = (item, index) => {
        const deletedFile = file.filter((data, i) => i !== index);
        setFile(deletedFile);
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        const newErrors = findFormErrors();
        setValidated(true);

        // check size is 10MB
        let totalSize = 0;
        file.forEach((item, i) => {
            totalSize += (item.size / Math.pow(1024, 1))
        });
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
        } else if (totalSize > 10000) {
            toast.error("Total size exceeds 10MB", { theme: "colored", autoClose: autocloseTiming });
        } else {
            documentUpload();
        }
    };
    const documentUpload = async () => {
        setLoading(true);
        let objCopy = { ...employeeObject };
        objCopy.eligibility_in_person = objCopy.eligibility_in_person === 'offline' ? 1 : 0;
        let data = new FormData();
        data.append('stage', 'doc_upload');
        data.append('eligibility_in_person', objCopy.eligibility_in_person);

        if (employeeObject.eligibility_in_person === 'online') {
            file.forEach((item, i) => {
                data.append('documents' + (i + 1), item);
            });
        }
        setLoading(true);

        const res = await postUploadData("employee_onboarding_steps", {}, data);
        if (res.status === true) {
            setLoading(false);
            localStorage.setItem("guhroo_user", res.user_type)
            localStorage.setItem("guhroo_user_details", JSON.stringify(res));
            localStorage.setItem("guhroo_onbord_status", res.onboard_status);
            localStorage.setItem("user_completion_status ", res.user_completion_status);
            setAutoUrl(res.auto_url)
            setSuccessSubmitShowModal(true);
        } else {
            toast.error(res.message, { theme: "colored", autoClose: autocloseTiming });
            setLoading(false);
        }
    };

    const handleDocUpload = (e) => {
        setEmployeeObject((prev) => ({
            ...prev,
            eligibility_in_person: e.target.value,
        }));
    };

    const handleCheckAcknowledgeChange = (e) => {
        const name = e.target.name;
        if (e.target.checked === true) {
            setEmployeeObject((prev) => ({
                ...prev,
                acknowledge: true,
            }));
            if (!!errors[name])
                setErrors({
                    ...errors,
                    acknowledge: null,
                });
        } else {
            setEmployeeObject((prev) => ({
                ...prev,
                acknowledge: false,
            }));
            if (!!errors[name])
                setErrors({
                    ...errors,
                    acknowledge: null,
                });
        }
    };

    const handleSizeError = (fileErr) => {
        toast.error(fileErr, { theme: "colored", autoClose: autocloseTiming });;
    }

    const handleFileTypeError = (fileErr) => {
        toast.error(fileErr, { theme: "colored", autoClose: autocloseTiming });;
    }
    return (
        <>

            {!loading && (
                <>
                    <div className="board-right board-tab-sections">
                        <h5 className="mb-3">Eligibility documentation</h5>
                        <div className="employee-title">Please provide documents that verifies your identity and eligibility to work. Provide single or multiple documents that verifies both your identity and eligibility to work.
                            <a href={employeeObject?.learn_more} target="_blank" without rel="noreferrer"> Learn more about eligible documents. <span className="asterisk">*</span> </a></div>
                        <Form noValidate validated={validated} onSubmit={handleSubmit} autoComplete="off" className="custom-form">
                            <Row>
                                <Col md={6}>
                                    <Form.Group className="mb-3 doc-check" controlId="eligibility_in_person">
                                        <div>
                                            <Form.Check
                                                label="I will provide my eligibility documents in person"
                                                name="eligibility_in_person"
                                                type="radio"
                                                checked={employeeObject.eligibility_in_person === "offline" ? true : false}
                                                value="offline"
                                                onChange={(e) => handleDocUpload(e)}
                                                id="offline"
                                            />
                                            <Form.Check
                                                label="Upload documents online"
                                                name="eligibility_in_person"
                                                type="radio"
                                                className="online-doc-upload"
                                                checked={employeeObject.eligibility_in_person === "online" ? true : false}
                                                value="online"
                                                onChange={(e) => handleDocUpload(e)}
                                                id="online"
                                            />
                                        </div>
                                        <Form.Control.Feedback type="invalid">
                                            <div className="err-feedback"> {errors.eligibility_in_person} </div>
                                        </Form.Control.Feedback>

                                    </Form.Group>
                                </Col>
                            </Row>
                            {
                                employeeObject.eligibility_in_person && employeeObject.eligibility_in_person === "online" ? (
                                    <>
                                        <Row>
                                            <Col md={6} className="drag-background">
                                                <div className="m-3">
                                                    <FileUploader
                                                        multiple={true}
                                                        handleChange={handleChange}
                                                        onSizeError={handleSizeError}
                                                        onTypeError={handleFileTypeError}
                                                        name="file"
                                                        label={file.length !== 0 ? "Uploaded Successfully!.Another upload?" : " Drop your files here or browse. You can add a maximum of 5 Images, and the total file size should not exceed 10MB."}
                                                        types={fileTypes}
                                                        classes="employee-document-upload"
                                                        maxSize="10"
                                                    />
                                                    {
                                                        validated && file.length === 0 && employeeObject.eligibility_in_person === "online" &&
                                                        <div className="err-feedback">Please upload the file</div>
                                                    }
                                                    {
                                                        file?.map(function (item, i) {
                                                            return (
                                                                <>
                                                                    <div className="file_list mt-3" key={i}>
                                                                        <div className="f-card d-flex justify-content-between position-relative">
                                                                            <div className="d-flex align-items-center">
                                                                                <div className="image_type"></div>
                                                                                <div className="file_name">{item.name}</div>
                                                                            </div>
                                                                            <div className="d-flex align-items-center">
                                                                                <div className="file_size">{(item.size / Math.pow(1024, 1)).toFixed(2)}kb</div>
                                                                                <div className="remove_file" onClick={() => clearFileUpload(item, i)}><RiCloseLine /></div>
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </>

                                                            )
                                                        })
                                                    }
                                                </div>
                                            </Col>

                                        </Row>
                                    </>
                                ) : null
                            }
                            <>
                                <Row className="check-custom mt-5">
                                    <Col md={8} className="align-seld-center">
                                        <Form.Group className="bank-check ack-step-last" controlId="acknowledge">
                                            <Form.Check
                                                label={
                                                    <span>
                                                        I agree to the
                                                        <a className="ms-1" href="https://flows.gusto.com/terms" target="_blank" rel="noopener noreferrer">
                                                            Terms of Service
                                                        </a>
                                                    </span>
                                                }
                                                name="acknowledge"
                                                type="checkbox"
                                                checked={employeeObject.acknowledge}
                                                value="1"
                                                onChange={(e) => handleCheckAcknowledgeChange(e)}
                                                id="acknowledge_check"
                                                required
                                            />
                                            {
                                                validated &&
                                                <div className="err-feedback">{errors.acknowledge}</div>
                                            }
                                        </Form.Group>
                                    </Col>
                                    <Col md={4}>
                                        <div className="text-end">
                                            <Button
                                                type="button"
                                                className="btn-outline-primary me-2"
                                                onClick={() => nextStep("eligibility_acknowledge")}
                                            >
                                                Back
                                            </Button>
                                            <Button
                                                type="submit"
                                                disabled={loading}
                                                loading={loading}
                                                className="btn-primary text-white"
                                            >
                                                Submit
                                            </Button>
                                        </div>

                                    </Col>
                                </Row>
                            </>

                        </Form>


                    </div>
                    {successSubmitShowModal && <SuccessSubmitModal show={successSubmitShowModal} onClose={successSubmitShowModalClose} autoUrl={autoUrl} user={onboardObject} />}
                </>
            )
            }
            {loading && <Loader />}

        </>
    )
}

export default DocumentUpload