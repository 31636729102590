import React from "react";
import "./style.scss";

export default function TitleComponent({ data }) {
  return (
    <div className="reports-csv-filter-header">
      <div className="board-header">
        <div className="heading-content">
          <div className="head_pad">
            <h5>
              <span className="text-capitalize">{data?.name}</span>
            </h5>
          </div>
        </div>
      </div>
    </div>
  );
}
