import React, { useState, useEffect } from "react";
import {
  Col,
  Row,
  Form,
  Button,
  InputGroup,
} from "react-bootstrap";
import emergencyIcon from "../../../assets/images/medical_services.svg";
import "./style.scss";
import { postData ,phoneNumberFormat} from "../../../api";
import { mustNumberswithPhone, onlyCharacterWithSpace, emptySpace, onlyCharacterWithSpaceAndComma, autocloseTiming } from "../../../api/regex";
import { toast } from "react-toastify";
import Loader from "../../../components/Loader";

const EmergencyTab = ({ emergencyData, userid, clientRole, relationshipList }) => {
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [emergencyTabData, setEmergencyTabData] = useState({
    pc_fullname: "",
    pc_relation: "",
    pc_other: "",
    pc_phone: "",
    pc_alternate_phone: "",
    // 
    sc_fullname: "",
    sc_relation: "",
    sc_other: "",
    sc_phone: "",
    sc_alternate_phone: "",

  });
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (emergencyData && Object.keys(emergencyData).length !== 0) {
      setEmergencyTabData(emergencyData);
    }
  }, [emergencyData]);



  const handleInput = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setEmergencyTabData((prev) => ({
      ...prev,
      [name]: value,
    }));
    if (!!errors[name])
      setErrors({
        ...errors,
        [name]: null,
      });
  };


  
  const handlePhoneFormatChange = (e) => {
    const name = e.target.name;
    e.target.value = phoneNumberFormat(e.target.value)
    setEmergencyTabData((prev) => ({
        ...prev,
        [name]: e.target.value,
    }));
}

const handlePhoneNumberKeyPress = (event) => {
    const keyCode = event.which || event.keyCode;
    const keyValue = String.fromCharCode(keyCode);
    const isValidKey = /^\d$/.test(keyValue);
    const inputValue = event.target.value?.replace(/-/g, ''); // Remove hyphens from input value
    const isInputValidLength = inputValue.length < 10;

    if (!isValidKey || !isInputValidLength) {
        event.preventDefault();
    }
};

  const findFormErrors = () => {
    const { pc_fullname, pc_relation, pc_alternate_phone, pc_other, pc_phone, sc_fullname, sc_alternate_phone, sc_relation, sc_other, sc_phone } = emergencyTabData;
    const newErrors = {};
    if (!pc_fullname || pc_fullname === "") newErrors.pc_fullname = "Please enter your full name.";
    else if (pc_fullname.length < 3) {
      newErrors.pc_fullname = "The full name should be  3 to 70 characters long";
    } else if (pc_fullname.length > 70) {
      newErrors.pc_fullname = "The full name should be  3 to 70 characters long";
    }
    else if (!pc_fullname?.match(emptySpace)) {
      newErrors.pc_fullname = "The full name should be  3 to 70 characters long";
    }
    else if (!pc_fullname?.match(onlyCharacterWithSpace)) {
      newErrors.pc_fullname = "The full name should be alphabetic";
    }
    if (!pc_relation || pc_relation === "") newErrors.pc_relation = "Please select relationship.";
    else if (pc_relation.length < 3) {
      newErrors.pc_relation = "The relationship name should be  3 to 70 characters long";
    } else if (pc_relation.length > 70) {
      newErrors.pc_relation = "The relationship name should be  3 to 70 characters long";
    }
    else if (!pc_relation?.match(emptySpace)) {
      newErrors.pc_relation = "The relationship name should be  3 to 70 characters long";
    }
    else if (!pc_relation?.match(onlyCharacterWithSpace)) {
      newErrors.pc_relation = "The relationship name should be alphabetic";
    }

    if (emergencyTabData.pc_relation === "Other") {
      if (!pc_other || pc_other === "") newErrors.pc_other = "Please enter your other relationship.";
      else if (pc_other.length < 3) {
        newErrors.pc_other = "The other relationship  should be  3 to 20 characters long";
      } else if (pc_other.length > 20) {
        newErrors.pc_other = "The other relationship should be  3 to 20 characters long";
      }
      else if (!pc_other?.match(onlyCharacterWithSpaceAndComma)) {
        newErrors.pc_other = "The full name should be alphabetic";
      }
    }

    if (!pc_phone || pc_phone === "" || pc_phone === null) newErrors.pc_phone = "Please enter your phone number.";
    if (pc_phone !== "") {
      const Phone_numbers_Only = pc_phone?.replace(/\D/g, '')
      if (!Phone_numbers_Only?.match(mustNumberswithPhone)) {
        newErrors.pc_phone = "The phone number should be numeric.";
      } else if (Phone_numbers_Only.length !== 10) {
        newErrors.pc_phone = "Please enter a 10 digit phone number";
      }
    }
    if (pc_alternate_phone !== "" && pc_alternate_phone !== undefined) {
      const Phone_numbers_Only = pc_alternate_phone?.replace(/\D/g, '')
      if (!Phone_numbers_Only?.match(mustNumberswithPhone)) {
        newErrors.pc_alternate_phone = "The phone number should be numeric.";
      } else if (Phone_numbers_Only.length !== 10) {
        newErrors.pc_alternate_phone = "Please enter a 10 digit phone number";
      }
    }

    // secondary

    if (sc_fullname !== "" && sc_fullname !== undefined) {
      if (sc_fullname.length < 3) {
        newErrors.sc_fullname = "The full name should be  3 to 70 characters long";
      } else if (sc_fullname.length > 70) {
        newErrors.sc_fullname = "The full name should be  3 to 70 characters long";
      }
      else if (!sc_fullname?.match(onlyCharacterWithSpace)) {
        newErrors.sc_fullname = "The full name should be alphabetic";
      }
      else if (!sc_fullname?.match(emptySpace)) {
        newErrors.sc_fullname = "The full name should be  3 to 70 characters long";
      }
    }
    if (sc_relation !== "" && sc_relation !== undefined) {
      if (sc_relation.length < 3) {
        newErrors.sc_relation = "The relationship name should be  3 to 70 characters long";
      } else if (sc_relation.length > 70) {
        newErrors.sc_relation = "The relationship name should be  3 to 70 characters long";
      }
      else if (!sc_relation?.match(emptySpace)) {
        newErrors.sc_relation = "The relationship name should be  3 to 70 characters long";
      }
      else if (!sc_relation?.match(onlyCharacterWithSpace)) {
        newErrors.sc_relation = "The relationship name should be alphabetic";
      }
    }

    if (emergencyTabData.sc_relation === "Other") {
      if (!sc_other || sc_other === "") newErrors.sc_other = "Please enter your other relationship.";
      else if (sc_other.length < 3) {
        newErrors.sc_other = "The other relationship  should be  3 to 20 characters long";
      } else if (sc_other.length > 20) {
        newErrors.sc_other = "The other relationship should be  3 to 20 characters long";
      }
      else if (!sc_other?.match(onlyCharacterWithSpaceAndComma)) {
        newErrors.sc_other = "The full name should be alphabetic";
      }
    }

    if (sc_phone !== "" && sc_phone !== undefined) {
      const Phone_numbers_Only = sc_phone?.replace(/\D/g, '')
      if (!Phone_numbers_Only?.match(mustNumberswithPhone)) {
        newErrors.sc_phone = "The phone number should be numeric.";
      } else if (Phone_numbers_Only.length !== 10) {
        newErrors.sc_phone = "Please enter a 10 digit phone number";
      }
    }
    if (sc_alternate_phone !== "" && sc_alternate_phone !== undefined) {
      const Phone_numbers_Only = sc_alternate_phone?.replace(/\D/g, '')
      if (!Phone_numbers_Only?.match(mustNumberswithPhone)) {
        newErrors.sc_alternate_phone = "The phone number should be numeric.";
      } else if (Phone_numbers_Only.length !== 10) {
        newErrors.sc_alternate_phone = "Please enter a 10 digit phone number";
      }
    }

    return newErrors;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const newErrors = findFormErrors();
    setValidated(true);
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      emergencyContact();
    }
  };

  const emergencyContact = async () => {
    setLoading(true);
    let data = {
      user_id: userid,
      stage: "emergency_contact",
      ...emergencyTabData
    }
    const res = await postData("profile", {}, data);
    if (res.status === true) {
      setLoading(false);
      toast.success(res.message, { theme: "colored", autoClose: autocloseTiming });
    } else {
      toast.error(res.message, { theme: "colored", autoClose: autocloseTiming });;
      setLoading(false);
    }

  };
  return (
    <>

      {!loading && (
        <div className="emerge+ncy_container">
          <div>
            <Row className="d-flex justify-content-between mb-3">
              <Col lg={2}>
                <h5 className="tabs_main_heading">
                  <span className="me-1">
                    <img src={emergencyIcon} alt="" srcSet="" />
                  </span>
                  Emergency
                </h5>
              </Col>
            </Row>
            <Form noValidate validated={validated} onSubmit={handleSubmit} autoComplete="off">
              <Row>
                <h6>Primary emergency contact</h6>
                <Col lg={4}>
                  <Form.Group className="mb-3" controlId="contactName">
                    <Form.Label>Contact Name <span className="asterisk">*</span> </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter full name"
                      value={emergencyTabData.pc_fullname}
                      onChange={handleInput}
                      isInvalid={!!errors.pc_fullname}
                      name="pc_fullname"
                      maxLength="70"
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.pc_fullname}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col lg={4}>
                  <Form.Group className="mb-3" controlId="contactRelationship">
                    <Form.Label>Contact relationship <span className="asterisk">*</span> </Form.Label>
                    <Form.Select
                      onChange={handleInput}
                      name="pc_relation"
                      value={emergencyTabData.pc_relation}
                      required
                      size="lg"
                    >
                      <option value="">Select relationship</option>
                      {relationshipList &&
                        relationshipList.map((item, index) => (
                          <option key={item.id} value={item.value}>
                            {item.name}
                          </option>
                        ))}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      {errors.pc_relation}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={4} className={`${emergencyTabData.pc_relation === "Other" ? "d-block" : 'd-none'}`}>
                  <Form.Group className="mb-3" controlId="pc_other">
                    <Form.Label>Other</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter other"
                      value={emergencyTabData.pc_other}
                      onChange={handleInput}
                      isInvalid={!!errors.pc_other}
                      name="pc_other"
                      maxLength="70"
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.pc_other}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col lg={4}>
                  <Form.Group className="mb-3" controlId="lastName">
                    <Form.Label>Phone Number <span className="asterisk">*</span> </Form.Label>
                    <InputGroup className="phone-group personal-phone">
                      <InputGroup.Text>
                        +1
                      </InputGroup.Text>
                      <Form.Control
                        type="text"
                        placeholder="Enter phone number"
                        value={emergencyTabData.pc_phone}
                        onChange={handleInput}
                        isInvalid={!!errors.pc_phone}
                        name="pc_phone"
                        onBlur={handlePhoneFormatChange}
                        onKeyPress={handlePhoneNumberKeyPress}
                        // maxLength="10"
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.pc_phone}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                </Col>
                <Col lg={4}>
                  <Form.Group className="mb-3" controlId="dateOfBirth">
                    <Form.Label>Alternate phone number</Form.Label>
                    <InputGroup className="phone-group personal-phone">
                      <InputGroup.Text>
                        +1
                      </InputGroup.Text>
                      <Form.Control
                        type="text"
                        placeholder="Enter alternate phone number"
                        value={emergencyTabData.pc_alternate_phone}
                        onChange={handleInput}
                        isInvalid={!!errors.pc_alternate_phone}
                        name="pc_alternate_phone"
                        onBlur={handlePhoneFormatChange}
                        onKeyPress={handlePhoneNumberKeyPress}
                      // maxLength="10"
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.pc_alternate_phone}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <h6>Alternate emergency contact</h6>
                <Col lg={4}>
                  <Form.Group className="mb-3" controlId="adress">
                    <Form.Label>Contact name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter full name"
                      value={emergencyTabData.sc_fullname}
                      onChange={handleInput}
                      isInvalid={!!errors.sc_fullname}
                      name="sc_fullname"
                      maxLength="70"
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.sc_fullname}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col lg={4}>
                  <Form.Group className="mb-3" controlId="sc_relation">
                    <Form.Label>Contact relationship</Form.Label>
                    <Form.Select
                      onChange={handleInput}
                      name="sc_relation"
                      value={emergencyTabData.sc_relation}
                      size="lg"
                    >
                      <option value="">Select relationship</option>
                      {relationshipList &&
                        relationshipList.map((item, index) => (
                          <option key={item.id} value={item.value}>
                            {item.name}
                          </option>
                        ))}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      {errors.sc_relation}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={4} className={`${emergencyTabData.sc_relation === "Other" ? "d-block" : 'd-none'}`}>
                  <Form.Group className="mb-3" controlId="sc_other">
                    <Form.Label>Other</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter other relationship"
                      value={emergencyTabData.sc_other}
                      onChange={handleInput}
                      isInvalid={!!errors.sc_other}
                      name="sc_other"
                      maxLength="70"
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.sc_other}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col lg={4}>
                  <Form.Group className="mb-3" controlId="stateSelect">
                    <Form.Label>Phone number</Form.Label>
                    <InputGroup className="phone-group personal-phone">
                      <InputGroup.Text>
                        +1
                      </InputGroup.Text>
                      <Form.Control
                        type="text"
                        placeholder="Enter phone number"
                        value={emergencyTabData.sc_phone}
                        onChange={handleInput}
                        isInvalid={!!errors.sc_phone}
                        name="sc_phone"
                        onBlur={handlePhoneFormatChange}
                        onKeyPress={handlePhoneNumberKeyPress}
                      // maxLength="10"
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.sc_phone}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                </Col>
                <Col lg={4}>
                  <Form.Group className="mb-3" controlId="stateSelect">
                    <Form.Label>Alternate Phone number </Form.Label>
                    <InputGroup className="phone-group personal-phone">
                      <InputGroup.Text>
                        +1
                      </InputGroup.Text>
                      <Form.Control
                        type="text"
                        placeholder="Enter alternate phone number"
                        value={emergencyTabData.sc_alternate_phone}
                        onChange={handleInput}
                        isInvalid={!!errors.sc_alternate_phone}
                        name="sc_alternate_phone"
                        onBlur={handlePhoneFormatChange}
                        onKeyPress={handlePhoneNumberKeyPress}
                      // maxLength="10"
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.sc_alternate_phone}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="d-flex justify-content-end">
                <Col sm={12} md={4} className="d-flex justify-content-end">
                  <Button variant="primary" className="save_btn" type="submit">
                    Save
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      )}
      {loading && <Loader />}
    </>
  );
};

export default EmergencyTab;
