import React, { useState } from "react";
import { Table, Row, Button } from "react-bootstrap";
import { FaDownload } from "react-icons/fa";

const TablePagination = ({ data, onClickHandler, type }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 10;
  const totalPages = Math.ceil(data?.length / rowsPerPage);

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const handlePrevPageClick = () => {
    setCurrentPage((currentPage) => Math.max(currentPage - 1, 1));
  };

  const handleNextPageClick = () => {
    setCurrentPage((currentPage) => Math.min(currentPage + 1, totalPages));
  };

  const renderRows = () => {
    const startIndex = (currentPage - 1) * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    if (data !== undefined && data.length !== 0) {
      return data.slice(startIndex, endIndex).map((row, index) => (
        <tr key={index}>
          {type === "paystub" && (
            <td onClick={() => onClickHandler(row)}>{row?.date}</td>
          )}
           {type === "w2" && (
            <td onClick={() => onClickHandler(row)}>{row?.year}</td>
          )}
          <td>
            <a href={row?.url} target="_blank" rel="noopener noreferrer">
              <FaDownload />
            </a>
          </td>
        </tr>
      ));
    }else{
      return (
        <tr>
          <td colSpan="2">
            <h4 className="no-data mb-0">No data found</h4>
          </td>
        </tr>
      );
    }
  };

  const renderPaginationButtons = () => {
    const pageNumbers = [];

    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(i);
    }

    return (
      <div className="pagination">
        <Button
          onClick={handlePrevPageClick}
          disabled={currentPage === 1}
          className="non_active_btn"
        >
          {"<"}
        </Button>
        {pageNumbers.map((pageNumber) => (
          <Button
            key={pageNumber}
            onClick={() => handlePageClick(pageNumber)}
            className={
              currentPage === pageNumber ? "active_btn" : "non_active_btn"
            }
          >
            {pageNumber}
          </Button>
        ))}
        <Button
          onClick={handleNextPageClick}
          disabled={currentPage === pageNumbers.length}
          className="non_active_btn"
        >
          {">"}
        </Button>
      </div>
    );
  };

  return (
    <>
      <Row className="table_mobile_scroll mt-3">
        <Table responsive className="header_bg_table">
          <thead>
            <tr>
              <th>Pay Date</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>{renderRows()}</tbody>
        </Table>
      </Row>
      {totalPages > 1 && renderPaginationButtons()}
    </>
  );
};

export default TablePagination;
