import React from "react";
import PayPeriodSummary from "../../components/payPeriodSummary";

export default function PayPeriodSummaryPage() {
  return (
    <>
      <PayPeriodSummary />
    </>
  );
}
