import React, { useState, useEffect } from "react";
import { Row, Col, Button, Container } from "react-bootstrap";
import "./style.scss";
import { postData, getRevertUser } from "../../api";
import { toast } from "react-toastify";
import OnboardHeading from "../../components/OnboardHeading";
import Loader from "../../components/Loader";
import revertIcon from "../../assets/images/fa_refresh.svg";
import { autocloseTiming } from "../../api/regex";
import OnboardSvg from "../../assets/images/settings_icons/onboard.svg";
import ForwardIcon from "../../assets/images/settings_icons/arrow_forward.svg";
import PayrollSvg from "../../assets/images/settings_icons/payroll.svg";
import PerformanceSvg from "../../assets/images/settings_icons/performance.svg";
import TimeOffSvg from "../../assets/images/settings_icons/timeoff.svg";
import NotificationSvg from "../../assets/images/settings_icons/notifications.svg";
import CompanyDetailsSvg from "../../assets/images/settings_icons/company_details.svg";
import SignatorySvg from "../../assets/images/settings_icons/signatory.svg";
import FormsSvg from "../../assets/images/settings_icons/forms.svg";
import StatetaxSvg from "../../assets/images/settings_icons/statetax.svg";
import CompanyProfileSvg from "../../assets/images/settings_icons/company_profile.svg";
import IntegrationSvg from "../../assets/images/settings_icons/integration.svg";
import TwoFactorSvg from "../../assets/images/settings_icons/2FA.svg";

const Settings = () => {
  const [loading, setLoading] = useState(false);
  const [settingsObject, setSettingsObject] = useState([]);
  const [displayedErrors, setDisplayedErrors] = useState([]);

  useEffect(() => {
    getSettingsList();
  }, []);

  const getSettingsList = async () => {
    setLoading(true);
    const res = await postData("setting_datas", {});
    if (res.status === true) {
      setLoading(false);
      setSettingsObject(res);
    } else {
      toast.error(res.message, {
        theme: "colored",
        autoClose: autocloseTiming,
      });
      setLoading(false);
    }
  };

  const handleRevertUser = () => {
    window.open(getRevertUser(), "_self");
  };

  const handleSettingsRedirectClick = (link) => {
    window.open(link, "_self");
  };

  return (
    <>
      {!loading && (
        <Container fluid className="new_company">
          <Row>
            <Col md={getRevertUser() ? 10 : 12} sm={getRevertUser() ? 8 : 12}>
              <OnboardHeading
                title="Settings"
                subtitle={settingsObject?.company_name}
              />
            </Col>
            {getRevertUser() ? (
              <Col
                md={2}
                sm={getRevertUser() ? 4 : 2}
                className="d-flex align-items-center justify-content-end mb-4 mb-md-0"
              >
                <div className="revert">
                  <Button
                    variant="danger"
                    className="revert_btn"
                    onClick={handleRevertUser}
                  >
                    <img
                      src={revertIcon}
                      alt="revert"
                      className="revert-img me-1"
                    />
                    Revert User
                  </Button>
                </div>
              </Col>
            ) : (
              ""
            )}
          </Row>
          <div className="settings mb-5">
            {settingsObject?.settings_data?.map((item, i) => (
              <React.Fragment key={i}>
                <div class="settings-item">
                  <h2 class="settings-header">
                    <button
                      type="button"
                      class="settings-button"
                      onClick={() => handleSettingsRedirectClick(item?.link)}
                    >
                      <div className="settings-new-icon me-2">
                        {item.name === "Onboarding" && (
                          <img src={OnboardSvg} alt="side-icon" />
                        )}
                        {item.name === "Payroll" && (
                          <img src={PayrollSvg} alt="side-icon" />
                        )}
                        {item.name === "Performance" && (
                          <img src={PerformanceSvg} alt="side-icon" />
                        )}
                        {item.name === "Time Off" && (
                          <img src={TimeOffSvg} alt="side-icon" />
                        )}
                        {item.name === "Notifications" && (
                          <img src={NotificationSvg} alt="side-icon" />
                        )}
                        {item.name === "Company Details" && (
                          <img src={CompanyDetailsSvg} alt="side-icon" />
                        )}
                        {item.name === "Pay Schedule" && (
                          <img src={SignatorySvg} alt="side-icon" />
                        )}
                        {item.name === "Signatory" && (
                          <img src={SignatorySvg} alt="side-icon" />
                        )}
                        {item.name === "Forms" && (
                          <img src={FormsSvg} alt="side-icon" />
                        )}
                        {item.name === "Employer State Tax" && (
                          <img src={StatetaxSvg} alt="side-icon" />
                        )}
                        {item.name === "Company Profile" && (
                          <img src={CompanyProfileSvg} alt="side-icon" />
                        )}
                        {item.name === "Integrations" && (
                          <img src={IntegrationSvg} alt="side-icon" />
                        )}
                        {item.name === "Two Factor Authentication" && (
                          <img src={TwoFactorSvg} alt="side-icon" />
                        )}
                      </div>
                      <div className="settings-heading"> {item?.name}</div>
                      {settingsObject?.onboardingErrStatus === true ? (
                        <>
                          {[
                            ...new Set(
                              settingsObject?.onboardingErr.map(
                                (errorData) => errorData.name
                              )
                            ),
                          ].map((uniqueName) => (
                            <>
                              {settingsObject?.onboardingErr.find(
                                (errorData) =>
                                  errorData.name === uniqueName &&
                                  item.name === errorData.name
                              ) && (
                                <div className="onboarding-errors">
                                  Incomplete
                                </div>
                              )}
                            </>
                          ))}
                        </>
                      ) : null}
                      <div className="settings-forward-icon">
                        <img src={ForwardIcon} alt="forward-icon" />
                      </div>
                    </button>
                  </h2>
                </div>
              </React.Fragment>
            ))}
          </div>
        </Container>
      )}
      {loading && <Loader />}
    </>
  );
};

export default Settings;
