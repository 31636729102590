import React, { useState, useEffect } from "react";
import "./style.scss";
import { postData, getRevertUser } from "../../api";
import { Row, Col, Button, Container } from "react-bootstrap";
import { toast } from "react-toastify";
import Loader from "../../components/Loader";
import revertIcon from "../../assets/images/fa_refresh.svg";
import OnboardHeading from "../../components/OnboardHeading";
import { autocloseTiming } from '../../api/regex';
import BackButtonComponent from "../../components/backButton";

const EmployerStateTax = () => {

    const [loading, setLoading] = useState(false);
    const [stateTaxLink, setStateTaxLink] = useState('');
    const companyName = localStorage.getItem("company_name");
    const [isIframeLoading, setIsIframeLoading] = useState(true);

    useEffect(() => {
        if (window.location.pathname === '/employer-state-tax') {
            document.body.classList.add('feedback-remove');
        } else {
            document.body.classList.remove('feedback-remove');
        }
    }, [window.location.pathname === '/employer-state-tax']);


    useEffect(() => {
        getEmployerStateTax();
    }, []);

    const getEmployerStateTax = async () => {
        setLoading(true);
        const res = await postData("employer-state-tax-flow", {});

        if (res.status === true) {
            setLoading(false);
            setStateTaxLink(res?.data?.url)
        } else {
            toast.error(res.message, { theme: "colored", autoClose: autocloseTiming });;
            setLoading(false);
        }
    };

    const handleRevertUser = () => {
        window.open(getRevertUser(), "_self");
    }

    const handleIframeLoad = () => {
        setIsIframeLoading(false);
    };

    return (
        <>
            {!loading && (
                <>
                    <Container fluid>
                        <Row>
                            <Col md={getRevertUser() ? 10 : 12} sm={getRevertUser() ? 8 : 12}>
                                <OnboardHeading title="Employer state tax" subtitle={companyName} />
                                <BackButtonComponent reDirectionpath="/settings"/>
                            </Col>
                            {
                                getRevertUser() ?
                                    <Col md={2} sm={getRevertUser() ? 4 : 2} className="d-flex align-items-center justify-content-end mb-4 mb-md-0">
                                        <div className="revert">
                                            <Button
                                                variant="danger"
                                                className="revert_btn"
                                                onClick={handleRevertUser}
                                            >
                                                <img src={revertIcon} alt="revert" className="revert-img me-1" />
                                                Revert User
                                            </Button>
                                        </div>
                                    </Col>
                                    : ''
                            }
                        </Row>
                        <div className="emp-state-tax-view">
                            {
                                isIframeLoading &&
                                 <div className="emp-iframe-loading">
                                    Loading...
                                </div>
                            }
                            <iframe src={stateTaxLink} name="emp-state-tax-view" height="100%" width="100%" title="Iframe Example" onLoad={handleIframeLoad}></iframe>
                        </div>
                    </Container>
                </>
            )}
            {loading && <Loader />}


        </>
    )
}

export default EmployerStateTax