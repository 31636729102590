import React, { useState } from "react";
import OtpVerification from "../../components/EmployeeTwoFactorAuth/otpVerification";
import SuccessPage from "../../components/EmployeeTwoFactorAuth/successPage";

export default function OtpVerificationPage() {
  const [phoneNumber] = useState("");
  const [showSuccessPage, setShowSuccessPage] = useState(false);
  return (
    <div>
      {showSuccessPage ? (
        <SuccessPage />
      ) : (
        <OtpVerification
          phoneNumber={phoneNumber}
          setShowSuccessPage={setShowSuccessPage}
        />
      )}
    </div>
  );
}
