import React, { createContext, useState } from "react";

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
    const [userProfileImage, setUserProfileImage] = useState(null);

    const updateUserProfileImage = (image) => {
        setUserProfileImage(image);
    };

    return (
        <UserContext.Provider value={{ userProfileImage, updateUserProfileImage }}>
            {children}
        </UserContext.Provider>
    );
};

