import React, { useState, useEffect } from "react";
import Header from "../Header";
import Sidebar from "../Sidebar";
import MobileHeader from '../Sidebar/MobileHeader'
import { Outlet } from "react-router-dom";
import "../style.scss";
import 'react-toastify/dist/ReactToastify.css';
import { postData } from '../../api';
import { toast } from "react-toastify";
import LayoutCheck from '../LayoutWithSidebar/LayoutCheck'
import { autocloseTiming } from "../../api/regex";
import Loader from "../../components/Loader";

export default function Layout(props) {

  const [menus, setMenus] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageLoad, setPageLoad] = useState(false);

  useEffect(() => {
    getSidebar()
  }, [])

  const getSidebar = async () => {
    setLoading(true);
    const res = await postData("side_menu_bar", {}, {});
    if (res.status === true) {
      setLoading(false);
      setMenus(res.data);
      setPageLoad(true);
      toast.success(res.message, { theme: "colored", autoClose: autocloseTiming });
    } else {
      setPageLoad(true);
      toast.error(res.message, { theme: "colored", autoClose: autocloseTiming });;
      setLoading(false);
    }
  }

  return (
    <>
      {!loading && (
        <LayoutCheck>
          <MobileHeader headerMenus={menus} />
          <main className="main-layout">
            <Sidebar sideMenus={menus} />
            {
              pageLoad &&
              <div className="main-body fixed">
                <Header headerMenus={menus} />
                <Outlet></Outlet>
              </div>
            }

          </main>
        </LayoutCheck>
      )}
      {loading && <Loader />}
    </>
  );
}
