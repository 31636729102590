import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import DownArrow from "../../assets/images/timekeeping_icons/btn_down_arrow.svg";
import RightArrow from "../../assets/images/timekeeping_icons/double_arrow_right.svg";
import LeftArrow from "../../assets/images/timekeeping_icons/double_arrow_left.svg";
import { Modal } from "react-bootstrap";
import "../FormModal/style.scss";
import InfoIcon from "../../assets/images/timekeeping_icons/info.svg";

export default function TimeDetails(props) {
  const { payPeriodData } = props;
  const [showModal, setShowModal] = useState(false);
  const [showReports, setShowReports] = useState(false);
  const formatTime = (minutes) => {
    const time = moment.duration(minutes, "minutes");
    const formattedTime = `${time.hours()}hrs ${time.minutes()}mins`;
    return formattedTime;
  };
  const handleModalOpen = () => {
    setShowModal(true);
  };
  const handleClose = () => {
    setShowModal(false);
    // window.open("/pay-period-summary", "_self");
  };
  const handleReportsOpen = () => {
    setShowReports(!showReports);
  };
  const handleReportClose = () => {
    setShowReports(false);
  };
  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     if (showReports && !ref.current.contains(event.target)) {
  //       setShowReports(false);
  //     }
  //   };

  //   document.addEventListener("mousedown", handleClickOutside);

  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, [showReports]);

  // const ref = useRef(null);

  return (
    <>
      <div className="filter-detail-wrapper py-5">
        <div className="filter-detail-cards">
          <p>Regular</p>
          <h5>{formatTime(payPeriodData?.overview?.regular_minutes)}</h5>
        </div>
        <div className="filter-detail-cards">
          <p>Overtime</p>
          <h5>{formatTime(payPeriodData?.overview?.overtime_minutes)}</h5>
        </div>
        <div className="filter-card-last-section">
          <div className="new-filter-cards">
            <p>Monthly</p>
            <h5>
              <img src={LeftArrow} alt="left-arrow" />
              {payPeriodData?.overview?.time_line}
              <img src={RightArrow} alt="right-arrow" />
            </h5>
          </div>
          <div className="timecard-approved-wrapper" />
          <div className="resp-text-info-wrapper">
            <p>
              Timecard approved: {payPeriodData?.overview?.approved}/
              {payPeriodData?.overview?.timecard}
            </p>
            <p>Edit requests: {payPeriodData?.overview?.edit_req}</p>
          </div>
        </div>
      </div>
      <div className="report-button-wrapper">
        <div className="info-text-wrapper">
          <img src={InfoIcon} alt="info-icon" />
          <p>
            Please manage time cards for both <span>'weekly'</span> and{" "}
            <span>'monthly'</span> pay periods before processing payroll.
          </p>
        </div>
        <div className="info-btn-wrapper">
          <button className="btn export-button" onClick={handleModalOpen}>
            Export to payroll relief
          </button>
          <button
            className="btn btn-primary go-report-button"
            onClick={handleReportsOpen}
          >
            Go to reports <img src={DownArrow} alt="down-arrow" />
          </button>
        </div>{" "}
        {showReports && (
          <div
            className="go-to-reports-sub-section"
            // ref={ref}
          >
            <a
              href="https://app.guhroo.co/reports/labor_timetracker/461"
              onClick={handleReportClose}
              className="go-to-report-lab"
            >
              Labor Report
            </a>
            <a
              href="https://app.guhroo.co/reports/timetracker/461"
              onClick={handleReportClose}
              className="go-to-report-summary"
            >
              Summary Report
            </a>
          </div>
        )}
      </div>
      <div>
        <Modal
          show={showModal}
          centered
          // onHide={onClose}
          // size={size}
          className="modal-main-wrapper"
        >
          <Modal.Header className="modal-expand-header">
            <div className="form-modal">
              <Modal.Title className="modal-tile-expand">
                Approval pending
              </Modal.Title>
              {/* {modalData.userName
                ? `Are you sure you want to ${modalData.data} ${modalData.userName}'s time cards?`
                : `Are you sure you want to ${modalData.data} time cards?`} */}
            </div>
          </Modal.Header>
          <Modal.Body className="modal-expand-body">
            <div className="modal-approve-content-title">
              <p>
                To ensure accurate accrual balances, please manage time cards
                before processing payroll. Note: the pending requests are
                highlighted.
              </p>
            </div>
            {/* <div>
            <div className="d-flex justify-content-between modal-wrap-class">
          
              <div className="modal-expand-content-title">
                <h5>In</h5>
                <p>{modalData?.selectedRow?.in}</p>
              </div>
              <div className="modal-expand-content-title">
                <h5>Out</h5>
                <p>{modalData?.selectedRow?.out}</p>
              </div>
              <div className="modal-expand-content-title">
                <h5>Total Hours</h5>
                <p>{modalData?.selectedRow?.totalHrs}</p>
              </div>
              <div className="modal-expand-content-title">
                <h5>Status</h5>
                <p
                  className={`text-${modalData?.selectedRow?.statusName.toLowerCase()}`}
                >
                  {modalData?.selectedRow?.statusName}
                </p>
              </div>
            </div>
            <div className="modal-expand-content-title width-wrapper-cus">
              <h5>Address</h5>
              <p>{modalData?.selectedRow?.address}</p>
            </div>
            <div className="modal-expand-content-title">
              <h5>Note</h5>
              <p>In</p>
            </div>
          </div> */}
          </Modal.Body>
          <Modal.Footer className="modal-expand-footer">
            <div className="d-flex button-gap justify-content-end ">
              <button
                className="manage-modal-btn deny-active-btn"
                onClick={handleClose}
              >
                manage Timekeeping 
              </button>
              {/* <button
              className="approve-all-button active-btn"
              onClick={handleClose}
            >
              Confirm
            </button> */}
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}
