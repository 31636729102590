import React from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import closebutton from "../../assets/images/multi-card-approval/x.svg";
import moment from "moment";
import InfoIcon from "../../assets/images/multi-card-approval/error_info.svg";

export default function ModalHolidayDetail(props) {
  const {
    show,
    closeModal,
    modalText,
    headerText,
    multiCardModalDate,
    handleModalOnClick,
    btnLoader,
    modalData,
    modalExportError,
    modalErrorMsg,
    payReliefModalError,
    payReliefError,
    handleDeleteTimeCardUser,
    btnDelLoading,
  } = props;
  return (
    <div>
      <Modal className="approval-final-modal" show={show} onHide={closeModal}>
        <Modal.Header className="modal-header-wrapper">
          <Modal.Title>
            <h4>{headerText ? headerText : "Time card approval"}</h4>
          </Modal.Title>
          <div className="modal-close" onClick={closeModal}>
            <img src={closebutton} alt="closebutton" />
          </div>
        </Modal.Header>
        <Modal.Body className="modal-confirm-body-wrapper">
          <p className={multiCardModalDate ? "" : "cus-width-modaltext"}>
            {payReliefError ? payReliefError : modalText}
          </p>
          {!payReliefModalError && (
            <>
              {multiCardModalDate && (
                <>
                  <div className="date-main-wrapper">
                    {" "}
                    <div className="date-modal-wrapper">
                      {" "}
                      <div className="sub-modal-date-wrapper">
                        <h5>Frequency</h5>
                        <p>{modalData?.frequency}</p>
                      </div>
                      <div className="sub-modal-date-wrapper">
                        <h5>Start date</h5>
                        <p>
                          {modalData?.start_date &&
                            moment(modalData.start_date).format("MM/DD/YYYY")}
                        </p>{" "}
                      </div>
                      <div className="sub-modal-date-wrapper">
                        <h5>End date</h5>
                        <p>
                          {modalData?.end_date &&
                            moment(modalData.end_date).format("MM/DD/YYYY")}
                        </p>{" "}
                      </div>
                    </div>
                  </div>
                  {modalExportError && (
                    <div className="modal-text-error-wrapper">
                      <div>
                        <h5>
                          <img src={InfoIcon} alt="info-icon" />
                          Export not completed
                        </h5>
                      </div>
                      <p>
                        {modalErrorMsg
                          ? modalErrorMsg
                          : "No records available to export. Check if the time records exist or if employees have been synced with payroll relief"}
                      </p>
                    </div>
                  )}
                </>
              )}
            </>
          )}
        </Modal.Body>
        {!modalExportError && (
          <Modal.Footer className="modal-footer-wrapper footer-bttn-flex-wrapper">
            <Button
              variant="primary"
              className="modal-btn-wrapper approval-confirm-bttn modal-footer-button"
              onClick={
                btnLoader || btnDelLoading
                  ? null
                  : handleDeleteTimeCardUser
                  ? handleDeleteTimeCardUser
                  : handleModalOnClick
              }
            >
              {btnLoader || btnDelLoading ? (
                <Spinner animation="border" size="sm" />
              ) : (
                "Confirm"
              )}
            </Button>
          </Modal.Footer>
        )}
        {payReliefModalError && (
          <Modal.Footer className="modal-footer-wrapper footer-bttn-flex-wrapper">
            <Button
              variant="primary"
              className="modal-btn-wrapper approval-confirm-bttn modal-footer-button"
              onClick={closeModal}
            >
              Close
            </Button>
          </Modal.Footer>
        )}
      </Modal>
    </div>
  );
}
