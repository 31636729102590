import React, { useState, useEffect,useContext } from "react";
import { Form, Row, Col } from "react-bootstrap";
import Button from "../../../components/Form/Button";
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap.css';
import { FiHelpCircle } from 'react-icons/fi';
import Routing from '../../../assets/images/routing.svg'
import Account from '../../../assets/images/account.svg';
import { postData } from "../../../api";
import { toast } from "react-toastify";
import { onlyCharacterWithSpace, mustNumbers, emptySpace ,autocloseTiming} from "../../../api/regex";
import Loader from "../../../components/Loader";
import { SignContext } from "../../../hooks/Context/SignContext";

const DirectDeposit = ({ nextStep, onboardObject }) => {

    const [validated, setValidated] = useState(false);
    const [loading, setLoading] = useState(false);
    const [clientObject, setClientObject] = useState({
        routing_num: "",
        acc_num: "",
        type: '',
        bank_account_number: ''
    });
    const [errors, setErrors] = useState({});
    const { setEsignValue } = useContext(SignContext);


    useEffect(() => {
        if (onboardObject?.direct_deposit) {
            setClientObject(onboardObject?.direct_deposit);
        }
    }, [onboardObject]);


    const handleInput = (e) => {
        const value = e.target.value;
        const name = e.target.name;
        setClientObject((prev) => ({
            ...prev,
            [name]: value,
        }));
        if (!!errors[name])
            setErrors({
                ...errors,
                [name]: null,
            });
    };

    const renderTooltip =
        <div>
            <h6 className="py-1">Routing number</h6>
            <div>
                <img src={Routing} alt="route img" />
            </div>
        </div>

    const renderAccountTooltip =
        <div>
            <h6 className="py-1"> Account number</h6>
            <div>
                <img src={Account} alt="route img" />
            </div>
        </div>



    const isValidRoutingNumber = (n) => {
        if (n.length !== 9 || isNaN(n)) {
            return false;
        }
        else {
            let digits = n.split("");
            var sum = digits[0] * 3 + digits[1] * 7 + digits[2] * 1
                + digits[3] * 3 + digits[4] * 7 + digits[5] * 1
                + digits[6] * 3 + digits[7] * 7 + digits[8] * 1;
            return sum % 10 === 0;
        }
    }

    const findFormErrors = () => {
        const { routing_num, acc_num, bank_account_number, type, nickname, bank_name } = clientObject;
        const newErrors = {};
        if (!routing_num || routing_num === "") newErrors.routing_num = "Please enter routing number.";
        else if (!routing_num.toString()?.match(mustNumbers)) {
            newErrors.routing_num = "The routing number should be numeric.";
        }
        else if (routing_num.length !== 9) {
            newErrors.routing_num = "The federal routing number should be 9 characters";
        } else if (isValidRoutingNumber(routing_num) === false) {
            newErrors.routing_num = "Please enter a valid routing number.";
        }
        if (!acc_num || acc_num === "") newErrors.acc_num = "Please enter your account number.";
        else if (!acc_num.toString()?.match(mustNumbers)) {
            newErrors.acc_num = "The account number should be numeric.";
        }
        else if (acc_num.length < 5) {
            newErrors.acc_num = "The account number should be  5 to 25 characters long.";
        } else if (acc_num.length > 25) {
            newErrors.acc_num = "The account number should be  5 to 25 characters long.";
        }

        if (bank_account_number !== acc_num) {
            newErrors.bank_account_number = 'Confirm account number is not matching with account number.';
        }
        if (type === '') {
            newErrors.type = 'Please enter your account type.';
        }
        if (!nickname || nickname === "") newErrors.nickname = "Please enter your nick name.";
        else if (nickname.length < 3) {
            newErrors.nickname = "The nick name should be  3 to 40 characters long.";
        } else if (nickname.length > 40) {
            newErrors.nickname = "The nick name should be  3 to 40 characters long.";
        }
        else if (!nickname?.match(onlyCharacterWithSpace)) {
            newErrors.nickname = "The nick name should be alphabetic.";
        }
        else if (!nickname?.match(emptySpace)) {
            newErrors.nickname = "The nick name should be  3 to 40 characters long.";
        }
        if (!bank_name || bank_name === "") newErrors.bank_name = "Please enter your bank name.";
        else if (bank_name.length < 3) {
            newErrors.bank_name = "The bank name should be  3 to 80 characters long.";
        } else if (bank_name.length > 80) {
            newErrors.bank_name = "The bank name should be  3 to 80 characters long.";
        }
        else if (!bank_name?.match(emptySpace)) {
            newErrors.bank_name = "The nick name should be  3 to 80 characters long.";
        }
        else if (!bank_name?.match(onlyCharacterWithSpace)) {
            newErrors.bank_name = "The bank name should be alphabetic.";
        }
        return newErrors;
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const newErrors = findFormErrors();
        setValidated(true);
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
        } else {
            addBankInfo();
        }

    };

    const addBankInfo = async () => {
        let copyClientObject = { ...clientObject }
        delete copyClientObject.bank_account_number;
        setLoading(true);
        let data = {
            stage: "direct_deposit",
            ...copyClientObject
        }

        const res = await postData("employee_onboarding_steps", {}, data);
        if (res.status === true) {
            setLoading(false);
            nextStep(res.next_steps)
            const eSignValue = res?.data?.eligibility?.e_sign;
            // Store eSignValue in sign context
            setEsignValue(eSignValue);
        } else {
             toast.error(res.message, { theme: "colored", autoClose: autocloseTiming });;
            setLoading(false);
        }
    };

    return (
        <>
            {!loading && (
                <div className="board-right board-tab-sections">
                    <h5 className="mb-3"> Direct Deposit</h5>

                    <Form noValidate validated={validated} onSubmit={handleSubmit} autoComplete="off" className="custom-form">
                        <Row>
                            <Col md={4}>
                                <Form.Group className="mb-3" controlId="nickname">
                                    <Form.Label className="me-2">Account nickname <span className="asterisk">*</span> </Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter account nickname"
                                        value={clientObject.nickname}
                                        onChange={handleInput}
                                        isInvalid={!!errors.nickname}
                                        name="nickname"
                                        maxLength="40"
                                        required
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.nickname}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col md={4}>
                                <Form.Group className="mb-3" controlId="type">
                                    <Form.Label className="me-2">Account type <span className="asterisk">*</span> </Form.Label>
                                    <Form.Select
                                        onChange={handleInput}
                                        name="type"
                                        value={clientObject.type}
                                        required
                                        size="lg"
                                    >
                                        <option value="">Select account type</option>
                                        <option value="CHECKING"> Checking</option>
                                        <option value="SAVINGS"> Savings</option>

                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        {errors.type}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col md={4}>
                                <Form.Group className="mb-3" controlId="bank_name">
                                    <Form.Label className="me-2">Bank name <span className="asterisk">*</span> </Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter bank name"
                                        value={clientObject.bank_name}
                                        onChange={handleInput}
                                        isInvalid={!!errors.bank_name}
                                        name="bank_name"
                                        maxLength="80"
                                        required
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.bank_name}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col md={4}>
                                <Form.Group className="mb-3" controlId="routing_num">
                                    <Form.Label className="me-2">Routing number (9 digits) <span className="asterisk">*</span> </Form.Label>
                                    <Tooltip placement="right" overlay={renderTooltip} overlayClassName="bank-tooltip">
                                        <FiHelpCircle className="help-circle" />
                                    </Tooltip>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter routing number (9 digits)"
                                        value={clientObject.routing_num}
                                        onChange={handleInput}
                                        isInvalid={!!errors.routing_num}
                                        name="routing_num"
                                        required
                                        maxLength="9"
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.routing_num}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col md={4}>
                                <Form.Group className="mb-3" controlId="acc_num">
                                    <Form.Label className="me-2">Account number <span className="asterisk">*</span> </Form.Label>
                                    <Tooltip placement="right" overlay={renderAccountTooltip} overlayClassName="bank-tooltip">
                                        <FiHelpCircle className="help-circle" />
                                    </Tooltip>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter account number"
                                        value={clientObject.acc_num}
                                        onChange={handleInput}
                                        isInvalid={!!errors.acc_num}
                                        name="acc_num"
                                        required
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.acc_num}
                                    </Form.Control.Feedback>

                                </Form.Group>
                            </Col>
                            <Col md={4}>
                                <Form.Group className="mb-3" controlId="bank_account_number">
                                    <Form.Label className="me-2">Confirm account number <span className="asterisk">*</span>  </Form.Label>

                                    <Form.Control
                                        type="number"
                                        placeholder="Enter Confirm account number"
                                        value={clientObject.bank_account_number}
                                        onChange={handleInput}
                                        isInvalid={!!errors.bank_account_number}
                                        name="bank_account_number"
                                        required
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.bank_account_number}
                                    </Form.Control.Feedback>

                                </Form.Group>
                            </Col>
                        </Row>

                        <div className="text-end mt-4">
                            <Button
                                type="button"
                                className="btn-outline-primary me-2"
                                onClick={() => nextStep('onboarding_tasks')}
                            >
                                Back
                            </Button>
                            <Button
                                type="submit"
                                disabled={loading}
                                loading={loading}
                                className="btn-primary text-white"
                            >
                                Save & Continue
                            </Button>
                        </div>

                    </Form>
                </div>
            )}
            {loading && <Loader />}

        </>
    )
}

export default DirectDeposit