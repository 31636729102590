import { Navigate } from "react-router-dom";
import { isLogedIn, userDetails, userRole } from "../../api";

const LoginCheck = ({ children }) => {

    if (!isLogedIn()) {
        localStorage.clear();
        return <Navigate to="/login" replace />;
    }
    if (userRole() === 'Employee' && userDetails()?.onboard_status !== 'completed' && userDetails()?.onboard_status !== 'offer_sign') {
        <Navigate to="/employee-onboard-sections" replace />
    } else if (userDetails()?.onboard_status === 'offer_sign') {
        <Navigate to="/offer-letter" replace />
    } else {
        window.open(userDetails()?.dashbaord_url, "_self");
    }
    if (userRole() === 'Client' && userDetails()?.onboard_status !== 'completed') {
        <Navigate to="/client-onboard-sections" replace />
    } else {
        window.open(userDetails()?.dashbaord_url, "_self");
    }


    return children;
}
export default LoginCheck;