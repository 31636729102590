export const baseURL = process.env.REACT_APP_API_URL;
export const cakePhpURL = process.env.REACT_APP_CAKE_PHP_BASE_URL;
export const reactURL = process.env.REACT_APP_REACT_BASE_URL;


export const timeZoneOffset = new Date().getTimezoneOffset();
export const isLoading = () => {
  document.getElementById("pageLoader").classList.add("d-block");
};
export const isNotLoading = () => {
  if (
    document.querySelector("#pageLoader") &&
    document.querySelector("#pageLoader").classList.contains("d-block")
  ) {
    document.querySelector("#pageLoader").classList.remove("d-block");
  }
};

export const phoneNumberFormat = (phNumber) => {
  phNumber = phNumber?.replaceAll('-', '');
  if (phNumber.length >= 10) {
    let phonenumber = phNumber?.replace(/\D/g, '')?.match(/(\d{3})(\d{3})(\d{4})/);
    return phonenumber[1] + '-' + phonenumber[2] + '-' + phonenumber[3];
  }
  else {
    return phNumber;
  }
}
export const socialSecurityNumberFormat = (ssNumber) => {
  ssNumber = ssNumber?.replaceAll('-', '');
  if (ssNumber.length >= 9) {
    let securitynumber = ssNumber?.replace(/\D/g, '')?.match(/(\d{3})(\d{2})(\d{4})/);
    return securitynumber[1] + '-' + securitynumber[2] + '-' + securitynumber[3];
  }
  else {
    return ssNumber;
  }
}
export const isLogedIn = () => {
  return !!localStorage.getItem("guhroo_token");
};
export const getRole = () => {
  return localStorage.getItem("guhroo_user");
};
export const userRole = () => {
  return localStorage.getItem("guhroo_user");
}
export const userDetails = () => {
  return JSON.parse(localStorage.getItem("guhroo_user_details"));
}
export const userOnboardStatus = () => {
  return localStorage.getItem("guhroo_onbord_status");
}

export const getCurrentUser = () => {
  return localStorage.getItem("guhroo_token");
};
export const getRevertUser = () => {
  return localStorage.getItem("revert_url");
};
export const getTermsPrivacyStatus = () => {
  return localStorage.getItem("term_privacy_status");
};
export const postData = async (url = "", headers = {}, data = {}) => {
  try {
    const response = await fetch(`${baseURL + url}`, {
      method: "POST",
      headers: {
        "content-Type": "application/json",
        "Accept": "application/json",
        Authorization: `Bearer ${getCurrentUser()}`,
      },
      body: JSON.stringify(data),
    });
    const responseData = await response.json();
    if (response.status === 401) {
      localStorage.clear();
      // alert("Your Session Has Been Expired.Please Login Again");
      // localStorage.clear();
      window.open(cakePhpURL + "users/logout", "_self");
      // window.location = "/login";
    }
    return responseData;
  } catch (e) {
    // console.log(e)
    return e;
  }
};

export const postUploadData = async (url = "", headers = {}, data) => {

  try {
    const response = await fetch(`${baseURL + url}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${getCurrentUser()}`,
      },
      body: data,
    });
    const responseData = await response.json();
    if (response.status === 401) {
      localStorage.clear();
      // alert("Your Session Has Been Expired.Please Login Again");
      window.open(cakePhpURL + "users/logout", "_self");
      // localStorage.clear();
      // window.location = "/login";
    }
    return responseData;
  } catch (e) {
    return e;
  }
};

export const getData = async (url = "", headers = {}) => {
  try {
    const response = await fetch(`${baseURL + url}`, {
      method: "GET",
      headers: {
        "content-Type": "application/json",
        Authorization: `Bearer ${getCurrentUser()}`,
      },
    });
    const responseData = await response.json();
    if (response.status === 401) {
      localStorage.clear();
      window.open(cakePhpURL + "users/logout", "_self");
      // localStorage.clear();
      // window.location = "/login";
    }
    return responseData;
  } catch (e) {
    return e;
  }
};

export const putData = async (url = "", headers = {}, data = {}) => {
  try {
    const response = await fetch(`${baseURL + url}`, {
      method: "PUT",
      headers: {
        "content-Type": "application/json",
        Authorization: `Token ${getCurrentUser()}`,
      },
      body: JSON.stringify(data),
    });
    const responseData = await response.json();
    if (response.status === 401) {
      localStorage.clear();
      window.open(cakePhpURL + "users/logout", "_self");
      // localStorage.clear();
      // window.location = "/login";
    }
    return responseData;
  } catch (e) {
    return e;
  }
};
export const deleteData = async (url = "", headers = {}, data = {}) => {
  try {
    const response = await fetch(`${baseURL + url}`, {
      method: "DELETE",
      headers: {
        "content-Type": "application/json",
        Authorization: `Token ${getCurrentUser()}`,
      },
      body: JSON.stringify(data),
    });
    const responseData = await response.json();
    if (response.status === 401) {
      localStorage.clear();
      window.open(cakePhpURL + "users/logout", "_self");
      // localStorage.clear();
      // window.location = "/login";
    }
    return responseData;
  } catch (e) {
    return e;
  }
};

export const convertUTCToLocalDate = (date) => {
  if (!date) {
    return date
  }
  date = new Date(date)
  date = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate())
  return date
}