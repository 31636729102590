import React, { createContext, useState } from "react";

export const EmployeeAgreementContext = createContext();

export const EmployeeAgreementContextProvider = ({ children }) => {
  const [empAgreeValue, setEmpAgreeValue] = useState(null);

  return (
    <EmployeeAgreementContext.Provider value={{ empAgreeValue, setEmpAgreeValue }}>
      {children}
    </EmployeeAgreementContext.Provider>
  );
};