import React, { useEffect, useState } from "react";
import PendingIcon from "../../assets/images/multi-card-approval/pending_icon.svg";
import ApproveIcon from "../../assets/images/multi-card-approval/approveIcon.svg";
import RejectIcon from "../../assets/images/multi-card-approval/rejectedIcon.svg";
import Select from "react-select";

// Function to generate options based on detailPage and groupId
const getOptions = (groupId) => {
  const baseOptions = [
    { value: "all", label: "All" },
    {
      value: "pending",
      label: (
        <div style={{ display: "flex", alignItems: "center" }}>
          <img src={PendingIcon} alt="Pending" style={{ marginRight: 5 }} />
          Pending
        </div>
      ),
    },
    {
      value: "approved",
      label: (
        <div style={{ display: "flex", alignItems: "center" }}>
          <img src={ApproveIcon} alt="Approved" style={{ marginRight: 5 }} />
          Approved
        </div>
      ),
    },
    {
      value: "denied",
      label: (
        <div style={{ display: "flex", alignItems: "center" }}>
          <img src={RejectIcon} alt="Rejected" style={{ marginRight: 5 }} />
          Rejected
        </div>
      ),
    },
  ];

  // Add "Edit Request" option only if detailPage is true and groupId is not 5 or 8
  if (groupId !== 5) {
    baseOptions.push({
      value: "editRequest",
      label: (
        <div style={{ display: "flex", alignItems: "center" }}>
          <img
            src={PendingIcon}
            alt="Edit Request"
            style={{ marginRight: 5 }}
          />
          Edit Request
        </div>
      ),
    });
  }

  return baseOptions;
};

export default function FilterSection(props) {
  const { handlePtoStatus, ptoStatus, detailPage, groupId } = props;

  const [selectedOption, setSelectedOption] = useState(
    getOptions(detailPage, groupId).find(
      (option) => option.value === ptoStatus
    ) || getOptions(detailPage, groupId)[0]
  );

  useEffect(() => {
    const newSelectedOption =
      getOptions(detailPage, groupId).find(
        (option) => option.value === ptoStatus
      ) || getOptions(detailPage, groupId)[0];
    if (newSelectedOption.value !== selectedOption.value) {
      setSelectedOption(newSelectedOption);
    }
  }, [ptoStatus, selectedOption, detailPage, groupId]);

  return (
    <div className="multi-card-go-to-reports">
      <p>Time Card Status</p>
      <div className="info-btn-wrapper">
        <Select
          closeMenuOnSelect={true}
          hideSelectedOptions={false}
          options={getOptions(detailPage, groupId)}
          value={selectedOption}
          onChange={(selectedOption) => {
            handlePtoStatus(selectedOption.value);
          }}
        />
      </div>
    </div>
  );
}
