import React from "react";
import WarningIcon from "../../assets/images/timekeeping_icons/warning_icon.svg";
import Tooltip from "rc-tooltip";
import "rc-tooltip/assets/bootstrap.css";
// import DownloadIcon from "../../assets/images/reports_icons/download_icon.svg";
import DeleteIcon from "../../assets/images/reports_icons/delete.svg";
import ProfileManageIcon from "../../assets/images/reports_icons/profile_add.svg";
import { useNavigate } from "react-router-dom";

export default function ReportsTable(props) {
  const navigate = useNavigate();
  const {
    reportsData,
    reportStatus,
    handleFilterModalOpen,
    handleDelModalOpen,
    rowsPerPage,
    handleRowsPerPageChange,
    totalPages,
    startIndex,
    endIndex,
    handlePageChange,
    currentPage,
    handleManageModalOpen,
    groupId,
  } = props;
  const handleRedirectionDownload = (data) => {
    const reportName = data?.name.replace(/\s+/g, "");
    navigate(`/report-download?reportName=${reportName}`, {
      state: { data },
    });
  };
  return (
    <>
      <div className="report-table-scroll">
        <table className="report-table">
          <thead>
            <tr>
              <th>Report Name</th>
              <th>Descriptions</th>
              {reportStatus === "custom" && <th>Action</th>}
            </tr>
          </thead>
          {reportsData.length > 0 ? (
            <tbody>
              {reportsData &&
                reportsData.slice(startIndex, endIndex).map((data, index) => (
                  <tr key={`pending-${data.id || index}`}>
                    <td className="report-data-column-wrapper">
                      {reportStatus === "custom" ? (
                        <>
                          {data?.name?.length > 35 ? (
                            <Tooltip
                              placement="top"
                              overlay={
                                <div
                                  style={{
                                    wordBreak: "break-word",
                                    textAlign: "center",
                                  }}
                                >
                                  {data?.name}
                                </div>
                              }
                              overlayClassName="timeoff-calender-tooltip"
                            >
                              <div>
                                <p
                                  className="cus-report-font-wrapper"
                                  onClick={() =>
                                    handleRedirectionDownload(data)
                                  }
                                >
                                  {data?.name?.substring(0, 35)}
                                  {data?.name?.length > 35 ? "..." : ""}
                                </p>
                              </div>
                            </Tooltip>
                          ) : (
                            <p
                              className="cus-report-font-wrapper"
                              onClick={() => handleRedirectionDownload(data)}
                            >
                              {data.name}
                            </p>
                          )}
                        </>
                      ) : (
                        <a href={data?.url}>{data.name}</a>
                      )}
                    </td>
                    <td>
                      {data?.description?.length > 60 ? (
                        <Tooltip
                          placement="top"
                          overlay={
                            <div style={{ wordBreak: "break-word" }}>
                              {data?.description}
                            </div>
                          }
                          overlayClassName="timeoff-calender-tooltip"
                        >
                          <div>
                            <p className="cus-font-weight-wrapper">
                              {data?.description?.substring(0, 60)}
                              {data?.description?.length > 60 ? "..." : ""}
                            </p>
                          </div>
                        </Tooltip>
                      ) : (
                        <p className="cus-font-weight-wrapper">
                          {data.description}
                        </p>
                      )}
                    </td>

                    {reportStatus === "custom" && (
                      <td className="action-column-wrapper">
                        <div className="run-btn-wrapper">
                          <button onClick={() => handleFilterModalOpen(data)}>
                            Run
                          </button>
                        </div>
                        {groupId === 1 || groupId === 6 ? (
                          <div className="reports-profile-manage-wrapper">
                            <Tooltip
                              placement="top"
                              overlay={<div>Manage assigns</div>}
                              overlayClassName="reports-tooltip"
                            >
                              <button
                                onClick={() => handleManageModalOpen(data)}
                              >
                                <img
                                  src={ProfileManageIcon}
                                  alt="profile-img"
                                />
                              </button>
                            </Tooltip>
                          </div>
                        ) : null}
                        <div className="action-btn-wrapper">
                          <Tooltip
                            placement="top"
                            overlay={<div>Delete</div>}
                            overlayClassName="reports-tooltip"
                          >
                            <button onClick={() => handleDelModalOpen(data)}>
                              <img src={DeleteIcon} alt="download-img" />
                            </button>
                          </Tooltip>
                        </div>
                      </td>
                    )}
                  </tr>
                ))}
            </tbody>
          ) : (
            <td colSpan={12}>
              <div className="no-data-text">
                <img src={WarningIcon} alt="warning" />
                <h5>
                  {reportStatus === "standard"
                    ? "No data yet. Your reports will be available once employees are onboarded."
                    : "No data yet. Your reports will be available once employees are onboarded."}
                </h5>
              </div>
            </td>
          )}
        </table>
      </div>
      {reportsData.length > 10 && (
        <div className="d-flex justify-content-between py-3 px-3">
          <div>
            <select value={rowsPerPage} onChange={handleRowsPerPageChange}>
              <option value={10}>10</option>
              <option value={25}>25</option>
              <option value={30}>30</option>
              <option value={50}>50</option>
            </select>
          </div>
          <div>
            {currentPage !== 0 && (
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                className="non-active-pagination-btn"
              >
                &lt;
              </button>
            )}
            {Array.from({ length: totalPages }, (_, i) => i).map((page) => (
              <button
                key={page}
                disabled={currentPage === page}
                onClick={() => handlePageChange(page)}
                className={
                  currentPage === page
                    ? "active-pagination-btn "
                    : "non-active-pagination-btn"
                }
              >
                {page + 1}
              </button>
            ))}
            {currentPage !== totalPages - 1 && (
              <button
                disabled={currentPage === totalPages - 1}
                onClick={() => handlePageChange(currentPage + 1)}
                className="non-active-pagination-btn"
              >
                &gt;
              </button>
            )}
          </div>
        </div>
      )}
    </>
  );
}
