import { Navigate, useNavigate } from "react-router-dom";
import { isLogedIn, userDetails, userRole } from "../../api";

const LayoutCheck = ({ children }) => {
    const navigate = useNavigate();
    const userDetail = userDetails();

    if (!isLogedIn()) {
        localStorage.clear();
        return <Navigate to="/login" replace />;
    }
    if (userDetails()?.user_completion_status) {
        return children;
    } else {
        if (userRole() === 'Employee') {
            if (userDetail?.onboard_status !== 'completed' && userDetail?.onboard_status !== 'offer_sign') {
                navigate("/employee-onboard-sections")
            } else if (userDetail?.onboard_status === 'offer_sign') {
                navigate("/offer-letter")
            } else if (userDetail?.onboard_status === 'completed') {
                window.open(userDetails()?.dashbaord_url, "_self");
            }
        } else if (userRole() === 'Client') {
            if (userDetail?.onboard_status !== 'completed' && userDetail?.onboard_status !== 'offer_sign') {
                navigate("/client-onboard-sections")
            } else if (userDetail?.onboard_status === 'offer_sign') {
                navigate("/offer-letter")
            } else if (userDetail?.onboard_status === 'completed') {
                window.open(userDetails()?.dashbaord_url, "_self");
            }
        }
    }

}
export default LayoutCheck;