import React, { useState, useEffect } from "react";
import { Form, Container, Row, Col } from "react-bootstrap";
import InputGroup from 'react-bootstrap/InputGroup';
import "./style.scss";
import logo from "../../assets/images/logo.png";
import Button from "../../components/Form/Button";
import { useNavigate, NavLink, useParams } from "react-router-dom";
import { postData } from "../../api";
import { toast } from "react-toastify";
import LoginFooter from "../LoginFooter";
import { FiEye, FiEyeOff } from 'react-icons/fi'
import { mustLowerCaseLetters, mustSpecialCharacterCheck, mustUpperCaseLetters ,autocloseTiming} from "../../api/regex";

const ResetPassword = () => {

  let navigate = useNavigate();
  let { id } = useParams();

  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [newPasswordShown, setNewPasswordShown] = useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);
  const [errorObject, setErrorObject] = useState({});
  const [resetObject, setResetObject] = useState({
    username: localStorage.getItem("forgotEmail"),
    password: "",
    reset_string: id,
    id: '',
    reenter_password: "",
  });


  useEffect(() => {
    validateResetString();
  }, []);// eslint-disable-line react-hooks/exhaustive-deps

  const validateResetString = async () => {
    let data = {
      reset_string: id
    }
    setLoading(true);
    const res = await postData("check_verification_code", {}, data);
    if (res.status === true) {
      setLoading(false);
      setResetObject((prev) => ({
        ...prev,
        username: res.username,
        id: res.id

      }));
    } else {
      navigate("/login");
       toast.error(res.message, { theme: "colored", autoClose: autocloseTiming });;
      setLoading(false);
    }
  }

  // toggle new password function
  const toggleNewPasswordVisiblity = () => {
    setNewPasswordShown(newPasswordShown ? false : true);
  };

  // toggle confirm password function
  const toggleConfirmPasswordVisiblity = () => {
    setConfirmPasswordShown(confirmPasswordShown ? false : true);
  };

  const handleInput = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    delete errorObject[name]
    setResetObject((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const checkValidate = () => {
    const { password, reenter_password } = resetObject;
    const newErrors = {};
    if (!password || password === "") newErrors.password = "Please enter a valid password.";
    else {
      if (password !== "" && (!mustSpecialCharacterCheck.test(password) || !mustLowerCaseLetters.test(password) || !mustUpperCaseLetters.test(password) || password.length < 8)
      ) {
        newErrors.password = "invalid password"
      }
    }
    if (!reenter_password || reenter_password === "") newErrors.reenter_password = "Please enter a valid password.";
    else if (reenter_password.trim() !== password.trim()) {
      newErrors.reenter_password = `Passwords must be the same.`;
    }

    return newErrors;

  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      let errors = checkValidate();
      setErrorObject(errors);
    }
    setValidated(true)
    if (form.checkValidity() === true) {
      let errors = checkValidate();

      if (Object.keys(errors).length === 0) {
        resetUserPassword();
      } else {
        setErrorObject(errors);
      }
    }
  };

  const resetUserPassword = async () => {
    setLoading(true);
    let obj = {
      username: resetObject?.username,
      password: resetObject?.password,
      reset_string: id,
      id: resetObject?.id
    }
    const res = await postData("reset_password", {}, obj);
    if (res.status === true) {
      localStorage.clear();
      setLoading(false);
      setSuccess(true);
      toast.success(res.message, { theme: "colored" ,autoClose: autocloseTiming });
    } else {
      setLoading(false);
       toast.error(res.message, { theme: "colored", autoClose: autocloseTiming });;
    }
  };



  return (
    <>
      <div className="login-body">
        <div className="login-header">
          <img src={logo} alt="logo" />
        </div>
        <section className="login-section">
          <Container>
            <Row className="justify-content-center align-items-center">
              <Col md={5}>
                <div className="login-box">
                  <div className="content">
                    <h5 className="pb-1"> We got your back</h5>
                    <div className="sub-title mb-3">
                      Create new password
                    </div>
                    {
                      success ?
                        <div className="content-success">
                          <div className="title my-4">
                            Your password has been changed successfully. Please Sign in to continue.
                          </div>
                          <div className="text-center">
                            <NavLink to="/login">
                              <Button
                                type="submit"
                                className="btn-primary button-width text-white"
                              >
                                Sign in
                              </Button>
                            </NavLink>
                          </div>
                        </div>
                        :
                        <Form
                          noValidate
                          validated={validated}
                          onSubmit={handleSubmit}
                          className="custom-form"
                        >
                          <Form.Group className="mb-3" controlId="password">
                            <Form.Label>New Password</Form.Label>
                            <InputGroup className="toggle-password">
                              <Form.Control
                                type={newPasswordShown ? "text" : "password"}
                                placeholder="Enter Password"
                                name="password"
                                value={resetObject.password}
                                onChange={handleInput}
                                isInvalid={errorObject.password}
                                required
                              />
                              <InputGroup.Text onClick={toggleNewPasswordVisiblity}>
                                {
                                  newPasswordShown ? <FiEye /> : <FiEyeOff />
                                }
                              </InputGroup.Text>
                              {validated && errorObject.password && (
                                <div className="err-feedback">
                                  {errorObject.password === 'invalid password' ?
                                    <div className="pass-invalid">Password should contain:
                                      <li> 8 or more characters</li>
                                      <li>At least one uppercase letter</li>
                                      <li>At least one lowercase letter</li>
                                      <li>At least one special characters</li></div> : errorObject.password}
                                </div>
                              )}
                            </InputGroup>
                          </Form.Group>
                          <Form.Group className="mb-3" controlId="reenter_password">
                            <Form.Label>Confirm password</Form.Label>
                            <InputGroup className="toggle-password">
                              <Form.Control
                                name="reenter_password"
                                type={confirmPasswordShown ? "text" : "password"}
                                placeholder="Re enter your password"
                                onChange={handleInput}
                                value={resetObject.reenter_password}
                                isInvalid={errorObject.reenter_password}
                                required
                              />
                              <InputGroup.Text onClick={toggleConfirmPasswordVisiblity}>
                                {
                                  confirmPasswordShown ? <FiEye /> : <FiEyeOff />
                                }
                              </InputGroup.Text>
                              <Form.Control.Feedback type="invalid">
                                {errorObject.reenter_password}
                              </Form.Control.Feedback>
                            </InputGroup>
                          </Form.Group>
                          <div className="text-center mt-4">
                            <Button
                              type="submit"
                              disabled={loading}
                              loading={loading}
                              className="btn-primary text-white"
                            >
                              Change password
                            </Button>
                          </div>
                        </Form>
                    }

                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
      <LoginFooter />
    </>
  );
};

export default ResetPassword;
