import React, { useState, useEffect } from "react";
import "./style.scss";
import SignModal from "./SignModal";

const Signature = (props) => {
  const {
    acknowledgeShow,
    signatureObj,
    eSign,
    signatureError,
    setSignatureError,
  } = props;
  const [uploadSignShowModal, setUploadSignShowModal] = useState(false);
  const [showUploadedImage, setShowUploadedImage] = useState(null);
  const [signObject, setSignObject] = useState({
    uploadSign: "",
    drawSign: eSign,
  });

  useEffect(() => {
    setSignObject({ drawSign: eSign, uploadSign: "" });
  }, [eSign]);

  //upload sign
  const uploadSignClick = () => {
    document.body.classList.add("upload-modal-open");
    setUploadSignShowModal(true);
  };

  const uploadSignModalClose = () => {
    document.body.classList.remove("upload-modal-open");
    setUploadSignShowModal(false);
  };

  const getSignature = (data) => {
    setShowUploadedImage(data?.uploadSign[0]);
    setSignObject(data);
    if (data?.drawSign !== "" || data?.uploadSign !== "") {
      acknowledgeShow(false);
    }
    signatureObj(data);
  };

  return (
    <>
      {(signObject?.drawSign === "" && signObject?.uploadSign === "") ||
      signObject?.drawSign === undefined ? (
        <div className="sign-box" onClick={uploadSignClick}>
          <div className="title">Click to add Signature</div>
          <div className="sub-title mt-2">
            Draw or upload signature to complete
          </div>
        </div>
      ) : (
        ""
      )}

      {signObject?.drawSign !== "" && signObject?.drawSign !== undefined ? (
        <>
          <div className="text-end clear-sign mb-2" onClick={uploadSignClick}>
            Edit Signature
          </div>
          <div className="e-sign-box mb-3">
            <img
              src={signObject?.drawSign}
              alt="sign1"
              className="upload-e-sign"
            />
          </div>
        </>
      ) : signObject?.uploadSign !== "" ? (
        <>
          <div className="text-end clear-sign mb-2" onClick={uploadSignClick}>
            Edit Signature
          </div>
          <div className="e-sign-box mb-3">
            <img
              src={window.URL.createObjectURL(showUploadedImage)}
              alt="sign"
              className="upload-e-sign"
            />
          </div>
        </>
      ) : (
        ""
      )}
      {signatureError !== "" ? (
        <div className="text-error">
          <p>{signatureError}</p>
        </div>
      ) : null}
      {uploadSignShowModal && (
        <SignModal
          show={uploadSignShowModal}
          handleClose={uploadSignModalClose}
          getSignature={getSignature}
          setSignatureError={setSignatureError}
        />
      )}
    </>
  );
};

export default Signature;
