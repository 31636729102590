import React, { useState, useEffect } from "react";
import { Tab, Tabs, Row, Col, Button } from "react-bootstrap";
import "./style.scss";
import OnboardHeading from "../../../components/OnboardHeading";
import Steper from "../../../components/StepperProgress";
import AddAddress from "./AddAddress";
import HeaderForm from "../../../components/HeaderForm";
import FederalTaxSetup from "./FederalTaxSetup";
import SelectIndustry from "./SelectIndustry";
import AddBankInfo from "./AddBankInfo";
import PayrollScheme from "./PayrollScheme";
import AddSignatory from "./AddSignatory";
import { postData, getData, getRevertUser } from "../../../api";
import { toast } from "react-toastify";
import Loader from "../../../components/Loader";
import revertIcon from "../../../assets/images/fa_refresh.svg";
import {autocloseTiming} from '../../../api/regex';

const ClientOnboardingForms = () => {

    const [loading, setLoading] = useState(false);
    const [step, setStep] = useState();
    const [stepNo, setStepNo] = useState();
    const [title, setTitle] = useState();
    const [clientObject, setClientObject] = useState({})
    const [tabAddressActive, setAddressTabActive] = useState(null);
    const [tabFederalActive, setFederalTabActive] = useState(null);
    const [tabIndustryActive, setIndustryTabActive] = useState(null);
    const [tabBankInfoActive, setBankInfoTabActive] = useState(null);
    const [tabPayrollActive, setPayrollTabActive] = useState(null);
    const [tabSignatoryActive, setSignatoryTabActive] = useState(null);
    const [stepArrays, setStepArrays] = useState(['add_address', 'federal_tax', 'industry', 'bank_info', 'payroll', 'add_signatory']);
    const [newTabArray, setNewTabArray] = useState([]);
    const [stateList, setStateList] = useState();
    const [taxPayerType, setTaxPayerType] = useState();
    const [taxFillingForm, setTaxFillingForm] = useState();
    const [industryType, setIndustryType] = useState();
    const [payFrequencyList, setPayFrequencyList] = useState();
    const [firstPayList, setFirstPayList] = useState();
    const [secondPayList, setSecondPayList] = useState();
    const [titleList, setTitleList] = useState();


    useEffect(() => {
        getClientStep();
        getStaticList();
    }, []);

    useEffect(() => {
        getTabTitle(step);
        activeTabChange(step)
    }, [step]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        tabActive()
    }, [newTabArray]);// eslint-disable-line react-hooks/exhaustive-deps

    const activeTabChange = async (step) => {
        let stepIndex = null;
        for (let index = 0; index < stepArrays.length; index++) {
            const element = stepArrays[index];
            if (step === element) {
                stepIndex = index + 1
            }
        }
        let newElement = stepArrays.splice(0, stepIndex);
        setNewTabArray([...newTabArray, ...newElement]);
    }

    const tabActive = () => {
        if (newTabArray.includes('add_address')) {
            setAddressTabActive('active')
        }
        if (newTabArray.includes('federal_tax')) {
            setFederalTabActive('active')
        }
        if (newTabArray.includes('industry')) {
            setIndustryTabActive('active')
        }
        if (newTabArray.includes('bank_info')) {
            setBankInfoTabActive('active')
        }
        if (newTabArray.includes('payroll')) {
            setPayrollTabActive('active')
        }
        if (newTabArray.includes('add_signatory')) {
            setSignatoryTabActive('active')
        }
    }
    const getClientStep = async () => {
        setLoading(true);
        const res = await getData("client_onboarding_steps", {});
        if (res.status === true) {
            setStep(res.data.status);
            setLoading(false);
            setClientObject(res.data)
        } else {
             toast.error(res.message, { theme: "colored", autoClose: autocloseTiming });;
            setLoading(false);
        }
    };

    const getStaticList = async () => {
        setLoading(true);
        const res = await postData("get_constant", {});
        if (res.status === true) {
            setStateList(res.data.stateList)
            setTaxPayerType(res.data.taxPayerType)
            setTaxFillingForm(res.data.taxFillingForm)
            setIndustryType(res.data.industryType)
            setPayFrequencyList(res.data.payFrequencyList)
            setFirstPayList(res.data.firstPayList)
            setSecondPayList(res.data.secondPayList)
            setTitleList(res.data.titleList)
            setLoading(false);
        } else {
             toast.error(res.message, { theme: "colored", autoClose: autocloseTiming });;
            setLoading(false);
        }
    }

    const getTabTitle = (step) => {
        if (step === "add_address") {
            setTitle("Add addresses")
            setStepNo(1)
        } else if (step === "federal_tax") {
            setTitle("Federal tax info")
            setStepNo(2)
        } else if (step === "industry") {
            setTitle("Select industry")
            setStepNo(3)
        } else if (step === "bank_info") {
            setTitle("Add bank info")
            setStepNo(4)
        } else if (step === "payroll") {
            setTitle("Payroll details")
            setStepNo(5)
        } else if (step === "add_signatory") {
            setTitle("Add signatory")
            setStepNo(6)
        }
    };

    const nextStep = (whichStep) => {
        setStep(whichStep);
    };

    const handleRevertUser = () => {
        window.open(getRevertUser(), "_self");
    }

    return (
        <>
            <HeaderForm />
            <div className="boarding-layout board-main-section">
                <Row>
                    <Col md={getRevertUser() ? 10 : 12} sm={getRevertUser() ? 8 : 12}>
                        <OnboardHeading title="client" />
                    </Col>
                    {
                        getRevertUser() ?
                            <Col md={2} sm={getRevertUser() ? 4 : 2} className="d-flex align-items-center justify-content-end mb-4 mb-md-0">
                                <div className="revert">
                                    <Button
                                        variant="danger"
                                        className="revert_btn"
                                        onClick={handleRevertUser}
                                    >
                                        <img src={revertIcon} alt="revert" className="revert-img me-1" />
                                        Revert User
                                    </Button>
                                </div>
                            </Col>
                            : ''
                    }
                </Row>
                {!loading && (
                    <div className="create-board">
                        <div className="board-container">
                            <div className="onboard-tabs">
                                <div className="Mobi-view justify-content-start">
                                    <Steper className="Form-step" max={6} ProgressValue={stepNo} />
                                    <h4 className="ms-2 mb-0 align-self-center fw-700">{title}</h4>
                                </div>
                                <Tabs
                                    activeKey={step}
                                    onSelect={(key) => setStep(key)}
                                    className="mb-3 Desk-view"
                                >
                                    <Tab eventKey="add_address" title="Add addresses" disabled={!newTabArray.includes('add_address')} tabClassName={tabAddressActive} >
                                        <AddAddress nextStep={nextStep} onboardObject={clientObject} stateList={stateList} />
                                    </Tab>
                                    <Tab eventKey="federal_tax" title="Federal tax info" disabled={!newTabArray.includes('federal_tax')} tabClassName={tabFederalActive}>
                                        <FederalTaxSetup nextStep={nextStep} onboardObject={clientObject} taxPayerType={taxPayerType} taxFillingForm={taxFillingForm} />
                                    </Tab>
                                    <Tab eventKey="industry" title="Select industry" disabled={!newTabArray.includes('industry')} tabClassName={tabIndustryActive}>
                                        <SelectIndustry nextStep={nextStep} onboardObject={clientObject} industryType={industryType} />
                                    </Tab>
                                    <Tab eventKey="bank_info" title="Add bank info" disabled={!newTabArray.includes('bank_info')} tabClassName={tabBankInfoActive}>
                                        <AddBankInfo nextStep={nextStep} onboardObject={clientObject} />
                                    </Tab>
                                    <Tab eventKey="payroll" title="Payroll details" disabled={!newTabArray.includes('payroll')} tabClassName={tabPayrollActive}>
                                        <PayrollScheme nextStep={nextStep} onboardObject={clientObject} payFrequencyList={payFrequencyList} firstPayList={firstPayList} secondPayList={secondPayList} />
                                    </Tab>
                                    <Tab eventKey="add_signatory" title="Add signatory" disabled={!newTabArray.includes('add_signatory')} tabClassName={tabSignatoryActive}>
                                        <AddSignatory nextStep={nextStep} onboardObject={clientObject} stateList={stateList} titleList={titleList} />
                                    </Tab>
                                </Tabs>
                            </div>
                        </div>
                    </div>
                )}
                {loading && <Loader />}

            </div>
        </>

    );
};

export default ClientOnboardingForms;
