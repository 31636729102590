import React from "react";
import ReactDOM from "react-dom/client";
import "react-toastify/dist/ReactToastify.css";
import "./assets/fonts/scss/font-awesome.scss";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import Toast from "./components/Toast";
import { UserProvider } from "./hooks/Context/UserContext";
import { SignContextProvider } from "./hooks/Context/SignContext";
import { EmployeeAgreementContextProvider } from "./hooks/Context/EmployeeAgreementContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.Fragment>
    <UserProvider>
      <SignContextProvider>
        <EmployeeAgreementContextProvider>
          <App />
        </EmployeeAgreementContextProvider>
      </SignContextProvider>
    </UserProvider>
    <Toast />
  </React.Fragment>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
