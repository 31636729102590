import React, { useEffect, useState } from "react";
import { Col, Row, Container } from "react-bootstrap";
import OnboardHeading from "../OnboardHeading";
import SelectAllEmployees from "./selectAllEmployees";
import BackButtonComponent from "../backButton";
import PayPeriodFilterSection from "./payPeriodFilterSection";
import { postData } from "../../api";
import Loader from "../Loader";
import TimeDetails from "./timeDetails";

const expandDetailData = [
  {
    id: 1,
    data: "Mon 07/02",
    in: "09:30am",
    out: "09:00pm",
    regularHours: "8hrs",
    oTHours: "1hrs 30mins",
    totalHrs: "9hrs 30mins",
    statusName: "Editing Request",
    status: "editingRequest",
    address:
      " 11, Mahatma Gandhi Rd, Shastri Nagar, Adyar, Chennai, Tamil Nadu 600020, India",
    action: "",
  },
  {
    id: 2,
    data: "Tue 08/02",
    in: "09:30am",
    out: "09:00pm",
    regularHours: "8hrs",
    oTHours: "1hrs 30mins",
    totalHrs: "9hrs 30mins",
    statusName: "Approved",
    status: "approved",
    address:
      " 11, Mahatma Gandhi Rd, Shastri Nagar, Adyar, Chennai, Tamil Nadu 600020, India",
    action: "",
  },
  {
    id: 3,
    data: "Wed 09/02",
    in: "09:30am",
    out: "09:00pm",
    regularHours: "8hrs",
    oTHours: "1hrs 30mins",
    totalHrs: "9hrs 30mins",
    statusName: "Unapproved",
    status: "unapproved",
    address:
      " 11, Mahatma Gandhi Rd, Shastri Nagar, Adyar, Chennai, Tamil Nadu 600020, India",
    action: "",
  },
  // {
  //   id: 4,
  //   data: "Thu 09/02",
  //   leave: "Sick Leave",
  // },
  {
    id: 5,
    data: "Fri 09/02",
    in: "09:30am",
    out: "09:00pm",
    regularHours: "8hrs",
    oTHours: "1hrs 30mins",
    totalHrs: "9hrs 30mins",
    statusName: "Pending",
    status: "Pending",
    address:
      " 11, Mahatma Gandhi Rd, Shastri Nagar, Adyar, Chennai, Tamil Nadu 600020, India",
    action: "",
  },
];
const sampleArrayData = [
  {
    name: "Devi",
    hours: "32 hours 6 minutes",
  },
  {
    name: "Shylaja",
    hours: "32 hours 6 minutes",
  },
  {
    name: "Keerthana",
    hours: "32 hours 6 minutes",
  },
  {
    name: "Yogadurai",
    hours: "32 hours 6 minutes",
  },
  {
    name: "Guhan",
    hours: "32 hours 6 minutes",
  },
  {
    name: "Sriram",
    hours: "32 hours 6 minutes",
  },
  {
    name: "Muralitharan",
    hours: "32 hours 6 minutes",
  },
];
export default function PayPeriodSummary() {
  const [allEmployeeArray, setAllEmployeeArray] = useState([]);
  const [payPeriodData, setPayPeriodData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filterLoading, setFilterLoading] = useState(false);

  const getPayPeriodData = async () => {
    setLoading(true);
    const res = await postData("time-keeping-filter-data", {});
    if (res.status === true) {
      setPayPeriodData(res.data);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };
  useEffect(() => {
    getPayPeriodData();
  }, []);
  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <Container fluid>
          <div className="time-card-container">
            <Row>
              <Col>
                <OnboardHeading title="PAY PERIOD SUMMARY" />
                <BackButtonComponent />
                <div className="d-flex justify-content-between w-100 bg-white mt-2">
                  <Col xs={12}>
                    <PayPeriodFilterSection
                      allEmployeeArray={allEmployeeArray}
                      setAllEmployeeArray={setAllEmployeeArray}
                      payPeriodData={payPeriodData}
                      timeCardStatus={payPeriodData?.filter?.employees_type}
                      employeeData={payPeriodData?.filter?.employees}
                      activeFrequency={
                        payPeriodData?.filter?.activePayFrequencies
                      }
                      inActiveFrequency={
                        payPeriodData?.filter?.inactivePayFrequencies
                      }
                      selectFrequency={payPeriodData?.selected?.frequency}
                      setPayPeriodData={setPayPeriodData}
                      setLoading={setLoading}
                      setFilterLoading={setFilterLoading}
                      filterLoading={filterLoading}
                    />
                  </Col>
                </div>
                {filterLoading ? (
                  <Loader />
                ) : (
                  <>
                    <div className="w-100 bg-white mb-2">
                      <TimeDetails payPeriodData={payPeriodData} />
                    </div>
                    <div className="d-flex justify-content-between w-100 bg-white">
                      <Col xs={12}>
                        <SelectAllEmployees
                          expandDetailData={expandDetailData}
                          sampleArrayData={sampleArrayData}
                          setAllEmployeeArray={setAllEmployeeArray}
                          payPeriodData={payPeriodData}
                          employeeData={payPeriodData?.dataList}
                          selectFrequency={payPeriodData?.selected?.frequency}
                          setLoading={setLoading}
                        />
                      </Col>
                    </div>
                  </>
                )}
              </Col>
            </Row>
          </div>
        </Container>
      )}
    </div>
  );
}
